import "./settingsPanel.scss";
import React from "react";

import SearchIcon from "@material-ui/icons/Search";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import Spinner from "../Spinner/Spinner";

import { panelStatusSelector } from "@root/actions/attendance";
import {
  getEmployeeBySearch,
  searchSelector,
} from "@root/actions/settingsSearch";
import {
  errorSelector,
  getPromotedEmployee,
  hasEmployeePromotedSelector,
} from "@root/actions/promoteEmployee";
import { useUpdateEffect } from "react-use";
import { getSettingsList, payloadSelector } from "@root/actions/settingsTable";
import { showNotificationError } from "@root/actions/toast-notification";

const SettingsTablePanel = (props: any) => {
  const isOpen = useSelector(panelStatusSelector);
  const [searchTerm, setSearchTerm] = useState("");
  const [cell, setCell] = useState<any>();
  const listDataSelector = useSelector(searchSelector);
  const isError = useSelector(errorSelector);
  const [error, setError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const promotedEmployee = useSelector(hasEmployeePromotedSelector);
  const payload = useSelector(payloadSelector);
  const dispatch = useDispatch();

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm) {
      const fetchData = async () => {
        await dispatch(
          getEmployeeBySearch({
            term: searchTerm,
          })
        );
      };

      const timer = setTimeout(() => {
        if (searchTerm.length >= 3 || searchTerm.length === 0) {
          fetchData();
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setCell(null);
    }
  }, [searchTerm]);

  const handleValue = (event: any) => {
    if (event.key === "Enter" && searchTerm) {
      dispatch(
        getEmployeeBySearch({
          term: searchTerm,
        })
      );
    }
  };

  useEffect(() => {
    setCell(listDataSelector[0]);
  }, [listDataSelector[0]]);

  useEffect(() => {
    setError(isError);
    if (error) {
      dispatch(showNotificationError({ type: "error", message: `${error}` }));
    }
  }, [isError, error]);

  const handlePromote = (id: any, fName: string, lName: string) => {
    dispatch(getPromotedEmployee({ id: id }));
    setFirstName(fName);
    setLastName(lName);
  };

  useUpdateEffect(() => {
    if (promotedEmployee) {
      dispatch(getSettingsList({ ...payload, status: "all" }));
      dispatch(
        showNotificationError({
          type: "success",
          message: `Admin access has been granted for ${firstName} ${lastName}`,
        })
      );
    }
  }, [promotedEmployee]);

  useEffect(() => {
    if (isOpen === false) setCell([]);
    setSearchTerm("");
  }, [isOpen]);

  return (
    <>
      <div className="d-flex flex-column h-100 position-relative">
        <div className="top-wrappers">
          {/* title haeder */}
          <div className="py-2 d-flex justify-content-between align-items-center title-header">
            <p className="mb-0 font-500 title">Add Admin User</p>
          </div>

          <div className="setting-search">
            <p>Search Employee to add:</p>
            <div className=" d-flex justify-content-between align-items-center">
              <div className="input-group search-input">
                <span className="search-input-box">
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="search-text-box"
                  onKeyPress={handleValue}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by employee Name"
                />
              </div>
            </div>
            {searchTerm ? (
              <table className="table search-table">
                <tbody>
                  {cell?.map((item: any) => {
                    return (
                      <tr key={item._id}>
                        <td scope="col">
                          {item?.first_name} {item?.last_name}
                        </td>
                        <td scope="col">{item?.description}</td>

                        <button
                          className="table-btn"
                          onClick={() =>
                            handlePromote(
                              item?._id,
                              item?.first_name,
                              item?.last_name
                            )
                          }
                        >
                          Add
                        </button>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsTablePanel;
