import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { showNotificationError } from "@root/actions/toast-notification";
import { createHoliday, getHolidays } from "@root/services/holidays";

type Holiday = {
  _id: string;
  title: string;
  date: string;
  type: string;
  company_holiday: boolean;
  account_id: string;
};

export const createNewHoliday = createAsyncThunk<
  Dictionary,
  { title: string; date: string; type: string; company_holiday: boolean },
  { rejectValue: ResponseError }
>(
  "@admin/createNewHoliday",
  async ({ title, date, type, company_holiday }, thunkAPI) => {
    try {
      const response = await createHoliday(title, date, type, company_holiday);
      const errMsg: any = response.data;

      if (response.status === 200) {
        store.dispatch(
          showNotificationError({
            type: "success",
            message: "New Holiday Created.",
          })
        );
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const getAllHolidays = createAsyncThunk<
  Dictionary,
  {
    year: string;
    status: string;
    company_holiday: boolean;
    page_size: number;
    page_index: number;
  },
  { rejectValue: ResponseError }
>(
  "@admin/getAllHolidays",
  async (
    { year, status, company_holiday, page_size, page_index },
    thunkAPI
  ) => {
    try {
      const response = await getHolidays(
        year,
        status,
        company_holiday,
        page_size,
        page_index
      );
      const errMsg: any = response.data;

      if (response.status === 200) {
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const initialState: {
  list: Holiday[];
  status: string;
  error: string;
  newRecord: boolean;
} = {
  list: [],
  status: "idle",
  error: "",
  newRecord: false,
};

const holidays = createSlice({
  name: "holidays",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllHolidays.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getAllHolidays.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.list = action.payload?.data.data;
    });
    builder.addCase(getAllHolidays.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });

    builder.addCase(createNewHoliday.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.newRecord = false;
    });
    builder.addCase(createNewHoliday.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      if (action.payload?.status) state.newRecord = true;
    });
    builder.addCase(createNewHoliday.rejected, (state, { payload }) => {
      state.status = "idle";
      state.newRecord = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.holidays;

export const holidaysList = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const isNewHoliday = createDraftSafeSelector(
  selfSelect,
  (state) => state.newRecord
);
export const newHolidayLoader = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export default holidays.reducer;
