import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getProfileAndCompany } from "@root/services/settings";
import { StoreDef } from "@root/store";

const initialProfileValue: ProfileAndCompanyState = {
  account: {
    status: "",
    _id: "",
    company_name: "",
    address_1: "",
    address_2: "",
    website: "",
    code: "",
    saas_plan: "",
    brand_logo: "",
  },
  admin: {
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  },
  me: {
    first_name: "",
    last_name: "",
    custom_id: "",
    image: "",
    status: "",
    phone: "",
    email: "",
    description: "",
    department: "",
    dob: "",
    joined: "",
    _id: "",
  },
};
const initialState = {
  error: "",
  companyData: initialProfileValue,
  status: "idle",
};

export const getEmployeeProfile = createAsyncThunk<any>(
  "@admin/fetch_profile",
  async (thunkAPI) => {
    try {
      const response: any = await getProfileAndCompany();
      // console.log(response);
      return {
        companyData: response.data,
      };
    } catch (error) {
      return thunkAPI;
    }
  }
);

const getProfileSlice = createSlice({
  name: "getProfile",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployeeProfile.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getEmployeeProfile.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.companyData = action.payload;
    });
    builder.addCase(getEmployeeProfile.rejected, (state, action) => {
      state.status = "idle";

      state.error = "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.getEmployeeProfileData;

export const employeeProfileDataSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.companyData
);

export const {} = getProfileSlice.actions;

export default getProfileSlice.reducer;
