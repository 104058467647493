// LargeSidePanel

import "./largeSidePanel.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fullScreenStatus,
  panelStatusSelector,
  togglePanel,
} from "@root/actions/attendance";
import CloseIcon from "@material-ui/icons/Close";
import { resetEmpInfo } from "@root/actions/editEmployee";
import React from "react";

const LargeSidePanel = (props: any) => {
  const isOpen = useSelector(panelStatusSelector);
  const isFullScreen = useSelector(fullScreenStatus);

  let classes = "large-side-drawer d-flex flex-column align-items-end";
  classes += isOpen ? " opened" : "";
  classes += isFullScreen ? " full-screen" : "";
  const dispatch = useDispatch();
  const handleCloseClick = () => {
    dispatch(togglePanel());
    dispatch(resetEmpInfo());
  };

  return (
    <div className={classes}>
      <div className="panel-wrapper shadow">
        <button onClick={handleCloseClick} className="panel-close shadow-sm">
          <CloseIcon />
        </button>
        <div className="panel-content">{props.children}</div>
      </div>
    </div>
  );
};

export default LargeSidePanel;
