/* eslint-disable react/jsx-props-no-spreading */
// import "./publicLayout.css";
import { showNotificationError } from "@root/actions/toast-notification";
import { validateSubdomain } from "@root/services/auth";
import React from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

const PublicLayout = (props: any) => {
  const dispatch = useDispatch();
  const subdomain =
    window && window.location.host.split(".").length > 1
      ? window.location.host.split(".")[0]
      : "";

  const query = useQuery<any, any>({
    queryKey: "get-account-data",
    queryFn: () => validateSubdomain(subdomain),
    onError: (error) => {
      dispatch(
        showNotificationError({ type: "error", message: "Invalid domain" })
      );
    },
    enabled: subdomain !== process.env.REACT_APP_SUB_DOMAIN,
  });

  return (
    <>
      {/* <div className="login-wrapper">
        <div className="container-fluid login-top-sec">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-left">
              <div className="content-area">
                <img src="./assests/images/hi-icon.png" alt="hi" />
                <h1>Hello</h1>
                <h2>I am Viba</h2>
                <span>Virtual Intelligence &amp; Beloved Assistant</span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-right">
              <div className="login-inputs mx-auto">{props.children}</div>
            </div>
          </div>
        </div>
        <div className="container-fluid login-footer">
          <div className="row">
            <div className="col-lg-12">
              <a href="https://viba.ai">powered by viba</a>
            </div>
          </div>
        </div>
      </div> */}
      <div className="grid w-screen grid-cols-12">
        <div className="col-start-1 col-end-10">
          <img src={"bg.png"} className="h-screen w-full" alt="Brand Logo" />
        </div>
        <div className="col-start-10 col-end-13 flex flex-col justify-between p-4">
          <div className="pt-8">
            <div>
              {query.isSuccess ? (
                <img
                  src={
                    query.data?.data?.brand_logo
                      ? query.data?.data?.brand_logo
                      : "logo-small.png"
                  }
                  alt="logo"
                  className="w-60"
                />
              ) : (
                <img src={"logo-small.png"} alt="logo" className="w-60" />
              )}
            </div>
            <div className="pt-16">
              <h1 className="text-3xl font-semibold">Login</h1>
              <p className="pt-2 text-[#707070]">
                The pace of the next gen at your workplace today.
              </p>
              <div className="pt-20">{props.children}</div>
            </div>
          </div>
          <div className="flex items-center pt-12">
            <img src="brand-icon.png" alt="Viba Brand" className="w-8" />
            <p className="pl-2 text-black">
              <a href="https://viba.ai" target={"_blank"} rel="noreferrer">
                Powered by Viba
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicLayout;
