import axios from "axios";

export const getNoticesList = async (page_size: number, pageIndex: number) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/notices`,
    params: {
      page_index: pageIndex,
      page_size,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export type NoticeType = "announcement" | "alert" | "event" | "general" | "all";
export const getNoticesListForPanel = async (
  page_size: number,
  pageIndex: number,
  type: NoticeType
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/notices/panel`,
    params: {
      page_index: pageIndex,
      type,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const updatenotice = async (
  _id: string,
  title: string,
  active: string,
  type: string,
  highlight: boolean,
  link: string,
  message: string
) => {
  const reqObj: any = {
    title: title,
    type: type,
    active: active,
    highlight: highlight,
    link: link,
    message: message,
  };

  return await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL}/notices/${_id}`,
    data: reqObj,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const createNotice = async (
  title: string,
  active: string,
  type: string,
  highlight: string,
  link: string,
  message: string
) => {
  const reqObj: any = {
    title: title,
    type: type,
    active: active,
    highlight: highlight,
    link: link,
    message: message,
  };

  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/notices`,
    data: reqObj,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
