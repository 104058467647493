import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { getSettingsTable } from "@root/services/settings-table";
import { StoreDef } from "@root/store";

const initialState: settingsListState = {
  error: "",
  list: [],
  status: "idle",

  filterMeta: {
    status: "",
    pageIndex: 0,
    hasNext: false,
  },
};

export const getSettingsList = createAsyncThunk<
  Dictionary,
  { pageIndex: number; status: statusType },
  { rejectValue: ResponseError }
>("auth/setList", async ({ pageIndex = 0, status }, thunkAPI) => {
  const pageSize = 25;
  try {
    const response: any = await getSettingsTable(pageIndex, pageSize, status);
    return {
      pageIndex,
      list: response.data.data,

      hasNext: response.hasNext,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const settingsListSlice = createSlice({
  name: "employeeList",
  initialState,
  reducers: {
    incPageIndex(state) {
      state.filterMeta.pageIndex = state.filterMeta.pageIndex + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettingsList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getSettingsList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      if (action.payload?.pageIndex === 0) {
        state.list = [...action.payload?.list];
        state.filterMeta.pageIndex = 0;
      } else {
        state.list = [state.list, ...action.payload?.list];
      }

      state.filterMeta.hasNext = action.payload?.hasNext;
    });
    builder.addCase(getSettingsList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.settingsTable;

export const hasNextSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterMeta.hasNext
);

export const settingsListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);

export const payloadSelector = createDraftSafeSelector(selfSelect, (state) => {
  return {
    status: state.filterMeta.status,
    pageIndex: state.filterMeta.pageIndex,
  };
});

export const { incPageIndex } = settingsListSlice.actions;

export default settingsListSlice.reducer;
