import { isAdminAuthorised } from "@root/actions/auth";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";

type PrivateRouteProps = {
  component: React.ComponentType<RouteComponentProps>;
  path: string;
};

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const history = useHistory();
  const isLoggedIn = useSelector(isAdminAuthorised);
  if (!isLoggedIn) {
    localStorage.setItem("redirectTo", history.location.pathname);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // logic for authenticated user to access /app part goes here.
        // e.g. check if user is logged-in logic.
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/login"} />
        );
      }}
    />
  );
};

export default PrivateRoute;
