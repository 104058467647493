import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import {
  getSosOTPService,
  validateSosOtpService,
  disableSOSService,
} from "@root/services/sos-alert";
import { showNotificationError } from "../toast-notification";
import { toggleGlSidePanel } from "../metadata";

export const getSOSotp = createAsyncThunk<
  Dictionary,
  { phone: string },
  { rejectValue: ResponseError }
>("@admin/getsos-otp", async ({ phone }, thunkAPI) => {
  try {
    const response = await getSosOTPService(phone);
    const errMsg: any = response.data;

    if (response.status === 200) {
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const validateSOSotp = createAsyncThunk<
  Dictionary,
  { otp: string },
  { rejectValue: ResponseError }
>("@admin/validate_sos-otp", async ({ otp }, thunkAPI) => {
  try {
    const response = await validateSosOtpService(otp);
    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(toggleGlSidePanel(false));
      store.dispatch(
        showNotificationError({
          type: "success",
          message: "Message sent to employees & visitors.",
        })
      );
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const disableSOS = createAsyncThunk<
  Dictionary,
  {},
  { rejectValue: ResponseError }
>("@admin/disable_sos-otp", async ({}, thunkAPI) => {
  try {
    const response = await disableSOSService();
    const errMsg: any = response.data;

    if (response.status === 200) {
      // store.dispatch(showNotificationError({ type: "success", message: 'Message sent to employees & visitors.' }));
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const sosAlertSlice = createSlice({
  name: "sosAlert",
  initialState: {
    isOTPGen: false,
    status: "idle",
    error: "",
    isSOSEnabled: false,
  },
  reducers: {
    resetSOS: (state) => {
      state.isOTPGen = false;
      state.isSOSEnabled = false;
      localStorage.removeItem("SOS");
    },
    lockSOSScreen: (state) => {
      state.isSOSEnabled = true;
      localStorage.setItem("SOS", "enabled");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSOSotp.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.isOTPGen = false;
      state.isSOSEnabled = false;
    });
    builder.addCase(getSOSotp.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isOTPGen = action.payload?.status ? true : false;
    });
    builder.addCase(getSOSotp.rejected, (state, { payload }) => {
      state.status = "idle";
      state.isOTPGen = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });

    builder.addCase(validateSOSotp.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.isSOSEnabled = false;
    });
    builder.addCase(validateSOSotp.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isSOSEnabled = action.payload?.status ? true : false;
      if (action.payload?.status) {
        localStorage.setItem("SOS", "enabled");
      }
    });
    builder.addCase(validateSOSotp.rejected, (state, { payload }) => {
      state.status = "idle";
      state.isSOSEnabled = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });

    builder.addCase(disableSOS.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.isSOSEnabled = true;
    });
    builder.addCase(disableSOS.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isSOSEnabled = action.payload?.status ? false : true;
      state.isOTPGen = action.payload?.status ? false : true;
      if (action.payload?.status) {
        localStorage.removeItem("SOS");
      }
    });
    builder.addCase(disableSOS.rejected, (state, { payload }) => {
      state.status = "idle";
      state.isSOSEnabled = true;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.sosAlert;

export const isSOSOTPGen = createDraftSafeSelector(
  selfSelect,
  (state) => state.isOTPGen
);
export const isSOSEnabledStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isSOSEnabled
);

export const { resetSOS, lockSOSScreen } = sosAlertSlice.actions;

export default sosAlertSlice.reducer;
