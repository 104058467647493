import {
  dismissToast,
  toastMessage,
  toastType,
} from "@root/actions/toast-notification";
import { useDispatch, useSelector } from "react-redux";
// import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";
import { useEffect } from "react";
import React from "react";

const Toastify = () => {
  const toastTypeSelector = useSelector(toastType);

  const toastMessageSelector = useSelector(toastMessage);
  const dispatch = useDispatch();
  let classes = "toaster shadow-sm d-flex";
  classes += " " + toastTypeSelector;

  useEffect(() => {
    setTimeout(() => {
      dispatch(dismissToast());
    }, 10000);
  });

  const dissMiss = () => {
    dispatch(dismissToast());
  };

  return (
    <div className={classes}>
      <div className="toast-content">
        <p className="mb-0">{toastMessageSelector}</p>
        <CloseIcon onClick={dissMiss} className="close-toast" />
      </div>
    </div>
  );
};
export default Toastify;
