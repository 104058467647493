import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { genEmployeesReport } from "../../services/report";

const initialState: ReportState = {
  report: {
    processed: false,
    url: "",
    _id: "",
    type: "",
    date: "",
    __v: 0,
  },
  status: "idle",
  error: "",
  isGenerated: false,
};

export const genEmployeesReportList = createAsyncThunk<
  Dictionary,
  { type: string; custom_id?: string },
  { rejectValue: ResponseError }
>("@admin/generate_emp_report", async ({ type, custom_id }, thunkAPI) => {
  try {
    const response: any = await genEmployeesReport(type, custom_id);
    const errMsg: any = response.data;
    return response.status === 200
      ? {
          data: response.data,
          status: true,
        }
      : { status: false, error: errMsg };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const empReportSlice = createSlice({
  name: "empreport",
  initialState,
  reducers: {
    resetEmpReporGenStatus: (state) => {
      state.isGenerated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(genEmployeesReportList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.isGenerated = false;
    });
    builder.addCase(genEmployeesReportList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isGenerated = action.payload.status;
      state.report = { ...action.payload.data };
    });
    builder.addCase(genEmployeesReportList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
      state.isGenerated = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.empreport;

export const employeeReportStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const employeeReportGenStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isGenerated
);
export const employeeErrorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const employeeFetchedReport = createDraftSafeSelector(
  selfSelect,
  (state) => state.report
);

const { resetEmpReporGenStatus } = empReportSlice.actions;

export default empReportSlice.reducer;
