import axios from "axios";

export const getTempUsers = async (
  date: string,
  page_size: number,
  page_index: number
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/meetings/high-temp/users`,
    params: {
      date,
      page_size,
      page_index,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getTempVisitors = async (
  date: string,
  page_size: number,
  page_index: number
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/meetings/high-temp/visitors`,
    params: {
      date,
      page_size,
      page_index,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
