import { TextareaAutosize } from "@material-ui/core";
import "./sendmsg.scss";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  msgFlagSelector,
  sendMessageTo,
  updateMsgFlag,
} from "@actions/sendMessage";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useState } from "react";
import { panelStatusSelector } from "@root/actions/attendance";
import React from "react";

const SendMessage = (props: any) => {
  const isMsgSent = useSelector(msgFlagSelector);
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const onTextChange = (e: React.SyntheticEvent) => {
    let target = e.target as HTMLInputElement;
    setText(target.value);
  };

  const onSubmitHandle = () => {
    dispatch(sendMessageTo({ _id: props.id, msg: text }));
    dispatch(updateMsgFlag(true));
    setText("");
    setTimeout(() => {
      dispatch(updateMsgFlag(false));
    }, 1000);
  };

  return (
    <>
      <div className={`send-message ${props._class}`}>
        <div className="msg-box">
          <div className="flex-grow-1 text-center">
            <h6>Send SMS</h6>
          </div>

          <div>
            <button className="msg-close" onClick={() => props.onClick()}>
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className="msg-container">
          <div className="msg-form">
            <div>
              <h6 className="mb-0">Message</h6>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={7}
                className="text-msg-area"
                placeholder="Start Typing..."
                value={text}
                onChange={onTextChange}
              />
              <button
                onClick={onSubmitHandle}
                className="btn btn-gradient mt-1"
              >
                Send
              </button>
            </div>
            {isMsgSent ? (
              <div className="msgSent-notification d-flex justify-content-center align-items-center flex-column">
                <div className="success-icon">
                  <CheckCircleIcon />
                </div>
                <h6 className="text-center">Message Sent</h6>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessage;
