import { useEffect, useState } from "react";
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setTitle,
  updateDatePickerStatus,
  updateEmpListFilterStatus,
  updatesendLinkStatus,
} from "@root/actions/metadata";
import {
  employeeProfileDataSelector,
  getEmployeeProfile,
} from "@root/actions/profileAndCompany";
// import { useUpdateEffect } from "react-use";
import moment from "moment";
import { employeeRoleSelector } from "@root/actions/auth";
import { closeFilter, closePanel } from "@root/actions/attendance";
// import { currentUserSelector, getCurrentUserInfo } from "@root/actions/getCurrentUser";
// import { startDateSelector, startDateStringSelector, setStartDate } from "@root/actions/header";
import React from "react";

const Profile = (props: any) => {
  const employeerole = useSelector(employeeRoleSelector);
  const [profile, setProfile] = useState<any>();
  const employeeProfile = useSelector(employeeProfileDataSelector);
  const roleSelector = useSelector(employeeRoleSelector);
  const [userRole, setUserRole] = useState(roleSelector);
  // const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle("My Profile"));
    dispatch(updateDatePickerStatus(false));
    dispatch(updateEmpListFilterStatus(false));
    dispatch(updatesendLinkStatus(false));

    dispatch(getEmployeeProfile());
    dispatch(closePanel(false));
    dispatch(closeFilter(false));
    // dispatch(getCurrentUserInfo())
    const role = localStorage.getItem("Role");
    if (!userRole) {
      setUserRole(role!);
    }
  }, []);

  useEffect(() => {
    setProfile(employeeProfile);
  }, [employeeProfile]);

  const getRole = (role: string) => {
    let _role;
    switch (role) {
      case "admin":
        _role = "Administrator";
        break;
      case "moderator":
        _role = "Moderator";
        break;
      case "employee":
        _role = "Employee";
        break;
      default:
        break;
    }
    return _role;
  };
  // console.log(employeeProfile, profile, employeerole)
  const getDateFormat = (timeString: string) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const time = new Date(timeString);
    return `${time.getDate()}-${months[time.getMonth()]}-${time.getFullYear()}`;
  };
  const getSaasEndPlan = (timeString: string) => {
    const today = moment(new Date(timeString)).add(30, "day").toDate();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // const time = new Date(timeString);
    return `${today.getDate()}-${
      months[today.getMonth()]
    }-${today.getFullYear()}`;
  };

  return (
    <div className="profile-container">
      <div className="d-flex">
        <div className="profile-col">
          <div className="card vba-profile-card vba-user-profile">
            <div className="card-header bg-white border-0">
              <h5 className="text-white">{getRole(userRole)}</h5>
              {/* <h5>{employeerole === 'admin' ? 'Administrator' : 'Moderator'}</h5> */}
              <div className="vba-profile-avatar">
                <img src={profile?.companyData?.me?.image} alt="profile" />
                <h2>
                  {profile?.companyData?.me?.first_name}{" "}
                  {profile?.companyData?.me?.last_name}
                </h2>
                <span>{profile?.companyData?.me?.custom_id}</span>
                {profile?.companyData?.me?.status === "approved" ? (
                  <a href="#" className="btn-active">
                    Active
                  </a>
                ) : (
                  <a href="#" className="btn-active">
                    InActive
                  </a>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h5>Phone</h5>
                  <span>
                    +
                    {`${profile?.companyData?.me?.phone.slice(
                      0,
                      2
                    )} ${profile?.companyData?.me?.phone.slice(
                      2,
                      7
                    )} ${profile?.companyData?.me?.phone.slice(7)}`}
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h5>Email</h5>
                  <span>{profile?.companyData?.me?.email}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h5>Title</h5>
                  <span>{profile?.companyData?.me?.description}</span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h5>Department</h5>
                  <span>{profile?.companyData?.me?.department}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h5>Date of Birth</h5>
                  <span>{getDateFormat(profile?.companyData?.me?.dob)}</span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h5>Date of Joining</h5>
                  <span>{getDateFormat(profile?.companyData?.me?.joined)}</span>
                </div>
              </div>
            </div>
            <div className="card-footer bg-white border-0">
              <p>
                <img
                  src="../../../../assests/images/info-icon-b.png"
                  alt="info"
                />
                Contact your super admin to update or change or update your
                profile
              </p>
            </div>
          </div>
        </div>

        <div className="flex-grow-1 " id="brand-col">
          <div className="card vba-profile-card vba-company-profile">
            <div className="card-header bg-white border-0">
              <h5>My Company Profile</h5>
              <div className="vba-profile-avatar">
                <img
                  src={profile?.companyData?.account.brand_logo}
                  alt="profile"
                />
                <h2 className="text-uppercase">
                  {profile?.companyData?.account.company_name}
                </h2>
                <span>{profile?.companyData?.account.code}</span>
                <a href="#" className="btn-active">
                  {profile?.companyData?.account.status.charAt(0).toUpperCase()}
                  {profile?.companyData?.account.status.slice(1)}
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h5>Address</h5>
                  <span>
                    {profile?.companyData?.account.address_1}{" "}
                    {profile?.companyData?.account.address_2}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Website</h5>
                  <span>{profile?.companyData?.account.website}</span>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <h5>Phone</h5>
                            <span>{profile?.companyData?.account.phone}</span>
                        </div> */}
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Subscription Plan</h5>
                  <span>{profile?.companyData?.account.saas_plan}</span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Subscription Start Date</h5>
                  <span>{getDateFormat(profile?.companyData?.me.joined)}</span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Subscription End Date</h5>
                  <span>{getSaasEndPlan(profile?.companyData?.me.joined)}</span>
                </div>
              </div>
              <div className="row">
                <h4>SUPER ADMIN DETAILS</h4>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Name</h5>
                  <span>
                    {profile?.companyData?.admin.first_name}
                    {profile?.companyData?.admin.last_name}
                  </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Email</h5>
                  <span>{profile?.companyData?.admin.email}</span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h5>Phone</h5>
                  <span>
                    +
                    {`${profile?.companyData?.admin.phone.slice(
                      0,
                      2
                    )} ${profile?.companyData?.admin.phone.slice(
                      2,
                      7
                    )} ${profile?.companyData?.admin.phone.slice(7)}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
