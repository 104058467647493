import "./filterPanel.scss";
import React from "react";

import { useSelector } from "react-redux";
import { filterStatusSelector } from "@root/actions/attendance";

const FilterPanel = (props: any) => {
  const openFilter = useSelector(filterStatusSelector);
  let classes = "filter-container";
  classes += openFilter ? " opened" : "";

  return (
    <>
      <div className={classes}>{props.children}</div>
    </>
  );
};

export default FilterPanel;
