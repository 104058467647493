import { useSelector } from "react-redux";
import { TbBeach, TbPlugConnected, TbReport } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { toggleStatus } from "@actions/sidebar";
import { IoSettingsSharp } from "react-icons/io5";
import "./Sidebar.scss";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import { currentUser, getCurrentUser } from "@root/actions/header";
import {
  FaTemperatureLow,
  FaUserTag,
  FaRegIdBadge,
  FaWalking,
} from "react-icons/fa";
import { MdMobileFriendly } from "react-icons/md";
import { ImQrcode } from "react-icons/im";

const Sidebar = (props: any) => {
  // const userrole = localStorage.getItem('Role')!;
  const isOpen = useSelector(toggleStatus);
  const dispatch = useDispatch();
  // const history = useHistory();
  const loggedInUser = useSelector(currentUser);
  const roleSelector = loggedInUser.role;

  let classes = "sidebar h-100 d-flex flex-column";
  classes += isOpen ? " expanded" : "";
  const navLinks = [
    // { path: "dashboard", name: "Dashboard", icon: <DashboardIcon /> },
    {
      isEmpAllowed: true,
      path: "my-attendance",
      name: "My Attendance",
      icon: <FaUserTag className="text-2xl" />,
    },
    {
      isEmpAllowed: true,
      path: "pre-invitations",
      name: "My Pre-Invitations",
      icon: <ImQrcode className="text-2xl" />,
    },
    // {
    //   isEmpAllowed: true,
    //   path: "holidays",
    //   name: "Holidays",
    //   icon: <TbBeach className="text-2xl" />,
    // },
    {
      isEmpAllowed: true,
      path: "integrations",
      name: "Integrations",
      icon: <TbPlugConnected className="text-2xl" />,
    },
    {
      isEmpAllowed: false,
      path: "attendance",
      name: "Employees Attendance",
      icon: <FaRegIdBadge className="text-2xl" />,
    },
    {
      isEmpAllowed: false,
      path: "employees",
      name: "Mobile App Requests",
      icon: <MdMobileFriendly className="pr-1 text-2xl" />,
    },
    {
      isEmpAllowed: false,
      path: "visitors",
      name: "Visitors",
      icon: <FaWalking className="text-2xl" />,
    },
    {
      isEmpAllowed: true,
      path: "abnormalTemperatureEmployee",
      name: "Abnormal Temperature",
      icon: <FaTemperatureLow className="pl-1 text-2xl" />,
      roles: ["admin", "moderator"],
    },
    {
      isEmpAllowed: true,
      path: "settings",
      name: "Settings",
      icon: <IoSettingsSharp className="text-2xl" />,
      roles: ["admin", "moderator"],
    },
    {
      isEmpAllowed: false,
      path: "reports",
      name: "Reports",
      icon: <TbReport className="text-2xl" />,
    },
  ]
    .filter((link) => {
      // Check for roles
      if (Array.isArray(link.roles) && link.roles.length > 0) {
        return link.roles.includes(roleSelector);
      }
      // If no explict role mentioned, treat it as employee
      return true;
    })
    .filter((link) => {
      // Check for saas_plan
      if (loggedInUser.account.subscription === "visitors_only") {
        return [
          "Visitors",
          "Abnormal Temperature",
          "Settings",
          "Reports",
          "Mobile App Requests",
        ].includes(link.name);
      }
      return true;
    });

  // const handleLogout = (event: any) => {
  //   event.preventDefault();
  //   dispatch(logoutSuccess());

  //   history.push("/");
  //   localStorage.clear();
  //   sessionStorage.clear();
  // };

  // const handleSOS = () => {
  //   dispatch(toggleSOS(true));
  // }

  // const handleclick= (event:any)=>{
  //      event.preventDefault();
  //      history.push('/profile')
  // }

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <>
      <div className={classes}>
        <div className="bar-header">
          <div className="brand py-3 px-2">
            <div className="when-closed h-12">
              <img
                src={`${
                  loggedInUser?.account?.saas_plan === "startup"
                    ? "../../../../brand-icon.png"
                    : loggedInUser?.account?.brand_logo
                } `}
                alt="Brand logo"
                className="img-fluid"
              />
            </div>
            <div className="when-opened h-12">
              <img
                src={`${
                  loggedInUser?.account?.saas_plan === "startup"
                    ? "../../../../logo-small.png"
                    : loggedInUser?.account?.brand_logo
                }`}
                alt="Brand logo"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="links-wrapper flex-grow-1 d-flex flex-column justify-content-between">
          <div className="navigations">
            {roleSelector && (
              <ul className="nav flex-column">
                {navLinks.map((link) => {
                  if (roleSelector === "employee" && !link.isEmpAllowed) {
                    return null;
                  } else {
                    return (
                      <li key={link.name} className="navigation-link">
                        <Tooltip
                          title={link.name}
                          placement="right"
                          color="secondary"
                          arrow
                        >
                          <NavLink activeClassName="active-link" to={link.path}>
                            <div className="d-flex link-content align-items-center">
                              <div className="icon">{link.icon}</div>
                              <div className="link-name when-opened">
                                <p className="mb-0">{link.name}</p>
                              </div>
                            </div>
                          </NavLink>
                        </Tooltip>
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          </div>

          <div className="bottom-links pb-3">
            <div className="when-closed">
              {loggedInUser?.account?.saas_plan === "growth" ? (
                <a
                  style={{ lineHeight: 1 }}
                  href="https://viba.ai"
                  target="_blank"
                  className="d-flex justify-content-center align-items-center mt-3 text-white"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "36px", height: "36px" }}
                    src="../../../../assests/images/logo-small.png"
                    alt="Brand logo"
                    className="img-fluid"
                  />
                </a>
              ) : null}
            </div>
            <div className="when-opened">
              {/* loggedInUser?.account?.saas_plan === 'startup' || loggedInUser?.account?.saas_plan === 'growth' */}
              {loggedInUser?.account?.saas_plan === "growth" ? (
                <a
                  style={{ lineHeight: 1 }}
                  href="https://viba.ai"
                  target="_blank"
                  className="d-flex justify-content-center align-items-center mt-3 text-white"
                  rel="noreferrer"
                >
                  <p className="mb-0 text-center text-white">Powered by Viba</p>
                </a>
              ) : null}
              {/* <div className="user-card">
                <div className="card flex-row p-2">
                  <div className="user-avatar d-flex align-items-center">
                    <div>
                      <img
                      onClick={handleclick}
                      //@ts-ignore
                        src={loggedInUser.image}
                        alt="User"
                        className="img-fluid  profile-pointer"
                      />
                    </div>
                  </div>
                  <div className="card-content pl-2">
                    <p className="mb-0 profile-pointer"  onClick={handleclick} >{loggedInUser.first_name} {loggedInUser.last_name}</p>
                    <button className="logout-link" onClick={handleLogout}>
                      Signout
                    </button>
                  </div>
                </div>
                <p className="mt-3 mb-0 text-white text-center"  >
                  Powered by <a href="https://ideeo.io/" target="_blank" className="text-white">Viba</a> 
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
