import axios from "axios";

// {{base_url}}/accounts/settings
export const getSettings = async () => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/accounts/settings`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

type ocHours = {
  hour: number;
  minute: number;
  type: string;
  grace_minutes: number;
  mode: number;
};
type cHours = { hour: number; minute: number; type: string; mode: number };

export const updateSettings = async (
  business_days: string[],
  default_checkin_mode: string,
  opens_at: ocHours,
  closes_at: cHours
) => {
  return await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL}/accounts/settings`,
    data: {
      business_days,
      default_checkin_mode,
      opens_at,
      closes_at,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export async function getProfileAndCompany() {
  const response = await axios.get<ProfileAndCompanyState>(
    `${process.env.REACT_APP_BASE_URL}/users/me-and-company`,
    {
      validateStatus: (status) => {
        return status >= 200 && status <= 404;
      },
    }
  );
  return response.data;
}

export async function getSettingsSearch(term: string) {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/users/search-employees`,
    params: {
      term,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
}

// update visitors question settings
export const updateVisitorQuestions = (
  purpose: boolean,
  otp: boolean,
  phone: boolean,
  signature: boolean
) => {
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}/accounts/visitor-questions`,
    {
      purpose,
      otp,
      phone,
      signature: signature ?? false,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};
