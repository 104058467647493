import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { sendMessage } from "@root/services/send-sms";

export const sendMessageTo = createAsyncThunk<
  Dictionary,
  { _id: string; msg: string },
  { rejectValue: ResponseError }
>("@admin/send_message", async ({ _id, msg }, thunkAPI) => {
  const pageSize = 25;
  try {
    // console.log(_id, msg);
    // const response: any = await sendMessage(_id, msg);
    // console.log(response.data);
    return {
      msgSent: "response.data",
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const sendMessageSlice = createSlice({
  name: "sendmessage",
  initialState: {
    error: "",
    msgSent: false,
    status: "idle",
    responseData: "",
  },
  reducers: {
    updateMsgFlag: (state, action) => {
      state.msgSent = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendMessageTo.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(sendMessageTo.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.msgSent = true;
      state.responseData = action.payload.msg;
    });
    builder.addCase(sendMessageTo.rejected, (state, action) => {
      state.status = "idle";
      state.msgSent = false;
      state.error =
        action.payload!.response!.data!.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.sendmessage;

export const msgFlagSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.msgSent
);

export const { updateMsgFlag } = sendMessageSlice.actions;

export default sendMessageSlice.reducer;
