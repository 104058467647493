import { useDispatch, useSelector } from "react-redux";
// import { useUpdateEffect } from 'react-use';
import "./profileDropdown.scss";
import { toggleSOS, toggleNotices } from "@root/actions/sidebar";
import { toggleGlSidePanel } from "@root/actions/metadata";
import { useUnmount } from "react-use";
import { Avatar } from "@mui/material";
import { useDisclosure } from "@mantine/hooks";
import SidePanelDrawer from "../SidePanel/SidePanelDrawer";
import ProfileCard from "./ProfileCard";
import { getProfileAndCompany } from "@root/services/settings";
import { useQuery } from "react-query";
import { FaFireExtinguisher, FaStickyNote } from "react-icons/fa";
import { currentUser } from "@root/actions/header";

const ProfileDropdown = (props: any) => {
  const user = props.user;
  const dispatch = useDispatch();
  const loggedInUser = useSelector(currentUser);
  const [isProfileSidebarOpen, profileSidebarHandler] = useDisclosure(false);
  const meAndCompanyQuery = useQuery<ProfileAndCompanyState>({
    queryKey: "getProfileAndCompany",
    queryFn: getProfileAndCompany,
  });

  const handleSOS = () => {
    dispatch(toggleGlSidePanel(true));
    dispatch(toggleSOS(true));
  };

  const handleNotices = () => {
    dispatch(toggleGlSidePanel(true));
    dispatch(toggleNotices(true));
  };

  useUnmount(() => {
    window.removeEventListener("click", () => {
      console.log("Removed");
    });
  });

  return (
    <>
      <div className="mr-2 flex items-center space-x-6">
        {user.role !== "employee" && (
          <button
            onClick={handleSOS}
            className="flex items-center justify-center text-[#F00]"
          >
            <FaFireExtinguisher className="mr-2 text-xl" />
            SOS
          </button>
        )}
        {/* {loggedInUser.account.subscription === "visitors_only" ? null : (
          <button
            onClick={handleNotices}
            className="flex items-center justify-center"
          >
            <FaStickyNote className="mr-2 text-xl" />
            eNotices
          </button>
        )} */}
        <button
          className="flex items-center justify-center"
          onClick={profileSidebarHandler.open}
        >
          <Avatar
            alt={`${user.first_name} ${user.last_name}`}
            src={user?.image}
          />
        </button>
      </div>
      {
        <SidePanelDrawer
          isOpen={isProfileSidebarOpen}
          isFullScreen={false}
          handleCloseClick={profileSidebarHandler.close}
          className="m-0 p-0"
        >
          {isProfileSidebarOpen && meAndCompanyQuery.isSuccess ? (
            <ProfileCard user={user} meAndCompanyQuery={meAndCompanyQuery} />
          ) : null}
        </SidePanelDrawer>
      }
    </>
  );
};

export default ProfileDropdown;
