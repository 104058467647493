import axios from "axios";

export const googleAuthUrl = async () => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/auth/google/url`,
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};

export const microsoftAuthUrl = async () => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/auth/microsoft/url `,
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};

export const disconnectIntegration = async (
  integration: "google" | "microsoft"
) => {
  return await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL}/auth/disconnect/${integration} `,
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};
