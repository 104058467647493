import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { getUnknownVisitors } from "@root/services/unknown-visitors";
import { showNotificationError } from "@root/actions/toast-notification";

type Invitations = {
  _id: string;
  name?: string;
  phone?: string;
  admin_comment: string;
  created_on: string;
  image: string;
  clockin?: _vClockin;
};

type _vClockin = {
  temperature: {
    value: number;
    measure: string;
  };
};

export const getUnknownVisitorsList = createAsyncThunk<
  Dictionary,
  { from_date: string; to_date: string; page_size: number; page_index: number },
  { rejectValue: ResponseError }
>(
  "@unknown-Visitors",
  async ({ from_date, to_date, page_size, page_index }, thunkAPI) => {
    try {
      const response = await getUnknownVisitors(
        from_date,
        to_date,
        page_size,
        page_index
      );
      const errMsg: any = response.data;

      return response.status === 200
        ? {
            data: response.data,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const initialState: {
  list: Invitations[];
  status: string;
  error: string;
  total: number;
  filterMeta: any;
  newRecord: boolean;
  recordUpdated: boolean;
} = {
  list: [],
  status: "idle",
  error: "",
  total: 0,
  filterMeta: {
    status: null,
    pageIndex: 0,
    hasNext: false,
  },
  newRecord: false,
  recordUpdated: false,
};

const unknownVisitors = createSlice({
  name: "unknownVisitors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get unknown visitors
    builder.addCase(getUnknownVisitorsList.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getUnknownVisitorsList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.list = [...action.payload?.data.data];
      state.filterMeta.hasNext = action.payload?.data.hasNext;
      state.total = action.payload?.data.total;
    });
    builder.addCase(getUnknownVisitorsList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.unknownVisitors;

export const unknownVisitorsList = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const filterMetaSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterMeta
);
export const totalUnknownVisistors = createDraftSafeSelector(
  selfSelect,
  (state) => state.total
);
export const unknownVisitorsError = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const newRecordFetch = createDraftSafeSelector(
  selfSelect,
  (state) => state.newRecord
);
export const recordUpdated = createDraftSafeSelector(
  selfSelect,
  (state) => state.recordUpdated
);
export const preInvLoader = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export default unknownVisitors.reducer;
