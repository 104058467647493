import { Pagination, Tooltip } from "@mui/material";
import {
  closePanel,
  panelStatusSelector,
  togglePanel,
} from "@root/actions/attendance";
import { startDateStringSelector } from "@root/actions/header";
import {
  setTitle,
  toggleFutureDates,
  updateDatePickerStatus,
} from "@root/actions/metadata";
import EmployeeNameWithProfileRenderer from "@root/components/EmployeeNameWithProfileRenderer/EmployeeNameWithProfileRenderer";
import SidePanel from "@root/components/SidePanel/SidePanel";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUnmount, useUpdateEffect } from "react-use";
import Select, { StylesConfig } from "react-select";
import "./preInvitations.scss";
import {
  totalInvitations,
  getPreInvitationsList,
  preInvitationList,
  newRecordFetch,
  recordUpdated,
} from "@root/actions/preInvitations";
// import VisitorNameWithProfile from '@root/components/EmployeeNameWithProfileRenderer/VisitorNameWithProfile';
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import NewInvitation from "@root/components/NewInvitation";
// import EditInvitation from '@root/components/EditInvitation';
import { showNotificationError } from "@root/actions/toast-notification";
import InfoAlert from "@root/components/InfoAlert";
import Spinner from "@root/components/Spinner/Spinner";
import FullscreenButton from "@root/components/FullscreenButton";
import FullscreentTitle from "@root/components/FullscreentTitle";
import CAlert from "@root/components/CAlert";

export const colourStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    height: "54px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      minWidth: "200px",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#fff"
        : isFocused
        ? "#fff"
        : "#fff",
      color: isDisabled ? "#000" : isSelected ? "#000" : "#000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#fff"
            : "#fff"
          : undefined,
        color: "#000",
      },
    };
  },
  input: (styles) => ({
    ...styles,
    color: "#000",
    minWidth: "150px",
    height: "54px",
  }),
  placeholder: (styles) => ({ ...styles, color: "#000" }),
  singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
  menu: (styles) => ({ ...styles, backgroundColor: "#fff" }),
};

const PreInvitations = () => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState<any>();
  const preInvitations = useSelector(preInvitationList);
  const startDate = useSelector(startDateStringSelector);
  const rowClass = "minin-class";
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const totalRecords = useSelector(totalInvitations);
  const [visitorId, setvisitorId] = useState("");
  const [selectedVisitor, setSelectedVisitor] = useState({
    _id: "",
    name: "",
    phone: "",
    purpose: "",
    start: "",
    end: "",
  });
  const isNewRecord = useSelector(newRecordFetch);
  const isRecordUpdated = useSelector(recordUpdated);
  const isOpen = useSelector(panelStatusSelector);
  const dropdown = [
    { value: "all", label: "All" },
    { value: "next", label: "Next in-Line" },
    { value: "in", label: "Checkedin" },
    { value: "out", label: "Checkedout" },
  ];
  const [filterOn, setFilterOn] = useState("all");
  const [filterLable, setFilterLable] = useState("All");
  const [isNew, setIsNew] = useState(false);
  const [isView, setIsView] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const coldefss = [
    {
      field: "name",
      headerName: "Visitor Name & Mobile",
      sortable: true,
      width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <div className="profile-picture">
              <img
                src={
                  params.data.image !== undefined
                    ? params.data.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            <div className="pr-3">
              <p className="mb-1"> {params.data.name} </p>
              <p className="mb-0">
                <small>{`+${params?.data.phone?.slice(
                  0,
                  2
                )} ${params?.data.phone?.slice(
                  2,
                  7
                )} ${params?.data.phone?.slice(7)}`}</small>
              </p>
            </div>
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },

    {
      field: "checkin",
      headerName: "TEMPERATURE [F]",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            {params.temperature ? (
              params.data.temperature.measure === "F" ? (
                <p
                  className={`line-height1 mb-0 ${
                    params.data.temperature.value > 99
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data.temperature.value}${"\u00b0"} ${
                  params.data.temperature.measure
                }`}</p>
              ) : (
                <p
                  className={`line-height1 mb-0 ${
                    params.data.temperature.value > 37.2
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data.temperature.value}${"\u00b0"} ${
                  params.data.temperature.measure
                }`}</p>
              )
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "PURPOSE",
      cellRendererFramework: (params: any) => {
        if (params.data.purpose) {
          return (
            <>
              <p className="mb-0">{params.data.purpose}</p>
            </>
          );
        }
        return "--";
      },
    },
    {
      field: "start",
      headerName: "APPOINTMENT TIME",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {params.data && params.data?.start
              ? getTimeFormat(params.data?.start)
              : "--"}{" "}
            -{" "}
            {params.data && params.data?.end
              ? getTimeFormat(params.data?.end)
              : "--"}
          </p>
        );
      },
    },
    {
      field: "",
      headerName: "CURRENT STATUS",
      cellRendererFramework: (params: any) => {
        if (params.data.clockin) {
          return params.data.clockin?.clocked_out_at ? (
            <>
              <div className="checkin-indicator">
                <DirectionsWalkIcon className="out checkdirection" />
                <p className="mb-0">Checkedout</p>
              </div>
            </>
          ) : (
            <div className=" checkin-indicator">
              <DirectionsWalkIcon className="checkdirection" />
              <p className="mb-0">Checkedin</p>
            </div>
          );
        }
        return "Scheduled"; //add change -- to Scheduled in pre-invitations
      },
    },
    // {
    //   field: "end",
    //   headerName: "END-TIME",
    //   cellRendererFramework: (params: any) => {
    //     return (
    //       <p className="mb-0 line-height1">
    //         {params.data && params.data?.end
    //           ? getTimeFormat(params.data?.end)
    //           : "--"}
    //       </p>
    //     );
    //   },
    // },
    {
      field: "checkin",
      headerName: "IN-TIME",
      sortable: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <div className="d-flex flex-column">
              <p className="line-height1 mb-2">
                {params.data.clockin
                  ? getTimeFormat(params.data?.clockin?.clocked_in_at)
                  : "--"}
              </p>
              {/* {params.data ? (
                  params.data?.clocked_in_status === "late" ? (
                    <p className="mb-0 text-danger line-height1">Late ClockIn</p>
                  ) : null
                ) : null} */}
            </div>
          </>
        );
      },
    },

    {
      field: "checkin",
      headerName: "OUT-TIME",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {params.data && params.data.clockin?.clocked_out_at
              ? getTimeFormat(params.data.clockin?.clocked_out_at)
              : "--"}
          </p>
        );
      },
    },
    {
      field: "checkin",
      headerName: "INVITATION TITLE",
      cellRendererFramework: (params: any) => {
        const trimTitle = (title: string) => {
          return title.length > 20 ? `${title.slice(0, 20)}...` : title;
        };
        return (
          <p className="line-height1 mb-0">
            {params.data?.title ? trimTitle(params.data?.title) : "--"}
            <span style={{ display: "block", fontSize: "12px" }}>
              {params.data ? params.data?.purpose : "--"}
            </span>
          </p>
        );
      },
    },

    {
      field: "",
      headerName: "ACTIONS",
      cellRendererFramework: (params: any) => {
        return (
          <>
            {params.data?.cancelled ? (
              "Cancelled"
            ) : (
              <button
                className="grid-action"
                onClick={() => {
                  handleVisitorViewclick(params.data?._id);
                }}
              >
                View
              </button>
            )}
          </>
        );
      },

      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <span className="pr-4">Actions</span>
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];

  const [colDefs, setColDefs] = useState(coldefss);

  const onTableFUllScreen = () => {
    setTableFullScreen(!tableFullScreen);
  };

  useEffect(() => {
    // const today = new Date();
    dispatch(setTitle("My Pre-Invitations"));
    dispatch(updateDatePickerStatus(true));
    dispatch(toggleFutureDates(true));
    dispatch(closePanel(false));
  }, []);

  useUnmount(() => {
    dispatch(updateDatePickerStatus(false));
    dispatch(closePanel(false));
    dispatch(toggleFutureDates(false));
  });

  useEffect(() => {
    fetchInvitationList();
  }, [startDate, pageSize, pageIndex]);

  useUpdateEffect(() => {
    setRowData(preInvitations);
    setColDefs(coldefss);
  }, [preInvitations, tableFullScreen]);

  useEffect(() => {
    if (isNewRecord || isRecordUpdated) {
      fetchInvitationList();
      // dispatch(showNotificationError({ type: "success", message: 'Invitation Created.'}));
    }
  }, [isNewRecord, isRecordUpdated]);

  const fetchInvitationList = () => {
    dispatch(
      getPreInvitationsList({
        date: startDate,
        status: filterOn,
        page_size: pageSize,
        page_index: pageIndex,
      })
    );
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const processCells = (params: any) => {
    if (params.accumulatedRowIndex === 0) {
      return params.node.data.name;
    } else return params.value;
  };
  const getParams = () => {
    return {
      // @ts-ignore
      processCellCallback: (params) => processCells(params),
    };
  };
  const onBtnExport = () => {
    // @ts-ignore
    gridApi.exportDataAsCsv(getParams());
  };

  const getRowClass = (params: any) => {
    if (params.data?._id === visitorId) {
      return "active";
    }
  };

  const onPageSizeChanged = () => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      ?.value;
    //@ts-ignore
    gridApi?.paginationSetPageSize(Number(value));
    setPageSize(Number(value));
  };

  const onPageNum = (e: any, num: any) => {
    setPageIndex(num - 1);
  };

  const handleFilter = (event: any) => {
    setFilterOn(event?.value);
    setFilterLable(event?.label);
  };

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);

    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  const onCreateNew = () => {
    dispatch(togglePanel());
    setIsView(false);
    setIsNew(true);
  };

  const onCancle = () => {
    dispatch(togglePanel());
    setIsView(false);
    setIsNew(false);
  };

  const handleVisitorViewclick = (id: string) => {
    setIsNew(false);
    setIsView(true);
    const selVisitor = preInvitations?.find((el: any) => el._id === id);
    if (selVisitor !== undefined) setSelectedVisitor(selVisitor);
    setTableFullScreen(false);
    dispatch(togglePanel());
    // dispatch(getVisitorByID({ _id: id, _date: startDate }));
    // dispatch(getVisitorActivityById({_id: id, page_index: 0}))
  };

  return (
    <>
      <div className="data-table h-100 p-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Select
            className="drop-select"
            styles={colourStyles}
            options={dropdown}
            isSearchable={false}
            value={{
              value: `${filterOn}`,
              label: filterLable,
            }}
            onChange={handleFilter}
          />
          <div className="flex-grow-1 d-flex justify-content-end">
            {/* <Tooltip
                          title={'Export to Excel'}
                          placement="bottom"
                          color="secondary"
                          arrow
                          >
                          <button className="export-btn export-border" style={{height: '54px'}}>
                          <img
                              src="../../../../assests/images/excel-file.png"
                              alt="icon"
                              className="m-0"
                              onClick={() => onBtnExport()}
                          />
                          </button>
                      </Tooltip> */}
            <div className="pl-3">
              <button
                className="btn btn-gradient"
                style={{ minWidth: "140px", height: "54px" }}
                onClick={onCreateNew}
              >
                + New
              </button>
            </div>
          </div>
        </div>
        {rowData?.length > 0 && (
          <div className="main-panel d-flex flex-column">
            <div
              className={`flex-grow-1 table-container ${
                tableFullScreen ? "full-scrn" : ""
              }`}
            >
              <FullscreentTitle tableFullScreen={tableFullScreen} />
              <div
                className="ag-theme-alpine"
                style={{
                  height: "calc(100% - 50px)",
                  width: "100%",
                  position: "relative",
                }}
              >
                <AgGridReact
                  frameworkComponents={{
                    employeeNameWithProfileRenderer:
                      EmployeeNameWithProfileRenderer,
                  }}
                  reactUi={true}
                  rowClass={rowClass}
                  getRowClass={getRowClass}
                  pagination={true}
                  defaultColDef={{
                    flex: 1,
                  }}
                  rowData={rowData}
                  rowHeight={100}
                  rowSelection={"single"}
                  columnDefs={colDefs}
                  onGridReady={onGridReady}
                  paginationPageSize={pageSize}
                  suppressPaginationPanel={true}
                ></AgGridReact>
                <div className="d-flex justify-content-between pagination-box bg-white p-2">
                  <select
                    onChange={onPageSizeChanged}
                    id="page-size"
                    className="pagination-dropdown"
                    value={pageSize}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>

                  <Pagination
                    count={Math.ceil(totalRecords / pageSize)}
                    showFirstButton
                    showLastButton
                    onChange={onPageNum}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {rowData?.length < 1 && (
          <CAlert>
            No Pre-Invitations found. Please try with another date.
          </CAlert>
        )}
        {!rowData ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : null}
        <SidePanel>
          {isOpen && (
            <NewInvitation
              onCancel={() => onCancle()}
              isNew={isNew}
              isView={isView}
              selectedVis={selectedVisitor}
            />
          )}
          {/* {isView && <EditInvitation />} */}
        </SidePanel>
      </div>
    </>
  );
};

export default PreInvitations;
