import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import "./holiday.scss";
import React from "react";

const Holiday = (props: any) => {
  const _date = new Date(props.holiday?.date);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <div className="holiday-card col">
        <div className="shadow-sm">
          <div className="d-flex justify-content-center">
            <div className="holiday-calendar d-flex justify-content-center align-items-center">
              <div className="cal-icon">
                <CalendarTodayIcon />
              </div>
              {/* <img src={'../../../../assests/images/holiday.svg'} alt="profile" /> */}
              <p className="holiday-date mb-0 text-center">{_date.getDate()}</p>
              {props.holiday?.company_holiday && <EmojiEmotionsIcon />}
            </div>
          </div>
          <div className="holiday-content text-center pt-3">
            <p className="mb-4">{`${days[_date.getDay()]} - ${
              months[_date.getMonth()]
            }`}</p>
            <p className="holiday-title mb-0">{props.holiday?.title}</p>
            <p className="holiday-type">
              {props.holiday?.type === "FES" ? "Festival" : "Celebrations"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Holiday;
