import "./glSidepanel.scss";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  glSidePanelStatusSelector,
  toggleGlSidePanel,
} from "@root/actions/metadata";
import React from "react";

const GlobalSidePanel = (props: any) => {
  const isOpen = useSelector(glSidePanelStatusSelector);

  let classes = "sideP-drawer d-flex flex-column align-items-end";
  classes += isOpen ? " opened" : "";
  const dispatch = useDispatch();
  const handleCloseClick = () => {
    dispatch(toggleGlSidePanel(false));
  };

  return (
    <div className={classes}>
      <div className="panel-wrapper shadow">
        <button onClick={handleCloseClick} className="panel-close shadow-sm">
          <CloseIcon />
        </button>
        <div className="panel-content">{props.children}</div>
      </div>
    </div>
  );
};

export default GlobalSidePanel;
