import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { genVisitorsReport } from "../../services/report";
import { showNotificationError } from "../toast-notification";

const initialState: ReportState = {
  report: {
    processed: false,
    url: "",
    _id: "",
    type: "",
    date: "",
    __v: 0,
  },
  status: "idle",
  error: "",
  isGenerated: false,
};

export const genVisitorsReportList = createAsyncThunk<
  Dictionary,
  {
    from_date: string;
    to_date: string;
    type: string;
    name?: string | undefined;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_visitor_report",
  async ({ from_date, to_date, type, name }, thunkAPI) => {
    try {
      const response: any = await genVisitorsReport(
        from_date,
        to_date,
        type,
        name
      );
      const errMsg: any = response.data;
      if (response.status === 200) {
        store.dispatch(
          showNotificationError({
            type: "success",
            message: "Report generated and sent to your email.",
          })
        );
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const visitorsReportSlice = createSlice({
  name: "visitorsreport",
  initialState,
  reducers: {
    resetVisitorReporGenStatus: (state) => {
      state.isGenerated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(genVisitorsReportList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.isGenerated = false;
    });
    builder.addCase(genVisitorsReportList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isGenerated = action.payload.status;
      state.report = { ...action.payload.data };
    });
    builder.addCase(genVisitorsReportList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
      state.isGenerated = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.visitorsreport;

export const visitorReportStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const visitorReportGenStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isGenerated
);
export const visitorErrorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const visitorFetchedReport = createDraftSafeSelector(
  selfSelect,
  (state) => state.report
);

const { resetVisitorReporGenStatus } = visitorsReportSlice.actions;

export default visitorsReportSlice.reducer;
