import "./attendance.scss";
import React from "react";
import CallIcon from "@material-ui/icons/Call";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { useState } from "react";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import CloseIcon from "@material-ui/icons/Close";
// import HomeIcon from "@mui/icons-material/Home";
import ShowActivity from "@components/ShowActivity";

import SmsIcon from "@mui/icons-material/Sms";
// import DomainIcon from "@material-ui/icons/Domain";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeDataSelector,
  placeSelector,
  resetPlace,
} from "@actions/getEmployee";
import Spinner from "../Spinner/Spinner";
import ClockInLabel from "../ClockInLabel";
import O7daysCalendar from "../O7daysCalender/O7daysCalendar";
import PanelProfile from "../PanelProfile/PanelProfile";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useUnmount, useUpdateEffect } from "react-use";
import {
  fullScreenStatus,
  panelStatusSelector,
} from "@root/actions/attendance";
import SendMessage from "../sendMessage/index";
import MapGoogle from "@components/MapGoogle/MapGoogle";
import { updateMsgFlag } from "@actions/sendMessage";

const Attendancepancel = (props: any) => {
  // const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(false);
  const isOpen = useSelector(panelStatusSelector);
  const employeeData = useSelector(employeeDataSelector);
  const isFullScreen = useSelector(fullScreenStatus);
  const dispatch = useDispatch();
  const lLatLng = { lat: 0, lng: 0 };
  const [activeActivity, setActiveActivity] = useState("");
  const [activeType, setActiveType] = useState(1);
  const [activeMode, setActiveMode] = useState("");
  const [initialLatLng, setInitialLatLng] = useState(lLatLng);
  const [markers, setMarkers] = useState<any[]>([]);
  const [lastClockedOut, setLastClockedOut] = useState("");

  const getLabel = (obj: any) => {
    return obj.clocked_out_at ? (
      <ClockInLabel status={"clockout"} />
    ) : (
      <ClockInLabel status={"clockin"} />
    );
  };

  useUpdateEffect(() => {
    // setInitialLatLng({
    //   //@ts-ignore
    //   clockin: employeeData.checkin?.geo_location?.clockin,
    //    //@ts-ignore
    //   clockout: employeeData.checkin?.geo_location?.clockout,
    // });
    if (employeeData.activities.length > 0) {
      setActiveActivity(employeeData.activities[0]._id);
      setActiveType(employeeData.activities[0].clocked_out_at ? 2 : 1);
      setActiveMode(
        employeeData.activities[0].clocked_out_at
          ? employeeData.activities[0].mode.clockout
          : employeeData.activities[0].mode.clockin
      );
      setInitialLatLng(employeeData.activities[0].geo_location.clockin);
      const _markers: any[] = [];
      employeeData.activities.forEach((el) => {
        const obj = [
          {
            _id: el._id,
            _geo: el.geo_location.clockin,
            _type: 1,
            _mode: el.mode.clockin,
            _time: getTimeFormat(el.clocked_in_at),
            _temp: el.temperature,
          },
          {
            _id: el._id,
            _geo: el.geo_location.clockout,
            _type: 2,
            _mode: el.mode.clockout,
            _time: getTimeFormat(el.clocked_out_at),
            _temp: el.temperature,
          },
        ];
        _markers.push(...obj);
      });
      const fl_m = _markers.filter((el) => el._geo !== null);
      console.log(fl_m);
      setMarkers(fl_m);

      const employeeActivityRecord: any[] = employeeData.activities.filter(
        (t) => t.clocked_out_at !== null
      );

      if (employeeActivityRecord.length > 0) {
        setLastClockedOut(employeeActivityRecord[0].clocked_out_at);
      } else {
        setLastClockedOut("");
      }
    }
  }, [employeeData]);

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  useUpdateEffect(() => {
    if (isOpen === false) {
      dispatch(resetPlace());
      setMsg(false);
    }
  }, [isOpen]);

  const handleMessageClick = (event: any) => {
    event.preventDefault();
    setMsg(true);
  };
  const handleclosemsgClick = () => {
    setMsg(false);
    dispatch(updateMsgFlag(false));
  };

  const onSelectActivity = (_id: any, _type: any, _geo: any) => {
    if (_geo) {
      setActiveActivity(_id);
      setActiveType(_type);
      setInitialLatLng(_geo);
    }
  };

  useUpdateEffect(() => {
    if (!isOpen) props.onClose();
  }, [isOpen]);

  return (
    <>
      {employeeData ? (
        <>
          <div className="row h-100">
            <div className="col-md part1 p-0 h-100">
              <div className="d-flex flex-column h-100 pb-4 emp-detail-container h-100">
                {/* status label  */}
                <div className="status-label-container d-flex justify-content-center align-items-center">
                  {employeeData.checkin ? getLabel(employeeData.checkin) : null}
                </div>

                {/* title haeder */}
                <div className="py-2 d-flex justify-content-between align-items-center title-header">
                  <div>
                    <p className="mb-0 font-500 title">View Attendance</p>
                  </div>
                  <div className="icons-container d-flex">
                    {/* <a
                    className="icons"
                    href={`mailto:${employeeData.user.email}`}
                    onClick={handleMessageClick}
                    target="__blank"
                  >
                    <SmsIcon/>
                  </a> */}

                    <a
                      className="icons"
                      href={`tel:${employeeData.user.phone}`}
                      target="__blank"
                    >
                      <CallIcon></CallIcon>
                    </a>
                  </div>
                </div>

                {/* panel-profile  */}
                <div className="d-flex justify-content-center">
                  <PanelProfile
                    image={employeeData.user.image}
                    name={`${employeeData.user.first_name} ${employeeData.user.last_name}`}
                    designation={employeeData.user.description}
                  />
                </div>

                {/* last 7days calendar  */}
                <div>{isOpen && <O7daysCalendar />}</div>

                {/* clockin clockout details  */}
                <div className="card-container d-flex mb-3 pt-4">
                  <div className="deatail-card">
                    <p>Temperature</p>
                    <div className="d-flex align-items-center">
                      <div className="icon">
                        <ThermostatIcon />
                      </div>
                      <div className="card-info">
                        {employeeData.checkin ? (
                          <div className="">
                            {employeeData?.checkin?.temperature ? (
                              <>
                                <p
                                  className={`mb-0 line-height1`}
                                >{`${employeeData.checkin.temperature.value}\u00b0 ${employeeData.checkin.temperature.measure}`}</p>
                                <span
                                  className={`${
                                    employeeData.checkin.temperature.measure ===
                                    "F"
                                      ? employeeData.checkin.temperature.value >
                                        99
                                        ? "text-danger"
                                        : "text-success"
                                      : employeeData.checkin.temperature.value >
                                        37.2
                                      ? "text-danger"
                                      : "text-success"
                                  }`}
                                >
                                  {employeeData.checkin?.temperature
                                    ?.measure === "F"
                                    ? employeeData.checkin.temperature.value >
                                      99
                                      ? "High"
                                      : "Normal"
                                    : employeeData.checkin.temperature.value >
                                      37.2
                                    ? "High"
                                    : "Normal"}
                                </span>
                              </>
                            ) : (
                              <p className={`mb-0 line-height1`}>N/A</p>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="deatail-card">
                    <p>1st Clockin</p>
                    <div className="d-flex align-items-center">
                      <div className="icon in">
                        <ArrowRightAltIcon />
                      </div>
                      <div className="card-info">
                        {employeeData.checkin ? (
                          <div className="">
                            {employeeData?.checkin ? (
                              <>
                                <p className="mb-0">
                                  {getTimeFormat(
                                    employeeData.checkin.clocked_in_at
                                  )}
                                </p>
                                <p className="mb-0">
                                  {employeeData.checkin.clocked_in_status ===
                                  "late" ? (
                                    <span className="text-danger">
                                      Late Clockedin
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      on-time
                                    </span>
                                  )}
                                </p>
                              </>
                            ) : (
                              <p className={`mb-0 line-height1`}>N/A</p>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="deatail-card" style={{ paddingLeft: "12px" }}>
                    <p>Last Clockout</p>
                    <div className="d-flex align-items-center">
                      <div className="icon out">
                        <ArrowRightAltIcon />
                      </div>
                      <div className="card-info">
                        {employeeData.checkin ? (
                          <div className="">
                            {employeeData?.checkin?.clocked_out_at ? (
                              <>
                                <p className="mb-0">
                                  {getTimeFormat(
                                    employeeData.checkin.clocked_out_at
                                  )}
                                </p>
                                <p className="mb-0">
                                  <span>
                                    {employeeData.checkin.mode.clockout}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <p className={`mb-0 line-height1`}>
                                {lastClockedOut !== ""
                                  ? getTimeFormat(lastClockedOut)
                                  : "N/A"}
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* activity container */}
                <div className={`today-activity2 pt-4`}>
                  <p className="">All Activities</p>
                  {employeeData.activities.length > 0 ? (
                    <div className="activity-container">
                      {employeeData.activities.map((activity) => {
                        return (
                          <ShowActivity
                            key={activity._id}
                            Activity={activity}
                            activeId={activeActivity}
                            isLast={
                              employeeData.activities[
                                employeeData.activities.length - 1
                              ] === activity
                                ? true
                                : false
                            }
                            activeType={activeType}
                            onSelectActivity={(
                              _type: any,
                              _geo: { lat: number; lng: number }
                            ) => onSelectActivity(activity._id, _type, _geo)}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mt-5">
                      <h6>No Activity To Show</h6>
                    </div>
                  )}
                </div>

                {/* <div className={`today-activity ${show ? "open" : ""}`}>
                <h6 className="text-center">Clockin/Clockout Activities</h6>
                {employeeData.activities.length > 0 ? (
                  <div className="p-3 activity-container">
                    {employeeData.activities.map((activity) => {
                      return (
                        <ShowActivity
                          key={activity._id}
                          Activity={activity}
                          isLast={
                            employeeData.activities[
                              employeeData.activities.length - 1
                            ] === activity
                              ? true
                              : false
                          }
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-5">
                    <h6>No Activity To Show</h6>
                  </div>
                )}
              </div> */}

                {/*Send msg*/}
              </div>

              {/*Send msg*/}
              <SendMessage
                _class={msg ? "open" : ""}
                onClick={handleclosemsgClick}
                id={employeeData.user._id}
              />
            </div>
            <div className="col-md part2">
              {/* geo Location  */}
              <div className="pt-3 pb-3 h-100">
                {/* <p>Employee Geo Location:</p> */}
                <div
                  className={`map-container ${isFullScreen ? "full-scrn" : ""}`}
                >
                  <div className="map">
                    <div className="h-100">
                      {/* initialLatLng */}
                      {isOpen &&
                      initialLatLng.lat !== 0 &&
                      initialLatLng.lng !== 0 ? (
                        <MapGoogle
                          clockinLatLng={initialLatLng}
                          // clockoutLatLng={
                          //   initialLatLng.clockout
                          // }
                          mode={activeMode}
                          markers={markers}
                          activeId={activeActivity}
                          activeType={activeType}
                          onSelectMarker={(_id: any, _type: any, _geo: any) =>
                            onSelectActivity(_id, _type, _geo)
                          }
                        />
                      ) : null}

                      {/* {isOpen  && 
                    (employeeData.checkin?.geo_location?.clockin?.lat !== 0  && 
                    employeeData.checkin?.geo_location?.clockin?.lng !== 0 ) ? 
                    <MapGoogle clockinLatLng={employeeData.checkin?.geo_location?.clockin}
                      clockoutLatLng={
                        employeeData.checkin?.geo_location?.clockout
                      }
                      mode={employeeData.checkin?.mode} />
                    : null } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Attendancepancel;
