import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import "./clockInLabel.scss";
import React from "react";

const ClockInLabel = (props: any) => {
  return (
    <>
      <div
        className={`d-flex text-white align-items-center time-indicator ${props.status}`}
      >
        <div className={`lable-icon ${props.status}`}>
          <ArrowRightAltIcon />
        </div>
        <p className="mb-0 text-white">
          {props.status === "clockin" ? "ClockedIn" : "ClockedOut"}
        </p>
      </div>
    </>
  );
};

export default ClockInLabel;
