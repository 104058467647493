import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getCurrentUser } from "@root/services/current-user";

type currentUser = {
  error: string;
  status: string;
  currentUser: any;
};

export const getCurrentUserInfo = createAsyncThunk<any>(
  "@admin/fetch_current_user",
  async (thunkAPI) => {
    try {
      const response: any = await getCurrentUser();
      const errMsg: any = response.data;

      return response.status === 200
        ? {
            userData: response.data,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error) {
      return thunkAPI;
    }
  }
);

const initialState: currentUser = {
  error: "",
  status: "idle",
  currentUser: null,
};

const GetCurrentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUserInfo.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getCurrentUserInfo.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.currentUser = action.payload.userData;
    });
    builder.addCase(getCurrentUserInfo.rejected, (state, action) => {
      state.status = "idle";
      state.error = "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.currentUser;

export const currentUserSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.currentUser
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);

export default GetCurrentUserSlice.reducer;
