import React, { useEffect } from "react";
import { useState } from "react";
import "./visitorfilter.scss";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  DateSelector,
  getVisitorsData,
  payloadSelector,
} from "@root/actions/visitor";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toggleFilter } from "@root/actions/attendance";

const Visitorfilter = () => {
  const dispatch = useDispatch();
  const [temp, setTemp] = useState<string>("all");
  const [purpose, setPurpose] = useState<string>("all");
  const [status, setStatus] = useState<string>("all");
  const filter = { temperature: "", purpose: "", status: "" };

  const payload = useSelector(payloadSelector);
  const startDate = useSelector(DateSelector);

  const handleApply = (event: any) => {
    event.preventDefault();
    filter.temperature = temp;
    filter.purpose = purpose;
    filter.status = status;
    console.log({
      from_date: startDate,
      to_date: startDate,
      temperature: filter.temperature,
      purpose: filter.purpose,
      status: filter.status,
      ...payload,
    });
    dispatch(
      getVisitorsData({
        from_date: startDate,
        to_date: startDate,
        temperature: filter.temperature,
        purpose: filter.purpose,
        status: filter.status,
        ...payload,
      })
    );

    dispatch(toggleFilter());
  };
  const handleResetClick = (event: any) => {
    event.preventDefault();
    setTemp("all");
    setPurpose("all");
    setStatus("all");
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 filter-col">
            <div className="row filter-rows">
              <h6 className="filtername">Temperature</h6>
              <div className="radio">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    name="temperature"
                    value={temp}
                    onChange={(e) => setTemp(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="pve"
                      control={<Radio />}
                      label="+Ve"
                    />
                    <FormControlLabel
                      value="mve"
                      control={<Radio />}
                      label="-Ve"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-6 filter-col">
            <div className="row filter-rows">
              <h6 className="filtername">Purpose</h6>
              <div className="radio">
                <FormControl
                  component="fieldset"
                  className="input-feild d-flex"
                >
                  <RadioGroup
                    row
                    name="purpose"
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="meeting"
                      control={<Radio />}
                      label="Meeting"
                    />
                    <FormControlLabel
                      value="interview"
                      control={<Radio />}
                      label="Interview"
                    />
                    <FormControlLabel
                      value="delivery"
                      control={<Radio />}
                      label="Delivery"
                    />
                    <FormControlLabel
                      value="official"
                      control={<Radio />}
                      label="Official"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-6 filter-col ">
            <div className="row filter-rows">
              <div>
                <h6 className="filtername">Current Status</h6>
              </div>
              <div className="radio">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    aria-label="clocked-time"
                    name="clocked-time"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="clocked-in"
                      control={<Radio />}
                      label="Checkedin"
                    />
                    <FormControlLabel
                      value="clocked-out"
                      control={<Radio />}
                      label="Checkedout"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type={"submit"}
          onClick={handleApply}
          className="btn btn-gradient  filterapply"
        >
          Apply
        </button>
        <button
          type={"submit"}
          onClick={() => dispatch(toggleFilter())}
          className="btn filtercancel "
        >
          cancel
        </button>
        <span style={{ cursor: "pointer" }} onClick={handleResetClick}>
          <RestartAltIcon />
          Reset
        </span>
      </div>
    </div>
  );
};

export default Visitorfilter;
