import axios from "axios";

export const createHoliday = (
  title: string,
  date: string,
  type: string,
  company_holiday: boolean
) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/holidays`,
    {
      title,
      date,
      type,
      company_holiday,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

// {{base_url}}/holidays/?year=2021&month=12&status=completed&company_holiday=true&page_size=5&page_index=0

export const getHolidays = async (
  year: string,
  status: string,
  company_holiday: boolean,
  page_size: number,
  page_index: number
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/holidays`,
    params: {
      year,
      status,
      company_holiday,
      page_size,
      page_index,
    },
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};
