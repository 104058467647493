import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getSettingsSearch } from "@root/services/settings";

import { StoreDef } from "@root/store";
interface searchEmployeeState {
  data: Dictionary;
  status: "idle" | "loading";
  error: string;
}
const initialState: searchEmployeeState = {
  data: [],
  status: "idle",
  error: "",
};

export const getEmployeeBySearch = createAsyncThunk<
  Dictionary,
  { term: string },
  { rejectValue: ResponseError }
>("search", async ({ term }, thunkAPI) => {
  try {
    const response: any = await getSettingsSearch(term);

    console.log(response);
    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const searchEmployeeSlice = createSlice({
  name: "employeeSearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployeeBySearch.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getEmployeeBySearch.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.data = [action.payload.data];
    });
    builder.addCase(getEmployeeBySearch.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.searchEmployee;

export const searchSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.data
);
export default searchEmployeeSlice.reducer;
