import "./switchInput.scss";
import React from "react";

const SwitchInput = (props: any) => {
  return (
    <div className="d-flex align-items-center">
      <label className={`switch ${props.color ? props.color : ""}`}>
        <input
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
          {...props}
        />
        <span className="slider round"></span>
      </label>{" "}
      &nbsp;
      <p className="mb-0">{props.label}</p>
    </div>
  );
};

export default SwitchInput;
