import axios from "axios";

export const canFeedback = async (id: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/vcheckins/can-feedback/${id}`,
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};

export const sendFeedback = (id: string, text: string, rate: string) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/vcheckins/feedback/${id}`,
    {
      text,
      rate,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};
