import axios from "axios";

export async function editEmployeeDetail(id: string) {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/users/${id}`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
}
export async function updateEmployeeDetail(
  joined: any,
  dob: any,
  id: string,
  firstName: string,
  lastName: string,
  description: string,
  gender: any,
  email: any,
  phone: string,
  department: string,
  status: string,
  custom_id: string,
  reject_reason: string
) {
  const reqObj: any = {
    joined: joined,
    dob: dob,
    first_name: firstName,
    last_name: lastName,
    description: description,
    gender: gender,
    email: email,
    phone: phone,
    status: status,
    department: department,
    custom_id: custom_id,
  };
  if (reject_reason) reqObj["reject_reason"] = reject_reason;

  return await axios({
    method: "put",
    url: `${process.env.REACT_APP_BASE_URL}/users/${id}`,
    data: reqObj,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
}
