import axios from "axios";

export const getCheckinByDate = async (date: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/checkins/get-by-date`,
    params: { date },
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};

///users/is-outside-organization

export const isOusideOrganization = (geo_location: {
  lat: number;
  lng: number;
}) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/is-outside-organization`,
    {
      geo_location,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

///checkins/clock-out

export const userClockout = (geo_location: { lat: number; lng: number }) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/checkins/clock-out`,
    {
      geo_location,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

// /checkins/clock-in

export const userClockin = (geo_location: { lat: number; lng: number }) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/checkins/clock-in`,
    {
      geo_location,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};
