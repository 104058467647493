import React, { useState, forwardRef, useMemo, useEffect } from "react";
import DatePicker from "react-datepicker";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "react-datepicker/dist/react-datepicker.css";
import "./dateSelect.scss";
import { useUpdateEffect, useMedia } from "react-use";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import { setStartDate } from "@root/actions/attendance";
import { setDate } from "@root/actions/visitor";
import {
  startDateSelector,
  startDateStringSelector,
  setStartDate,
} from "@root/actions/header";
import { enableFutureDates } from "@root/actions/metadata";

const DateSelect = (props: any) => {
  const { selected = new Date(), onChange } = props;
  const dispatch = useDispatch();
  const resetDate = useSelector(startDateStringSelector);
  const stDate = useSelector(startDateSelector);
  const isEnableFutureDates = useSelector(enableFutureDates);
  const [startDate, setStartDd] = useState<Date | undefined>(new Date(stDate));
  const isSmall = useMedia("(max-width: 480px)");

  const handleOnChange: { (date: Date, e: React.SyntheticEvent<any>): void } = (
    date,
    event
  ) => {
    setStartDd(date);
    dateFormator(date);
  };

  const dateFormator = (dd: Date | undefined) => {
    const dteFormated = `${dd?.getFullYear()}-${
      dd?.getMonth()! + 1
    }-${dd?.getDate()}`;
    dispatch(setStartDate(dd?.toString()));
    dispatch(setDate(dteFormated));
  };

  useUpdateEffect(() => {
    if (onChange) {
      onChange(new Date(stDate));

      dateFormator(new Date(stDate));
    }

    setStartDd(new Date(stDate));
  }, [resetDate]);

  // useUpdateEffect(() => {
  //   const today = new Date();
  //   // dateFormator(today);
  //   setStartDd(today);
  //   console.log(startDate)

  // }, [resetDate])

  const ExampleCustomInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => (
      <button className={"feild-btn"} onClick={onClick} ref={ref}>
        {value}
        <EventIcon />
      </button>
    )
  );

  const handleBackWard: { (event: React.SyntheticEvent<any>): void } = () => {
    const newDate: Date | null = moment(startDate).subtract(1, "day").toDate();
    setStartDd(newDate);
    dateFormator(newDate);
  };

  const handleForWard: { (event: React.SyntheticEvent<any>): void } = () => {
    const today = moment(new Date()).add(1, "day").toDate();
    const newDate: Date | null = moment(startDate).add(1, "day").toDate();
    if (
      !isEnableFutureDates &&
      today.toLocaleDateString() === newDate.toLocaleDateString()
    ) {
      return;
    }
    setStartDd(newDate);
    dateFormator(newDate);
  };

  const dateFmt = useMemo(
    () => (isSmall ? "dd MMM yyyy" : "eee, dd MMM yyyy"),
    [isSmall]
  );

  return (
    <>
      <div className={"d-picker d-inline-flex flex-shrink-0"}>
        <button className={"cal-btn"} onClick={handleBackWard}>
          <KeyboardArrowLeftIcon />
        </button>
        {resetDate ? (
          <DatePicker
            showMonthDropdown
            showYearDropdown
            adjustDateOnChange
            dateFormat={dateFmt}
            className={"h-full outline-none"}
            selected={startDate}
            maxDate={isEnableFutureDates ? null : new Date()}
            onChange={handleOnChange}
            customInput={<ExampleCustomInput />}
          />
        ) : null}
        <button className={"cal-btn"} onClick={handleForWard}>
          <KeyboardArrowRightIcon />
        </button>
      </div>
      {/* <p>{resetDate}-{stDate.toString()}</p> */}
    </>
  );
};

export default DateSelect;
