import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  LegacyRef,
} from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { useUnmount, useUpdateEffect } from "react-use";
import { setTitle, updateDatePickerStatus } from "@actions/metadata";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import SidePanel from "@components/SidePanel/SidePanel";
import CloseIcon from "@material-ui/icons/Close";
import GridViewIcon from "@mui/icons-material/GridView";
import TocIcon from "@mui/icons-material/Toc";
import FilterPanel from "@root/components/FilterPanel/FilterPanel";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import Pusher from "pusher-js";
import SmileyIcon from "@material-ui/icons/SentimentSatisfiedTwoTone";
import Select, { StylesConfig } from "react-select";

import {
  toggleFilter,
  togglePanel,
  filterStatusSelector,
  closePanel,
  closeFilter,
  panelStatusSelector,
} from "@root/actions/attendance";
// import { getAttendaceByID } from "@actions/getEmployee";
import Spinner from "@root/components/Spinner/Spinner";
import VistorPanel from "@components/visitorpanel";
import Tooltip from "@mui/material/Tooltip";

import {
  getVisitorsData,
  getPreInviteVisitorsData,
  preInvitationVisitorList,
  getVisitorsFeedbackData,
  feedbackListSelector,
  payloadSelector,
  setVisitorId,
  visitorListSelector,
  matrixCountSelector,
  totalRecordsSelector,
  feedbackMatrixCountSelector,
} from "@root/actions/visitor";
import {
  unknownVisitorsList,
  getUnknownVisitorsList,
  totalUnknownVisistors,
} from "@root/actions/unknownVisitors";
import VisitorNameWithProfile from "@root/components/EmployeeNameWithProfileRenderer/VisitorNameWithProfile";
import { getVisitorByID } from "@root/actions/getvisitor";
import Visitorfilter from "@root/components/visitorfilter";
import MatrixLabel from "@root/components/matrixLabel/matrixLabel";
import MatrixIconPanel from "@root/components/MatrixIconPanel/matrixIconPanel";
import { startDateStringSelector, setStartDate } from "@root/actions/header";
import Pagination from "@mui/material/Pagination";
import PersonCard from "@root/components/PersonCard";
import NewInvitation from "@root/components/NewInvitation";
import EmployeeNameWithProfileRenderer from "@root/components/EmployeeNameWithProfileRenderer/EmployeeNameWithProfileRenderer";

import DetailRowRenderer from "@root/components/DetailRowRenderer/DetailRowRenderer";
import { getSelectedVisitorList } from "@root/services/visitors";
import InfoAlert from "@root/components/InfoAlert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenButton from "@root/components/FullscreenButton";
import FullscreentTitle from "@root/components/FullscreentTitle";
import CAlert from "@root/components/CAlert";

const Visitors = (props: any) => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      height: "54px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        minWidth: "200px",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "#fff",
        color: isDisabled ? "#000" : isSelected ? "#000" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#fff"
              : "#fff"
            : undefined,
          color: "#000",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      color: "#000",
      minWidth: "150px",
      height: "54px",
    }),
    placeholder: (styles) => ({ ...styles, color: "#000" }),
    singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
    menu: (styles) => ({ ...styles, backgroundColor: "#fff" }),
  };
  const [filterOn, setFilterOn] = useState("all");
  const [filterLable, setFilterLable] = useState("All");

  const payload = useSelector(payloadSelector);
  const visitorList = useSelector(visitorListSelector);
  const unknownVisitors = useSelector(unknownVisitorsList);

  const [rowData, setRowData] = useState<any>();
  const [rowPreInviteData, setRowPreInviteData] = useState<any>();
  const [unknownVisitorsData, setUnknownVisitorsData] = useState<any>();
  const [rowFeedbackData, setRowFeedbackData] = useState<any>();
  // const startDate = useSelector(DateSelector);
  const startDate = useSelector(startDateStringSelector);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const totalRecords = useSelector(totalRecordsSelector);
  const totalUnknownVisitorsRecords = useSelector(totalUnknownVisistors);

  const isFilterOpen = useSelector(filterStatusSelector);
  const [searchTerm, setSearchTerm] = useState("");
  const countSelector = useSelector(matrixCountSelector);
  const feedbackMatrix = useSelector(feedbackMatrixCountSelector);
  const [matrixCount, setMatrixCount] = useState(countSelector);
  const [newClockin, setNewClockin] = useState({ visitor_id: "", checkin: {} });
  const [newClockout, setNewClockout] = useState({
    visitor_id: "",
    checkin: {},
  });
  const [tableGridView, settableGridView] = useState<"table" | "grid">("table");
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const dropdown = [
    { value: "all", label: `All(${feedbackMatrix.all})` },
    { value: "excellent", label: `Excellent(${feedbackMatrix.excellent})` },
    { value: "good", label: `Good(${feedbackMatrix.good})` },
    { value: "okay", label: `Okay(${feedbackMatrix.okay})` },
    { value: "bad", label: `Bad(${feedbackMatrix.bad})` },
    { value: "worst", label: `Worst(${feedbackMatrix.worst})` },
  ];

  const [visitorId, setvisitorId] = useState("");
  const acc_id = localStorage.getItem("account_id");

  const [activeTab, setActiveTab] = useState(0);

  const pushr = JSON.parse(localStorage.getItem("pusher")!);
  let pusher: Pusher;
  const accChannelId = `acc-${acc_id}`;

  const getRowClass = (params: any) => {
    if (params.data?._id === visitorId) {
      return "active";
    }
  };

  const toggleTableGridView = (view: "table" | "grid") => {
    settableGridView(view);
  };

  const coldefss = [
    {
      field: "name",
      headerName: "Visitor Name & Picture",
      sortable: true,
      cellRendererFramework: VisitorNameWithProfile,
    },

    {
      field: "checkin",
      headerName: "TEMPERATURE [C | F]",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            {params.data?.temperature ? (
              params.data.temperature.measure === "F" ? (
                <p
                  className={`line-height1 mb-0 ${
                    params.data?.temperature.value > 99
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data?.temperature.value}${"\u00b0"} ${
                  params.data?.temperature.measure
                }`}</p>
              ) : (
                <p
                  className={`line-height1 mb-0 ${
                    params.data?.temperature.value > 37.2
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data?.temperature.value}${"\u00b0"} ${
                  params.data?.temperature.measure
                }`}</p>
              )
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      field: "checkin",
      headerName: "IN-TIME",
      sortable: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <div className="d-flex flex-column">
              <p className="line-height1 mb-2">
                {params.data ? getTimeFormat(params.data?.clocked_in_at) : "--"}
              </p>
              {params.data ? (
                params.data?.clocked_in_status === "late" ? (
                  <p className="text-danger line-height1 mb-0">Late ClockIn</p>
                ) : null
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      field: "checkin",
      headerName: "OUT-TIME",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {params.data && params.data?.clocked_out_at
              ? getTimeFormat(params.data?.clocked_out_at)
              : "--"}
          </p>
        );
      },
    },
    {
      field: "checkin",
      headerName: "PURPOSE",
      cellRendererFramework: (params: any) => {
        return params.data?.purpose
          ? `${params.data?.purpose
              .charAt(0)
              .toUpperCase()}${params.data?.purpose.slice(1)}`
          : "--";
      },
    },
    {
      field: "",
      headerName: "CURRENT STATUS",
      cellRendererFramework: (params: any) => {
        if (params.data) {
          return params.data?.clocked_out_at ? (
            <>
              <div className="checkin-indicator">
                <DirectionsWalkIcon className="out checkdirection" />
                <p className="mb-0">Checkedout</p>
              </div>
            </>
          ) : (
            <div className=" checkin-indicator">
              <DirectionsWalkIcon className="checkdirection" />
              <p className="mb-0">Checkedin</p>
            </div>
          );
        }
        return "--";
      },
    },
    {
      field: "",
      headerName: "FEEDBACK",
      cellRendererFramework: (params: any) => {
        return params.data?.feedback ? (
          <p className="mb-0">{`${params.data?.feedback?.rate
            .charAt(0)
            .toUpperCase()}${params.data?.feedback?.rate.slice(1)}`}</p>
        ) : (
          "--"
        );
      },
    },
    {
      field: "",
      headerName: "ACTIONS",
      cellRendererFramework: (params: any) => {
        return (
          <button
            className="grid-action"
            onClick={() => {
              handleVisitorViewclick(params.data?.visitor._id);
            }}
          >
            View
          </button>
        );
      },
      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <span className="pr-4">Actions</span>
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];
  const [colDefs, setColDefs] = useState(coldefss);

  const coldefssPreInvite = [
    {
      field: "name",
      headerName: "VISITOR DETAILS",
      sortable: true,
      width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <div className="profile-picture">
              <img
                src={
                  params.data?.visitor?.image !== undefined
                    ? params.data?.visitor?.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            <div className="pr-3">
              <p className="mb-1"> {params.data?.visitor?.name} </p>
              <p className="mb-0">
                <small>{`+${params?.data?.visitor?.phone?.slice(
                  0,
                  2
                )} ${params?.data?.visitor?.phone?.slice(
                  2,
                  7
                )} ${params?.data?.visitor?.phone?.slice(7)}`}</small>
              </p>
            </div>
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },
    {
      field: "checkin",
      headerName: "TEMPERATURE [F]",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            {params.temperature ? (
              params.data.temperature.measure === "F" ? (
                <p
                  className={`line-height1 mb-0 ${
                    params.data.temperature.value > 99
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data.temperature.value}${"\u00b0"} ${
                  params.data.temperature.measure
                }`}</p>
              ) : (
                <p
                  className={`line-height1 mb-0 ${
                    params.data.temperature.value > 37.2
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data.temperature.value}${"\u00b0"} ${
                  params.data.temperature.measure
                }`}</p>
              )
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      field: "checkin",
      headerName: "IN-TIME/OUT-TIME",
      sortable: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <div className="d-flex flex-column">
              <p className="line-height1 mb-0">
                {params.data.clockin
                  ? getTimeFormat(params.data?.clockin?.clocked_in_at)
                  : "--"}{" "}
                /{" "}
                {params.data && params.data.clockin?.clocked_out_at
                  ? getTimeFormat(params.data.clockin?.clocked_out_at)
                  : "--"}
              </p>
            </div>
          </>
        );
      },
    },
    {
      field: "",
      headerName: "PURPOSE",
      cellRendererFramework: (params: any) => {
        if (params.data.purpose) {
          return (
            <>
              <p className="mb-0">{params.data.purpose}</p>
            </>
          );
        }
        return "--";
      },
    },
    {
      field: "invitation",
      headerName: "INVITATION TITLE",
      cellRendererFramework: (params: any) => {
        const trimTitle = (title: string) => {
          return title.length > 20 ? `${title.slice(0, 20)}...` : title;
        };
        return (
          <p className="line-height1 mb-0">
            {params.data?.invitation?.title
              ? trimTitle(params.data?.invitation?.title)
              : "--"}
            <span style={{ display: "block", fontSize: "12px" }}>
              {params.data ? params.data?.purpose : "--"}
            </span>
          </p>
        );
      },
    },
    {
      field: "name",
      headerName: "HOST DETAILS",
      sortable: true,
      width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <div className="profile-picture">
              <img
                src={
                  params.data?.host?.image !== undefined
                    ? params.data?.host?.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            <div className="pr-3">
              <p className="mb-1">
                {" "}
                {params.data?.host?.first_name || params.data?.host?.last_name
                  ? params.data?.host?.first_name +
                    " " +
                    params.data?.host?.last_name
                  : "--"}{" "}
              </p>
              {params.data?.host?.description ? (
                <p className="mb-0">{params.data?.host?.description}</p>
              ) : (
                "--"
              )}
            </div>
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },
    {
      field: "status",
      headerName: "CURRENT STATUS",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {params.data.clockin?.clocked_out_at ? "Clock-In" : "Clock-out"}
          </p>
        );
      },
    },
    {
      field: "start",
      headerName: "APPOINTMENT TIME",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {params.data && params.data?.clocked_in_at
              ? getTimeFormat(params.data?.clocked_in_at)
              : "--"}{" "}
            -{" "}
            {params.data && params.data?.clocked_out_at
              ? getTimeFormat(params.data?.clocked_out_at)
              : "--"}
          </p>
        );
      },
    },

    {
      field: "",
      headerName: "ACTIONS",
      cellRendererFramework: (params: any) => {
        return (
          <>
            {params.data?.cancelled ? (
              "Cancelled"
            ) : (
              <button
                className="grid-action"
                onClick={() => {
                  handlePreInvitationViewclick(params.data?._id, params.data);
                }}
              >
                View
              </button>
            )}
          </>
        );
      },
      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <span className="pr-4">Actions</span>
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];
  const [colPreInvite, setColPreInvite] = useState(coldefssPreInvite);

  const colUnknownVisitorsDefss = [
    {
      field: "name",
      headerName: "Visitor Photo",
      sortable: true,
      // width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <div className="profile-picture">
              <img
                src={
                  params.data.image !== undefined
                    ? params.data.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            {/* <div className="pr-3">
              <p className="mb-1"> {params.data.name} </p>
              <p className="mb-0">
                <small>{`+${params?.data.phone?.slice(
                  0,
                  2
                )} ${params?.data.phone?.slice(
                  2,
                  7
                )} ${params?.data.phone?.slice(7)}`}</small>
              </p>
            </div> */}
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },

    {
      field: "checkin",
      headerName: "TEMPERATURE [F]",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            {params.temperature ? (
              params.data.temperature.measure === "F" ? (
                <p
                  className={`line-height1 mb-0 ${
                    params.data.temperature.value > 99
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data.temperature.value}${"\u00b0"} ${
                  params.data.temperature.measure
                }`}</p>
              ) : (
                <p
                  className={`line-height1 mb-0 ${
                    params.data.temperature.value > 37.2
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data.temperature.value}${"\u00b0"} ${
                  params.data.temperature.measure
                }`}</p>
              )
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      field: "checkin",
      headerName: "IN-TIME",
      sortable: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <div className="d-flex flex-column">
              <p className="line-height1 mb-2">
                {params.data.clockin
                  ? getTimeFormat(params.data?.clockin?.clocked_in_at)
                  : "--"}
              </p>
              {/* {params.data ? (
                  params.data?.clocked_in_status === "late" ? (
                    <p className="mb-0 text-danger line-height1">Late ClockIn</p>
                  ) : null
                ) : null} */}
            </div>
          </>
        );
      },
    },

    {
      field: "",
      headerName: "ADMIN  COMMENT",
      cellRendererFramework: (params: any) => {
        const trimTitle = (title: string) => {
          return title.length > 20 ? `${title.slice(0, 20)}...` : title;
        };
        return (
          <p className="line-height1 mb-0">
            {params.data?.admin_comment
              ? trimTitle(params.data?.admin_comment)
              : "--"}
          </p>
        );
      },
    },

    {
      field: "",
      headerName: "",
      // width: 80,
      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];

  const [colUnknownVisitors, setColUnknownVisitors] = useState(
    colUnknownVisitorsDefss
  );

  const colDefFeedback = [
    {
      field: "visitor",
      headerName: "VISITOR NAME & PICTURE",
      sortable: true,
      width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <div className="profile-picture">
              <img
                src={
                  params.data?.visitor?.image !== undefined
                    ? params.data?.visitor?.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            <div className="pr-3">
              <p className="mb-1"> {params.data?.visitor?.name} </p>
              <p className="mb-0">
                {params.data?.visitor?.phone ? (
                  <small>{`+${params.data?.visitor?.phone?.slice(
                    0,
                    2
                  )} ${params.data?.visitor?.phone?.slice(
                    2,
                    7
                  )} ${params.data?.visitor?.phone?.slice(7)}`}</small>
                ) : (
                  <small>--</small>
                )}
              </p>
            </div>
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },
    {
      field: "visitor",
      headerName: "PHONE NUMBER",
      sortable: true,
      width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <p className="mb-0">
              {params.data?.visitor?.phone ? (
                <small>{`+${params.data?.visitor?.phone?.slice(
                  0,
                  2
                )} ${params.data?.visitor?.phone?.slice(
                  2,
                  7
                )} ${params.data?.visitor?.phone?.slice(7)}`}</small>
              ) : (
                <small>--</small>
              )}
            </p>
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },
    {
      field: "purpose",
      headerName: "PURPOSE",
      cellRendererFramework: (params: any) => {
        if (params.data.purpose) {
          return (
            <>
              <p className="mb-0">{params.data.purpose}</p>
            </>
          );
        }
        return "--";
      },
    },
    {
      field: "host",
      headerName: "HOST DETAILS",
      sortable: true,
      width: 220,
      cellRendererFramework: (params: any) => {
        return (
          <div className="employee-profile">
            <div className="profile-picture">
              <img
                src={
                  params.data?.host?.image !== undefined
                    ? params.data?.host?.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            {params.data?.host ? (
              <div className="pr-3">
                <p className="mb-1">
                  {" "}
                  {params.data?.host?.first_name +
                    params.data?.host?.last_name}{" "}
                </p>
                <p className="mb-0">{params.data?.host?.description}</p>
              </div>
            ) : (
              <div className="pr-3">--</div>
            )}
          </div>
        );
      },
      // cellRendererFramework: VisitorNameWithProfile,
    },
    {
      field: "clocked_out_at",
      headerName: "OUT-TIME",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {getTimeFormat(params.data?.clocked_out_at)}
          </p>
        );
      },
    },
    {
      field: "feedback",
      headerName: "OVERALL EXPERIENCE",
      cellRendererFramework: (params: any) => {
        return (
          <p
            className={`line-height1 mb-0 ${getColorCode(
              params.data?.feedback?.rate
            )}`}
          >
            {params.data?.feedback?.rate}
          </p>
        );
      },
    },
    {
      field: "",
      headerName: "COMMENTS",
      cellRendererFramework: (params: any) => {
        return (
          <Tooltip
            title={
              <div className="p-2">
                <label>Comments:</label>
                <p className="pt-2"> {params.data?.feedback?.text}</p>
              </div>
            }
            placement="left"
            color="secondary"
            arrow
          >
            <button
              className="grid-action"
              onClick={(event) => onClickShow(event)}
            >
              <p className="line-height1 mb-0">{"Show"}</p>
            </button>
          </Tooltip>
        );
      },
    },
    {
      field: "",
      headerName: "",
      width: 80,
      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];
  const [colFeedback, setColFeedback] = useState(colDefFeedback);

  const gridRef: LegacyRef<AgGridReact> = useRef(null);

  const getColorCode = (rate: any) => {
    switch (rate) {
      case "good":
        return "g-icon";
      case "bad":
        return "b-icon";
      case "excellent":
        return "e-icon";
      case "okay":
        return "o-icon";
      case "worst":
        return "w-icon";
    }
  };
  const isOpen = useSelector(panelStatusSelector);

  const [isNew, setIsNew] = useState(false);
  const [isView, setIsView] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState({
    _id: "",
    name: "",
    phone: "",
    purpose: "",
    start: "",
    end: "",
  });
  const preInvitations = useSelector(preInvitationVisitorList);

  const feedbackDataList = useSelector(feedbackListSelector);

  // useEffect(()=>{

  //   switch (activeTab) {
  //     case 1:
  //         dispatch(
  //           getPreInviteVisitorsData({
  //             from_date: startDate,
  //             page_size: pageSize,
  //             pageIndex: pageIndex,
  //             to_date: startDate
  //           })
  //         );
  //       break;
  //     case 2:
  //       dispatch(
  //         getUnknownVisitorsList({
  //           from_date: '2022-03-18',
  //           to_date: startDate,
  //           page_size: pageSize,
  //           page_index: pageIndex
  //         })
  //       );
  //       break;
  //       case 3:
  //       dispatch(
  //         getVisitorsFeedbackData({
  //           from_date: startDate,
  //           page_size: pageSize,
  //           pageIndex: pageIndex,
  //           to_date: startDate,
  //           rate: "all"
  //         })
  //       );
  //         break;
  //     default:
  //         dispatch(
  //           getVisitorsData({
  //             from_date: startDate,
  //             to_date: startDate,
  //             ...payload,
  //             page_size: pageSize,
  //             pageIndex: pageIndex
  //           })
  //         );
  //       break;
  //   }

  // },[activeTab])

  const onTableFUllScreen = () => {
    console.log(tableFullScreen);

    setTableFullScreen(!tableFullScreen);
  };

  useEffect(() => {
    if (pushr) {
      pusher = new Pusher(pushr.api_key, {
        cluster: pushr.cluster,
      });

      // account specific channel
      const accChannel = pusher.subscribe(accChannelId);
      // accChannel.bind("new-visitor-created", (data:any) => {
      //   console.log(data);
      // });
      accChannel.bind("visitor-clocked-in", (data: any) => {
        setNewClockin({ visitor_id: data.checkin.visitor_id, checkin: data });
        setMatrixCount(data.counts);
      });

      accChannel.bind("visitor-clocked-out", (data: any) => {
        setNewClockout({ visitor_id: data.checkin.visitor_id, checkin: data });
        setMatrixCount(data.counts);
      });

      return () => {
        accChannel.unbind_all();
        accChannel.unsubscribe();
        if (pusher) pusher.disconnect();
      };
    }
  }, []);

  useUpdateEffect(() => {
    setMatrixCount(countSelector);
  }, [countSelector]);

  useUpdateEffect(() => {
    checkinUpdateList(newClockin.visitor_id, newClockin.checkin);
  }, [newClockin]);

  useUpdateEffect(() => {
    checkoutUpdateList(newClockout.visitor_id, newClockout.checkin);
  }, [newClockout]);

  const handlePreInvitationViewclick = (id: string, selectedVisitor: any) => {
    setIsNew(false);
    setIsView(true);
    const userData: any = {
      email: selectedVisitor.invitation.email,
      end: selectedVisitor.clocked_out_at,
      name: selectedVisitor.visitor.name,
      phone: selectedVisitor.visitor.phone,
      purpose: selectedVisitor.purpose,
      start: selectedVisitor.clocked_in_at,
      title: selectedVisitor.invitation.title,
      _id: id,
    };
    setSelectedVisitor(userData);
    setTableFullScreen(false);
    dispatch(togglePanel());
    // dispatch(getVisitorByID({ _id: id, _date: startDate }));
    // dispatch(getVisitorActivityById({_id: id, page_index: 0}))
  };

  const checkinUpdateList = (id: string, checkin: any) => {
    const element = rowData?.find((el: any) => el.visitor._id === id);
    let obj: any;
    if (!element) {
      obj = {
        temperature: checkin.checkin.temperature,
        purpose: checkin.checkin.purpose,
        _id: checkin.checkin._id,
        clocked_out_at: checkin.checkin.clocked_out_at,
        clocked_in_at: checkin.checkin.clocked_in_at,
        visitor: {
          _id: checkin.visitor._id,
          name: checkin.visitor.name,
          phone: checkin.visitor.phone,
          image: checkin.visitor.image,
        },
      };

      const newList = [...rowData, obj];
      setRowData(newList);
    } else {
      const newElement = {
        ...element,
        clocked_out_at: checkin.checkin.clocked_out_at,
      };
      const newList = rowData.map((el: any) =>
        el.visitor._id === id ? newElement : el
      );
      setRowData(newList);
    }
  };

  const checkoutUpdateList = (id: string, checkin: any) => {
    const element = rowData?.find((el: any) => el.visitor._id === id);
    if (element) {
      const newElement = {
        ...element,
        clocked_out_at: checkin.checkin.clocked_out_at,
      };
      const newList = rowData.map((el: any) =>
        el.visitor._id === id ? newElement : el
      );
      setRowData(newList);
    }
  };

  useEffect(() => {
    const today = new Date();
    dispatch(setStartDate(today.toString()));
    dispatch(setTitle("Visitors"));
    dispatch(updateDatePickerStatus(true));

    dispatch(closePanel(false));
    dispatch(closeFilter(false));
  }, []);

  useUnmount(() => {
    const today = new Date();

    dispatch(setStartDate(today));
    dispatch(updateDatePickerStatus(false));
  });

  useEffect(() => {
    // setTableFullScreen(false);
    switch (activeTab) {
      case 1:
        dispatch(
          getPreInviteVisitorsData({
            from_date: startDate,
            page_size: pageSize,
            pageIndex: pageIndex,
            to_date: startDate,
          })
        );
        break;
      case 2:
        dispatch(
          getUnknownVisitorsList({
            from_date: startDate,
            to_date: startDate,
            page_size: pageSize,
            page_index: pageIndex,
          })
        );
        break;
      case 3:
        dispatch(
          getVisitorsFeedbackData({
            from_date: startDate,
            page_size: pageSize,
            pageIndex: pageIndex,
            to_date: startDate,
            rate: "all",
          })
        );
        break;
      default:
        dispatch(
          getVisitorsData({
            from_date: startDate,
            to_date: startDate,
            ...payload,
            page_size: pageSize,
            pageIndex: pageIndex,
          })
        );
        break;
    }
  }, [activeTab, startDate, pageSize, pageIndex]);

  // useEffect(() => {
  //   if (empId) dispatch(getAttendaceByID({ _id: empId, _date: startDate }));
  // }, [empId, startDate]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const processCells = (params: any) => {
    if (params.accumulatedRowIndex === 0) {
      return params.node.data.visitor.name;
    } else if (params.accumulatedRowIndex === 1) {
      const temp = params.node.data.temperature;
      if (temp === undefined) {
        return "--";
      }

      return temp ? `${temp?.value} ${temp?.measure}` : "--";
    } else if (params.accumulatedRowIndex === 2) {
      const inTime = params.node.data.clocked_in_at;
      if (inTime === null) {
        return "--";
      }
      return inTime ? `${getTimeFormat(inTime)}` : "--";
    } else if (params.accumulatedRowIndex === 3) {
      const outTime = params.node.data.clocked_out_at;
      if (outTime === null) {
        return "--";
      }
      return outTime ? `${getTimeFormat(outTime)}` : "--";
    } else if (params.accumulatedRowIndex === 4) {
      const purpose = params.node.data.purpose;
      if (purpose === undefined) {
        return "--";
      }
      return params.node.data.purpose;
    } else if (params.accumulatedRowIndex === 5) {
      const clocked_out_at = params.node.data.clocked_out_at;
      if (params.node.data) {
        return params.node.data?.clocked_out_at ? "Checkedout" : "Checkedin";
        return "--";
      }
      return params.node.data.clocked_out_at;
    } else return params.value;
  };
  const getParams = () => {
    return {
      // @ts-ignore

      processCellCallback: (params) => processCells(params),
    };
  };
  const onBtnExport = () => {
    // @ts-ignore
    gridApi.exportDataAsCsv(getParams());
  };

  useUpdateEffect(() => {
    setRowData(visitorList);
    setColDefs(coldefss);
  }, [visitorList, startDate]);

  useUpdateEffect(() => {
    setRowPreInviteData(preInvitations);
    setColPreInvite(colPreInvite);
  }, [preInvitations]);

  useUpdateEffect(() => {
    setUnknownVisitorsData(unknownVisitors);
    setColUnknownVisitors(colUnknownVisitors);
  }, [unknownVisitors, startDate]);

  useUpdateEffect(() => {
    setRowFeedbackData(feedbackDataList);
    setColFeedback(colDefFeedback);
  }, [feedbackDataList, startDate]);

  useEffect(() => {
    setColDefs(coldefss);
    setColPreInvite(coldefssPreInvite);
    setColUnknownVisitors(colUnknownVisitorsDefss);
    setColFeedback(colDefFeedback);
  }, [tableFullScreen]);

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);

    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };
  const handleVisitorViewclick = (id: string) => {
    dispatch(setVisitorId(id));
    setTableFullScreen(false);
    dispatch(togglePanel());
    dispatch(getVisitorByID({ _id: id, _date: startDate }));
    // dispatch(getVisitorActivityById({_id: id, page_index: 0}))
  };

  const handleIconChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  useUpdateEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getVisitorsData({
          from_date: startDate,
          to_date: startDate,
          ...payload,
          search: searchTerm,
        })
      );
    };

    const timer = setTimeout(() => {
      if (searchTerm.length >= 3 || searchTerm.length === 0) {
        fetchData();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleValue = (event: any) => {
    if (event.key === "Enter") {
      console.log("times Enter");
      dispatch(
        getVisitorsData({
          from_date: startDate,
          to_date: startDate,
          ...payload,
          search: searchTerm,
        })
      );
    }
  };

  const onCancle = () => {
    dispatch(togglePanel());
    setIsView(false);
    setIsNew(false);
  };

  const handleCloseIcon = () => {
    setSearchTerm("");

    dispatch(
      getVisitorsData({
        from_date: startDate,
        to_date: startDate,
        ...payload,
      })
    );
  };
  const onPageSizeChanged = () => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      ?.value;
    //@ts-ignore
    gridApi.paginationSetPageSize(Number(value));
    setPageSize(Number(value));
  };

  const onPageNum = (e: any, num: any) => {
    setPageIndex(num - 1);
  };

  const handleFilter = (event: any) => {
    setFilterOn(event?.value);
    setFilterLable(event?.label);
    if (event?.value === "all") {
      setRowFeedbackData(feedbackDataList);
    } else {
      setRowFeedbackData(
        feedbackDataList.filter((item) => item?.feedback?.rate == event?.value)
      );
    }
  };

  const onClickShow = useCallback((params) => {
    (gridRef.current as AgGridReact).api?.forEachNode((node: any) => {
      node.setExpanded(true);
    });
  }, []);

  return (
    <>
      <div className="visitor h-100 d-flex flex-column">
        <div className="mb-3 bg-white">
          <div className="row d-flex justify-content-between align-items-center">
            <ul className="nav visitor-tabs">
              <li className={`${activeTab === 0 ? "active" : ""}`}>
                <button className="btn" onClick={() => setActiveTab(0)}>
                  Walk-in Visitors
                </button>
              </li>
              {
                <li className={`${activeTab === 1 ? "active" : ""}`}>
                  <button className="btn" onClick={() => setActiveTab(1)}>
                    Pre-Invitation Visitors
                  </button>
                </li>
              }
              {
                <li className={`${activeTab === 2 ? "active" : ""}`}>
                  <button className="btn" onClick={() => setActiveTab(2)}>
                    Unknown Visitors
                  </button>
                </li>
              }
              {
                <li className={`${activeTab === 3 ? "active" : ""}`}>
                  <button className="btn" onClick={() => setActiveTab(3)}>
                    Visitors Feedback
                  </button>
                </li>
              }
            </ul>
          </div>
        </div>

        {[
          rowPreInviteData,
          rowData,
          rowFeedbackData,
          unknownVisitorsData,
        ].every((data) => !Boolean(data)) ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : null}

        {activeTab === 0 && (
          <>
            {/* {acutalDate.toString()} */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <div className="input-group search-field">
                  <input
                    type="text"
                    className="form-control"
                    id="filter-text-box"
                    onKeyPress={handleValue}
                    value={searchTerm}
                    onChange={handleIconChange}
                    // onInput={handleInput}
                    placeholder="Search by employee Name"
                  />

                  <span className="input-group-text">
                    {searchTerm ? (
                      <CloseIcon onClick={handleCloseIcon} />
                    ) : (
                      <SearchIcon />
                    )}
                  </span>
                </div>

                <div className="d-flex">
                  <Tooltip
                    title={"Filters"}
                    placement="bottom"
                    color="secondary"
                    arrow
                  >
                    <button
                      onClick={() => dispatch(toggleFilter())}
                      className={`filter-btn ml-3 ${
                        isFilterOpen ? "active" : ""
                      }`}
                    >
                      <FilterListIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
              {matrixCount ? (
                <div className="matrix-container flex-grow-1 d-flex justify-content-end">
                  <MatrixLabel
                    label={"Checkedin"}
                    count={`${matrixCount.clocked_in}`}
                    iClass={"d-in"}
                  >
                    <DirectionsWalkIcon />
                  </MatrixLabel>
                  <MatrixLabel
                    label={"Checkedout"}
                    count={`${matrixCount.clocked_out}`}
                    iClass={"d-out"}
                  >
                    <DirectionsWalkIcon />
                  </MatrixLabel>
                  <MatrixLabel
                    label={"Not Normal"}
                    count={`${matrixCount.not_normal}`}
                    iClass={""}
                  >
                    <ThermostatIcon />
                  </MatrixLabel>
                </div>
              ) : null}
              <div className="d-inline-flex">
                <div className="export-border pe-0 ps-0 pt-2 pb-2">
                  <Tooltip
                    title={"Table View"}
                    placement="bottom"
                    color="secondary"
                    arrow
                  >
                    <button
                      onClick={() => toggleTableGridView("table")}
                      className={`viewToggle-btn ${
                        tableGridView === "table" ? "active" : ""
                      }`}
                    >
                      <TocIcon />
                    </button>
                  </Tooltip>
                  <Tooltip
                    title={"Grid View"}
                    placement="bottom"
                    color="secondary"
                    arrow
                  >
                    <button
                      onClick={() => toggleTableGridView("grid")}
                      className={`viewToggle-btn m-0 ${
                        tableGridView === "grid" ? "active" : ""
                      }`}
                    >
                      <GridViewIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="main-panel d-flex flex-column">
              {/* filter panel */}
              <FilterPanel>
                <Visitorfilter />
              </FilterPanel>

              {/* AG grid panel  */}

              <div
                className={`flex-grow-1 table-container ${
                  tableFullScreen ? "full-scrn" : ""
                }`}
                style={{ height: "100%", width: "100%" }}
              >
                <FullscreentTitle
                  title={"Walk-in Visitors"}
                  tableFullScreen={tableFullScreen}
                />
                {rowData?.length > 0 && (
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: "95%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {tableGridView === "table" && (
                      <AgGridReact
                        frameworkComponents={{
                          employeeNameWithProfileRenderer:
                            VisitorNameWithProfile,
                        }}
                        reactUi={true}
                        rowClass={"minin-class"}
                        getRowClass={getRowClass}
                        pagination={true}
                        paginationPageSize={pageSize}
                        rowData={rowData}
                        rowHeight={100}
                        rowSelection={"single"}
                        columnDefs={colDefs}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={"loadingTemplate"}
                        overlayNoRowsTemplate={
                          "No visitors found. Please try with another date."
                        }
                        suppressPaginationPanel={true}
                      ></AgGridReact>
                    )}
                    {tableGridView === "grid" && (
                      <div className="row">
                        {visitorList.length > 0 &&
                          visitorList.map((visitor) => (
                            <PersonCard
                              key={visitor._id}
                              type={"visitor"}
                              person={visitor}
                              action={() =>
                                handleVisitorViewclick(visitor.visitor._id)
                              }
                            />
                          ))}
                      </div>
                    )}
                    <div className="d-flex justify-content-between pagination-box bg-white p-2">
                      <select
                        onChange={onPageSizeChanged}
                        id="page-size"
                        className="pagination-dropdown"
                        value={pageSize}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>

                      <Pagination
                        count={Math.ceil(totalRecords / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={onPageNum}
                      />
                    </div>
                  </div>
                )}
                {rowData?.length < 1 && (
                  <CAlert>
                    No visitors found. Please try with another date.
                  </CAlert>
                )}
              </div>

              {/* sidepanel  */}
              <SidePanel>
                <VistorPanel />
              </SidePanel>
            </div>
          </>
        )}

        {activeTab === 1 && (
          <>
            {rowPreInviteData?.length > 0 && (
              <div
                className={`flex-grow-1 table-container ${
                  tableFullScreen ? "full-scrn" : ""
                }`}
              >
                <FullscreentTitle
                  title={"Pre-Invitation Visitors"}
                  tableFullScreen={tableFullScreen}
                />

                <div
                  className="ag-theme-alpine"
                  style={{
                    height: "calc(100% - 50px)",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <AgGridReact
                    frameworkComponents={{
                      employeeNameWithProfileRenderer:
                        EmployeeNameWithProfileRenderer,
                    }}
                    reactUi={true}
                    rowClass={"minin-class"}
                    getRowClass={getRowClass}
                    pagination={true}
                    rowData={rowPreInviteData}
                    rowHeight={100}
                    rowSelection={"single"}
                    columnDefs={colPreInvite}
                    onGridReady={onGridReady}
                    paginationPageSize={pageSize}
                    suppressPaginationPanel={true}
                  ></AgGridReact>
                  <div className="d-flex justify-content-between pagination-box bg-white p-2">
                    <select
                      onChange={onPageSizeChanged}
                      id="page-size"
                      className="pagination-dropdown"
                      value={pageSize}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>

                    <Pagination
                      count={Math.ceil(totalRecords / pageSize)}
                      showFirstButton
                      showLastButton
                      onChange={onPageNum}
                    />
                  </div>
                </div>
              </div>
            )}
            {rowPreInviteData?.length < 1 && (
              <CAlert>
                No Pre-Invitations found. Please try with another date.
              </CAlert>
            )}
            <SidePanel>
              {isOpen && (
                <NewInvitation
                  onCancel={() => onCancle()}
                  isNew={isNew}
                  isView={isView}
                  selectedVis={selectedVisitor}
                />
              )}
              {/* {isView && <EditInvitation />} */}
            </SidePanel>
          </>
        )}

        {activeTab === 2 && (
          <>
            {unknownVisitorsData?.length > 0 && (
              <div
                className={`flex-grow-1 table-container ${
                  tableFullScreen ? "full-scrn" : ""
                }`}
              >
                <FullscreentTitle
                  title={"Unknown Visitors"}
                  tableFullScreen={tableFullScreen}
                />

                <div
                  className="ag-theme-alpine"
                  style={{
                    height: "calc(100% - 50px)",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <AgGridReact
                    frameworkComponents={{
                      employeeNameWithProfileRenderer:
                        EmployeeNameWithProfileRenderer,
                    }}
                    reactUi={true}
                    rowClass={"minin-class"}
                    getRowClass={getRowClass}
                    pagination={true}
                    rowData={unknownVisitorsData}
                    rowHeight={100}
                    rowSelection={"single"}
                    columnDefs={colUnknownVisitors}
                    onGridReady={onGridReady}
                    paginationPageSize={pageSize}
                    suppressPaginationPanel={true}
                  ></AgGridReact>
                  <div className="d-flex justify-content-between pagination-box bg-white p-2">
                    <select
                      onChange={onPageSizeChanged}
                      id="page-size"
                      className="pagination-dropdown"
                      value={pageSize}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>

                    <Pagination
                      count={Math.ceil(totalUnknownVisitorsRecords / pageSize)}
                      showFirstButton
                      showLastButton
                      onChange={onPageNum}
                    />
                  </div>
                </div>
              </div>
            )}
            {unknownVisitorsData?.length < 1 && (
              <CAlert>
                No Unknown visitors found. Please try with another date.
              </CAlert>
            )}
            <SidePanel>
              {isOpen && (
                <NewInvitation
                  onCancel={() => onCancle()}
                  isNew={isNew}
                  isView={isView}
                  selectedVis={selectedVisitor}
                />
              )}
              {/* {isView && <EditInvitation />} */}
            </SidePanel>
          </>
        )}

        {activeTab === 3 && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Select
                className="drop-select"
                styles={colourStyles}
                options={dropdown}
                isSearchable={false}
                value={{
                  value: `${filterOn}`,
                  label: filterLable,
                }}
                onChange={handleFilter}
              />
              <div className="matrix-icon-panel align-self-end d-flex justify-content-end">
                <MatrixIconPanel count={`${feedbackMatrix.excellent}`}>
                  <SmileyIcon className="e-icon" />
                </MatrixIconPanel>
                <MatrixIconPanel count={`${feedbackMatrix.good}`}>
                  <SmileyIcon className="g-icon" />
                </MatrixIconPanel>
                <MatrixIconPanel count={`${feedbackMatrix.okay}`}>
                  <SmileyIcon className="o-icon" />
                </MatrixIconPanel>
                <MatrixIconPanel count={`${feedbackMatrix.bad}`}>
                  <SmileyIcon className="b-icon" />
                </MatrixIconPanel>
                <MatrixIconPanel
                  count={`${feedbackMatrix.worst}`}
                  islastChild={true}
                >
                  <SmileyIcon className="w-icon" />
                </MatrixIconPanel>
              </div>
            </div>
            {rowFeedbackData?.length > 0 && (
              <div
                className={`flex-grow-1 table-container ${
                  tableFullScreen ? "full-scrn" : ""
                }`}
              >
                <FullscreentTitle
                  title={"Visitors Feedback"}
                  tableFullScreen={tableFullScreen}
                />

                <div
                  className="ag-theme-alpine"
                  style={{
                    height: "calc(100% - 50px)",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    frameworkComponents={{
                      employeeNameWithProfileRenderer:
                        EmployeeNameWithProfileRenderer,
                    }}
                    masterDetail={true}
                    detailCellRenderer={DetailRowRenderer}
                    reactUi={true}
                    rowClass={"minin-class"}
                    getRowClass={getRowClass}
                    pagination={true}
                    rowData={rowFeedbackData}
                    rowHeight={100}
                    rowSelection={"single"}
                    columnDefs={colFeedback}
                    onGridReady={onGridReady}
                    paginationPageSize={pageSize}
                    suppressPaginationPanel={true}
                    detailRowHeight={100}
                    embedFullWidthRows={true}
                  ></AgGridReact>
                  <div className="d-flex justify-content-between pagination-box bg-white p-2">
                    <select
                      onChange={onPageSizeChanged}
                      id="page-size"
                      className="pagination-dropdown"
                      value={pageSize}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>

                    <Pagination
                      count={Math.ceil(totalRecords / pageSize)}
                      showFirstButton
                      showLastButton
                      onChange={onPageNum}
                    />
                  </div>
                </div>
              </div>
            )}
            {rowFeedbackData?.length < 1 && (
              <CAlert>No visitors found. Please try with another date.</CAlert>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Visitors;
