import "./profile-card.scss";
import SwitchInput from "@root/components/SwitchInput";
import { getPrettyDate } from "@root/utils/date";
import { useToggle } from "@mantine/hooks";
import { UseQueryResult } from "react-query";
import { useHistory } from "react-router";
import { logoutSuccess } from "@root/actions/auth";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import CAlert from "../CAlert";
import classNames from "classnames";
import { GoSignOut } from "react-icons/go";

type ProfileCardProps = {
  meAndCompanyQuery: UseQueryResult<ProfileAndCompanyState>;
  user: any;
};

function ProfileCard(props: ProfileCardProps) {
  const userDataMap = {
    "Emp ID": props.user.custom_id,
    Status: props.user.status,
    Phone: props.user.phone,
    Email: props.user.email,
    "Date of Birth": getPrettyDate(props.user.dob),
    "Date of Join": getPrettyDate(props.user.joined),
    Designation: props.user.description,
    Role: props.user.role,
    Department: props.user.department,
    Gender: props.user.gender,
  };
  const [isLogoutAlertOpen, toggleLogoutAlert] = useToggle(false, [
    true,
    false,
  ]);

  const [showMyCompanyDetails, toggleCompanyDetails] = useToggle(false, [
    false,
    true,
  ]);
  const history = useHistory();
  const { data: accountData } = props.meAndCompanyQuery;
  const dispatch = useDispatch();
  if (accountData === undefined) {
    return null;
  }
  const companyDataMap = {
    Company: accountData.account.company_name,
    Status: accountData.account.status,
    "Company ID": accountData.account.code,
    Website: accountData.account.website,
    Address: [
      accountData.account.address_1,
      accountData.account.address_2,
    ].join(", "),
    "Account Owner": [
      accountData.admin.first_name,
      accountData.admin.last_name,
    ].join(" "),
    Role: props.user.role,
    "Account Owner Email": accountData.admin.email,
    "Account Owner Phone": accountData.admin.phone,
  };

  const handleLogout: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    dispatch(logoutSuccess());

    history.push("/");
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <>
      <div>
        <div
          className={classNames(
            "flex h-52 items-center justify-center",
            props.user.gender === "m"
              ? "header-background-m"
              : "header-background-f"
          )}
        >
          <div className="absolute right-3 top-3">
            <button
              className="flex items-center rounded-md border-[#FF0000] bg-white px-4 py-3 text-[#FF0000] shadow-md"
              onClick={() => toggleLogoutAlert()}
            >
              <GoSignOut className="mr-2 text-red-500" /> Signout
            </button>
          </div>
          <div>
            <img
              className="mt-1 h-40 w-40 rounded-full bg-white"
              alt={`${props.user.first_name} ${props.user.last_name}`}
              src={props.user?.image}
            />
          </div>
        </div>
        <h1
          className={`text-center text-2xl font-semibold ${
            props.user.gender === "m" ? "text-[#007FFF]" : "text-[#FF0968]"
          }`}
        >
          {props.user.first_name} {props.user.last_name}
        </h1>
        <h2 className="text-center text-base capitalize text-black">
          {props.user.description} | {props.user.role}
        </h2>
        <div className="p-4">
          <div className="mb- flex justify-end">
            <SwitchInput
              hidden
              label="My Company"
              color="secondary"
              checked={showMyCompanyDetails}
              onChange={() => toggleCompanyDetails()}
            />
          </div>
          <div className="mt-4 grid grid-cols-2 gap-5">
            {Object.entries(
              showMyCompanyDetails ? companyDataMap : userDataMap
            ).map(([key, value]) => (
              <ProfileData key={key} label={key} value={value} />
            ))}
          </div>
          {showMyCompanyDetails ? null : (
            <CAlert type="info" className="mt-6">
              Contact your admin or HR team to make any changes in your profile.
            </CAlert>
          )}
        </div>
      </div>
      <div
        className={`vb-modal ${isLogoutAlertOpen ? "open" : ""}`}
        style={{ right: "5px", top: "5px" }}
      >
        <div className="vb-modal-dialog">
          <div className="vb-modal-content">
            <div className="vb-modal-header">
              <p className="vb-modal-title mb-0" id="staticBackdropLabel">
                Are you sure you want to signout?
              </p>
            </div>

            <div className="vb-modal-footer">
              <button
                type="button"
                className="btn btn-outline-dark revoke-btns"
                onClick={() => toggleLogoutAlert()}
              >
                No
              </button>
              <button
                type="submit"
                className="btn btn-gradient revoke-btns"
                onClick={handleLogout}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

type ProfileDataProps = { label: string; value: string };
function ProfileData(props: ProfileDataProps) {
  const valueClassName = `pt-2 capitalize ${
    props.label === "Status" ? "text-[#1E9F00]" : "text-black"
  }`;

  return (
    <div className="flex flex-col">
      <span className="text-[#707070]">{props.label}</span>
      <span className={valueClassName}>{props.value || "-"}</span>
    </div>
  );
}

export default ProfileCard;
