import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { showNotificationError } from "@root/actions/toast-notification";
import {
  googleAuthUrl,
  microsoftAuthUrl,
  disconnectIntegration,
} from "@root/services/integration";

export const getGoogleAuthUrl = createAsyncThunk<Dictionary>(
  "@admin/getGoogle_authUrl",
  async () => {
    try {
      const response = await googleAuthUrl();
      const errMsg: any = response.data;
      if (response.status === 200) {
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return error;
    }
  }
);

export const getMicrosoftAuthUrl = createAsyncThunk<Dictionary>(
  "@admin/getMicrosoft_authUrl",
  async () => {
    try {
      const response = await microsoftAuthUrl();
      const errMsg: any = response.data;

      if (response.status === 200) {
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return error;
    }
  }
);

export const putDisconnectIntegration = createAsyncThunk<
  Dictionary,
  { integration: "google" | "microsoft" },
  { rejectValue: ResponseError }
>("@admin/Disconnect_Integrations", async ({ integration }, thunkAPI) => {
  try {
    const response = await disconnectIntegration(integration);
    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(
        showNotificationError({
          type: "success",
          message: "Disconnected Successfully.",
        })
      );
      return {
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const integrationSlice = createSlice({
  name: "integrations",
  initialState: {
    status: "idle",
    error: "",
    googleUrl: "",
    msUrl: "",
    disconnected: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGoogleAuthUrl.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.googleUrl = "";
    });
    builder.addCase(getGoogleAuthUrl.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.googleUrl = action.payload.data;
    });
    builder.addCase(getGoogleAuthUrl.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = "something went wrong";
    });

    builder.addCase(getMicrosoftAuthUrl.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.msUrl = "";
    });
    builder.addCase(getMicrosoftAuthUrl.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.msUrl = action.payload.data;
    });
    builder.addCase(getMicrosoftAuthUrl.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = "something went wrong";
    });

    // putDisconnectIntegration
    builder.addCase(putDisconnectIntegration.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.disconnected = false;
    });
    builder.addCase(putDisconnectIntegration.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.disconnected = action.payload.status ? true : false;
    });
    builder.addCase(putDisconnectIntegration.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = "something went wrong";
      state.disconnected = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.integrations;

export const googleURLSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.googleUrl
);
export const microsoftURLSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.msUrl
);
export const isDisconnectedIntegration = createDraftSafeSelector(
  selfSelect,
  (state) => state.disconnected
);

export default integrationSlice.reducer;
