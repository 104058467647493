import React from "react";
import { logoutSuccess, terminationDetailSelector } from "@root/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./AccountTerminated.scss";
import ErrorIcon from "@mui/icons-material/Error";

const AccountTerminated = (prps: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const terminationDetail = useSelector(terminationDetailSelector);

  const onOkay = () => {
    //force logout
    dispatch(logoutSuccess());

    history.push("/");
    localStorage.clear();
  };

  return (
    <>
      <div className="freeze-window d-flex justify-content-center align-items-start">
        <div className="freeze-popup shadow bg-white p-4">
          <div className="acc-alert d-flex align-items-center">
            <ErrorIcon />
            &nbsp;&nbsp;
            <p className="mb-0">ACCOUNT ALERT</p>
          </div>
          <div className="freeze-content">
            <p>{terminationDetail.msg}</p> <br />
            {terminationDetail.contact && (
              <div>
                <p className="p-lable mb-1">Admin details</p>
                <p className="mb-0 bold">{`${terminationDetail.contact.first_name} ${terminationDetail.contact.last_name}`}</p>
                <p className="mb-0">{terminationDetail.contact.email}</p>
              </div>
            )}
          </div>
          <div>
            <button onClick={onOkay} className="btn btn-gradient">
              Okay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTerminated;
