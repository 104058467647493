import "./enoticelisting.scss";
import { toggleNotices } from "@root/actions/sidebar";
import { useDispatch } from "react-redux";
import { useUnmount } from "react-use";
import moment from "moment";
import { useInfiniteQuery } from "react-query";
import { getNoticesListForPanel, NoticeType } from "@root/services/notices";
import CAlert from "../CAlert";
import Select from "react-select";
import { colourStyles } from "@root/pages/private/preInvitations";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";

const dropdown = [
  { label: "All", value: "all" },
  { label: "Announcement", value: "announcement" },
  { label: "Alert", value: "alert" },
  { label: "Event", value: "event" },
  { label: "General", value: "general" },
];

const EnoticeListing = (props: any) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<{
    label: String;
    value: string;
  }>({ label: "All", value: "all" });
  const handleSOSClose = () => {
    dispatch(toggleNotices(false));
  };

  useUnmount(() => {
    handleSOSClose();
  });

  const query = useInfiniteQuery({
    queryKey: ["notices", filter?.value],
    queryFn: async ({ pageParam = 0 }) => {
      return await (
        await getNoticesListForPanel(
          25,
          pageParam,
          filter === null ? "all" : (filter.value as NoticeType)
        )
      ).data;
    },
    getNextPageParam: (lastPage: any, allPages) => {
      return lastPage!.has_next ? allPages.length : undefined;
    },
  });

  const handleFilter = (value: any) => {
    setFilter(value);
  };

  return (
    <div className="enotice-list-container">
      <div className="sos-header p-0 py-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex-grow-1">
            <h6 className="font-semibold">eNotice Board</h6>
          </div>
          <div className="w-50">
            <Select
              className="drop-select"
              styles={colourStyles}
              options={dropdown}
              isSearchable={false}
              value={filter}
              onChange={handleFilter}
            />
          </div>
        </div>
      </div>
      <div className="sos-content">
        {query.isLoading ? (
          <div className="pt-20">
            <Spinner isInline />
          </div>
        ) : null}
        {query.data?.pages.map((page: any) =>
          page.data.map((item: any, index: number) => (
            <div className="position-relative item-container" key={index}>
              <div
                className={`d-flex display-col-${item.type} mt-2`}
                key={index}
              >
                {item.active && (
                  <div className="new-title mr-2 text-right">
                    <img
                      src="../../../../assests/images/new.gif"
                      alt="New"
                      height="50"
                      width="50"
                    />
                  </div>
                )}
                <div className="mt-4">{item.title}</div>
                <div className="message">
                  <p>{item.message}</p>
                </div>
                <div className="posted-by">{`-Posted on ${moment(
                  new Date(item.date)
                ).format("DD MMM YYYY")} by ${
                  item.created_by?.first_name + item.created_by?.last_name
                } ${item.created_by?.description}`}</div>
              </div>
              <div className={`type-container-${item.type} ${item.type}`}>
                {item.type}
              </div>
            </div>
          ))
        )}
        {query.hasNextPage ? (
          <div className="my-5 flex justify-center">
            <button
              type="button"
              onClick={() => query.fetchNextPage()}
              className={"btn btn-gradient"}
              disabled={query.isFetching}
            >
              {query.isFetching ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : null}
        {query.isSuccess &&
        Array.isArray(query.data.pages) &&
        query.data.pages.length > 0 &&
        query.data.pages[0]?.data?.length === 0 ? (
          <CAlert>No eNotices at this moment.</CAlert>
        ) : null}
      </div>
    </div>
  );
};

export default EnoticeListing;
