import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";

const metadataSlice = createSlice({
  name: "metadata",
  initialState: {
    title: "",
    showDatePicker: false,
    showEmpListFilter: false,
    dateValue: "",
    empListfilterValue: "approved",
    openGlSidePanel: false,
    showsendLink: false,
    showReportBtn: false,
    mapView: false,
    showMapViewSwitch: false,
    futureDates: false,
    userLocation: { lat: 0, lng: 0 },
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    updateDatePickerStatus: (state, action) => {
      state.showDatePicker = action.payload;
    },
    updatesendLinkStatus: (state, action) => {
      state.showsendLink = action.payload;
    },
    updateEmpListFilterStatus: (state, action) => {
      state.showEmpListFilter = action.payload;
    },
    setDateValue: (state, action) => {
      state.dateValue = action.payload;
    },
    setEmpListFilterValue: (state, action) => {
      state.empListfilterValue = action.payload;
    },
    toggleGlSidePanel: (state, action) => {
      state.openGlSidePanel = action.payload;
    },
    updateReportBtnStatus: (state, action) => {
      state.showReportBtn = action.payload;
    },
    toggleMapView: (state, action) => {
      state.mapView = action.payload;
    },
    updateMapviewSwitchStatus: (state, action) => {
      state.showMapViewSwitch = action.payload;
    },
    toggleFutureDates: (state, action) => {
      state.futureDates = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
  },
});

const selfSelect = (state: StoreDef) => state.metadata;

export const titleSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.title
);
export const datePickerStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.showDatePicker
);
export const sendLinkStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.showsendLink
);

export const showEmpListFilterSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.showEmpListFilter
);

export const dateValueSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.dateValue
);

export const empListfilterValueSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.empListfilterValue
);

export const glSidePanelStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.openGlSidePanel
);

export const reportBtnStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.showReportBtn
);

export const mapViewSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.mapView
);
export const showMapviewSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.showMapViewSwitch
);
export const enableFutureDates = createDraftSafeSelector(
  selfSelect,
  (state) => state.futureDates
);
export const userGeoLocation = createDraftSafeSelector(
  selfSelect,
  (state) => state.userLocation
);

// export const pusherSelector = createDraftSafeSelector(selfSelect, state => state.pusher);

export const {
  setTitle,
  updateDatePickerStatus,
  updatesendLinkStatus,
  updateEmpListFilterStatus,
  setDateValue,
  setEmpListFilterValue,
  toggleGlSidePanel,
  updateReportBtnStatus,
  toggleMapView,
  updateMapviewSwitchStatus,
  toggleFutureDates,
  setUserLocation,
} = metadataSlice.actions;

export default metadataSlice.reducer;
