import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getOtpService, validateOtpService } from "@root/services/auth";
import { StoreDef } from "@root/store";
import * as serviceWorker from "@root/serviceWorker";

const initialState: LoginState = {
  isAuthorised: false,
  isOTPGen: false,
  status: "idle",
  error: null,
  phone: null,
  token: null,
  loading: false,
  role: "",
  isAccTerminated: false,
  terminationDetail: {
    msg: "",
  },
};

export const makeLoginRequest = createAsyncThunk<
  // Return type of the payload creator
  ReturnTypeLogin,
  { phone: string; account_id: string },
  { rejectValue: ResponseError }
>("@admin/make_login", async ({ phone, account_id }, thunkApi) => {
  try {
    const response = await getOtpService(phone, account_id);
    const errMsg: any = response.data;

    return response.status === 200
      ? { phone: phone, isSuccess: true }
      : { phone: "", isSuccess: false, error: errMsg };
  } catch (error) {
    return thunkApi.rejectWithValue(error as ResponseError);
  }
});

export const makeOTPRequest = createAsyncThunk<
  Dictionary,
  { phone: string; otp: string; account_id: string },
  { rejectValue: ResponseError }
>("auth/otpverify", async ({ phone, otp, account_id }, thunkAPI) => {
  try {
    const response: any = await validateOtpService({
      phone,
      sms_otp: otp,
      account_id,
    });
    const errMsg: any = response.data;
    return response.status === 200
      ? {
          token: response["data"]["token"],
          role: response.data.data.role,
          _id: response.data.data._id,
          error: null,
          acc_id: response.data.data.account_id,
          pusher: response.data.data.pusher,
          vms_only: response.data.data.vms_only,
        }
      : { error: errMsg };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    invalidOtpSubmission: (state, action) => {
      state.error = action.payload.error;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthorised = true;
      state.status = "idle";
      state.token = action.payload.token;
      serviceWorker.register();
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.isAuthorised = false;
      state.isOTPGen = false;
      state.status = "idle";
      state.token = null;
      state.error = null;
      state.phone = null;
      // serviceWorker.unregister();
    },
    setLoadingStatus: (state, action) => {
      state.loading = action.payload;
    },
    hasOtpGenerated: (state) => {
      state.isOTPGen = false;
      state.phone = null;
      state.isAuthorised = false;
    },
    accTerminated: (state, action) => {
      state.terminationDetail.contact = undefined;
      state.isAccTerminated = action.payload.status;
      state.terminationDetail.msg = action.payload.msg;
      if (action.payload.contact)
        state.terminationDetail.contact = action.payload.contact;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(makeLoginRequest.pending, (state) => {
      state.status = "loading";
      state.phone = null;
      state.error = null;
      state.isOTPGen = false;
    });
    builder.addCase(makeLoginRequest.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.error = payload.error?.error!;
      state.phone = payload.phone;
      state.isOTPGen = payload.isSuccess;
    });
    builder.addCase(makeLoginRequest.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response.data.error || "something is wrong";
      state.isOTPGen = false;
      state.phone = null;
    });

    builder.addCase(makeOTPRequest.pending, (state) => {
      state.status = "loading";
      state.token = null;
      state.error = null;
    });
    builder.addCase(makeOTPRequest.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.token = payload.token;
      state.isAuthorised = true;
      state.error = payload.error?.error!;
      state.role = payload.role;
      state.isAccTerminated = payload.token ? false : true;

      localStorage.setItem("Role", payload.role);
      localStorage.setItem("vms_only", payload.vms_only);
      localStorage.setItem("account_id", payload.acc_id);
      localStorage.setItem("userId", payload._id);
      localStorage.setItem("pusher", JSON.stringify(payload.pusher));
    });
    builder.addCase(makeOTPRequest.rejected, (state, { payload }) => {
      state.status = "idle";
      state.token = null;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.auth;

export const hasOTPSuccessSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.isOTPGen
);
export const loadingSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status === "loading"
);

export const tokenSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.token
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const isAdminAuthorised = createDraftSafeSelector(
  selfSelect,
  (state) => state.isAuthorised
);
export const otpRegisteredPhone = createDraftSafeSelector(
  selfSelect,
  (state) => state.phone
);
export const getLoadingStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.loading
);
export const fetchSpinnerSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const employeeRoleSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.role
);
export const accStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.isAccTerminated
);
export const terminationDetailSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.terminationDetail
);

export const {
  loginSuccess,
  logoutSuccess,
  setLoadingStatus,
  hasOtpGenerated,
  accTerminated,
  invalidOtpSubmission,
} = authSlice.actions;

export default authSlice.reducer;
