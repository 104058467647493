import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { demoteEmployee } from "@root/services/demote-employee";
import { StoreDef } from "@root/store";

interface demoteEmployeeState {
  status: "idle" | "loading";
  message: string;
  error: string;
  hasDemoted: boolean;
}

const initialState: demoteEmployeeState = {
  status: "idle",
  message: "",
  error: "",
  hasDemoted: false,
};

export const getDemotedEmployee = createAsyncThunk<
  any,
  { id: string },
  { rejectValue: ResponseError }
>("demote/employee", async ({ id }, thunkAPI) => {
  try {
    const response = await demoteEmployee(id);
    const errMsg: any = response.data;
    return response.status === 200
      ? {
          message: response.data,
          status: true,
        }
      : { status: false, error: errMsg };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});
const demoteEmployeeSlice = createSlice({
  name: "demoteEmployee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDemotedEmployee.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.hasDemoted = false;
    });
    builder.addCase(getDemotedEmployee.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.hasDemoted = action.payload.status;
      state.message = { ...action.payload.message };
    });
    builder.addCase(getDemotedEmployee.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
      state.hasDemoted = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.demoteEmp;

export const messageSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.message
);
export const hasEmployeeDemotedSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasDemoted
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);

export default demoteEmployeeSlice.reducer;
