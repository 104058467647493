import axios from "axios";

export const getAttendance = async (
  from_date: string,
  to_date: string,
  page_size: number,
  page_index: number,
  search?: string,
  temperature?: string,
  only_clockins?: boolean,
  on_time?: string,
  mode?: string,
  status?: string
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/users/employee-attendance`,
    params: {
      only_clockins,
      from_date,
      to_date,
      page_size,
      page_index,
      search,
      temperature,
      on_time,
      mode,
      status,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

// {{base_url}}/users/attendance-single/6161aac86cae7043edb8f04f?date=2021-10-23
export const getAttendaceById = async (_id: string, _date: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/users/attendance-single/${_id}`,
    params: {
      date: _date,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
