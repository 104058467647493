import React, { useEffect, useState } from "react";
import CallIcon from "@material-ui/icons/Call";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EmailIcon from "@material-ui/icons/Email";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import PanelProfile from "../PanelProfile/PanelProfile";
// import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import "./visitor.scss";
import Visitoractivity from "../visitoractivity";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { useDispatch, useSelector } from "react-redux";
import {
  feedbackSelector,
  getVisitorFeedbackById,
  hasNextActivitySelector,
  resetVisitorInfo,
  visitorActivitySelector,
  visitorDataSelector,
} from "@root/actions/getvisitor";
// import ClockInLabel from '../ClockInLabel';
import Checkinlabel from "../checkinlabel";
import SmsIcon from "@mui/icons-material/Sms";
import { updateMsgFlag } from "@actions/sendMessage";
// import CloseIcon from "@material-ui/icons/Close";
import SendMessage from "../sendMessage";
import { useUpdateEffect } from "react-use";
import { panelStatusSelector } from "@root/actions/attendance";
import { startDateStringSelector } from "@root/actions/header";

export default function VisitorPanel() {
  const startDate = useSelector(startDateStringSelector);
  const isOpen = useSelector(panelStatusSelector);
  const visitorData = useSelector(visitorDataSelector);
  const visitorActivity = useSelector(visitorActivitySelector);
  const hasNextActivity = useSelector(hasNextActivitySelector);
  const visitorFeedback = useSelector(feedbackSelector);
  const [acPageIndx, setAcPageIndx] = useState(0);
  const [activeTab, setActiveTab] = useState<"details" | "feedback">("details");
  const [msg, setMsg] = useState(false);
  const dispatch = useDispatch();
  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };
  const getLabel = (obj: any) => {
    return obj.clocked_out_at ? (
      <Checkinlabel status={"checkout"} />
    ) : (
      <Checkinlabel status={"checkin"} />
    );
  };
  const handleMessageClick = (event: any) => {
    event.preventDefault();
    setMsg(true);
  };
  const toggleTabs = (tab: "details" | "feedback") => {
    setActiveTab(tab);
  };
  const handleclosemsgClick = () => {
    setMsg(false);
    dispatch(updateMsgFlag(false));
  };
  const getDay = (timeString: string) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const time = new Date(timeString);
    return `${days[time.getUTCDay()]}`;
  };
  useUpdateEffect(() => {
    if (isOpen) {
      dispatch(resetVisitorInfo);
    } else {
      setMsg(false);
      setActiveTab("details");
    }
  }, [isOpen]);

  useEffect(() => {
    if (visitorData.visitor._id)
      dispatch(
        getVisitorFeedbackById({
          _id: visitorData.visitor._id,
          date: startDate,
        })
      );
  }, [visitorData]);

  const getDateFormat = (timeString: string) => {
    const time = new Date(timeString);
    return `${time.getUTCFullYear()}-${
      time.getUTCMonth() + 1
    }-${time.getUTCDate()}`;
    // return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
  };

  const getMetwith = (activities: any[]) => {
    const metWith: any[] = [];

    activities.forEach((el) => {
      if (el.invited_by) {
        metWith.push(el.invited_by?.first_name);
      }
    });

    return metWith.join(", ");
  };

  return (
    <>
      <div className="d-flex flex-column h-100 position-relative">
        <div className="top-wrapper">
          {/* status label  */}
          <div className="status-label-container d-flex justify-content-center align-items-center">
            {visitorData.checkin ? getLabel(visitorData.checkin) : null}
          </div>
          {/* title haeder */}
          <div className="pt-2 d-flex justify-content-between align-items-center title-header">
            <div>
              <p className="mb-0 font-500 title">View Visitor</p>
            </div>
            <div className="icons-container d-flex">
              {/* <a
                className="icons"
                href='abc@viba.com'
                target="__blank"
                onClick={handleMessageClick}

              >
              <SmsIcon/>
              </a> */}

              <a
                className="icons"
                href={`tel:${visitorData.visitor.phone}`}
                target="__blank"
              >
                <CallIcon></CallIcon>
              </a>
            </div>
          </div>

          {/* panel-profile  */}
          <div
            style={{ marginTop: "-20px" }}
            className="d-flex justify-content-center minus-m"
          >
            <PanelProfile
              image={visitorData?.visitor?.image}
              name={visitorData?.visitor?.name}
              designation={`${visitorData?.visitor?.phone?.slice(
                0,
                2
              )} ${visitorData?.visitor?.phone?.slice(
                2,
                7
              )} ${visitorData?.visitor?.phone?.slice(7)}`}
            />

            {/* {visitorData.checkin.clocked_in_at} */}
          </div>
          {/* tabs */}
          <div className="pb-3">
            <div className="link-tabs d-flex">
              <button
                onClick={() => toggleTabs("details")}
                className={`link-tab-btn ${
                  activeTab === "details" ? "active" : ""
                }`}
              >
                {" "}
                Visitor Details{" "}
              </button>
              <button
                onClick={() => toggleTabs("feedback")}
                className={`link-tab-btn ${
                  activeTab === "feedback" ? "active" : ""
                }`}
              >
                {" "}
                Visitor Feedback{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="h-full flex-grow-1">
          {activeTab === "details" && (
            <>
              <div className="vistor-date">
                <div>
                  <h6>
                    {" "}
                    <span className="visitorspan">Date:&nbsp;</span>{" "}
                    {getDay(visitorData.checkin.clocked_in_at)},&nbsp;
                    {getDateFormat(visitorData.checkin.clocked_in_at)}
                  </h6>
                </div>
                <div>
                  {/* <h6> <span className="visitorspan">Purpose : </span> {visitorData.visitor.purpose}</h6> */}
                </div>
              </div>
              <div className="card-container d-flex mb-2 mt-3">
                <div className="deatail-card">
                  <p>Temperature</p>
                  <div className="d-flex align-items-center">
                    <div className="icon">
                      <ThermostatIcon />
                    </div>
                    <div className="card-info">
                      {visitorData.checkin ? (
                        <div className="">
                          {visitorData?.checkin?.temperature ? (
                            <>
                              <p
                                className={`mb-0 line-height1 ${
                                  visitorData.checkin.temperature.measure ===
                                  "F"
                                    ? visitorData.checkin.temperature.value >
                                      99.5
                                      ? "text-danger"
                                      : "text-success"
                                    : visitorData.checkin.temperature.value >
                                      37.5
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >{`${visitorData.checkin.temperature.value} \u00b0  ${visitorData.checkin.temperature.measure}`}</p>
                              <span
                                className={`${
                                  visitorData.checkin.temperature.measure ===
                                  "F"
                                    ? visitorData.checkin.temperature.value >
                                      99.5
                                      ? "text-danger"
                                      : "text-success"
                                    : visitorData.checkin.temperature.value >
                                      37.5
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {visitorData.checkin.temperature.measure === "F"
                                  ? visitorData.checkin?.temperature?.value >
                                    99.5
                                    ? "Abnormal"
                                    : "Normal"
                                  : visitorData.checkin?.temperature?.value >
                                    37.5
                                  ? "Abnormal"
                                  : "Normal"}
                              </span>
                            </>
                          ) : (
                            <p className={`mb-0 line-height1`}>N/A</p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="deatail-card">
                  <p>Checkedin</p>
                  <div className="d-flex align-items-center">
                    <div className="checkout-icon ">
                      <DirectionsWalkIcon />
                    </div>
                    <div className="card-info">
                      {visitorData.checkin ? (
                        <div className="">
                          {visitorData?.checkin ? (
                            <>
                              <p className="mb-0">
                                {getTimeFormat(
                                  visitorData.checkin.clocked_in_at
                                )}
                              </p>
                            </>
                          ) : (
                            <p className={`mb-0 line-height1`}>N/A</p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="deatail-card">
                  <p>Checkedout</p>
                  <div className="d-flex align-items-center">
                    <div className="checkout-icon out">
                      <DirectionsWalkIcon />
                    </div>
                    <div className="card-info">
                      {visitorData.checkin ? (
                        <div className="">
                          {visitorData?.checkin?.clocked_out_at ? (
                            <>
                              <p className="mb-0">
                                {getTimeFormat(
                                  visitorData.checkin.clocked_out_at
                                )}
                              </p>
                            </>
                          ) : (
                            <p className={`mb-0 line-height1`}>N/A</p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <h6>Visitor History</h6>
                </div>
                <div className="mt-3 vis-Activity">
                  {visitorActivity.length > 0
                    ? visitorActivity.map((activity) => {
                        return (
                          <Visitoractivity
                            key={activity._id}
                            Activity={activity}
                            isLast={
                              visitorActivity[visitorActivity.length - 1] ===
                              activity
                                ? true
                                : false
                            }
                          />
                        );
                      })
                    : null}

                  {hasNextActivity && (
                    <div>
                      <div className="d-flex justify-content-center pt-2">
                        <button
                          onClick={() => setAcPageIndx(acPageIndx + 1)}
                          className="btn-loadmore w-100 bg-white"
                        >
                          Show more activities
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {activeTab === "feedback" && (
            <>
              <div className="feedback-panel p-3">
                {visitorFeedback.length > 0 ? (
                  <>
                    <div className="fb-row d-flex">
                      <div className="first-child">
                        <p className="fb-title mb-2">Date Visited</p>
                        <p>
                          {getDay(visitorData.checkin.clocked_in_at)},&nbsp;
                          {getDateFormat(visitorData.checkin.clocked_in_at)}
                        </p>
                      </div>
                      <div className="flex-grow-1">
                        <p className="fb-title mb-2">Purpose of Visit?</p>
                        <p>{visitorData.visitor.purpose}</p>
                      </div>
                    </div>

                    <div className="fb-row d-flex">
                      <div className="first-child">
                        <p className="fb-title mb-2">Pre-Invitation?</p>
                        <p>
                          {visitorData.checkin?.invitation_id ? "Yes" : "No"}
                        </p>
                      </div>
                      <div className="flex-grow-1">
                        <p className="fb-title mb-2">Met with</p>
                        <p>
                          {visitorData.checkin?.invitation_id
                            ? getMetwith(visitorActivity)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    {visitorFeedback.length > 0 &&
                      visitorFeedback.map((feedback, idx) => {
                        return (
                          <div key={feedback._id}>
                            <div className="fb-row d-flex">
                              <div className="first-child">
                                <p className="fb-title mb-2">
                                  {idx + 1}.1 Overall Experience
                                </p>
                                <p
                                  className={`fb-rate ${feedback.feedback.rate}`}
                                >
                                  {feedback.feedback.rate}
                                </p>
                              </div>
                            </div>
                            <div className="fb-row d-flex">
                              <div className="flex-grow-1">
                                <p className="fb-title mb-2">
                                  {idx + 1}.2 Feedback
                                </p>
                                <p>{feedback.feedback.text}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <p>No feedback yet.</p>
                )}
              </div>
            </>
          )}
        </div>
        {/*Send msg*/}

        {/*Send msg*/}
        <SendMessage
          _class={msg ? "open" : ""}
          onClick={handleclosemsgClick}
          id={visitorData.visitor._id}
        />
      </div>
    </>
  );
}
