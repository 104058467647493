import axios from "axios";

export const getUnknownVisitors = async (
  from_date: string,
  to_date: string,
  page_size: number,
  page_index: number
) => {
  console.log(from_date, to_date, page_size, page_index);

  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/unknown`,
    params: {
      from_date,
      to_date,
      page_size,
      page_index,
    },
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};
