import "./report.scss";
import {
  closeFilter,
  closePanel,
  panelStatusSelector,
} from "@root/actions/attendance";
import { setTitle, updateReportBtnStatus } from "@root/actions/metadata";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allReportsSelector,
  getAllRepotsList,
  hasNextSelector,
  statusSelector,
} from "@root/actions/allReports";
import DownloadIcon from "@mui/icons-material/Download";
import SidePanel from "@root/components/SidePanel/SidePanel";
import ReportPanel from "@root/components/ReportPanel";
import { useUnmount, useUpdateEffect } from "react-use";
import { resetReporGenStatus } from "@root/actions/reportAttendance";
import { dismissToast } from "@root/actions/toast-notification";
import Spinner from "@root/components/Spinner/Spinner";
import Pusher from "pusher-js";
import { saveAs } from "file-saver";
import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import TocIcon from "@mui/icons-material/Toc";
import Tooltip from "@mui/material/Tooltip";
import { togglePanel } from "@root/actions/attendance";
import Select, { StylesConfig } from "react-select";
import { AgGridReact } from "ag-grid-react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenButton from "@root/components/FullscreenButton";
import FullscreentTitle from "@root/components/FullscreentTitle";

const Report = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(panelStatusSelector);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const reports = useSelector(allReportsSelector);
  const [filteredReports, setFilteredReports] = useState(reports);
  const isLoading = useSelector(statusSelector);
  const hasNext = useSelector(hasNextSelector);
  const usr_id = localStorage.getItem("userId");
  const pushr = JSON.parse(localStorage.getItem("pusher")!);
  const [tableGridView, settableGridView] = useState<"table" | "grid">("table");
  const [reportId, setReportId] = useState("");

  let pusher: Pusher;
  const usrChannelId = `usr-${usr_id}`;

  const monts = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      height: "54px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        minWidth: "200px",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "#fff",
        color: isDisabled ? "#000" : isSelected ? "#000" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#fff"
              : "#fff"
            : undefined,
          color: "#000",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      color: "#000",
      minWidth: "150px",
      height: "54px",
    }),
    placeholder: (styles) => ({ ...styles, color: "#000" }),
    singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
    menu: (styles) => ({ ...styles, backgroundColor: "#fff" }),
  };
  const dropdown = [
    { value: "all", label: "All" },
    { value: "attendance", label: "Employee Attendance" },
    { value: "visitor", label: "Visitors" },
    { value: "employee", label: "Employee" },
    { value: "feedback", label: "Feedback" },
  ];
  const [filterOn, setFilterOn] = useState("all");
  const [filterLable, setFilterLable] = useState("All");
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const coldefss = [
    {
      headerName: "REPORT TYPE",
      cellRendererFramework: (params: {
        data: { _id: string; type: string };
      }) => (
        <div className="d-flex align-items-center flex-row">
          <div className="report-card-icon d-flex justify-content-center">
            <img
              src={`../../../../../assests/images/reports_${params?.data?.type}Icon.png`}
              alt=""
            />
          </div>
          <p className="title m-2 mb-0 text-center">{`${params?.data?.type
            ?.charAt(0)
            .toUpperCase()}${params?.data?.type?.slice(1)}`}</p>
        </div>
      ),
    },
    {
      headerName: "GENERATED DATE",
      cellRendererFramework: (params: {
        data: { _id: string; date: string };
      }) => {
        const _date = new Date(params?.data?.date);
        return (
          <p className="mb-0">{`${_date.getUTCDate()} ${
            monts[_date.getUTCMonth()]
          } ${_date.getUTCFullYear()}`}</p>
        );
      },
    },
    {
      headerName: "GENERATED TIME",
      cellRendererFramework: (params: {
        data: { _id: string; date: string };
      }) => <p className="mb-0">{getTimeFormat(params?.data?.date)}</p>,
    },
    {
      headerName: "GENERATED BY",
      cellRendererFramework: (params: {
        data: {
          created_by: {
            first_name: string;
            last_name: string;
            image: string;
            description: string;
          };
        };
      }) => (
        <div className="employee-profile">
          <div className="profile-picture">
            <img
              src={
                params.data?.created_by?.image !== undefined
                  ? params.data?.created_by?.image
                  : "../../../../assests/images/user.png"
              }
              alt="profile"
            />
          </div>
          <div className="employee-data">
            <p className="d-flex flex-column mb-0">
              {params.data?.created_by?.first_name +
                " " +
                params.data?.created_by?.last_name}
              <span className="role">
                {params.data?.created_by?.description
                  ? params.data?.created_by?.description
                  : null}
              </span>
            </p>
          </div>
        </div>
      ),
    },
    {
      headerName: "ACTIONS",
      cellRendererFramework: (params: {
        data: { _id: string; processed: boolean; url: string };
      }) => (
        <>
          {params?.data?.processed ? (
            <button
              className="grid-action"
              onClick={() => {
                saveFile(params?.data?.url);
                setReportId(params?.data?._id);
              }}
            >
              <DownloadIcon /> &nbsp;Download
            </button>
          ) : (
            <p className="mb-0">Processing...</p>
          )}
        </>
      ),
      headerComponentFramework: (props: any) => {
        return (
          <FullscreenButton
            tableFullScreen={tableFullScreen}
            onTableFUllScreen={onTableFUllScreen}
          />
        );
      },
    },
  ];

  const onTableFUllScreen = () => {
    setTableFullScreen(!tableFullScreen);
  };

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [colDefs, setColDefs] = useState(coldefss);
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const toggleTableGridView = (view: "table" | "grid") => {
    settableGridView(view);
  };
  const handleFilter = (event: any) => {
    setFilterOn(event?.value);
    setFilterLable(event?.label);
    setFilteredReports(
      event?.value === "all"
        ? reports
        : reports.filter((el) => el.type === event?.value)
    );
  };

  const getRowClass = (params: any) => {
    if (params.data?._id === reportId) {
      return "active";
    }
  };

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  const saveFile = (url: string) => {
    saveAs(url, "report.xlsx");
  };

  const getDateFrmt = (dd: string) => {
    const _date = new Date(dd);
    return (
      <>
        <p className="mb-0 text-center">{`${_date.getUTCDate()} ${
          monts[_date.getUTCMonth()]
        } ${_date.getUTCFullYear()}`}</p>
        <p className="mb-0 text-center">{getTimeFormat(dd)}</p>
      </>
    );
  };

  useEffect(() => {
    if (pushr) {
      pusher = new Pusher(pushr.api_key, {
        cluster: pushr.cluster,
      });

      // user specific channel
      const usrChannel = pusher.subscribe(usrChannelId);
      usrChannel.bind("report-processed", (data: any) => {
        console.log(data);
      });

      return () => {
        usrChannel.unbind_all();
        usrChannel.unsubscribe();
        if (pusher) pusher.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    dispatch(setTitle("Reports"));
    dispatch(updateReportBtnStatus(false));
    dispatch(closePanel(false));
    dispatch(closeFilter(false));
    setPageIndex(0);
    dispatch(getAllRepotsList({ pageSize: pageSize, pageIndex: pageIndex }));
  }, []);

  useUpdateEffect(() => {
    dispatch(getAllRepotsList({ pageSize: pageSize, pageIndex: pageIndex }));
  }, [pageIndex]);

  useUpdateEffect(() => {
    if (reports && reports.length > 0) {
      setFilteredReports(reports);
    }
  }, [reports]);

  useEffect(() => {
    setColDefs(coldefss);
  }, [tableFullScreen]);

  useUnmount(() => {
    dispatch(updateReportBtnStatus(false));
    dispatch(dismissToast());
    dispatch(resetReporGenStatus());
    if (pusher) pusher.unsubscribe(usrChannelId);
    if (pusher) pusher.disconnect();
  });

  return (
    <>
      <div className="report-container h-100">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Select
            className="drop-select"
            styles={colourStyles}
            options={dropdown}
            isSearchable={false}
            value={{
              value: `${filterOn}`,
              label: filterLable,
            }}
            onChange={handleFilter}
          />
          <div className="flex-grow-1 d-flex justify-content-end align-items-center">
            <div className="export-border pe-0 ps-0 pt-2 pb-2">
              <Tooltip
                title={"Table View"}
                placement="bottom"
                color="secondary"
                arrow
              >
                <button
                  onClick={() => toggleTableGridView("table")}
                  className={`viewToggle-btn ${
                    tableGridView === "table" ? "active" : ""
                  }`}
                >
                  <TocIcon />
                </button>
              </Tooltip>
              <Tooltip
                title={"Grid View"}
                placement="bottom"
                color="secondary"
                arrow
              >
                <button
                  onClick={() => toggleTableGridView("grid")}
                  className={`viewToggle-btn m-0 ${
                    tableGridView === "grid" ? "active" : ""
                  }`}
                >
                  <GridViewIcon />
                </button>
              </Tooltip>
            </div>

            <div className="pl-3">
              <button
                className="btn btn-gradient"
                onClick={() => dispatch(togglePanel())}
              >
                Generate New Report
              </button>
            </div>
          </div>
        </div>

        {tableGridView === "table" && (
          <div className="main-panel d-flex flex-column">
            <div
              className={`flex-grow-1 table-container ${
                tableFullScreen ? "full-scrn" : ""
              }`}
            >
              <FullscreentTitle tableFullScreen={tableFullScreen} />

              <div
                className="ag-theme-alpine"
                style={{ height: "100%", width: "100%", position: "relative" }}
              >
                <AgGridReact
                  reactUi={true}
                  rowClass={"report-class"}
                  pagination={true}
                  rowData={filteredReports}
                  getRowClass={getRowClass}
                  rowHeight={100}
                  rowSelection={"single"}
                  columnDefs={colDefs}
                  onGridReady={onGridReady}
                  paginationPageSize={pageSize}
                  suppressPaginationPanel={true}
                ></AgGridReact>
              </div>
            </div>
          </div>
        )}

        {tableGridView === "grid" && (
          <>
            <div className="reports-card-container row justify-content-start">
              {filteredReports.map((el) => {
                return (
                  <div className="report-card col" key={el._id}>
                    <div>
                      <div className="report-card-icon d-flex justify-content-center mb-2">
                        <img
                          src={`../../../../../assests/images/reports_${el.type}Icon.png`}
                          alt=""
                        />
                      </div>
                      <p className="title mb-0 text-center">{`${el.type
                        .charAt(0)
                        .toUpperCase()}${el.type.slice(1)}`}</p>

                      <div>{getDateFrmt(el.date)}</div>

                      {/* <Link to={el.url}><DownloadIcon/>&nbsp;Download</Link> */}
                      <div className="download-rep d-flex justify-content-center pt-5">
                        {el.processed ? (
                          <button onClick={() => saveFile(el.url)}>
                            <DownloadIcon />
                            &nbsp;Download
                          </button>
                        ) : (
                          <p className="mb-0">Processing...</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {hasNext && (
              <div>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    onClick={() => setPageIndex(pageIndex + 1)}
                    className="btn-loadmore w-100 bg-white"
                  >
                    Show next 25 reports
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {/* sidepanel  */}
        <SidePanel>{isOpen && <ReportPanel />}</SidePanel>
        {isLoading === "loading" && (
          <div className="loader-container">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default Report;
