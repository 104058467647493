import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getEmployeelist } from "@root/services/employee-list";
import { StoreDef } from "@root/store";

const initialState: EmployeeListState = {
  error: null,
  list: [],
  status: "idle",
  countMeta: {
    all: null,
    draft: null,
    approved: null,
    rejected: null,
    in_review: null,
  },
  matrix: {
    all: 0,
    approved: 0,
    rejected: 0,
    draft: 0,
    in_review: 0,
  },
  filterMeta: {
    status: null,
    pageIndex: 0,
    hasNext: false,
  },
  total: 0,
};

export const getEmployee = createAsyncThunk<
  Dictionary,
  { pageIndex: number; pageSize: number; status: statusType; search?: string },
  { rejectValue: ResponseError }
>(
  "auth/empList",
  async ({ pageIndex = 0, pageSize, status, search }, thunkAPI) => {
    try {
      const response: any = await getEmployeelist(
        pageIndex,
        pageSize,
        status,
        search
      );

      const errMsg: any = response.data;

      return response.status === 200
        ? {
            pageIndex,
            list: response.data.data,
            count: response.data.counts,
            hasNext: response.hasNext,
            total: response.data.total,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const employeeListSlice = createSlice({
  name: "employeeList",
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      if (state.filterMeta.status !== action.payload) {
        state.list = [];
        state.filterMeta.pageIndex = 0;
      }
      state.filterMeta.status = action.payload;
    },
    incPageIndex(state) {
      state.filterMeta.pageIndex = state.filterMeta.pageIndex + 1;
    },
    updateEmpMatrix: (state, action) => {
      state.matrix = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployee.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.list = [...action.payload?.list];
      state.countMeta = { ...action.payload?.count } as countType;
      state.matrix = action.payload.count;
      state.filterMeta.hasNext = action.payload?.hasNext;
      state.total = action.payload?.total;
    });
    builder.addCase(getEmployee.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.employeeList;
export const filterTypeSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterMeta.status
);
export const fetchStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export const pageIndexSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterMeta.pageIndex
);
export const hasNextSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterMeta.hasNext
);
export const countSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.countMeta
);
export const empListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const empListStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const matrixCountSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.matrix
);
export const totalRecordsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.total
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const payloadSelector = createDraftSafeSelector(selfSelect, (state) => {
  return {
    status: state.filterMeta.status,
    pageSize: 25,
    pageIndex: state.filterMeta.pageIndex,
  };
});

export const { updateFilter, incPageIndex, updateEmpMatrix } =
  employeeListSlice.actions;

export default employeeListSlice.reducer;
