/* eslint-disable react/jsx-props-no-spreading */
import "./PrivateLayout.scss";
import Header from "@components/Header/index";
import Sidebar from "@components/Sidebar/index";
import { useDispatch, useSelector } from "react-redux";
// import { sosStatusSelector } from '@root/actions/sidebar';
import SosPanel from "../../components/sos/index";
import EnoticeListing from "../../components/EnoticeListing/index";
import GlobalSidePanel from "@root/components/GlobalSidePanel/GlobalSidePanel";
import SendAppLink from "@root/components/SendAppLink";
import { glSidePanelStatusSelector } from "@root/actions/metadata";
import AccountTerminated from "@root/components/AccountTerminated/AccountTerminated";
import { accStatusSelector } from "@root/actions/auth";
import SosLockScreen from "@root/components/SosLockScreen";
import { isSOSEnabledStatus, lockSOSScreen } from "@root/actions/sosAlert";
import { useEffect } from "react";
import { sosStatusSelector, enoticeSelector } from "@root/actions/sidebar";
import { openSendLinkSelector } from "@root/actions/header";
import React from "react";

const PrivateLayout = (props: any) => {
  const isOpen = useSelector(glSidePanelStatusSelector);
  const isOpenAppLink = useSelector(openSendLinkSelector);
  const isOpenSOS = useSelector(sosStatusSelector);
  const isOpenNotice = useSelector(enoticeSelector);
  const isAccTerminated = useSelector(accStatusSelector);
  const enableSos = useSelector(isSOSEnabledStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    const sos = localStorage.getItem("SOS");
    if (sos) {
      dispatch(lockSOSScreen());
    }
  }, []);

  return (
    <>
      <div className="container-fluid h-100 p-0">
        <div className="wrapper h-100 d-flex">
          <div className="sidebar-container h-100 d-flex">
            <Sidebar />
          </div>
          <div className="content-container flex-grow-1 h-100">
            <Header />
            <div className="content">{props.children}</div>
            <div className="sos-relatives">
              <SosPanel />
            </div>
          </div>
          <GlobalSidePanel>
            {isOpen && isOpenAppLink && <SendAppLink />}
            {isOpen && isOpenSOS && <SosPanel />}
            {isOpen && isOpenNotice && <EnoticeListing />}
          </GlobalSidePanel>

          {isAccTerminated && <AccountTerminated />}

          {enableSos && <SosLockScreen />}
        </div>
      </div>
    </>
  );
};

export default PrivateLayout;
