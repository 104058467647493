import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getAttendaceById } from "@root/services/attendance";

const initialUser: empDataType = {
  user: {
    role: "",
    status: "",
    joined: null,
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    account_id: "",
    __v: 0,
    image: "",
    approved_at: "",
    custom_id: "",
    department: "",
    description: "",
  },
  checkin: {
    user_id: "",
    _id: "",
    account_id: "",
    clocked_in_at: "",
    clocked_in_status: "",
    geo_location: {
      clockin: {
        lat: 0,
        lng: 0,
      },
      clockout: {
        lat: 0,
        lng: 0,
      },
    },
    mode: {
      clockin: "",
      clockout: "",
    },
    temperature: {
      measure: "",
      value: 0,
    },
    clocked_out_at: "",
  },
  activities: [
    {
      clocked_out_at: "",
      _id: "",
      clocked_in_at: "",
      geo_location: {
        clockin: {
          lat: 0,
          lng: 0,
        },
        clockout: {
          lat: 0,
          lng: 0,
        },
      },
      temperature: {
        measure: "",
        value: 0,
      },
      mode: {
        clockin: "",
        clockout: "",
      },
    },
  ],
};
const initialState = {
  selectedPlace: "",
  error: "",
  empData: initialUser,
  status: "idle",
};

export const getAttendaceByID = createAsyncThunk<
  Dictionary,
  { _id: string; _date: string },
  { rejectValue: ResponseError }
>("@admin/fetch_Singleattendance", async ({ _id, _date }, thunkAPI) => {
  try {
    const response: any = await getAttendaceById(_id, _date);
    const errMsg: any = response.data;

    return response.status === 200
      ? {
          emplData: response.data,
          status: true,
        }
      : { status: false, error: errMsg };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const getEmployeeSlice = createSlice({
  name: "getEmployee",
  initialState: initialState,
  reducers: {
    setSelectedPlace: (state, action) => {
      state.selectedPlace = action.payload;
    },
    resetPlace: (state) => {
      state.selectedPlace = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAttendaceByID.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getAttendaceByID.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.empData = action.payload.emplData;
    });
    builder.addCase(getAttendaceByID.rejected, (state, action) => {
      state.status = "idle";
      state.error =
        action.payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.getEmployee;

export const employeeDataSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.empData
);
export const placeSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.selectedPlace
);

export const { setSelectedPlace, resetPlace } = getEmployeeSlice.actions;

export default getEmployeeSlice.reducer;
