import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  editEmployeeDetail,
  updateEmployeeDetail,
} from "@root/services/edit-employee";
import { StoreDef, store } from "@root/store";

import moment from "moment";
import { showNotificationError } from "../toast-notification";

const initialEmpData: EmployeeState = {
  status: "",
  first_name: "",
  last_name: "",
  email: "",
  joined: "",
  phone: "",
  description: "",
  department: "",
  gender: "",
  dob: "",
  custom_id: "",
  _id: "",
  reject_reason: "",
  image: "",
};
const initialState: EditEmployeeStateType = {
  error: "",
  empData: initialEmpData,
  hasUpdated: null,
  list: null,
  hasError: null,
  status: "idle",
  isOpenPanel: false,
  selectedEmpId: "",
  updateEmpData: false,
};

export const fetchSelectedUserInfo = createAsyncThunk<
  object,
  { id: string },
  { rejectValue: ResponseError }
>("edit/getUserInfo", async ({ id }, thunkAPI) => {
  try {
    const response = await editEmployeeDetail(id);
    const errMsg: any = response.data;

    return response.status === 200
      ? {
          data: response.data,
          status: true,
        }
      : { status: false, error: errMsg };

    // return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const updateSelectedUserInfo = createAsyncThunk<
  Dictionary,
  {
    status: string;
    first_name: string;
    last_name: string;
    email: string;
    joined: string;
    phone: string;
    description: string;
    department: string;
    gender: string;
    dob: string;
    custom_id: string;
    id: string;
    reject_reason: string;
  },
  { rejectValue: ResponseError }
>("edit/updateUserInfo", async (payload, thunkAPI) => {
  try {
    let {
      id,
      joined,
      dob,
      first_name,
      last_name,
      description,
      gender,
      email,
      phone,
      department,
      status,
      custom_id,
      reject_reason,
    } = payload;

    let _joined = moment(new Date(joined)).format("YYYY-MM-DD");
    let _dob = moment(new Date(dob)).format("YYYY-MM-DD");
    const response = await updateEmployeeDetail(
      _joined,
      _dob,
      id,
      first_name,
      last_name,
      description,
      gender,
      email,
      phone,
      department,
      status,
      custom_id,
      reject_reason
    );

    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(
        showNotificationError({
          type: "success",
          message: "Employee record has been successfully updated.",
        })
      );
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const EditEmployee = createSlice({
  name: "editEmployee",
  reducers: {
    resetEmpInfo: (state) => {
      state.hasError = null;
      state.empData = initialEmpData;
      state.status = "idle";
      state.selectedEmpId = "";
    },
    togglepanel: (state) => {
      state.isOpenPanel = !state.isOpenPanel;
    },
    setEmployeeId: (state, action) => {
      state.selectedEmpId = action.payload.id;
    },
    setUpdatedEmpData: (state) => {
      state.updateEmpData = !state.updateEmpData;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSelectedUserInfo.pending, (state) => {
      state.hasError = null;
      state.empData = null;

      state.status = "loading";
    });

    builder.addCase(fetchSelectedUserInfo.fulfilled, (state, { payload }) => {
      state.hasError = null;
      // @ts-ignore
      state.empData = payload.data;

      state.status = "idle";
    });
    builder.addCase(fetchSelectedUserInfo.rejected, (state, { payload }) => {
      // state.hasError = payload?.response.data.error || "something is wrong";
      state.empData = null;
      state.status = "idle";
    });

    builder.addCase(updateSelectedUserInfo.pending, (state) => {
      state.hasError = false;
      state.status = "loading";
      state.hasUpdated = false;
      state.error = "";
    });

    builder.addCase(updateSelectedUserInfo.fulfilled, (state, action) => {
      state.error = action.payload.error?.error!;
      state.hasError = action.payload.error ? false : true;
      state.status = "idle";
      state.empData = action.payload.data;
      state.hasUpdated = action.payload.error ? false : true;
    });
    builder.addCase(updateSelectedUserInfo.rejected, (state, { payload }) => {
      state.error = payload?.response?.data?.error || "something is wrong";
      state.hasError = true;
      state.status = "idle";
      state.hasUpdated = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.editEmployee;

export const togglepanelStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isOpenPanel
);

export const empDetailsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.empData
);

export const hasUpdatedSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasUpdated
);

export const statusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export const hasUpdatedErrorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasError
);

export const getSelectedEmployeeId = createDraftSafeSelector(
  selfSelect,
  (state) => state.selectedEmpId
);

export const updatedEmployeeDetails = createDraftSafeSelector(
  selfSelect,
  (state) => state.updateEmpData
);

export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);

export const updatedData = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);

// actions
export const { resetEmpInfo, togglepanel, setEmployeeId, setUpdatedEmpData } =
  EditEmployee.actions;

export default EditEmployee.reducer;
