import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isOpen: false,
    openSOS: false,
    openNotices: false,
  },
  reducers: {
    togglebar: (state) => {
      state.isOpen = !state.isOpen;
    },
    toggleSOS: (state, action) => {
      state.openSOS = action.payload;
    },
    toggleNotices: (state, action) => {
      state.openNotices = action.payload;
    },
  },
});

const selfSelect = (state: StoreDef) => state.sidebar;
export const toggleStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isOpen
);
export const sosStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.openSOS
);
export const enoticeSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.openNotices
);

export const { togglebar, toggleSOS, toggleNotices } = sidebarSlice.actions;

export default sidebarSlice.reducer;
