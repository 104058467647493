import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { showNotificationError } from "@root/actions/toast-notification";
import {
  getCheckinByDate,
  isOusideOrganization,
  userClockin,
  userClockout,
} from "@root/services/global-clockin";

export const checkinByDate = createAsyncThunk<
  Dictionary,
  { date: string },
  { rejectValue: ResponseError }
>("@admin/checkinBydate", async ({ date }, thunkAPI) => {
  try {
    const response = await getCheckinByDate(date);
    const errMsg: any = response.data;

    if (response.status === 200) {
      return {
        data: response.data,
        status: true,
      };
    } else {
      // store.dispatch(showNotificationError({ type: "error", message: errMsg.error }));
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const userClockinRequest = createAsyncThunk<
  Dictionary,
  { geo_location: { lat: number; lng: number } },
  { rejectValue: ResponseError }
>("@admin/userclockinrequest", async ({ geo_location }, thunkAPI) => {
  try {
    const response = await userClockin(geo_location);
    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(
        showNotificationError({ type: "success", message: "Clockin Success." })
      );
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const userClockoutRequest = createAsyncThunk<
  Dictionary,
  { geo_location: { lat: number; lng: number } },
  { rejectValue: ResponseError }
>("@admin/userclockoutrequest", async ({ geo_location }, thunkAPI) => {
  try {
    const response = await userClockout(geo_location);
    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(
        showNotificationError({ type: "success", message: "Clockout Success." })
      );
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const checkIfOutsideOrganization = createAsyncThunk<
  Dictionary,
  { geo_location: { lat: number; lng: number } },
  { rejectValue: ResponseError }
>("@admin/checkifoutsideOrganization", async ({ geo_location }, thunkAPI) => {
  try {
    const response = await isOusideOrganization(geo_location);
    const errMsg: any = response.data;

    if (response.status === 200) {
      return {
        data: response.data,
        status: true,
      };
    } else {
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

type CheckinType = {
  user_id: string;
  _id: string;
  account_id: string;
  clocked_in_at: string;
  clocked_in_status: string;
  geo_location: {
    clockin: {
      lat: number;
      lng: number;
    };
    clockout: {
      lat: number;
      lng: number;
    };
  };
  mode: {
    clockin: string;
    clockout: string;
  };
  temperature?: {
    measure: string;
    value: number;
  };
  clocked_out_at: string;
};

const initialState: {
  status: string;
  error: string;
  checkin: CheckinType | null;
  outside_org: boolean;
  org_status: string;
  clockinStatus: boolean;
  clockoutStatus: boolean;
} = {
  status: "idle",
  error: "",
  checkin: null,
  outside_org: false,
  org_status: "idle",
  clockinStatus: false,
  clockoutStatus: false,
};

const globalClockin = createSlice({
  name: "checkin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkinByDate.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(checkinByDate.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.checkin = action.payload?.data?.checkin;
    });
    builder.addCase(checkinByDate.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });

    builder.addCase(checkIfOutsideOrganization.pending, (state) => {
      state.org_status = "loading";
      state.error = "";
    });
    builder.addCase(checkIfOutsideOrganization.fulfilled, (state, action) => {
      state.org_status = "idle";
      state.error = action.payload.error?.error!;
      state.outside_org = action.payload?.data?.outside_org;
    });
    builder.addCase(
      checkIfOutsideOrganization.rejected,
      (state, { payload }) => {
        state.org_status = "idle";
        state.error = payload?.response?.data?.error || "something is wrong";
      }
    );

    builder.addCase(userClockinRequest.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.clockinStatus = false;
      state.clockoutStatus = false;
    });
    builder.addCase(userClockinRequest.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.checkin = action.payload?.data;
      state.clockinStatus = action.payload?.status ? true : false;
    });
    builder.addCase(userClockinRequest.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
      state.clockinStatus = false;
    });

    builder.addCase(userClockoutRequest.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.clockinStatus = false;
      state.clockoutStatus = false;
    });
    builder.addCase(userClockoutRequest.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.checkin = action.payload?.data;
      state.clockoutStatus = action.payload?.status ? true : false;
    });
    builder.addCase(userClockoutRequest.rejected, (state, { payload }) => {
      state.status = "idle";
      state.clockoutStatus = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.checkin;

export const userCheckin = createDraftSafeSelector(
  selfSelect,
  (state) => state.checkin
);
export const isOutsedeOrg = createDraftSafeSelector(
  selfSelect,
  (state) => state.outside_org
);
export const clockinLoading = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const orgLoading = createDraftSafeSelector(
  selfSelect,
  (state) => state.org_status
);
export const clockinSuccess = createDraftSafeSelector(
  selfSelect,
  (state) => state.clockinStatus
);
export const clockoutSuccess = createDraftSafeSelector(
  selfSelect,
  (state) => state.clockoutStatus
);

export default globalClockin.reducer;
