import classNames from "classnames";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import "./calert.scss";

type CAlertProps = {
  type?: "success" | "error" | "warning" | "info";
  children: React.ReactNode;
  className?: string;
};

function CAlert(props: CAlertProps) {
  return (
    <div
      className={classNames(
        "c-alert",
        `c-alert-${props.type || "info"}`,
        props.className
      )}
    >
      <p>
        <span>
          <InfoIcon className="info-icon" />
        </span>
        {props.children}
      </p>
    </div>
  );
}

export default CAlert;
