import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getAllreports } from "../../services/report";

export const getAllRepotsList = createAsyncThunk<
  Dictionary,
  { pageSize: number; pageIndex: number },
  { rejectValue: ResponseError }
>("@admin/fetch_allreports", async ({ pageSize, pageIndex }, thunkAPI) => {
  try {
    const _idx = pageIndex;
    const response = await getAllreports(pageSize, pageIndex);
    const errMsg: any = response.data;
    // console.log(response.data)

    return response.status === 200
      ? {
          data: response.data,
          status: true,
          pageIndex: _idx,
        }
      : { status: false, error: errMsg };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

type objR = {
  date: string;
  processed: boolean;
  url: string;
  _id: string;
  created_by: string;
  type: string;
  __v: 0;
};

type allReportType = {
  status: string;
  error: string;
  reports: objR[];
  hasNext: boolean;
};

const initialState: allReportType = {
  status: "idle",
  error: "",
  reports: [],
  hasNext: false,
};

const allReportsSlice = createSlice({
  name: "all_reports",
  initialState: initialState,
  reducers: {
    appendReport: (state, action) => {
      state.reports = [action.payload, ...state.reports];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRepotsList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.hasNext = false;
    });
    builder.addCase(getAllRepotsList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      if (action.payload.data) {
        if (action.payload.pageIndex > 0) {
          state.reports = [...state.reports, ...action.payload.data.data];
        } else {
          state.reports = action.payload.data.data;
        }
        state.hasNext = action.payload.data.hasNext
          ? action.payload.data.hasNext
          : false;
      }
    });
    builder.addCase(getAllRepotsList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.all_reports;
export const allReportsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.reports
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const statusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const hasNextSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasNext
);

export const { appendReport } = allReportsSlice.actions;

export default allReportsSlice.reducer;
