import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import DatePicker from "@mui/lab/DatePicker";
import { styled } from "@mui/material/styles";
import { TextField as MuiTextField, Tooltip } from "@mui/material";
import EmployeeNameWithProfileRenderer from "@root/components/EmployeeNameWithProfileRenderer/EmployeeNameWithProfileRenderer";
import CreatedByUser from "@root/components/EmployeeNameWithProfileRenderer/CreatedByUser";

import {
  settingsSelector,
  getAllSettings,
  updateAllSettings,
  statusSelector,
  hasUpdatedSelector,
  updateVisitorFlowQuestions,
} from "@root/actions/settings";

import {
  getAllNotices,
  noticeSelector,
  totalRecordsSelector,
} from "@root/actions/notices";

import { hasPinGeneratedSelector, newPinSelector } from "@actions/generatePin";
import { setTitle } from "@actions/metadata";
import {
  getSettingsList,
  payloadSelector,
  settingsListSelector,
} from "@root/actions/settingsTable";
import { useUpdateEffect } from "react-use";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { showNotificationError } from "@root/actions/toast-notification";
import Spinner from "@root/components/Spinner/Spinner";
import { getDemotedEmployee } from "@root/actions/demoteEmployee";
import { hasEmployeeDemotedSelector } from "../../../actions/demoteEmployee/index";
import {
  closeFilter,
  closePanel,
  panelStatusSelector,
  togglePanel,
} from "@root/actions/attendance";
import SidePanel from "@root/components/SidePanel/SidePanel";
import SettingsTablePanel from "../../../components/SettingsTablePanel/index";
import { employeeRoleSelector } from "@root/actions/auth";
// import { currentUserSelector, getCurrentUserInfo } from "@root/actions/getCurrentUser";
// import { startDateSelector, startDateStringSelector, setStartDate } from "@root/actions/header";
import "./setting.scss";
import Holiday from "@root/components/Holiday";
import NewHoliday from "@root/components/NewHoliday";
import { holidaysList, getAllHolidays } from "@root/actions/holidays";
import IntegrationCard from "@root/components/IntegrationCard";
import {
  googleURLSelector,
  microsoftURLSelector,
  getGoogleAuthUrl,
  getMicrosoftAuthUrl,
  putDisconnectIntegration,
  isDisconnectedIntegration,
} from "@root/actions/integrations";
import React from "react";
import Pagination from "@mui/material/Pagination";
import ENotice from "@root/components/ENotice";
import moment from "moment";
import InfoAlert from "@root/components/InfoAlert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getVisitorQuestions,
  addCustomQuestion,
  updateCustomQuestionsStatus,
  UpdateCustomQuestionsStatusPayload,
  deleteCustomQuestion,
} from "@root/services/visitors";
import { APIError } from "@root/types";
import { useDisclosure } from "@mantine/hooks";
import SidePanelDrawer from "@root/components/SidePanel/SidePanelDrawer";
import classNames from "classnames";
import CAlert from "@root/components/CAlert";
import Integrations from "./Integrations";
import Holidays from "./Holidays";
import ToastConfirmation from "@root/components/ToastConfirmation";
import FullscreenButton from "@root/components/FullscreenButton";
import FullscreentTitle from "@root/components/FullscreentTitle";
import { currentUser } from "@root/actions/header";

const Settings = (props: any) => {
  const isOpen = useSelector(panelStatusSelector);
  const loggedInUser = useSelector(currentUser);
  const [activeTab, setActiveTab] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const rowClass = "minin-class";
  const payload = useSelector(payloadSelector);
  const settingList = useSelector(settingsListSelector);
  const [rowData, setRowData] = useState<any>();

  const bHours = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const l2Hours = [...Array(12).keys()].map((i) => i + 1);
  const z4Hours = [...Array(24).keys()].map((i) => i + 1);

  const minutes = [...Array(13).keys()].map((i) => i * 5);
  const [card, setCard] = useState(false);
  const dispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const [Id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessDays, setBusinessDays] = useState(settings.business_days);
  const [opensAt, setOpensAt] = useState(settings.opens_at);
  const [closesAt, setClosesAt] = useState(settings.closes_at);
  const demoteEmployee = useSelector(hasEmployeeDemotedSelector);
  const [tabletPin, setTabletPin] = useState(settings.tablet_pin);
  const hasUpdatedSettings = useSelector(hasUpdatedSelector);
  const isPinGenerated = useSelector(hasPinGeneratedSelector);
  const status = useSelector(statusSelector);
  const newPin = useSelector(newPinSelector);
  const roleSelector = useSelector(employeeRoleSelector);
  const [userRole, setUserRole] = useState(roleSelector);
  const [questions, setQuestions] = useState(settings.visitor_questions);
  const [tableGridView, settableGridView] = useState<"table" | "grid">("table");
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const colDef = [
    {
      field: "first_name",
      headerName: "EMPLOYEE NAME",
      sortable: true,
      cellRendererFramework: (params: any) => {
        const temp = EmployeeNameWithProfileRenderer(params, true);
        return temp;
      },
      // cellRendererFramework: EmployeeNameWithProfileRenderer,
      width: 300,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 150,
      cellRendererFramework: (params: any) => {
        return params.data?.status === "approved" ? (
          <p className={`emp-status-indicator ${params.data?.status}`}>
            Active
          </p>
        ) : (
          <p className={`emp-status-indicator ${params.data?.status}`}>
            Inactive
          </p>
        );
      },
    },
    { field: "description", headerName: "DESIGNATION", width: 200 },
    {
      field: "email",
      headerName: "CONTACT DETAILS",
      width: 200,
      cellRendererFramework: (params: any) => {
        return (
          <div>
            <p className="mb-2">{params.data?.email}</p>
            <p className="mb-0">
              +
              {`${params.data?.phone.slice(0, 2)} ${params.data?.phone.slice(
                2,
                7
              )} ${params.data?.phone.slice(7)}`}
            </p>
          </div>
        );
      },
    },
    {
      cellRendererFramework: (params: any) => {
        return (
          <>
            {params.data?.role === "admin" ? null : (
              <button
                className="grid-action"
                onClick={() => handleCardModal(params)}
              >
                Revoke
              </button>
            )}
          </>
        );
      },
      headerComponentFramework: (props: any) => {
        return (
          <FullscreenButton
            tableFullScreen={tableFullScreen}
            onTableFUllScreen={onTableFUllScreen}
          />
        );
      },
    },
  ];
  const [colDefs, setColDefs] = useState(colDef);

  const colENoticeDef = [
    {
      field: "type",
      headerName: "TYPE",
      width: 150,
      cellRendererFramework: (params: any) => {
        return <p className={params.data?.type}>{params.data?.type}</p>;
      },
    },
    {
      field: "created_by",
      headerName: "POSTED BY",
      sortable: true,
      cellRendererFramework: (params: any) => {
        const temp = CreatedByUser(params?.data?.created_by, true);
        return temp;
      },
      width: 300,
    },
    {
      field: "status",
      headerName: "POSTED DATE",
      width: 150,
      cellRendererFramework: (params: any) => {
        return (
          <p className="mb-0">
            {params.data && params.data?.date
              ? moment(new Date(params.data?.date)).format("YYYY-MM-DD")
              : "--"}
          </p>
        );
      },
    },
    { field: "title", headerName: "TITLE", width: 200 },
    {
      field: "active",
      headerName: "STATUS",
      width: 200,
      cellRendererFramework: (params: any) => {
        return params.data?.active ? (
          <p className="active">Active</p>
        ) : (
          <p className="inactive">Inactive</p>
        );
      },
    },
    {
      headerName: "ACTIONS",
      cellRendererFramework: (params: any) => {
        return (
          <>
            {params.data?.role === "admin" ? null : (
              <button
                className="grid-action"
                onClick={() => handleNoticeClick(params.data)}
              >
                View
              </button>
            )}
          </>
        );
      },
      headerComponentFramework: (props: any) => {
        return (
          <FullscreenButton
            tableFullScreen={tableFullScreen}
            onTableFUllScreen={onTableFUllScreen}
          />
        );
      },
    },
  ];
  const [colENoticeDefs, setENoticeColDefs] = useState(colENoticeDef);

  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const totalRecords = useSelector(totalRecordsSelector);
  const allNotices = useSelector(noticeSelector);
  const [rowNotices, setRowNotices] = useState<any>();

  const [isNew, setIsNew] = useState(false);
  const [isView, setIsView] = useState(false);
  const [fromNotice, setFromNotice] = useState(false);

  const [selectedNotice, setSelectedNotice] = useState({});

  const [isAddQuestionSidePanelOpen, addQuestionModalHandler] =
    useDisclosure(false);
  const [customQuestion, setCustomQuestion] = useState("");

  // add new user
  const onAddUser = () => {
    if (userRole !== "admin") {
      dispatch(
        showNotificationError({
          type: "error",
          message:
            "You may not have access to add new user. Please contact Administrator.",
        })
      );
    } else {
      dispatch(togglePanel());
    }
  };

  const questionsChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    let newArry = {
      name: true,
      phone: true,
      otp: true,
      purpose: true,
      signature: true,
    };

    if (
      (target.name === "phone" && target.checked === false) ||
      (target.name === "otp" && target.checked === true)
    ) {
      newArry = { ...questions, phone: target.checked, otp: target.checked };
    } else {
      newArry = { ...questions, [target.name]: target.checked };
    }

    setQuestions(newArry);
  };

  const onTableFUllScreen = () => {
    setTableFullScreen(!tableFullScreen);
  };

  const handleCardModal = (params: any) => {
    const role = localStorage.getItem("Role");
    if (role !== "admin") {
      dispatch(
        showNotificationError({
          type: "error",
          message:
            "You may not have access to revoke user. Please contact Administrator.",
        })
      );
    } else {
      setCard(true);
      setId(params.data._id);
      setFirstName(params.data.first_name);
      setLastName(params.data.last_name);
    }
  };

  useEffect(() => {
    dispatch(setTitle("Settings"));
    dispatch(getAllSettings({}));
    dispatch(closePanel(false));
    dispatch(closeFilter(false));

    const role = localStorage.getItem("Role");
    setUserRole(role!);
    if (role !== "employee") {
      dispatch(getSettingsList({ ...payload, status: "all" }));
    }
    if (!userRole) {
      setUserRole(role!);
    }
  }, []);

  useUpdateEffect(() => {
    if (activeTab === 4) {
      dispatch(
        getAllNotices({
          page_size: pageSize,
          pageIndex: pageIndex,
        })
      );
    }
  }, [activeTab]);

  const doRefreshPage = () => {
    dispatch(
      getAllNotices({
        page_size: pageSize,
        pageIndex: pageIndex,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAllNotices({
        page_size: pageSize,
        pageIndex: pageIndex,
      })
    );
  }, [pageSize, pageIndex]);

  useUpdateEffect(() => {
    setRowNotices(allNotices);
    setENoticeColDefs(colENoticeDef);
  }, [allNotices, tableFullScreen]);

  useUpdateEffect(() => {
    setRowData(settingList);
  }, [settingList]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    setBusinessDays(settings.business_days);
    setOpensAt(settings.opens_at);
    setClosesAt(settings.closes_at);
    setTabletPin(settings.tablet_pin);
    setQuestions(settings.visitor_questions);
  }, [settings]);

  useUpdateEffect(() => {
    if (hasUpdatedSettings) {
      // dispatch(toastNotificationSuccess());
      dispatch(
        showNotificationError({
          type: "success",
          message: "Business hours has been updated",
        })
      );
    }
  }, [hasUpdatedSettings]);

  useUpdateEffect(() => {
    if (isPinGenerated) {
      setTabletPin(newPin);
      dispatch(
        showNotificationError({
          type: "success",
          message: "New PIN has been generated for the Kiosk security PIN",
        })
      );
    }
  }, [newPin]);

  const openAtModeChange = (val: number) => {
    const newArry = { ...opensAt, mode: val };
    setOpensAt(newArry);
  };

  const closeAtModeChange = (val: number) => {
    const newArry = { ...closesAt, mode: val };
    setClosesAt(newArry);
  };

  const openAtTypeChange = (val: string) => {
    const newArry = { ...opensAt, type: val };
    setOpensAt(newArry);
  };

  const closeAtTypeChange = (val: string) => {
    const newArry = { ...closesAt, type: val };
    setClosesAt(newArry);
  };

  const openAtHourChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = { ...opensAt, hour: +target.value };
    setOpensAt(newArry);
  };

  const closeAtHourChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = { ...closesAt, hour: +target.value };
    setClosesAt(newArry);
  };

  const openAtMinuteChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = { ...opensAt, minute: +target.value };
    setOpensAt(newArry);
  };

  const closeAtMinuteChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = { ...closesAt, minute: +target.value };
    setClosesAt(newArry);
  };

  const hanndleBusinessHours = (e: string) => {
    const newArry = [...businessDays];
    let finalArray = [];
    if (businessDays.includes(e)) {
      finalArray = newArry.filter((el) => el !== e);
    } else {
      finalArray = [e, ...businessDays];
    }
    setBusinessDays(finalArray);
  };

  const onGraceMMChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = { ...opensAt, grace_minutes: +target.value };
    setOpensAt(newArry);
  };

  const onUpdateSettings = () => {
    if (userRole !== "admin") {
      dispatch(
        showNotificationError({
          type: "error",
          message:
            "You may not have access to update business hours. Please contact Administrator Sridhar Reddy.",
        })
      );
    } else {
      if (opensAt.mode === 24) opensAt.type = "";
      dispatch(
        updateAllSettings({
          default_checkin_mode: "WFO",
          opens_at: { ...opensAt },
          business_days: [...businessDays],
          closes_at: { ...closesAt },
        })
      );
    }
  };

  const [activeCustomQuestions, setActiveCustomQuestions] = useState<string[]>(
    []
  );
  const visitorQuestionsQuery = useQuery<Array<VisitorQuestion>, any>({
    queryKey: "visitorQuestions",
    queryFn: getVisitorQuestions,
    onSuccess: (data) => {
      setActiveCustomQuestions(
        data.filter((item) => item.active).map((item) => item._id)
      );
    },
    enabled: userRole === "admin",
  });

  const addQuestionMutation = useMutation<unknown, APIError, string>({
    mutationKey: "addQuestion",
    mutationFn: addCustomQuestion,
    onSuccess: () => {
      visitorQuestionsQuery.refetch();
      addQuestionModalHandler.close();
    },
  });

  const updateCustomQuestionsStatusMutation = useMutation<
    unknown,
    APIError,
    UpdateCustomQuestionsStatusPayload
  >({
    mutationKey: "updateCustomQuestionsStatus",
    mutationFn: updateCustomQuestionsStatus,
    onSuccess: () => {
      visitorQuestionsQuery.refetch();
    },
  });
  const onUpdateQuestions = () => {
    const obj = {
      purpose: questions.purpose,
      otp: questions.otp,
      phone: questions.phone,
      signature: questions.signature,
    };
    dispatch(updateVisitorFlowQuestions(obj));
    updateCustomQuestionsStatusMutation.mutate(
      visitorQuestionsQuery.data?.map((question) => ({
        _id: question._id,
        active: activeCustomQuestions?.includes(question._id) ?? false,
      })) ?? []
    );
  };

  const queryClient = useQueryClient();
  const deleteCustomQuestionMutation = useMutation<unknown, APIError, string>({
    mutationKey: "deleteCustomQuestion",
    mutationFn: deleteCustomQuestion,
  });

  const handleCustomQuestionDelete = (question_id: string) => {
    NiceModal.show(ToastConfirmation, {
      handleYes: () => {
        deleteCustomQuestionMutation.mutate(question_id, {
          onSuccess: (data) => {
            queryClient.setQueryData(
              "visitorQuestions",
              visitorQuestionsQuery.data?.filter(
                (question) => question._id !== question_id
              )
            );
          },
          onError: (error) => {
            dispatch(
              showNotificationError({ type: "error", message: error.error })
            );
          },
        });
      },
      message: "Are you sure, you want to delete the question?",
    });
  };

  // const onGeneratePin = () => {
  //   // dispatch generate action
  //   dispatch(generateTabletPin());
  // }

  // const handleDateChange = (event: any) => {
  //   const _date = new Date(event);
  //   setDateHoliday(_date);
  //   // console.log(`${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`);
  // }

  const toggleTableGridView = (view: "table" | "grid") => {
    settableGridView(view);
  };

  // const [testwin, setTestwin] = useState<any>(false);
  // useUpdateEffect(() => {
  //   if(testwin) {
  //     alert('closed');
  //     console.log(testwin)
  //   }
  // }, [testwin]);

  // const checkPopup = () => {
  //   const check = setInterval(() => {
  //     if (!windowObjectReference || windowObjectReference.closed || windowObjectReference.closed === undefined) {
  //       clearInterval(check);
  //     } else {
  //       console.log('hoy')
  //     }
  //   }, 1000)
  // }

  const handleOkayClick = (id: string) => {
    dispatch(getDemotedEmployee({ id: Id }));
    setCard(false);
  };
  useUpdateEffect(() => {
    if (demoteEmployee) {
      dispatch(getSettingsList({ ...payload, status: "all" }));
      dispatch(
        showNotificationError({
          type: "success",
          message: `Admin access has been revoked for ${firstName} ${lastName}`,
        })
      );
    }
  }, [demoteEmployee]);

  const onPageSizeChanged = () => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      ?.value;
    //@ts-ignore
    gridApi.paginationSetPageSize(Number(value));
    setPageSize(Number(value));
  };

  const onPageNum = (e: any, num: any) => {
    setPageIndex(num - 1);
  };

  const onCancle = () => {
    dispatch(togglePanel());
    setTimeout(() => {
      setIsView(false);
      setIsNew(false);
      setFromNotice(false);
    });
  };

  const onCreateNew = () => {
    setFromNotice(true);
    setIsView(false);
    setIsNew(true);
    dispatch(togglePanel());
  };

  const handleNoticeClick = (selectedNotice: any) => {
    if (selectedNotice !== undefined) setSelectedNotice(selectedNotice);
    setIsNew(false);
    setIsView(true);
    setFromNotice(true);
    dispatch(togglePanel());
  };

  const switchTab = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (typeof event.currentTarget.dataset.tab === "string") {
      setActiveTab(+event.currentTarget.dataset.tab);
    }
  };

  return (
    <div className="settings h-100 d-flex flex-column">
      <div className="row mb-3  bg-white">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav my-company-tabs">
              <li className={`${activeTab === 0 ? "active" : ""}`}>
                <button className="btn" data-tab="0" onClick={switchTab}>
                  Business Hours
                </button>
              </li>
              {userRole !== "employee" && (
                <li className={`${activeTab === 1 ? "active" : ""}`}>
                  <button className="btn" data-tab="1" onClick={switchTab}>
                    Admin &amp; Moderators
                  </button>
                </li>
              )}
              {userRole !== "employee" && (
                <li className={`${activeTab === 2 ? "active" : ""}`}>
                  <button className="btn" data-tab="2" onClick={switchTab}>
                    Visitor Flow Questions
                  </button>
                </li>
              )}
              {/* <li className={`${activeTab === 3 ? "active" : ""}`}>
                <button className="btn" data-tab="3" onClick={switchTab}>
                  Holidays
                </button>
              </li> */}

              {/* {userRole !== "employee" &&
                loggedInUser.account.subscription !== "visitors_only" && (
                  <li className={`${activeTab === 4 ? "active" : ""}`}>
                    <button className="btn" data-tab="4" onClick={switchTab}>
                      eNotice Board
                    </button>
                  </li>
                )} */}
            </ul>

            {activeTab === 4 && (
              <div className="flex-grow-1 d-flex justify-content-end">
                <div className="pl-3">
                  <button
                    className="btn btn-gradient"
                    style={{ minWidth: "140px", height: "40px" }}
                    onClick={onCreateNew}
                  >
                    + New Notice
                  </button>
                </div>
              </div>
            )}
            {activeTab === 2 && (
              <div className="flex-grow-1 d-flex justify-content-end">
                <div className="pl-3">
                  <button
                    className="btn btn-gradient"
                    style={{ minWidth: "140px", height: "40px" }}
                    onClick={() => {
                      if (
                        visitorQuestionsQuery?.data &&
                        visitorQuestionsQuery.data?.length >= 5
                      ) {
                        return void dispatch(
                          showNotificationError({
                            type: "error",
                            message:
                              "You can not add more than 5 custom questions",
                          })
                        );
                      }
                      addQuestionModalHandler.open();
                    }}
                  >
                    + New Question
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row flex-grow-1  bg-white">
        <div className={`vb-modal ${card ? "open" : ""}`}>
          <div className="vb-modal-dialog">
            <div className="vb-modal-content">
              <div className="vb-modal-header">
                <p className="vb-modal-title mb-0" id="staticBackdropLabel">
                  Are you sure you want to revoke?
                </p>
              </div>

              <div className="vb-modal-footer">
                <button
                  type="submit"
                  className="btn btn-gradient revoke-btns"
                  onClick={(params: any) => handleOkayClick(params.data?._id)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark revoke-btns"
                  onClick={() => setCard(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {activeTab === 0 && (
          <div className="col-sm-8 col-lg-5">
            <div className="card card-body business-hours border-0">
              <h4 className="card-heading">Business Hours</h4>
              <div className="day-pill-container mb-3">
                {bHours.map((element) => {
                  return (
                    <>
                      <input
                        key={element}
                        type="checkbox"
                        id={element}
                        name="selector"
                        checked={businessDays.includes(element) ? true : false}
                        value={element}
                        onChange={(e) => hanndleBusinessHours(element)}
                        disabled={userRole !== "admin" ? true : false}
                      />
                      <label className="selector" htmlFor={element}>
                        {`${element.charAt(0).toUpperCase()}${element.slice(
                          1
                        )}`}
                      </label>
                    </>
                  );
                })}
              </div>

              <div className="card hours-card no-radius border-0">
                <ul
                  className="nav nav-tabs day-format-tabs"
                  id="dayTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        opensAt.mode === 12 ? "active" : ""
                      }`}
                      onClick={() => openAtModeChange(12)}
                      id="day-format-one-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#day-format-one"
                      type="button"
                      role="tab"
                      aria-controls="day-format-one"
                      aria-selected="true"
                      disabled={userRole !== "admin" ? true : false}
                    >
                      12H
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        opensAt.mode === 24 ? "active" : ""
                      }`}
                      onClick={() => openAtModeChange(24)}
                      id="day-format-two-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#day-format-two"
                      type="button"
                      role="tab"
                      aria-controls="day-format-two"
                      aria-selected="false"
                      disabled={userRole !== "admin" ? true : false}
                    >
                      24H
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="day-format-one"
                    role="tabpanel"
                    aria-labelledby="day-format-one-tab"
                  >
                    <h6>Business Open Hours:</h6>
                    <div className="row row-one">
                      <div className="form-group col-auto">
                        <select
                          className="form-select"
                          onChange={openAtHourChange}
                          value={opensAt.hour}
                          disabled={userRole !== "admin" ? true : false}
                        >
                          {opensAt.mode === 12
                            ? l2Hours.map((el) => (
                                <option key={el} value={el}>
                                  {el < 10 ? `0${el}` : el}
                                </option>
                              ))
                            : z4Hours.map((el) => (
                                <option key={el} value={el}>
                                  {el < 10 ? `0${el}` : el}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div className="form-group col-auto">
                        <select
                          className="form-select"
                          onChange={openAtMinuteChange}
                          value={opensAt.minute}
                          disabled={userRole !== "admin" ? true : false}
                        >
                          {minutes.map((el) => (
                            <option key={el} value={el}>
                              {el < 10 ? `0${el}` : el}
                            </option>
                          ))}
                        </select>
                      </div>
                      {opensAt.mode === 12 ? (
                        <div className="form-group col-5 am-pm">
                          <div>
                            <input
                              type="radio"
                              className="btn-check"
                              name="options"
                              id="option1"
                              autoComplete="off"
                              checked={opensAt.type === "am" ? true : false}
                              onChange={() => openAtTypeChange("am")}
                              disabled={userRole !== "admin" ? true : false}
                            />
                            <label className="btn-secondary" htmlFor="option1">
                              AM
                            </label>

                            <input
                              type="radio"
                              className="btn-check"
                              name="options"
                              id="option2"
                              autoComplete="off"
                              checked={opensAt.type === "pm" ? true : false}
                              onChange={() => openAtTypeChange("pm")}
                              disabled={userRole !== "admin" ? true : false}
                            />
                            <label className="btn-secondary" htmlFor="option2">
                              PM
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <div className="row row-two">
                        <div className="form-group col-auto">
                          <h6 className="mb-0">Grace Time:</h6>
                        </div>
                        <div className="form-group ms-3 col-auto">
                          <select
                            value={opensAt.grace_minutes}
                            onChange={onGraceMMChange}
                            className="form-select"
                            disabled={userRole !== "admin" ? true : false}
                          >
                            <option value="0">MM</option>
                            <option value="5">05</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                          </select>
                        </div>
                        <div className="form-group col-auto">
                          <span>Minutes</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card hours-card no-radius bg-white">
                <ul
                  className="nav nav-tabs day-format-tabs close-time-tabs"
                  id="dayTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        closesAt.mode === 12 ? "active" : ""
                      }`}
                      onClick={() => closeAtModeChange(12)}
                      id="day-format-three-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#day-format-three"
                      type="button"
                      role="tab"
                      aria-controls="day-format-three"
                      aria-selected="true"
                      disabled={userRole !== "admin" ? true : false}
                    >
                      12H
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        closesAt.mode === 24 ? "active" : ""
                      }`}
                      onClick={() => closeAtModeChange(24)}
                      id="day-format-four-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#day-format-four"
                      type="button"
                      role="tab"
                      aria-controls="day-format-four"
                      aria-selected="false"
                      disabled={userRole !== "admin" ? true : false}
                    >
                      24H
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="day-format-three"
                    role="tabpanel"
                    aria-labelledby="day-format-three-tab"
                  >
                    <h6>Closes at:</h6>
                    <div className="row row-one mb-0">
                      <div className="form-group col-auto">
                        <select
                          className="form-select"
                          onChange={closeAtHourChange}
                          value={closesAt.hour}
                          disabled={userRole !== "admin" ? true : false}
                        >
                          {closesAt.mode === 12
                            ? l2Hours.map((el) => (
                                <option key={el} value={el}>
                                  {el < 10 ? `0${el}` : el}
                                </option>
                              ))
                            : z4Hours.map((el) => (
                                <option key={el} value={el}>
                                  {el < 10 ? `0${el}` : el}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div className="form-group col-auto">
                        <select
                          className="form-select"
                          onChange={closeAtMinuteChange}
                          value={closesAt.minute}
                          disabled={userRole !== "admin" ? true : false}
                        >
                          {minutes.map((el) => (
                            <option key={el} value={el}>
                              {el < 10 ? `0${el}` : el}
                            </option>
                          ))}
                        </select>
                      </div>
                      {closesAt.mode === 12 ? (
                        <div className="form-group col-5 am-pm">
                          <div>
                            <input
                              type="radio"
                              className="btn-check"
                              name="options3"
                              id="option5"
                              autoComplete="off"
                              checked={closesAt.type === "am" ? true : false}
                              onChange={() => closeAtTypeChange("am")}
                              disabled={userRole !== "admin" ? true : false}
                            />
                            <label className="btn-secondary" htmlFor="option5">
                              AM
                            </label>

                            <input
                              type="radio"
                              className="btn-check"
                              name="options3"
                              id="option6"
                              autoComplete="off"
                              checked={closesAt.type === "pm" ? true : false}
                              onChange={() => closeAtTypeChange("pm")}
                              disabled={userRole !== "admin" ? true : false}
                            />
                            <label className="btn-secondary" htmlFor="option6">
                              PM
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {userRole !== "admin" ? (
                <p className="mb-0">
                  *Please contact your administrator to make any changes.
                </p>
              ) : (
                <button
                  className="btn btn-gradient btn-save"
                  onClick={onUpdateSettings}
                  // disabled={roleSelector==="admin" ? false : true}
                >
                  Save
                </button>
              )}
            </div>
            {/* <div className="d-flex ">
            <div className="card card-body bg-white admin-pin-card">
              <h4 className="card-heading">Admin PIN Number for Kiosk</h4>
              <div>
                <p>{tabletPin}</p>
                {userRole ==="admin" &&
                <button type="button" onClick={onGeneratePin}>Regenerate</button>}   
              </div>
              {userRole !=="admin" &&
              <p className="mb-0">*Please contact administrator to generate new pin number.</p>}
            </div>
          </div> */}
          </div>
        )}

        {activeTab === 1 && (
          <div className="col-lg-12">
            <div className="card card-body vba-admin-users-card border-0">
              {/* <div className="main-panel d-flex flex-column"> */}
              {/* <div
                    className={`flex-grow-1 table-container ${
                      tableFullScreen ? "full-scrn" : ""
                    }`}
                  > */}
              <div className="d-flex justify-content-between">
                <h4 className="card-heading">Administrator &amp; Moderators</h4>
                {userRole !== "admin" ? (
                  <p className="mb-0">
                    *Please contact your administrator to make any changes.
                  </p>
                ) : null}
              </div>
              <div className="table-responsive vba-admin-usr-table">
                {userRole === "admin" ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    // eslint-disable-next-line no-script-url
                    href={"javascript:void(0)"}
                    className="add-user"
                    onClick={onAddUser}
                  >
                    <img
                      src="../../../../assests/images/add-icon.png"
                      alt="add"
                    />
                  </a>
                ) : null}
                {/* place table here  */}

                <div
                  className={`ag-theme-alpine ${
                    userRole === "admin" ? "admin" : "moderator"
                  }`}
                  style={{ height: "100%", width: "100%" }}
                >
                  {userRole ? (
                    <AgGridReact
                      frameworkComponents={{
                        employeeNameWithProfileRenderer:
                          EmployeeNameWithProfileRenderer,
                      }}
                      reactUi={true}
                      rowClass={rowClass}
                      defaultColDef={{
                        resizable: true,
                      }}
                      // getRowClass={getRowClass}
                      pagination={true}
                      rowData={rowData}
                      rowHeight={100}
                      rowSelection={"single"}
                      columnDefs={colDefs}
                      onGridReady={onGridReady}
                      suppressLoadingOverlay={true}
                    ></AgGridReact>
                  ) : null}
                </div>
                {!rowData ? (
                  <div className="loader-container">
                    <Spinner />
                  </div>
                ) : null}
              </div>
              {/* </div>
              </div> */}
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <div style={{ padding: "0 2rem" }}>
              <CAlert type="info" className="my-4">
                Customize your visitor questions here. These questions can be
                asked at the time of visitor checkin.
              </CAlert>
            </div>
            <div className="card card-body card-padding border-0 pt-0">
              <h4 className="card-heading">Customize Visitor Flow Questions</h4>
              <FormGroup>
                <FormControlLabel
                  disabled
                  control={<Checkbox checked name="name" color="default" />}
                  label="Your Name Please?"
                />
                <FormControlLabel
                  disabled={userRole !== "admin" ? true : false}
                  control={
                    <Checkbox
                      checked={questions.phone}
                      name="phone"
                      color="default"
                    />
                  }
                  label="Can I Have Your Mobile Number?"
                  onChange={questionsChange}
                />
                <FormControlLabel
                  disabled={userRole !== "admin" ? true : false}
                  control={
                    <Checkbox
                      checked={questions.otp}
                      name="otp"
                      color="default"
                    />
                  }
                  label="OTP Please?"
                  onChange={questionsChange}
                />
                <FormControlLabel
                  disabled={userRole !== "admin" ? true : false}
                  control={
                    <Checkbox
                      checked={questions.purpose}
                      onChange={questionsChange}
                      name="purpose"
                      color="default"
                    />
                  }
                  label="Purpose Of Visit?"
                />
                <FormControlLabel
                  disabled={userRole !== "admin" ? true : false}
                  control={
                    <Checkbox
                      checked={questions.signature}
                      onChange={questionsChange}
                      name="signature"
                      color="default"
                    />
                  }
                  label="Visitor Signature?"
                />
                {visitorQuestionsQuery.isSuccess &&
                  visitorQuestionsQuery.data.map((question) => (
                    <div className="flex">
                      <FormControlLabel
                        disabled={userRole !== "admin" ? true : false}
                        control={
                          <Checkbox
                            checked={activeCustomQuestions?.includes(
                              question._id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setActiveCustomQuestions([
                                  ...activeCustomQuestions,
                                  question._id,
                                ]);
                              }
                              if (!e.target.checked) {
                                setActiveCustomQuestions(
                                  activeCustomQuestions.filter(
                                    (id) => id !== question._id
                                  )
                                );
                              }
                            }}
                            name={question._id}
                            color="default"
                          />
                        }
                        label={question.text}
                      />
                      {/* <button
                        className="text-xl text-[#F00]"
                        onClick={() => handleCustomQuestionDelete(question._id)}
                      >
                        <TiDeleteOutline />
                      </button> */}
                    </div>
                  ))}
              </FormGroup>
              <br />
              {userRole !== "admin" ? (
                <p className="mb-0">
                  *Please contact your administrator to make any changes.
                </p>
              ) : (
                <button
                  className="btn btn-gradient btn-save my-3"
                  onClick={onUpdateQuestions}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        )}
        {activeTab === 3 && <Holidays />}

        {activeTab === 4 && (
          <div className="col-lg-12">
            <div className="card card-body vba-admin-users-card border-0">
              <div className="d-flex justify-content-between">
                {userRole !== "admin" ? (
                  <p className="mb-0">
                    *Please contact your administrator to make any changes.
                  </p>
                ) : null}
              </div>
              {rowNotices?.length > 0 && (
                <div
                  className={`flex-grow-1 table-container ${
                    tableFullScreen ? "full-scrn" : ""
                  }`}
                >
                  <FullscreentTitle tableFullScreen={tableFullScreen} />

                  <div className="table-responsive vba-admin-usr-table">
                    <div
                      className={`ag-theme-alpine ${
                        userRole === "admin" || userRole === "moderator"
                          ? "admin"
                          : "moderator"
                      }`}
                      style={{ height: "100%", width: "100%" }}
                    >
                      {userRole ? (
                        <AgGridReact
                          frameworkComponents={{
                            employeeNameWithProfileRenderer:
                              EmployeeNameWithProfileRenderer,
                          }}
                          reactUi={true}
                          rowClass={rowClass}
                          defaultColDef={{
                            resizable: true,
                            flex: 1,
                          }}
                          paginationPageSize={pageSize}
                          // getRowClass={getRowClass}
                          pagination={true}
                          rowData={rowNotices}
                          rowHeight={100}
                          rowSelection={"single"}
                          columnDefs={colENoticeDefs}
                          onGridReady={onGridReady}
                        ></AgGridReact>
                      ) : null}
                    </div>

                    <div className="d-flex justify-content-between pagination-box bg-white p-2">
                      <select
                        onChange={onPageSizeChanged}
                        id="page-size"
                        className="pagination-dropdown"
                        value={pageSize}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>

                      <Pagination
                        count={Math.ceil(totalRecords / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={onPageNum}
                      />
                    </div>
                  </div>
                </div>
              )}
              {rowNotices?.length < 1 && (
                <CAlert>No eNotices at this moment.</CAlert>
              )}
              {!rowNotices ? (
                <div className="loader-container">
                  <Spinner />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {/* {testwin &&
          <div className="popup-window">
            <WindowPopup />
          </div>
        } */}
      {/* sidepanel  */}

      {
        <SidePanelDrawer
          isOpen={isAddQuestionSidePanelOpen}
          handleCloseClick={addQuestionModalHandler.close}
        >
          {isAddQuestionSidePanelOpen ? (
            <>
              <p className="font-500 title my-5 mb-0 pb-3">
                Add Visitor Question
              </p>
              <div className="row mb-3">
                <div className="col-12 mt-3">
                  <TextField
                    label="Question"
                    value={customQuestion}
                    onChange={(e) => setCustomQuestion(e.target.value)}
                    name="title"
                    variant="outlined"
                    className="input-field add-question-input"
                    color="secondary"
                    fullWidth
                  />
                </div>
              </div>
              <div>
                <p className="text-mutated">Example:</p>
                <p>1. What's your location?</p>
                <p>2. May I know your company name?</p>
              </div>
              <div className="button-container d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  onClick={addQuestionModalHandler.close}
                  className={"btn btn-outline-dark me-2"}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  disabled={
                    customQuestion.length === 0 || addQuestionMutation.isLoading
                  }
                  onClick={() => addQuestionMutation.mutate(customQuestion)}
                  style={{ marginRight: "1rem" }}
                  className={classNames("btn btn-gradient", {
                    "btn-disabled": customQuestion.length === 0,
                  })}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </SidePanelDrawer>
      }

      <SidePanel>
        {fromNotice ? (
          <ENotice
            onCancel={() => onCancle()}
            isNew={isNew}
            isView={isView}
            selectedNotice={selectedNotice}
            doRefreshPage={doRefreshPage}
          />
        ) : (
          <SettingsTablePanel />
        )}
      </SidePanel>
      {status === "loading" || visitorQuestionsQuery.isFetching ? (
        <div className="spinner-container-main">
          <Spinner />{" "}
        </div>
      ) : null}
    </div>
  );
};

export default Settings;
