import GoogleMapReact from "google-map-react";
import "./mapGoogle.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { placeSelector, setSelectedPlace } from "@root/actions/getEmployee";
// import HomeIcon from "@mui/icons-material/Home";
// import DomainIcon from "@material-ui/icons/Domain";
import {
  fullScreenStatus,
  toggleFullScreen,
  togglePanel,
} from "@root/actions/attendance";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { useUpdateEffect } from "react-use";
import React from "react";

const AnyReactComponent = (props: any) => {
  const img = props.mType === 1 ? "ClockinPin" : "ClockoutPin";
  const isActive = props._id === props.activeId ? props.activeType : 0;
  const temperature = props.temperature;
  const workMode = props.mode;
  const time = props.time;

  return (
    <>
      {/* {console.log(translate(-50%, 50%) translateX(${(props.idx * 1) + 10}px)})} */}
      {/* transform: `translate(-50%, 50%) ${props.mType === 1 ? 'translateX(0)' : 'translateX(10px)'}`  */}
      <div style={{ position: "relative" }}>
        <img
          src={`../../../assests/images/${img}_${
            props.mType === isActive ? "Pink" : "Black"
          }.svg`}
          style={{
            color: props.mType === isActive ? "#ff0062" : "#000",
            cursor: "pointer",
            fontSize: "3rem",
            transform: `translate(-50%, 50%) translateX(${
              props.idx * 10 + 10
            }px)`,
            position: "relative",
            zIndex: props.mType === isActive ? 1 : 0,
          }}
          onClick={() => props.onSelectMarker()}
        />

        {props.mType === isActive && (
          <div
            className="map-tooltip bg-white p-3"
            style={{
              position: "absolute",
              top: "50px",
              right: "20px",
              transform: `translateX(calc(0% + ${props.idx * 10 + 10}px))`,
            }}
          >
            {/* ClockinIcon.svg */}
            <img
              className="indicator-icon"
              src={`../../../assests/images/${
                props.mType === 1 ? "ClockinIcon" : "ClockoutIcon"
              }.svg`}
              alt="icon"
            />
            <p className="theme-color info-title mb-1 mt-2">{`${
              props.mType === 1 ? "Clockedin at" : "Clockedout at"
            } ${time} | ${
              temperature
                ? `${temperature.value}\u00b0 ${temperature.measure}`
                : ""
            } | ${workMode === "WFH" ? "At Home" : "At Office"}`}</p>

            <p className="mb-0">{props.selectedPlace}</p>
          </div>
        )}
      </div>
    </>
  );
};

//@ts-ignore
const MapGoogle = (props: any) => {
  const markers: any[] = props.markers;
  const activeType = props.activeType;
  const activeId = props.activeId;
  const clockinLatLng = props.clockinLatLng;
  const workMode: { clockin: string; clockout: string } = props.mode;
  const selectedplace = useSelector(placeSelector);
  const [selPlace, setPlace] = useState(selectedplace);
  const [modeFwork, setModeFwork] = useState(workMode?.clockin);
  const dispatch = useDispatch();
  const isFullScreen = useSelector(fullScreenStatus);
  // const area_elements = [
  //     "sublocality_level_1",
  //     "locality",
  //     "administrative_area_level_1",
  // ];
  const defaultProps = {
    zoom: 12,
  };

  const onSelectMarker = (_id: any, _type: any, _geo: any, _mode: string) => {
    props.onSelectMarker(_id, _type, _geo);
    geocoderLatLng({
      lat: _geo.lat,
      lng: _geo.lng,
      mode: _mode,
      status: _type === 1 ? "Clockedin at" : "Clockedout at",
    });
  };

  useUpdateEffect(() => {
    if (clockinLatLng && clockinLatLng.lat !== 0 && clockinLatLng.lng !== 0) {
      geocoderLatLng({
        lat: clockinLatLng.lat,
        lng: clockinLatLng.lng,
        status: activeType === 1 ? "Clockedin at" : "Clockedout at",
        mode: workMode?.clockin,
      });
    }
  }, [activeType, activeId]);

  // get selected place with lat and lng
  const geocoderLatLng = (latLng: {
    lat: number;
    lng: number;
    mode: string;
    status?: string;
  }) => {
    if (window?.google?.maps) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results) => {
        if (results) {
          let filteredArea: string[] = [];
          // area_elements.forEach((area) => {
          results[0].address_components.forEach((address: any) => {
            // if (address.types.includes(area)) {
            filteredArea.push(address.long_name);
            // }
          });
          // });
          setModeFwork(latLng.mode);
          setPlace(filteredArea.join(", "));
        }
      });
    }
  };

  useEffect(() => {
    dispatch(setSelectedPlace(selPlace));
  }, [selPlace]);

  const isMapLoaded = (m: any, ms: any) => {
    if (window.google.maps) {
      if (clockinLatLng && clockinLatLng.lat !== 0 && clockinLatLng.lng !== 0) {
        geocoderLatLng({
          lat: clockinLatLng.lat,
          lng: clockinLatLng.lng,
          status: activeType === 1 ? "Clockedin at" : "Clockedout at",
          mode: workMode?.clockin,
        });
        // setActiveMarker(1);
      }
    }
  };

  const _onBound = (e: any) => {
    geocoderLatLng({
      lat: clockinLatLng.lat,
      lng: clockinLatLng.lng,
      status: activeType === 1 ? "Clockedin at" : "Clockedout at",
      mode: workMode?.clockin,
    });
    //  setActiveMarker(activeMarker);
  };

  const defaultMapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  if (clockinLatLng?.lat) {
    return (
      <div style={{ height: "100%", minHeight: "300px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDmFnIjwbzoZeEweUWne0NoVBD3PbF3U_o" }} //Google map API key changed //old key => "AIzaSyDRNF3q8NL0ZksDCUjlz4RpOG680oLxvWk"
          // defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={{ lat: clockinLatLng.lat, lng: clockinLatLng.lng }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => isMapLoaded(map, maps)}
          onChange={_onBound}
          options={defaultMapOptions}
        >
          {markers.length > 0
            ? markers.map((marker, idx) => {
                return (
                  <AnyReactComponent
                    key={`${marker._id}${marker._type}`}
                    _id={marker._id}
                    lat={marker._geo.lat}
                    lng={marker._geo.lng}
                    activeId={activeId}
                    activeType={activeType}
                    mType={marker._type}
                    idx={idx}
                    temperature={marker._temp}
                    mode={marker._mode}
                    time={marker._time}
                    selectedPlace={selectedplace}
                    onSelectMarker={() =>
                      onSelectMarker(
                        marker._id,
                        marker._type,
                        marker._geo,
                        marker._mode
                      )
                    }
                    // onClick={()=> geocoderLatLng({ lat: marker._geo.lat, lng: marker._geo.lng, status: marker._type === 1 ? 'Clockedin at' : 'Clockedout at', active: 1, mode: marker._mode })}
                  />
                );
              })
            : null}
        </GoogleMapReact>
        <div className="map-info-box d-flex justify-content-end">
          {/* <div className="work-location d-flex flex-grow-1 justify-content-between align-items-center">
              <div className="flex-grow-1">
                <p className="mb-0">{selectedplace}</p>
              </div>
              <div className="d-inline-flex">
                <p className="mb-0">{modeFwork === 'WFH' ? 'At Home' : 'At Office'}&nbsp; </p> &nbsp;
                {modeFwork === 'WFH' ? <HomeIcon /> : <DomainIcon />}
                
              </div>
            </div> */}
          <div className="pl-3">
            {isFullScreen ? (
              <button
                className="zoom-control"
                onClick={() => dispatch(toggleFullScreen(false))}
              >
                <FullscreenExitIcon />
                Narrow Screen view
              </button>
            ) : (
              <button
                className="zoom-control"
                onClick={() => dispatch(toggleFullScreen(true))}
              >
                <FullscreenIcon />
                Fullscreen
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "300px" }}
      >
        {" "}
        <p className="mb-0">Geo Location not found</p>
      </div>
    );
  }
};

export default MapGoogle;
