import React from "react";

import "./O7daysCalendar.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import { panelStatusSelector } from "@root/actions/attendance";
import { startDateStringSelector } from "@root/actions/header";
import {
  getAttendaceByID,
  employeeDataSelector,
} from "@root/actions/getEmployee";

const O7daysCalendar = () => {
  const dispatch = useDispatch();
  const today = new Date();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const startDate = useSelector(startDateStringSelector);
  const [selectedDate, setSelectedDate] = useState(startDate);
  const employeeData = useSelector(employeeDataSelector);
  const isOpen = useSelector(panelStatusSelector);

  const getDataByDate = (dd: string) => {
    setSelectedDate(dd);
  };

  const getFormat = (startDate: Date) => {
    return `${startDate?.getFullYear()}-${
      startDate?.getMonth()! + 1
    }-${startDate?.getDate()}`;
  };

  useUpdateEffect(() => {
    if (selectedDate) {
      dispatch(
        getAttendaceByID({ _id: employeeData.user._id, _date: selectedDate })
      );
    }
  }, [selectedDate]);

  return (
    <>
      {isOpen && (
        <div className="last7-days d-flex">
          {[0, 1, 2, 3, 4, 5, 6].map((el) => {
            const newDate: Date | null = moment(startDate)
              .subtract(el, "day")
              .toDate();
            const tDate = getFormat(today);
            const eDate = getFormat(newDate);
            let classes = "date-btn";
            classes += selectedDate === getFormat(newDate) ? " active" : "";
            return (
              <button
                onClick={() => getDataByDate(getFormat(newDate))}
                className={classes}
                key={el}
              >
                {tDate === eDate
                  ? "Today"
                  : `${newDate.getDate()} ${days[newDate.getDay()]}`}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export default O7daysCalendar;
