import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  TextField,
} from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
// import Box from "@mui/material/Box";
import { useUnmount, useUpdateEffect } from "react-use";
import { useEffect, useState } from "react";
import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
import TimePicker from "@mui/lab/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelPreInvitation,
  newPreInvitation,
  updatePreInvitation,
  newRecordFetch,
  recordUpdated,
  preInvLoader,
} from "@root/actions/preInvitations";
import { panelStatusSelector } from "@root/actions/attendance";
import { showNotificationError } from "@root/actions/toast-notification";
import DeleteIcon from "@mui/icons-material/Delete";
import "./enotice.scss";
import Spinner from "../Spinner/Spinner";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { updateNotice, createNewNotice } from "@root/actions/notices";

const VibaTextField = styled(MuiTextField)({
  //#FF0062
  "& label.Mui-focused": {
    color: "#FF0062",
  },
  "& label.Mui-error": {
    color: "#FF0000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FF0062",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.32)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.8)",
    },
    "&.Mui-focused fieldset": {
      borderColor: `#FF0062`,
    },
    "&.Mui-error fieldset": {
      borderColor: `#FF0000`,
      borderWidth: "2px",
    },
  },
});

const ENotice = (props: any) => {
  const labels = {
    title: "Title",
    active: "Status",
    type: "Type",
    highlight: "Highlight",
    link: "Link",
    message: "Message",
  };
  const dispatch = useDispatch();
  const isOpen = useSelector(panelStatusSelector);
  const selectedNotice = props.selectedNotice;
  const invitation = {
    _id: "",
    active: "true",
    type: "announcement",
    title: "",
    highlight: true,
    link: "",
    message: "",
  };

  const [preInvitation, setPreInvitation] = useState(invitation);
  const [dateTimeStr, setdateTimeStr] = useState<Date | null>();
  const [fieldDisable, setFieldDisable] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
  const isNewRecord = useSelector(newRecordFetch);
  const isRecordUpdated = useSelector(recordUpdated);
  const isLoading = useSelector(preInvLoader);

  const handleChange = (event: any) => {
    let target = event.target as HTMLInputElement;

    if (target.name === "name" || target.name === "title") {
      target.value = target.value.replace(/[^A-Za-z]/gi, "");
    }
    let newInvitation;
    if (target.name === "highlight") {
      newInvitation = { ...preInvitation, [target.name]: target.checked };
    } else {
      newInvitation = { ...preInvitation, [target.name]: target.value };
    }

    setPreInvitation(newInvitation);
    // console.log(target.name, target.value)
  };

  const handleDateChange = (event: any) => {
    const _date = new Date(event);
    // console.log(`${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`);
    const newInvitation = {
      ...preInvitation,
      _date: `${_date.getFullYear()}-${
        _date.getMonth() + 1
      }-${_date.getDate()}`,
    };
    setPreInvitation(newInvitation);
    setdateTimeStr(_date);
  };

  const resetForm = () => {
    setPreInvitation({
      _id: "",
      active: "true",
      type: "announcement",
      highlight: true,
      title: "",
      link: "",
      message: "",
    });
  };
  useUpdateEffect(() => {
    if (!isOpen) resetForm();
  }, [isOpen]);

  useEffect(() => {
    if (props.isView) {
      setPreInvitation({
        ...preInvitation,
        _id: selectedNotice?._id,
        title: selectedNotice?.title ? selectedNotice?.title : "",
        active: selectedNotice?.active ? "true" : "false",
        type: selectedNotice?.type,
        highlight: selectedNotice?.highlight,
        link: selectedNotice?.link,
        message: selectedNotice?.message,
      });
    }
    if (props.isNew) {
      setFieldDisable(false);
    } else {
      setFieldDisable(true);
    }
  }, [selectedNotice]);

  useUpdateEffect(() => {
    if (isNewRecord || isRecordUpdated) props.onCancel();
  }, [isNewRecord, isRecordUpdated]);

  useUnmount(() => {
    // console.log('Unmounted');
    props.onCancel();
    resetForm();
  });

  const handleSubmit = () => {
    if (props.isView) {
      if (fieldDisable) {
        props.onCancel();
      } else {
        updateInvitation();
      }
    } else {
      createInvitation();
    }
  };

  const createInvitation = () => {
    // official, interview, meeting, delivery
    // newPreInvitation
    const obj = {
      title: preInvitation.title,
      active: preInvitation.active,
      type: preInvitation.type,
      highlight: preInvitation.highlight ? "true" : "false",
      link: preInvitation.link,
      message: preInvitation.message,
    };
    // console.log(obj);
    const inval: string[] = [];
    for (const [key, value] of Object.entries(obj)) {
      //@ts-ignore
      if (key != "link") {
        //@ts-ignore
        if (!value.trim()) inval.push(labels[key]);
      }
    }

    let message: string = "";
    if (inval.length > 0) {
      message = `All fields are mandatory. Please check ${inval.join(
        ", "
      )}. \r\n`;
    }

    if (inval.length > 0) {
      dispatch(showNotificationError({ type: "error", message }));
      setInvalidInputs(inval);
    } else {
      dispatch(createNewNotice(obj));
      props.doRefreshPage();
      handleCancelClick();
    }
  };

  const updateInvitation = () => {
    const noticeItem = {
      _id: preInvitation._id,
      title: preInvitation.title,
      active: preInvitation.active,
      type: preInvitation.type,
      highlight: preInvitation.highlight,
      link: preInvitation.link,
      message: preInvitation.message,
    };
    dispatch(updateNotice(noticeItem));
    props.doRefreshPage();
    handleCancelClick();
  };

  const cancelInvitation = () => {
    const obj = {
      _id: selectedNotice?._id,
      name: `${selectedNotice?.name}`,
      purpose: selectedNotice?.purpose,
      start: selectedNotice?.start,
      end: selectedNotice?.end,
    };
    // console.log(obj);
    dispatch(cancelPreInvitation(obj));
    setShowConfirm(false);
    // props.onCancel();
  };

  const handleCancelClick = () => {
    if (fieldDisable) {
      setFieldDisable(false);
    } else {
      resetForm();
      props.onCancel();
    }
  };

  return (
    <>
      <div className="d-flex flex-column h-100 position-relative enotice">
        <div className="top-wrapper">
          <div className="py-2 d-flex justify-content-between align-items-center title-header">
            <div>
              <p className="mb-0 font-500 title">
                {`${props.isNew ? "Create New" : "View"}`} Notice
              </p>
            </div>

            {props.isView && fieldDisable ? (
              <button
                type="button"
                onClick={handleCancelClick}
                className={"btn btn-outline-dark edit-button"}
              >
                <EditIcon />
                &nbsp;Edit{" "}
              </button>
            ) : null}
          </div>
        </div>

        <div className="h-full notice-form flex-grow-1">
          <form noValidate autoComplete="OFF">
            <p className="mb-2 text-right">
              <span className="text-danger">*</span>All fields are required.
            </p>
            <div className="row mb-3">
              <div className="col-12 mt-3">
                <FormControl fullWidth>
                  <InputLabel id="purpose-select-label">Status</InputLabel>
                  <Select
                    labelId="purpose-select-label"
                    id="demo-simple-select"
                    value={preInvitation?.active}
                    label="Purpose of visit"
                    variant="outlined"
                    name="active"
                    disabled={fieldDisable ? true : false}
                    onChange={handleChange}
                    error={invalidInputs.includes("status") ? true : false}
                  >
                    <MenuItem value={"true"}>
                      <span className="approved">Approve</span>
                    </MenuItem>
                    <MenuItem value={"false"}>
                      <span className="rejected">Reject</span>
                    </MenuItem>{" "}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row mb-3 ">
              <div className="col-12 mt-3">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="type"
                    name="type"
                    color="secondary"
                  >
                    <FormControlLabel
                      value="announcement"
                      checked={preInvitation?.type === "announcement"}
                      onChange={handleChange}
                      control={<Radio />}
                      label="Announcement"
                      disabled={fieldDisable ? true : false}
                    />
                    <FormControlLabel
                      value="general"
                      checked={preInvitation?.type === "general"}
                      onChange={handleChange}
                      control={<Radio />}
                      label="General"
                      disabled={fieldDisable ? true : false}
                    />
                    <FormControlLabel
                      value="alert"
                      checked={preInvitation?.type === "alert"}
                      onChange={handleChange}
                      control={<Radio />}
                      label="Alert"
                      disabled={fieldDisable ? true : false}
                    />
                    <FormControlLabel
                      value="event"
                      checked={preInvitation?.type === "event"}
                      onChange={handleChange}
                      control={<Radio />}
                      label="Event"
                      disabled={fieldDisable ? true : false}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Title"
                  value={preInvitation?.title}
                  onChange={handleChange}
                  name="title"
                  variant="outlined"
                  color="secondary"
                  disabled={fieldDisable ? true : false}
                  fullWidth
                  error={invalidInputs.includes("Title") ? true : false}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Message"
                  value={preInvitation?.message}
                  multiline
                  maxRows={4}
                  onChange={handleChange}
                  name="message"
                  variant="outlined"
                  color="secondary"
                  disabled={fieldDisable ? true : false}
                  fullWidth
                  error={invalidInputs.includes("Message") ? true : false}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Additional Info Link"
                  value={preInvitation?.link}
                  name="link"
                  onChange={handleChange}
                  variant="outlined"
                  className="email"
                  disabled={fieldDisable ? true : false}
                  fullWidth
                  color="secondary"
                  error={
                    invalidInputs.includes("Additional Info Link")
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <FormGroup>
                  <FormControlLabel
                    name="highlight"
                    control={<Checkbox checked={preInvitation.highlight} />}
                    label="Highlight this one as New event"
                    onChange={handleChange}
                    disabled={fieldDisable ? true : false}
                  />
                </FormGroup>
              </div>
            </div>
          </form>
        </div>

        <div className="button-container d-flex align-items-center justify-content-between">
          <div className="flex-grow-1 d-flex justify-content-end">
            {props.isView && !fieldDisable ? (
              <button
                type="button"
                onClick={handleCancelClick}
                className={"btn btn-outline-dark me-2"}
              >
                Cancel
              </button>
            ) : null}

            <button
              type="button"
              onClick={handleSubmit}
              className={"btn btn-gradient"}
            >
              {props.isView ? (fieldDisable ? "Okay" : "Update") : "Submit"}
            </button>
          </div>
        </div>
        {showConfirm && (
          <div className="confirm-box d-flex justify-content-center align-items-center">
            <div className="confirm-wrapper shadow">
              <div className="confirm-header">
                <p>Are sure you want to cancel e-notice?</p>
              </div>
              <div className="confirm-actions pt-4">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    onClick={() => setShowConfirm(false)}
                    className={"btn btn-outline-dark edit-button"}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={cancelInvitation}
                    className={"btn btn-gradient"}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading === "loading" && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default ENotice;
