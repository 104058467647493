import "./mapview.scss";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { useUpdateEffect } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeDataSelector,
  getAttendaceByID,
} from "@root/actions/getEmployee";
import { startDateStringSelector } from "@root/actions/header";
import ClockInLabel from "../ClockInLabel";
import Spinner from "../Spinner/Spinner";
import PanelProfile from "../PanelProfile/PanelProfile";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CloseIcon from "@material-ui/icons/Close";
import { resetEmpInfo } from "@root/actions/editEmployee";
import ShowActivity from "../ShowActivity";
import React from "react";
import { fullScreenStatus, toggleFullScreen } from "@root/actions/attendance";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import CAlert from "../CAlert";

const AnyReactComponent = (props: any) => {
  const isActive = props.isActive;
  const pImage = props.img;
  const temperature = props.temperature;
  const workMode = props.mode;
  const time = props.time;

  return (
    <>
      <div className="">
        <div
          style={{
            position: "relative",
            width: "38px",
            cursor: "pointer",
            transform: `translate(-50%, 50%) translateX(${
              props.idx * 10 + 10
            }px)`,
            zIndex: isActive ? 1 : 0,
          }}
          onClick={() => props.onSelectMarker()}
        >
          <img
            src={`../../../assests/images/ClockinPin_${
              isActive ? "Pink" : "Black"
            }.svg`}
            style={{
              color: props.mType === isActive ? "#ff0062" : "#000",
              fontSize: "3rem",
              width: "38px",
            }}
          />

          <div
            style={{
              width: "38px",
              position: "absolute",
              height: "38px",
              borderRadius: "50%",
              overflow: "hidden",
              zIndex: 1,
              left: 0,
              top: 0,
              padding: "3px",
            }}
          >
            <img
              style={{ width: "100%", borderRadius: "50%" }}
              src={pImage}
              alt="profile"
            />
          </div>
        </div>
        {isActive && (
          <div
            className="map-tooltip bg-white p-3"
            style={{
              position: "absolute",
              top: "65px",
              right: "16px",
              transform: `translateX(calc(0% + ${props.idx * 10 + 10}px))`,
            }}
          >
            {/* ClockinIcon.svg */}
            <img
              className="indicator-icon"
              src={`../../../assests/images/ClockinIcon.svg`}
              alt="icon"
            />
            <p className="theme-color info-title mb-1 mt-2">{`${"Clockedin at"} ${time} | ${
              temperature
                ? `${temperature.value}\u00b0 ${temperature.measure}`
                : ""
            } | ${workMode === "WFH" ? "At Home" : "At Office"}`}</p>

            <p className="mb-0">{props.selectedPlace}</p>
          </div>
        )}
      </div>
    </>
  );
};

const MapView = (props: any) => {
  const employees = props.mapPins;
  const dispatch = useDispatch();
  const [activeMarker, setActiveMarker] = useState("");
  const startDate = useSelector(startDateStringSelector);
  const employeeData = useSelector(employeeDataSelector);
  const [isOpenEmpDetail, setIsOpenEmpDetail] = useState(false);
  const [modeFwork, setModeFwork] = useState("");
  const [place, setPlace] = useState("");
  const isFullScreen = useSelector(fullScreenStatus);
  const defaultProps = {
    zoom: 10,
  };
  const defaultMapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  const toggleMapFullScreen = (toggle: boolean) => {
    dispatch(toggleFullScreen(toggle));
    if (toggle) {
      setIsOpenEmpDetail(false);
    }
  };

  const onSelectMarker = (
    id: string,
    lat: number,
    lng: number,
    mode: string
  ) => {
    setActiveMarker(id);
    isFullScreen ? setIsOpenEmpDetail(false) : setIsOpenEmpDetail(true);
    geocoderLatLng({ lat, lng, mode });
  };

  const onCloseDetails = () => {
    setActiveMarker("");
    setIsOpenEmpDetail(false);
    dispatch(resetEmpInfo());
  };

  // get selected place with lat and lng
  const geocoderLatLng = (latLng: {
    lat: number;
    lng: number;
    mode: string;
  }) => {
    if (window.google?.maps) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results) => {
        if (results) {
          let filteredArea: string[] = [];
          results[0].address_components.forEach((address: any) => {
            filteredArea.push(address.long_name);
          });
          setModeFwork(latLng.mode);
          setPlace(filteredArea.join(", "));
        }
      });
    }
  };

  useUpdateEffect(() => {
    if (activeMarker)
      dispatch(getAttendaceByID({ _id: activeMarker, _date: startDate }));
  }, [activeMarker, startDate]);

  const getLabel = (obj: any) => {
    return obj.clocked_out_at ? (
      <ClockInLabel status={"clockout"} />
    ) : (
      <ClockInLabel status={"clockin"} />
    );
  };

  const getDay = (timeString: string) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const time = new Date(timeString);
    return `${days[time.getDay()]}`;
  };
  const getDateFormat = (timeString: string) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const time = new Date(timeString);

    return `${time.getUTCDate()} ${
      months[time.getUTCMonth()]
    } ${time.getUTCFullYear()}`;
    // return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
  };

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  return (
    <>
      <div
        className="row mapview-container m-0"
        style={{ height: "100%", minHeight: "300px", width: "100%" }}
      >
        <div
          className={`part1 h-100 p-0 ${isOpenEmpDetail ? "col-md open" : ""}`}
        >
          <div className="h-100  pl-3 pr-3 shadow">
            {employeeData ? (
              <>
                <div className="d-flex flex-column h-100 emp-detail-container h-100 pb-4">
                  {/* status label  */}
                  <div className="status-label-container d-flex justify-content-center align-items-center">
                    {employeeData.checkin
                      ? getLabel(employeeData.checkin)
                      : null}
                  </div>

                  {/* title haeder */}
                  <div className="d-flex justify-content-end align-items-center title-header py-2">
                    <button
                      onClick={onCloseDetails}
                      className="panel-close shadow-sm"
                    >
                      <CloseIcon />
                    </button>
                  </div>

                  {/* panel-profile  */}
                  <div className="d-flex justify-content-center">
                    <PanelProfile
                      image={employeeData.user.image}
                      name={`${employeeData.user.first_name} ${employeeData.user.last_name}`}
                      designation={employeeData.user.description}
                    />
                  </div>
                  <h6 className="mb-0 text-center">
                    {getDay(startDate)},&nbsp;{getDateFormat(startDate)}
                  </h6>

                  {/* clockin clockout details  */}
                  <div className="card-container d-flex mb-3 pt-4">
                    <div className="deatail-card">
                      <p>Temperature</p>
                      <div className="d-flex align-items-center">
                        <div className="icon">
                          <ThermostatIcon />
                        </div>
                        <div className="card-info">
                          {employeeData.checkin ? (
                            <div className="">
                              {employeeData?.checkin?.temperature ? (
                                <>
                                  <p
                                    className={`line-height1 mb-0`}
                                  >{`${employeeData.checkin.temperature.value}\u00b0 ${employeeData.checkin.temperature.measure}`}</p>
                                  <span
                                    className={`${
                                      employeeData.checkin.temperature
                                        .measure === "F"
                                        ? employeeData.checkin.temperature
                                            .value > 99
                                          ? "text-danger"
                                          : "text-success"
                                        : employeeData.checkin.temperature
                                            .value > 37.2
                                        ? "text-danger"
                                        : "text-success"
                                    }`}
                                  >
                                    {employeeData.checkin?.temperature
                                      ?.measure === "F"
                                      ? employeeData.checkin.temperature.value >
                                        99
                                        ? "High"
                                        : "Normal"
                                      : employeeData.checkin.temperature.value >
                                        37.2
                                      ? "High"
                                      : "Normal"}
                                  </span>
                                </>
                              ) : (
                                <p className={`line-height1 mb-0`}>N/A</p>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="deatail-card">
                      <p>1st Clockin</p>
                      <div className="d-flex align-items-center">
                        <div className="icon in">
                          <ArrowRightAltIcon />
                        </div>
                        <div className="card-info">
                          {employeeData.checkin ? (
                            <div className="">
                              {employeeData?.checkin ? (
                                <>
                                  <p className="mb-0">
                                    {getTimeFormat(
                                      employeeData.checkin.clocked_in_at
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    {employeeData.checkin.clocked_in_status ===
                                    "late" ? (
                                      <span className="text-danger">
                                        Late Clockedin
                                      </span>
                                    ) : (
                                      <span className="text-success">
                                        on-time
                                      </span>
                                    )}
                                  </p>
                                </>
                              ) : (
                                <p className={`line-height1 mb-0`}>N/A</p>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div
                      className="deatail-card"
                      style={{ paddingLeft: "12px" }}
                    >
                      <p>Last Clockout</p>
                      <div className="d-flex align-items-center">
                        <div className="icon out">
                          <ArrowRightAltIcon />
                        </div>
                        <div className="card-info">
                          {employeeData.checkin ? (
                            <div className="">
                              {employeeData?.checkin?.clocked_out_at ? (
                                <>
                                  <p className="mb-0">
                                    {getTimeFormat(
                                      employeeData.checkin.clocked_out_at
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    <span>
                                      {employeeData.checkin.mode.clockout}
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <p className={`line-height1 mb-0`}>N/A</p>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* activity container */}
                  <div className={`today-activity2 pt-4`}>
                    <p className="">Day Activity</p>
                    {employeeData.activities.length > 0 ? (
                      <div className="activity-container">
                        {employeeData.activities.map((activity) => {
                          return (
                            <ShowActivity
                              key={activity._id}
                              Activity={activity}
                              noEvent={true}
                              isLast={
                                employeeData.activities[
                                  employeeData.activities.length - 1
                                ] === activity
                                  ? true
                                  : false
                              }
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center mt-5">
                        <h6>No Activity To Show</h6>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <div className="col-md part2 h-100 bg-white p-0">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%", minHeight: "300px", width: "100%" }}
          >
            {employees?.length > 0 ? (
              <div
                style={{ height: "100%", minHeight: "300px", width: "100%" }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDmFnIjwbzoZeEweUWne0NoVBD3PbF3U_o",
                  }} // Change Google map API key // old key AIzaSyDRNF3q8NL0ZksDCUjlz4RpOG680oLxvWk
                  // defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={{
                    lat: employees[0].checkin.geo_location.lat,
                    lng: employees[0].checkin.geo_location.lng,
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  // onGoogleApiLoaded={({ map, maps }) => isMapLoaded(map, maps)}
                  // onChange={_onBound }
                  options={defaultMapOptions}
                >
                  {employees?.length > 0
                    ? employees.map((el: any, idx: any) => {
                        return (
                          <AnyReactComponent
                            key={el._id}
                            idx={idx}
                            lat={el.checkin.geo_location.lat}
                            lng={el.checkin.geo_location.lng}
                            img={el.image}
                            temperature={el.checkin.temperature}
                            mode={el.checkin.clockin}
                            time={getTimeFormat(el.checkin.clocked_in_at)}
                            isActive={activeMarker === el._id ? true : false}
                            selectedPlace={place}
                            onSelectMarker={() =>
                              onSelectMarker(
                                el._id,
                                el.checkin.geo_location.lat,
                                el.checkin.geo_location.lng,
                                el.checkin.clockin
                              )
                            }
                          />
                        );
                      })
                    : null}
                </GoogleMapReact>
                <div className="map-info-box d-flex justify-content-end">
                  {/* <div className="work-location d-flex flex-grow-1 justify-content-between align-items-center">
              <div className="flex-grow-1">
                <p className="mb-0">{selectedplace}</p>
              </div>
              <div className="d-inline-flex">
                <p className="mb-0">{modeFwork === 'WFH' ? 'At Home' : 'At Office'}&nbsp; </p> &nbsp;
                {modeFwork === 'WFH' ? <HomeIcon /> : <DomainIcon />}
                
              </div>
            </div> */}
                  <div className="pl-3">
                    {isFullScreen ? (
                      <button
                        className="zoom-control"
                        onClick={() => toggleMapFullScreen(false)}
                      >
                        <FullscreenExitIcon className="text-[#8000ff]" />
                        <span className="text-[#8000ff]">Exit Fullscreen</span>
                      </button>
                    ) : (
                      <button
                        className="zoom-control"
                        onClick={() => toggleMapFullScreen(true)}
                      >
                        <FullscreenIcon />
                        Fullscreen
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <CAlert>
                No records at this moment. Please try with another date.
              </CAlert>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MapView;
