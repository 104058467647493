import React, { useEffect } from "react";
import { useState } from "react";
import "./filter.css";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttendanceList,
  payloadSelector,
  setFilterData,
  startDateSelector,
  toggleFilter,
} from "@root/actions/attendance";

import {
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { startDateStringSelector } from "@root/actions/header";

const Filter = (props: any) => {
  const dispatch = useDispatch();
  const [temp, setTemp] = useState<string>("all");
  const [inTime, setInTime] = useState<string>("all");
  const [work, setWork] = useState<string>("all");
  const [clock, setclock] = useState<string>("all");
  const payload = useSelector(payloadSelector);
  // const startDate = useSelector(startDateSelector);
  const startDate = useSelector(startDateStringSelector);
  const filter = {
    temperature: "",
    time: "",
    workType: "",
    status: "",
  };
  const handleApply = (event: any) => {
    event.preventDefault();
    filter.temperature = temp;
    filter.time = inTime;
    filter.workType = work;
    filter.status = clock;

    dispatch(setFilterData(filter));
    // dispatch(filterList());
    console.log(startDate);
    dispatch(
      getAttendanceList({
        from_date: startDate,
        to_date: startDate,
        ...payload,
        temperature: filter.temperature,
        on_time: filter.time,
        mode: filter.workType,
        status: filter.status,
      })
    );
    dispatch(toggleFilter());
  };
  const handleResetClick = (event: any) => {
    event.preventDefault();
    setTemp("all");
    setInTime("all");
    setWork("all");
    setclock("all");
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 filter-col">
            <div className="row filter-rows">
              <h6 className="filtername">Temperature</h6>
              <div className="radio">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    name="temperature"
                    value={temp}
                    onChange={(e) => setTemp(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="pve"
                      control={<Radio />}
                      label="+Ve"
                    />
                    <FormControlLabel
                      value="nve"
                      control={<Radio />}
                      label="-Ve"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="row filter-rows">
              <h6 className="filtername">Work Mode:</h6>
              <div className="radio">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    name="work-from"
                    value={work}
                    onChange={(e) => setWork(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="WFH"
                      control={<Radio />}
                      label="WFH"
                    />
                    <FormControlLabel
                      value="WFO"
                      control={<Radio />}
                      label="WFO"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row filter-rows">
              <div>
                <h6 className="filtername">Clockedin Status</h6>
              </div>
              <div className="radio">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    aria-label="in-time"
                    name="in-time"
                    value={inTime}
                    onChange={(e) => setInTime(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="on-time"
                      control={<Radio />}
                      label="On-Time"
                    />
                    <FormControlLabel
                      value="late"
                      control={<Radio />}
                      label="Late Clockedin"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="row filter-rows">
              <div>
                {" "}
                <h6 className="filtername">Status</h6>
              </div>

              <div className="radio">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    aria-label="status"
                    name="status"
                    value={clock}
                    onChange={(e) => setclock(e.target.value)}
                    color="secondary"
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="clocked-in"
                      control={<Radio />}
                      label="ClockedIn  "
                    />
                    <FormControlLabel
                      value="clocked-out"
                      control={<Radio />}
                      label="ClockedOut"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type={"submit"}
          onClick={handleApply}
          className="btn btn-gradient  filterapply"
        >
          Apply
        </button>
        <button
          type={"submit"}
          onClick={() => dispatch(toggleFilter())}
          className="btn filtercancel "
        >
          cancel
        </button>
        <span style={{ cursor: "pointer" }} onClick={handleResetClick}>
          <RestartAltIcon />
          Reset
        </span>
      </div>
    </div>
  );
};

export default Filter;
