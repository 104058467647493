import "./sendAppLink.scss";
import { closePanel } from "@root/actions/attendance";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleGlSidePanel } from "@root/actions/metadata";
import { useUnmount } from "react-use";
import { toggleOpenAppLink } from "@root/actions/header";
import React from "react";

const SendAppLink = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closePanel(false));
  }, []);

  useUnmount(() => {
    dispatch(toggleOpenAppLink(false));
  });

  return (
    <>
      <div className="d-flex appLink-container flex-column h-100 pb-4">
        {/* title haeder */}
        <div className="py-2 d-flex justify-content-between align-items-center title-header">
          <div>
            <p className="mb-0 font-500 title">Send App Link</p>
          </div>
        </div>

        {/* last 7days calendar  */}
        <div className="pt-4">
          <div className="link-mediums d-flex">
            <button className="link-medium-btn active"> Email </button>
            <button className="link-medium-btn"> SMS </button>
            <button className="link-medium-btn"> Whatsapp </button>
            <button className="link-medium-btn"> Slack </button>
          </div>
        </div>

        <div className="pt-4">
          <p className="mb-2">To</p>
          <div className="form-group mb-2">
            <input type="text" className="form-control" />
          </div>
          <p className="info-text text-right">
            Use comma to add multiple emails.
          </p>
        </div>

        <div className="pt-5 body-container">
          <p className="mb-2">Email Subject</p>
          <div className="email-template p-3 mb-3">
            <p>
              Dear Employee, <br /> <br /> We are using VIBA app for the Clockon
              & Clockout and other HR related matters. Please use below link to
              download Viba EMS app to Clockin or Clockout from office or remote
              and use other features as well.
            </p>

            <div className="pt-3">
              <p className="download">CLICK HERE TO DOWNLOAD IOS APP</p>
              <br />
              <p className="download">CLICK HERE TO DOWNLOAD ANDROID APP</p>
            </div>
            <p className="mb-0">
              Thanks & Regards, <br /> <br />
              Team Viba
            </p>
            <p>www.viba.ai</p>
          </div>
        </div>
      </div>
      <div className="button-container d-flex align-items-center justify-content-end">
        <button
          type="button"
          onClick={() => dispatch(toggleGlSidePanel(false))}
          className={"btn btn-outline-dark me-2"}
        >
          Cancel
        </button>
        <button type="button" className={"btn btn-gradient"}>
          Send
        </button>
      </div>
    </>
  );
};

export default SendAppLink;
