import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import {
  getSettings,
  updateSettings,
  updateVisitorQuestions,
} from "@root/services/settings";
import { showNotificationError } from "../toast-notification";

type setting = {
  status: "loading" | "idle";
  error: null | string;
  hasUpdated: boolean;
  settings: {
    business_days: Array<string>;
    opens_at: {
      hour: number;
      minute: number;
      type: string;
      grace_minutes: number;
      mode: number;
    };
    closes_at: {
      hour: number;
      minute: number;
      type: string;
      mode: number;
    };
    visitor_questions: {
      name: boolean;
      phone: boolean;
      otp: boolean;
      purpose: boolean;
      signature: boolean;
    };
    default_checkin_mode: string;
    _id: string;
    tablet_pin: string;
    integration: {
      google: boolean;
      microsoft: boolean;
    };
  };
};

const initialState: setting = {
  hasUpdated: false,
  error: "",
  status: "idle",
  settings: {
    business_days: [],
    opens_at: {
      hour: 9,
      minute: 45,
      type: "am",
      grace_minutes: 10,
      mode: 12,
    },
    closes_at: {
      hour: 6,
      minute: 30,
      type: "pm",
      mode: 12,
    },
    visitor_questions: {
      name: true,
      phone: true,
      otp: true,
      purpose: true,
      signature: true,
    },
    default_checkin_mode: "WFO",
    _id: "615c95bffa612356f5f09267",
    tablet_pin: "12345",
    integration: {
      google: false,
      microsoft: false,
    },
  },
};

export const updateVisitorFlowQuestions = createAsyncThunk<
  Dictionary,
  { purpose: boolean; otp: boolean; phone: boolean; signature: boolean },
  { rejectValue: ResponseError }
>(
  "@admin/visitors_question_update",
  async ({ purpose, otp, phone, signature }, thunkAPI) => {
    try {
      const response = await updateVisitorQuestions(
        purpose,
        otp,
        phone,
        signature
      );
      const errMsg: any = response.data;
      if (response.status === 200) {
        store.dispatch(
          showNotificationError({
            type: "success",
            message: "VisitorQuestions Updated.",
          })
        );
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const getAllSettings = createAsyncThunk<
  any,
  {},
  { rejectValue: ResponseError }
>("@admin/getSettings", async ({}, thunkAPI) => {
  try {
    const response: any = await getSettings();
    return {
      settings: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

type ocHours = {
  hour: number;
  minute: number;
  type: string;
  grace_minutes: number;
  mode: number;
};
type cHours = { hour: number; minute: number; type: string; mode: number };

export const updateAllSettings = createAsyncThunk<
  Dictionary,
  {
    business_days: string[];
    default_checkin_mode: string;
    opens_at: ocHours;
    closes_at: cHours;
  },
  { rejectValue: ResponseError }
>(
  "@admin/updateSettings",
  async (
    { business_days, default_checkin_mode, opens_at, closes_at },
    thunkAPI
  ) => {
    try {
      const response: any = await updateSettings(
        business_days,
        default_checkin_mode,
        opens_at,
        closes_at
      );
      console.log(response.data);
      return {
        settings: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSettings.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getAllSettings.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.settings = { ...action.payload?.settings };
    });
    builder.addCase(getAllSettings.rejected, (state, action) => {
      state.status = "idle";
      state.error = "something is wrong";
    });
    builder.addCase(updateAllSettings.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(updateAllSettings.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.settings = action.payload?.settings;
      state.hasUpdated = true;
    });
    builder.addCase(updateAllSettings.rejected, (state, action) => {
      state.status = "idle";
      state.hasUpdated = false;
      state.error = "something is wrong";
    });
    // updateVisitorFlowQuestions
    builder.addCase(updateVisitorFlowQuestions.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(updateVisitorFlowQuestions.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
    });
    builder.addCase(updateVisitorFlowQuestions.rejected, (state, action) => {
      state.status = "idle";
      state.error = "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.settings;

export const settingsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.settings
);
export const hasUpdatedSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasUpdated
);
export const statusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export default settingsSlice.reducer;
