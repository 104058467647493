import "./integrationCard.scss";
import React from "react";

type IntegrationProps = {
  show: boolean;
  image: string;
  title: string;
  isIntegrated: boolean;
  onClick: Function;
  onDisconnect: Function;
};

const IntegrationCard = (props: IntegrationProps) => {
  return (
    <div className="integration">
      <div>
        <div className="integration-icon d-flex justify-content-start align-items-center">
          <h6 className="mb-0">{props.title}</h6>
          <div className="ms-2">
            <img src={props.image} alt="Icon" className="img-fluid" />
          </div>
        </div>
        {props.show && (
          <div className="integration-content pt-3">
            <div className="integration-action">
              {props.isIntegrated ? (
                <div className="d-flex">
                  <button className="btn btn-connected" disabled>
                    Connected
                  </button>
                  <button
                    onClick={() => props.onDisconnect()}
                    className="btn btn-disconnect"
                  >
                    Disconnect
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => props.onClick()}
                  className="btn btn-gradient w-100"
                >
                  Authorize to Integrate
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationCard;
