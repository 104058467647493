import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import {
  getPreInvitations,
  createInvitation,
  updateInvitation,
  cancelInvitation,
} from "@root/services/pre-invitations";
import { showNotificationError } from "@root/actions/toast-notification";

type Invitations = {
  _id: string;
  cancelled: boolean;
  name: string;
  phone: string;
  purpose: string;
  start: string;
  end: string;
  image: string;
  clockin?: _vClockin;
};

type _vClockin = {
  _id: string;
  clocked_out_at: string;
  clocked_in_at: string;
  temperature: {
    value: number;
    measure: string;
  };
};

export const updatePreInvitation = createAsyncThunk<
  Dictionary,
  { _id: string; name: string; purpose: string; start: string; end: string },
  { rejectValue: ResponseError }
>(
  "@pre-Invitations/update",
  async ({ _id, name, purpose, start, end }, thunkAPI) => {
    try {
      const response = await updateInvitation(_id, name, purpose, start, end);
      const errMsg: any = response.data;
      if (response.status === 200) {
        store.dispatch(
          showNotificationError({
            type: "success",
            message: "Invitation Updated.",
          })
        );
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const cancelPreInvitation = createAsyncThunk<
  Dictionary,
  { _id: string; name: string; purpose: string; start: string; end: string },
  { rejectValue: ResponseError }
>(
  "@pre-Invitations/cancel",
  async ({ _id, name, purpose, start, end }, thunkAPI) => {
    try {
      const response = await cancelInvitation(_id, name, purpose, start, end);
      const errMsg: any = response.data;
      if (response.status === 200) {
        store.dispatch(
          showNotificationError({
            type: "success",
            message: "Invitation Cancelled.",
          })
        );
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }

      // return (response.status === 200) ? {
      //     data: response.data,
      //     status: true
      // } : {status: false, error: errMsg}
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const newPreInvitation = createAsyncThunk<
  Dictionary,
  {
    title: string;
    name: string;
    email: string;
    phone: string;
    purpose: string;
    start: string;
    end: string;
  },
  { rejectValue: ResponseError }
>(
  "@pre-Invitations/create",
  async ({ title, name, email, phone, purpose, start, end }, thunkAPI) => {
    try {
      const response = await createInvitation(
        title,
        name,
        email,
        phone,
        purpose,
        start,
        end
      );
      const errMsg: any = response.data;

      if (response.status === 200) {
        store.dispatch(
          showNotificationError({
            type: "success",
            message: "Invitation Created.",
          })
        );
        return {
          data: response.data,
          status: true,
        };
      } else {
        store.dispatch(
          showNotificationError({ type: "error", message: errMsg.error })
        );
        return { status: false, error: errMsg };
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const getPreInvitationsList = createAsyncThunk<
  Dictionary,
  { date: string; status: string; page_size: number; page_index: number },
  { rejectValue: ResponseError }
>(
  "@pre-Invitations",
  async ({ date, status, page_size, page_index }, thunkAPI) => {
    try {
      const response = await getPreInvitations(
        date,
        status,
        page_size,
        page_index
      );
      const errMsg: any = response.data;

      return response.status === 200
        ? {
            data: response.data,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const initialState: {
  list: Invitations[];
  status: string;
  error: string;
  total: number;
  filterMeta: any;
  newRecord: boolean;
  recordUpdated: boolean;
} = {
  list: [],
  status: "idle",
  error: "",
  total: 0,
  filterMeta: {
    status: null,
    pageIndex: 0,
    hasNext: false,
  },
  newRecord: false,
  recordUpdated: false,
};

const preInvitations = createSlice({
  name: "preInvitaions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPreInvitationsList.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getPreInvitationsList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.list = [...action.payload?.data.data];
      state.filterMeta.hasNext = action.payload?.data.hasNext;
      state.total = action.payload?.data.total;
    });
    builder.addCase(getPreInvitationsList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });

    builder.addCase(newPreInvitation.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.newRecord = false;
    });
    builder.addCase(newPreInvitation.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.newRecord = action.payload?.status ? true : false;
    });
    builder.addCase(newPreInvitation.rejected, (state, { payload }) => {
      state.status = "idle";
      state.newRecord = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
    // updatePreInvitation
    builder.addCase(updatePreInvitation.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.recordUpdated = false;
    });
    builder.addCase(updatePreInvitation.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.recordUpdated = action.payload?.status ? true : false;
    });
    builder.addCase(updatePreInvitation.rejected, (state, { payload }) => {
      state.status = "idle";
      state.recordUpdated = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
    // cancelPreInvitation
    builder.addCase(cancelPreInvitation.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.recordUpdated = false;
    });
    builder.addCase(cancelPreInvitation.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.recordUpdated = action.payload?.status ? true : false;
    });
    builder.addCase(cancelPreInvitation.rejected, (state, { payload }) => {
      state.status = "idle";
      state.recordUpdated = false;
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.preInvitaions;

export const preInvitationList = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const filterMetaSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterMeta
);
export const totalInvitations = createDraftSafeSelector(
  selfSelect,
  (state) => state.total
);
export const preInvitationError = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const newRecordFetch = createDraftSafeSelector(
  selfSelect,
  (state) => state.newRecord
);
export const recordUpdated = createDraftSafeSelector(
  selfSelect,
  (state) => state.recordUpdated
);
export const preInvLoader = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export default preInvitations.reducer;
