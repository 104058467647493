import axios from "axios";

export const getCurrentUser = () => {
  // return await axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/users/me`,
  // });
  return axios.get(`${process.env.REACT_APP_BASE_URL}/users/me`, {
    validateStatus: function (status) {
      return status >= 200 && status <= 404;
    },
  });
};
