import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./statusLable.scss";
import React from "react";

const StatusLabel = (props: any) => {
  return (
    <>
      <div
        className={`d-flex align-items-center status-indicator ${props.status}`}
      >
        <p className="mb-0">
          {`${props.status.charAt(0).toUpperCase()}${props.status.slice(1)}`}
        </p>
      </div>
    </>
  );
};

export default StatusLabel;
