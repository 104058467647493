import axios from "axios";

export const getAllreports = async (page_size: number, page_index: number) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/reports`,
    params: {
      page_size,
      page_index,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getAttendanceReport = async (
  from_date: string,
  to_date: string,
  email: boolean,
  dob: boolean,
  doj: boolean,
  gender: boolean,
  department: boolean,
  wfo_days: boolean,
  late_clockins: boolean,
  abnormal_temp: boolean,
  type: string,
  late_count: boolean,
  custom_id?: string
) => {
  const obj: any = {
    from_date,
    to_date,
    email,
    dob,
    doj,
    gender,
    department,
    wfo_days,
    late_clockins,
    abnormal_temp,
    type,
    late_count,
  };
  if (custom_id) {
    obj["custom_id"] = custom_id;
  }

  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/reports/attendance`,
    params: obj,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const genEmployeesReport = async (type: string, custom_id?: string) => {
  const reqObj: any = { type };
  if (custom_id) reqObj["custom_id"] = custom_id;

  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/reports/employees`,
    params: reqObj,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const genVisitorsReport = async (
  from_date: string,
  to_date: string,
  type: string,
  name?: string
) => {
  const reqObj: any = { from_date, to_date, type };
  if (name) reqObj["name"] = name;

  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/reports/visitors`,
    params: reqObj,
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};

export const genFeedbackReport = async (from_date: string, to_date: string) => {
  const reqObj: any = { from_date, to_date };

  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/reports/feedback`,
    params: reqObj,
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};
