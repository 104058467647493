import axios from "axios";

export const getLoggedinUser = async () => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/users/me`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
