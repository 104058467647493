import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { generatePin } from "@root/services/generate-pin";

const initialState = {
  hasUpdated: false,
  error: "",
  status: "idle",
  tablet_pin: "",
};

export const generateTabletPin = createAsyncThunk<any>(
  "@admin/generatePin",
  async (thunkAPI) => {
    try {
      const response: any = await generatePin();

      return {
        pin: response.data,
      };
    } catch (error) {
      return thunkAPI;
    }
  }
);

const generatePinSlice = createSlice({
  name: "tabpin",
  initialState,
  reducers: {
    resetPin: (state) => {
      state.tablet_pin = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateTabletPin.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.hasUpdated = false;
    });
    builder.addCase(generateTabletPin.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.hasUpdated = true;
      state.tablet_pin = action.payload.pin.tablet_pin;
    });
    builder.addCase(generateTabletPin.rejected, (state, action) => {
      state.status = "idle";
      state.hasUpdated = false;
      state.error = "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.tabpin;

export const hasPinGeneratedSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasUpdated
);
export const newPinSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.tablet_pin
);
export const { resetPin } = generatePinSlice.actions;

export default generatePinSlice.reducer;
