import { setTitle } from "@root/actions/metadata";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./dashboard.scss";
import React from "react";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Dashboard"));
  }, []);

  return (
    <>
      <div className="report-container h-100">
        <h2>Under development</h2>
      </div>
    </>
  );
};

export default Dashboard;
