import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";

const notificationSlice = createSlice({
  name: "toastify",
  initialState: {
    toastEnable: false,
    notificationType: "success",
    message: "",
  },
  reducers: {
    toastNotificationSuccess: (state) => {
      state.notificationType = "success";
      state.message = "Employee record has been successfully updated";
      state.toastEnable = !state.toastEnable;
    },
    toastNotificationFailure: (state) => {
      state.notificationType = "error";
      state.message = "Employee record has been successfully rejected";
      state.toastEnable = !state.toastEnable;
    },
    showNotificationError: (state, action) => {
      state.notificationType = action.payload.type;
      state.message = action.payload.message;
      state.toastEnable = true;
    },
    dismissToast: (state) => {
      state.toastEnable = false;
      state.message = "";
    },
  },
});

const selfSelect = (state: StoreDef) => state.toast;
export const toastType = createDraftSafeSelector(
  selfSelect,
  (state) => state.notificationType
);
export const toastMessage = createDraftSafeSelector(
  selfSelect,
  (state) => state.message
);
export const enableToast = createDraftSafeSelector(
  selfSelect,
  (state) => state.toastEnable
);

export const {
  toastNotificationSuccess,
  toastNotificationFailure,
  dismissToast,
  showNotificationError,
} = notificationSlice.actions;
export default notificationSlice.reducer;
