import { APIError } from "@root/types";
import axios from "axios";

export const getVisitors = async (
  from_date: string,
  to_date: string,
  page_size: number,
  pageIndex: number,
  search?: string,
  temperature?: string,
  purpose?: string,
  status?: string
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/walk-in`,
    params: {
      from_date,
      to_date,
      page_size,
      page_index: pageIndex,
      search,
      temperature,
      purpose,
      status,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getVisitorById = async (_id: string, _date: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/attendance-single/${_id}`,
    params: {
      date: _date,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

// export const getVisitorActivity = async (_id: string, page_index: number) => {
//   return await axios({
//     method: 'GET',
//     url: `${process.env.REACT_APP_BASE_URL}/visitors/activities-single/${_id}`,
//     params: {
//       page_index
//     },
//     validateStatus: (status) => {
//       return status >= 200 && status <= 404
//     }
//   })
// }

// /visitors/feedbacks-by-date/id

export const getVisitorFeedback = async (_id: string, date: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/feedbacks-by-date/${_id}`,
    params: {
      date,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getinvitedVisitors = async (
  from_date: string,
  to_date: string,
  page_size: number,
  pageIndex: number
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/invited`,
    params: {
      from_date,
      to_date,
      page_size,
      page_index: pageIndex,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getVisitorsFeedbackList = async (
  from_date: string,
  to_date: string,
  page_size: number,
  pageIndex: number,
  rate: string
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/feedback`,
    params: {
      from_date,
      to_date,
      page_size,
      page_index: pageIndex,
      rate: rate,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getSelectedVisitorList = async (invitation_id: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/visitors/invited/single`,
    params: {
      invitation_id,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const getVisitorQuestions = async () => {
  const response = await axios.get<Array<VisitorQuestion>>(
    `${process.env.REACT_APP_BASE_URL}/questions`,
    {
      validateStatus: (status) => {
        return status >= 200 && status <= 404;
      },
    }
  );
  return response.data;
};

export interface UpdateQuestionPayload {
  question_id: string;
  state: "activate" | "deactivate";
}

export const toggleVisitorQuestion = async ({
  question_id,
  state,
}: UpdateQuestionPayload) => {
  const response = await axios.put<any, VisitorQuestionUpdateResponse>(
    `${process.env.REACT_APP_BASE_URL}/questions/${state}/${question_id}`,
    {
      validateStatus: (status: number) => {
        return status >= 200 && status <= 404;
      },
    }
  );
  return response;
};

export const addCustomQuestion = async (text: string) => {
  const response = await axios.post<any, VisitorQuestionUpdateResponse>(
    `${process.env.REACT_APP_BASE_URL}/questions`,
    {
      text,
    },
    {
      validateStatus: (status: number) => {
        return status >= 200 && status <= 404;
      },
    }
  );
  return response;
};

export type UpdateCustomQuestionsStatusPayload = Array<{
  _id: string;
  active: boolean;
}>;
export const updateCustomQuestionsStatus = async (
  data: UpdateCustomQuestionsStatusPayload
) => {
  const response = await axios.put<any, VisitorQuestionUpdateResponse>(
    `${process.env.REACT_APP_BASE_URL}/questions/status-many`,
    data,
    {
      validateStatus: (status: number) => {
        return status >= 200 && status <= 404;
      },
    }
  );
  return response;
};

export const deleteCustomQuestion = async (question_id: string) => {
  return axios
    .delete<any>(
      `${process.env.REACT_APP_BASE_URL}/questions/${question_id}`,

      {
        validateStatus: (status) => {
          return status >= 200 && status < 300;
        },
      }
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error?.response ? error.response.data : null);
    });
};
