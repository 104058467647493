import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import {
  getNoticesList,
  updatenotice,
  createNotice,
} from "@root/services/notices";
import { showNotificationError } from "../toast-notification";

type notice = {
  status: "loading" | "idle";
  error: null | string;
  hasUpdated: boolean;
  list: Array<any>;
  notice: any;
};

const initialState: notice = {
  hasUpdated: false,
  error: "",
  status: "idle",
  list: [],
  notice: {},
};

export const getAllNotices = createAsyncThunk<
  any,
  {
    page_size: number;
    pageIndex: number;
  },
  { rejectValue: ResponseError }
>("@admin/getNotices", async ({ page_size, pageIndex }, thunkAPI) => {
  try {
    const response: any = await getNoticesList(page_size, pageIndex);
    return {
      list: response.data.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const updateNotice = createAsyncThunk<
  Dictionary,
  {
    _id: string;
    title: string;
    active: string;
    type: string;
    highlight: boolean;
    link: string;
    message: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/updateNotice",
  async ({ _id, title, active, type, highlight, link, message }, thunkAPI) => {
    try {
      const response: any = await updatenotice(
        _id,
        title,
        active,
        type,
        highlight,
        link,
        message
      );
      return {
        notice: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const createNewNotice = createAsyncThunk<
  Dictionary,
  {
    title: string;
    active: string;
    type: string;
    highlight: string;
    link: string;
    message: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/createNotice",
  async ({ title, active, type, highlight, link, message }, thunkAPI) => {
    try {
      const response: any = await createNotice(
        title,
        active,
        type,
        highlight,
        link,
        message
      );
      return {
        notice: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const selfSelect = (state: StoreDef) => state.notices;

export const noticeSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const totalRecordsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.list.length
);
export const hasUpdatedSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasUpdated
);
export const statusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

const noticesSlice = createSlice({
  name: "notices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNotices.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getAllNotices.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.list = [...action.payload?.list];
    });
    builder.addCase(getAllNotices.rejected, (state, action) => {
      state.status = "idle";
      state.error = "something is wrong";
    });

    builder.addCase(createNewNotice.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(createNewNotice.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.notice = [...action.payload?.notice];
    });
    builder.addCase(createNewNotice.rejected, (state, action) => {
      state.status = "idle";
      state.error = "something is wrong";
    });

    builder.addCase(updateNotice.pending, (state) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(updateNotice.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = "";
      state.notice = [...action.payload?.notice];
    });
    builder.addCase(updateNotice.rejected, (state, action) => {
      state.status = "idle";
      state.error = "something is wrong";
    });
  },
});

export default noticesSlice.reducer;
