import axios from "axios";

export const getSosOTPService = (phone: string) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL + "/accounts/request-sos-otp"}`,
    {
      phone,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

export const validateSosOtpService = async (otp: string) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/accounts/enable-sos`,
    params: {
      otp,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

// /accounts/disable-sos

// export const disableSOSService = () => {
//     return axios.post(`${process.env.REACT_APP_BASE_URL + "/accounts/disable-sos"}`,{}, {
//         validateStatus: (status) => status >= 200 && status <= 404
//     });
// }

export const disableSOSService = async () => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}/accounts/disable-sos`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
