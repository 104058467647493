import "./matrixLabel.scss";
import React from "react";

const MatrixLabel = (props: any) => {
  return (
    <>
      <div className="matrix d-flex justify-content-between align-items-center">
        <div className="matrix-info d-flex justify-content-between align-items-center pe-3">
          <div className={`icon ${props.iClass}`}>{props.children}</div>
          <p className="mb-0">{props.label}</p>
        </div>
        <div className="matrix-count">
          <p className="mb-0">{props.count}</p>
        </div>
      </div>
    </>
  );
};

export default MatrixLabel;
