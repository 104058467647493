import { currentUser } from "@root/actions/header";
import { disableSOS } from "@root/actions/sosAlert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./sosLockScreen.scss";
// resetSOS
import React from "react";

const SosLockScreen = () => {
  const dispatch = useDispatch();
  const curUser = useSelector(currentUser);

  useEffect(() => {
    console.log(curUser);
  }, [curUser]);

  return (
    <>
      <div className="sos_lock_screen">
        <div className="sos_lock_screen_bg">
          {/* <img src="../../../../assests/images/lockscreen.png" alt="icon" /> */}
        </div>
        <div className="sos_lock_screen_content d-flex flex-column justify-content-between">
          <div className="logo">
            <img
              src="../../../../assests/images/logo-big-white.png"
              alt="Brand logo"
              className="img-fluid"
            />
          </div>
          <div className="text-white fire-text">
            <p className="mb-0">SOS</p>
            <h2>Fire Alert</h2>
            <h3>
              Please use stairs <br />
              for emergency exit
            </h3>
          </div>
          <div>
            {curUser?.role !== "employee" ? (
              <button
                onClick={() => dispatch(disableSOS({}))}
                className="btn btn-sos-off"
              >
                Turn Off SOS/Fire Alert
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SosLockScreen;
