import "./reportPanel.scss";
import React, { useEffect } from "react";

import { panelStatusSelector, togglePanel } from "@root/actions/attendance";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { showNotificationError } from "@root/actions/toast-notification";
import {
  errorSelector,
  fetchedReport,
  getReportlist,
  reportGenStatus,
  reportStatusSelector,
} from "@root/actions/reportAttendance";
import { appendReport } from "@root/actions/allReports";
import { fetchSpinnerSelector } from "@root/actions/reportAttendance";
import Buttonspinner from "../buttonspinner";
import { useUpdateEffect } from "react-use";
import {
  genVisitorsReportList,
  visitorFetchedReport,
  visitorErrorSelector,
  visitorReportGenStatus,
  visitorReportStatus,
} from "@root/actions/reportVisitors";
import {
  genEmployeesReportList,
  employeeFetchedReport,
  employeeErrorSelector,
  employeeReportGenStatus,
  employeeReportStatus,
} from "@root/actions/reportEmployees";
import {
  feedbackFetchedReport,
  feedbackReportGenStatus,
  feedbackReportStatus,
  genFeedbackReportList,
} from "@root/actions/reportFeedbacks";
import Spinner from "../Spinner/Spinner";
import { currentUser } from "@root/actions/header";

const ReportPanel = () => {
  const statusSelector = useSelector(fetchSpinnerSelector);
  const loggedInUser = useSelector(currentUser);
  const dispatch = useDispatch();
  const isOpen = useSelector(panelStatusSelector);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [activeTab, setActiveTab] = useState<
    "attendance" | "visitor" | "employees" | "feedback"
  >("attendance");
  const isError = useSelector(errorSelector);
  const isRepoGenerated = useSelector(reportGenStatus);
  const isVisitorError = useSelector(visitorErrorSelector);
  const isVisitorRepGenerated = useSelector(visitorReportGenStatus);
  const isEmpError = useSelector(employeeErrorSelector);
  const isEmployeeRepGenerated = useSelector(employeeReportGenStatus);
  const isFeedbackRepGenerated = useSelector(feedbackReportGenStatus);
  const report = useSelector(fetchedReport);
  const visitorReport = useSelector(visitorFetchedReport);
  const employeeReport = useSelector(employeeFetchedReport);
  const feedbackReport = useSelector(feedbackFetchedReport);
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

  const isAtLoading = useSelector(reportStatusSelector);
  const isVisLoading = useSelector(visitorReportStatus);
  const isEmpLoading = useSelector(employeeReportStatus);
  const isFedLoading = useSelector(feedbackReportStatus);

  const toggleTabs = (
    tab: "attendance" | "visitor" | "employees" | "feedback"
  ) => {
    setActiveTab(tab);
    setFromDate(null);
    setToDate(null);
    setInvalidInputs([]);
  };

  const [options, setOptions] = useState({
    email: true,
    dob: true,
    doj: true,
    gender: true,
    department: true,
    wfo_days: true,
    late_clockins: true,
    abnormal_temp: true,
    type: "all",
    custom_id: "",
    late_count: true,
  });

  const [empOptions, setEmpOptions] = useState({
    type: "all",
    custom_id: "",
  });

  const [visitorOption, setVisitorOption] = useState({
    type: "all",
    name: "",
  });

  useUpdateEffect(() => {
    dispatch(appendReport(report));
  }, [report]);

  useUpdateEffect(() => {
    dispatch(appendReport(visitorReport));
  }, [visitorReport]);

  useUpdateEffect(() => {
    dispatch(appendReport(employeeReport));
  }, [employeeReport]);

  useUpdateEffect(() => {
    dispatch(appendReport(feedbackReport));
  }, [feedbackReport]);

  const empOptionChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = {
      ...options,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    };
    setOptions(newArry);
  };

  const empOptionChange2 = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = {
      ...empOptions,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    };
    setEmpOptions(newArry);
  };

  const visitorsOptionChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    const newArry = {
      ...visitorOption,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    };
    setVisitorOption(newArry);
  };

  const generateEmpReport = () => {
    const inval: string[] = [];
    if (
      !fromDate ||
      !toDate ||
      (options.type === "unique" && !options.custom_id)
    ) {
      if (!fromDate) inval.push("From Date");
      if (!toDate) inval.push("To Date");
      if (options.type === "unique" && !options.custom_id) {
        inval.push("Employee ID");
      }
      setInvalidInputs(inval);
      dispatch(
        showNotificationError({
          type: "error",
          message: `${inval.join(", ")} required.`,
        })
      );
    } else {
      const frmdate = `${fromDate.getFullYear()}-${
        fromDate.getMonth() + 1
      }-${fromDate.getDate()}`;
      const todate = `${toDate.getFullYear()}-${
        toDate.getMonth() + 1
      }-${toDate.getDate()}`;
      const finalAarray = { from_date: frmdate, to_date: todate, ...options };

      dispatch(getReportlist(finalAarray));
    }
  };

  const generateVisitorReport = () => {
    const inval: string[] = [];
    if (
      !fromDate ||
      !toDate ||
      (visitorOption.type === "unique" && !visitorOption.name)
    ) {
      if (!fromDate) inval.push("From Date");
      if (!toDate) inval.push("To Date");
      if (visitorOption.type === "unique" && !visitorOption.name) {
        inval.push("Visitor ID");
      }
      setInvalidInputs(inval);
      dispatch(
        showNotificationError({
          type: "error",
          message: `${inval.join(", ")} required.`,
        })
      );
    } else {
      const frmdate = `${fromDate.getFullYear()}-${
        fromDate.getMonth() + 1
      }-${fromDate.getDate()}`;
      const todate = `${toDate.getFullYear()}-${
        toDate.getMonth() + 1
      }-${toDate.getDate()}`;
      const finalAarray = {
        from_date: frmdate,
        to_date: todate,
        ...visitorOption,
      };
      dispatch(genVisitorsReportList(finalAarray));
    }
  };

  const generateOnlyEmpReport = () => {
    const inval: string[] = [];
    if (empOptions.type === "unique" && !empOptions.custom_id) {
      inval.push("Employee ID");
      setInvalidInputs(inval);
      dispatch(
        showNotificationError({
          type: "error",
          message: "Employee Id is required.",
        })
      );
    } else {
      const finalAarray = { ...empOptions };
      dispatch(genEmployeesReportList(finalAarray));
    }
  };

  const generateFeedbackReport = () => {
    const inval: string[] = [];
    if (!fromDate || !toDate) {
      if (!fromDate) inval.push("From Date");
      if (!toDate) inval.push("To Date");
      setInvalidInputs(inval);
      dispatch(
        showNotificationError({
          type: "error",
          message: `${inval.join(", ")} required.`,
        })
      );
    } else {
      const frmdate = `${fromDate.getFullYear()}-${
        fromDate.getMonth() + 1
      }-${fromDate.getDate()}`;
      const todate = `${toDate.getFullYear()}-${
        toDate.getMonth() + 1
      }-${toDate.getDate()}`;
      const finalAarray = { from_date: frmdate, to_date: todate };
      dispatch(genFeedbackReportList(finalAarray));
    }
  };

  const onReportGenerate = () => {
    switch (activeTab) {
      case "attendance":
        generateEmpReport();
        break;

      case "visitor":
        generateVisitorReport();
        break;

      case "employees":
        generateOnlyEmpReport();
        break;

      case "feedback":
        generateFeedbackReport();
        break;

      default:
        break;
    }
  };

  useUpdateEffect(() => {
    if (isError) {
      console.log("Error: ", isError);
      dispatch(showNotificationError({ type: "error", message: isError }));
      dispatch(togglePanel());
    }
    if (isVisitorError) {
      dispatch(
        showNotificationError({ type: "error", message: isVisitorError })
      );
      dispatch(togglePanel());
    }
    if (isEmpError) {
      dispatch(showNotificationError({ type: "error", message: isEmpError }));
      dispatch(togglePanel());
    }

    if (
      isRepoGenerated ||
      isVisitorRepGenerated ||
      isEmployeeRepGenerated ||
      isFeedbackRepGenerated
    ) {
      dispatch(
        showNotificationError({
          type: "success",
          message: "Report generated and sent to your email.",
        })
      );
      dispatch(togglePanel());
    }
  }, [
    isError,
    isRepoGenerated,
    isVisitorError,
    isVisitorRepGenerated,
    isEmpError,
    isEmployeeRepGenerated,
    isFeedbackRepGenerated,
  ]);

  useEffect(() => {
    if (loggedInUser.account.subscription === "visitors_only") {
      toggleTabs("visitor");
    }
  }, [loggedInUser.account.subscription]);

  return (
    <>
      <div className="genRep-container">
        <div className="d-flex  flex-column h-100 pb-4">
          {/* title haeder */}
          <div className="d-flex justify-content-between align-items-center title-header py-2">
            <div>
              <p className="font-500 title mb-0">Generate New Report</p>
            </div>
          </div>

          {/* tabs */}
          <div className="pt-3">
            <div className="link-tabs d-flex">
              {loggedInUser.account.subscription === "visitors_only" ? null : (
                <button
                  onClick={() => toggleTabs("attendance")}
                  className={`link-tab-btn ${
                    activeTab === "attendance" ? "active" : ""
                  }`}
                >
                  {" "}
                  Employee Attendance{" "}
                </button>
              )}
              <button
                onClick={() => toggleTabs("visitor")}
                className={`link-tab-btn ${
                  activeTab === "visitor" ? "active" : ""
                }`}
              >
                {" "}
                Visitors{" "}
              </button>
              {loggedInUser.account.subscription === "visitors_only" ? null : (
                <button
                  onClick={() => toggleTabs("employees")}
                  className={`link-tab-btn ${
                    activeTab === "employees" ? "active" : ""
                  }`}
                >
                  {" "}
                  Employees{" "}
                </button>
              )}
              <button
                onClick={() => toggleTabs("feedback")}
                className={`link-tab-btn ${
                  activeTab === "feedback" ? "active" : ""
                }`}
              >
                {" "}
                Feedback{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="rp-body-contaner">
          {/* attendance form  */}
          {activeTab === "attendance" && (
            <div className="report-form">
              {/* date range  */}
              <div className="row pb-4">
                <div className="col-sm-12">
                  <p>Date Range</p>
                </div>
                <div className="col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="From"
                      value={fromDate}
                      className="datepicker"
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            invalidInputs.includes("From Date") ? true : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="To"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      minDate={fromDate}
                      maxDate={new Date()}
                      className="datepicker"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            invalidInputs.includes("To Date") ? true : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              {/* Basic fields  */}
              <FormGroup>
                <div className="row m-0 pb-4">
                  <div className="col-sm-12">
                    <p className="mb-1">Basic Fields</p>
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="Employee ID"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="Employee Name"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="Designation"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.department}
                          onChange={empOptionChange}
                          color="default"
                        />
                      }
                      label="Department"
                      name="department"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.email}
                          color="default"
                          onChange={empOptionChange}
                          name="email"
                        />
                      }
                      label="Email"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="Phone Number"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.gender}
                          onChange={empOptionChange}
                          name="gender"
                          color="default"
                        />
                      }
                      label="Gender"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.doj}
                          color="default"
                          onChange={empOptionChange}
                          name="doj"
                        />
                      }
                      label="Date Of Joining"
                    />
                  </div>
                </div>
              </FormGroup>

              {/* Time sheet fields  */}

              <FormGroup>
                <div className="row m-0 pb-4">
                  <div className="col-sm-12">
                    <p className="mb-1">Timesheet Fields</p>
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="# Days Worked"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="# Hours Worked"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="# Days WFH"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.wfo_days}
                          color="default"
                          onChange={empOptionChange}
                          name="wfo_days"
                        />
                      }
                      label="# Days WFO"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="# Days On-Time"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.late_clockins}
                          color="default"
                          onChange={empOptionChange}
                          name="late_clockins"
                        />
                      }
                      label="# Days Late Clockedin"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={true} color="default" />}
                      label="# Temperature Normal"
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.abnormal_temp}
                          color="default"
                          onChange={empOptionChange}
                          name="abnormal_temp"
                        />
                      }
                      label="# Temperature Not Normal"
                    />
                  </div>
                </div>
              </FormGroup>

              <RadioGroup
                aria-label="type"
                name="type"
                value={options.type}
                onChange={empOptionChange}
              >
                <div className="row m-0 pb-0">
                  <div className="col-sm-12">
                    <p className="mb-1">Employees</p>
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="all"
                      control={<Radio color="default" />}
                      label="All Employees"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="active"
                      control={<Radio color="default" />}
                      label="Active Employees Only"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="unique"
                      control={<Radio color="default" />}
                      label="Specific Employee Only"
                    />
                  </div>
                </div>
              </RadioGroup>
              {options.type === "unique" && (
                <div className="form-group mb-2">
                  <input
                    type="text"
                    onChange={empOptionChange}
                    name="custom_id"
                    className={`form-control ${
                      invalidInputs.includes("Employee ID") ? "error" : ""
                    }`}
                    placeholder="Enter Employee ID"
                  />
                </div>
              )}
            </div>
          )}

          {/* visitors form */}

          {activeTab === "visitor" && (
            <div className="report-form">
              <div className="row pb-4">
                <div className="col-sm-12">
                  <p>Date Range</p>
                </div>
                <div className="col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="From"
                      value={fromDate}
                      className="datepicker"
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            invalidInputs.includes("From Date") ? true : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="To"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      minDate={fromDate}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            invalidInputs.includes("To Date") ? true : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <RadioGroup
                aria-label="type"
                name="type"
                value={visitorOption.type}
                onChange={visitorsOptionChange}
              >
                <div className="row m-0 pb-0">
                  <div className="col-sm-12">
                    <p className="mb-1">Visitors</p>
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="all"
                      control={<Radio color="default" />}
                      label="All Visitors"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="invited"
                      control={<Radio color="default" />}
                      label="Pre-Invitation Visitors"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="unique"
                      control={<Radio color="default" />}
                      label="Specific Visitor Only"
                    />
                  </div>
                </div>
              </RadioGroup>
              {visitorOption.type === "unique" && (
                <div className="form-group mb-2">
                  <input
                    type="text"
                    onChange={visitorsOptionChange}
                    name="name"
                    className={`form-control ${
                      invalidInputs.includes("Visitor ID") ? "error" : ""
                    }`}
                    placeholder="Enter Visitor ID"
                  />
                </div>
              )}
            </div>
          )}

          {/* employees form  */}
          {activeTab === "employees" && (
            <div className="report-form">
              <RadioGroup
                aria-label="type"
                name="type"
                value={empOptions.type}
                onChange={empOptionChange2}
              >
                <div className="row m-0 pb-0">
                  <div className="col-sm-12">
                    <p className="mb-1">Employees</p>
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="all"
                      control={<Radio color="default" />}
                      label="All Employees"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="active"
                      control={<Radio color="default" />}
                      label="Active Employees Only"
                    />
                  </div>
                  <div className="col-sm-6">
                    <FormControlLabel
                      value="unique"
                      control={<Radio color="default" />}
                      label="Specific Employee Only"
                    />
                  </div>
                </div>
              </RadioGroup>
              {empOptions.type === "unique" && (
                <div className="form-group mb-2">
                  <input
                    type="text"
                    onChange={empOptionChange2}
                    name="custom_id"
                    className={`form-control ${
                      invalidInputs.includes("Employee ID") ? "error" : ""
                    }`}
                    placeholder="Enter Employee ID"
                  />
                </div>
              )}
            </div>
          )}

          {activeTab === "feedback" && (
            <div className="report-form">
              <div className="row pb-4">
                <div className="col-sm-12">
                  <p>Date Range</p>
                </div>
                <div className="col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="From"
                      value={fromDate}
                      className="datepicker"
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            invalidInputs.includes("From Date") ? true : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="To"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      minDate={fromDate}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            invalidInputs.includes("To Date") ? true : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          )}
        </div>

        {isOpen && (
          <div className="button-container d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={() => dispatch(togglePanel())}
              className={"btn btn-outline-dark me-2"}
            >
              {" "}
              Cancel{" "}
            </button>

            <button
              type="button"
              onClick={() => onReportGenerate()}
              className={"btn btn-gradient mr-3"}
            >
              {" "}
              {statusSelector === "loading" ? (
                <Buttonspinner />
              ) : (
                "Generate Report"
              )}
            </button>
          </div>
        )}

        {(isAtLoading === "loading" ||
          isVisLoading === "loading" ||
          isEmpLoading === "loading" ||
          isFedLoading === "loading") && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default ReportPanel;
