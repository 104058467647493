import axios from "axios";

export const demoteEmployee = async (id: string) => {
  return await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL}/users/demote/${id}`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};

export const promoteEmployee = async (id: string) => {
  return await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL}/users/promote/${id}`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
