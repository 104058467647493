import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getLoggedinUser } from "@root/services/header";

const today = new Date();
const todayFormat = `${today?.getFullYear()}-${
  today?.getMonth()! + 1
}-${today?.getDate()}`;

type meUserType = {
  role: string;
  status: string;
  joined: string;
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  dob: string;
  gender: string;
  image: string;
  custom_id: string;
  department: string;
  description: string;
  account: {
    _id: string;
    company_name: string;
    code: string;
    brand_logo: string;
    saas_plan: string;
    subscription: string;
  };
};

interface headerState {
  error: null | string;
  status: "idle" | "loading";
  data: meUserType;
  startDate: string;
  startDateString: string;
  resetDate: string;
  openSendAppLink: boolean;
}

const initialData = {
  role: "",
  status: "",
  joined: "",
  _id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  dob: "",
  gender: "",
  image: "",
  custom_id: "",
  department: "",
  description: "",
  account: {
    _id: "",
    company_name: "",
    code: "",
    brand_logo: "",
    saas_plan: "",
    subscription: "",
  },
};

const initialState: headerState = {
  error: null,
  status: "idle",
  data: initialData,
  startDate: today.toString(),
  startDateString: todayFormat,
  resetDate: today.toString(),
  openSendAppLink: false,
};

export const getCurrentUser = createAsyncThunk<Dictionary>(
  "@common/user_Me",
  async (thunkAPI) => {
    try {
      const response: any = await getLoggedinUser();

      const errMsg: any = response.data;

      return response.status === 200
        ? {
            data: response.data,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error) {
      return thunkAPI;
    }
  }
);

const GetLogoSlice = createSlice({
  name: "getlogo",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      const dd: Date = new Date(action.payload);
      state.startDate = dd.toString();
      state.startDateString = `${dd?.getFullYear()}-${
        dd?.getMonth()! + 1
      }-${dd?.getDate()}`;
    },
    resetDate: (state, action) => {
      state.resetDate = action.payload;
    },
    toggleOpenAppLink: (state, action) => {
      state.openSendAppLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = initialData;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.data = payload.data;
      state.error = payload?.error?.error!;
    });
    builder.addCase(getCurrentUser.rejected, (state, { payload }) => {
      state.status = "idle";
      //@ts-ignore
      state.error = "something is wrong" || payload!.response.data.error;
    });
  },
});

const selfSelect = (state: StoreDef) => state.getLogo;

export const currentUser = createDraftSafeSelector(
  selfSelect,
  (state) => state.data
);
export const resetDateSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.resetDate
);
export const startDateSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.startDate
);
export const startDateStringSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.startDateString
);
export const openSendLinkSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.openSendAppLink
);

export const { resetDate, setStartDate, toggleOpenAppLink } =
  GetLogoSlice.actions;

export default GetLogoSlice.reducer;
