import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { showNotificationError } from "@root/actions/toast-notification";
import { canFeedback, sendFeedback } from "@root/services/feedback";

export const checkCanFeedback = createAsyncThunk<
  Dictionary,
  { id: string },
  { rejectValue: ResponseError }
>("@visitor/checkcanfeedback", async ({ id }, thunkAPI) => {
  try {
    const response = await canFeedback(id);
    const errMsg: any = response.data;

    if (response.status === 200) {
      return {
        data: response.data,
        status: true,
      };
    } else {
      // store.dispatch(showNotificationError({ type: "error", message: errMsg.error }));
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const submitFeedback = createAsyncThunk<
  Dictionary,
  { id: string; text: string; rate: string },
  { rejectValue: ResponseError }
>("@visitor/sendfeedback", async ({ id, text, rate }, thunkAPI) => {
  try {
    const response = await sendFeedback(id, text, rate);
    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(
        showNotificationError({
          type: "success",
          message: "Thank you for your feedback.",
        })
      );
      console.log(response);
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const feedBack = createSlice({
  name: "feedback",
  initialState: {
    status: "idle",
    error: "",
    allowFeedback: false,
    visitorName: "",
    submitted: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkCanFeedback.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.allowFeedback = false;
    });
    builder.addCase(checkCanFeedback.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.visitorName = action.payload?.data?.data?.visitor_name || "";
      state.allowFeedback = action.payload?.status ? true : false;
    });
    builder.addCase(checkCanFeedback.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
      state.allowFeedback = false;
    });

    builder.addCase(submitFeedback.pending, (state) => {
      state.status = "loading";
      state.error = "";
      state.submitted = false;
    });
    builder.addCase(submitFeedback.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.submitted = action.payload?.status ? true : false;
    });
    builder.addCase(submitFeedback.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
      state.submitted = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.feedback;

export const canVisitorFeedback = createDraftSafeSelector(
  selfSelect,
  (state) => state.allowFeedback
);
export const visitorName = createDraftSafeSelector(
  selfSelect,
  (state) => state.visitorName
);
export const isSubmitted = createDraftSafeSelector(
  selfSelect,
  (state) => state.submitted
);
export const feedLoader = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const feedbackError = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);

export default feedBack.reducer;
