import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getVisitorById, getVisitorFeedback } from "@root/services/visitors";

type visitorActivityType = {
  clocked_out_at: string;
  _id: string;
  purpose: string;
  clocked_in_at: string;
  temperature: {
    value: number;
    measure: string;
  };
  invited_by?: {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    description: string;
  };
};

const initialActivity: visitorActivityType[] = [];

type visFeedbackType = {
  _id: string;
  feedback: {
    rate: string;
    text: string;
  };
};

const initialFeedback: visFeedbackType[] = [];

const initialUser: visitorDataType = {
  visitor: {
    _id: "",
    name: "",
    phone: "",
    image: "",
    purpose: "",
    account_id: "",
    __v: 0,
  },
  checkin: {
    clocked_out_at: "",
    _id: "",
    visitor_id: "",
    account_id: "",
    purpose: "",
    clocked_in_at: "",
    temperature: {
      value: 10,
      measure: "",
    },
    invitation_id: "",
    __v: 0,
  },
};
const initialState = {
  error: "",
  visitorData: initialUser,
  status: "idle",
  visitorActivity: initialActivity,
  feedback: initialFeedback,
  hasNextActivity: false,
};

export const getVisitorFeedbackById = createAsyncThunk<
  Dictionary,
  { _id: string; date: string },
  { rejectValue: ResponseError }
>("@admin/getVisito_Feedback", async ({ _id, date }, thunkAPI) => {
  try {
    const response: any = await getVisitorFeedback(_id, date);
    const errMsg: any = response.data;
    return response.status === 200
      ? {
          visitorFeedback: response.data,
          hasNext: response.data.hasNext,
          status: true,
        }
      : { status: false, error: errMsg };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

export const getVisitorByID = createAsyncThunk<
  Dictionary,
  { _id: string; _date: string },
  { rejectValue: ResponseError }
>("@admin/fetch_visitor", async ({ _id, _date }, thunkAPI) => {
  const pageSize = 25;
  try {
    const response: any = await getVisitorById(_id, _date);
    const errMsg: any = response.data;

    return response.status === 200
      ? {
          visitorData: response.data,
          status: true,
        }
      : { status: false, error: errMsg };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});
const getVisitorSlice = createSlice({
  name: "getVisitor",
  initialState: initialState,
  reducers: {
    resetVisitorInfo: (state) => {
      state.visitorData = initialUser;
      state.status = "idle";
      state.visitorActivity = initialActivity;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVisitorByID.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getVisitorByID.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.visitorData = action.payload?.visitorData;
      state.visitorActivity = action.payload?.visitorData?.activities;

      // state.feedback = action.payload?.visitorData?.checkin?.feedback;
    });
    builder.addCase(getVisitorByID.rejected, (state, action) => {
      state.status = "idle";
      state.error =
        action.payload?.response?.data?.error || "something is wrong";
    });
    // getVisitorActivityById
    builder.addCase(getVisitorFeedbackById.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      // state.hasNextActivity = false;
    });
    builder.addCase(getVisitorFeedbackById.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.feedback = action.payload?.visitorFeedback;
    });
    builder.addCase(getVisitorFeedbackById.rejected, (state, action) => {
      state.status = "idle";
      state.error =
        action.payload?.response?.data?.error || "something is wrong";
      // state.hasNextActivity = false;
    });
  },
});
const selfSelect = (state: StoreDef) => state.getVisitor;

export const visitorDataSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.visitorData
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const visitorActivitySelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.visitorActivity
);
export const hasNextActivitySelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasNextActivity
);
export const feedbackSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.feedback
);

export const { resetVisitorInfo } = getVisitorSlice.actions;

export default getVisitorSlice.reducer;
