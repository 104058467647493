import TaskAltIcon from "@mui/icons-material/TaskAlt";
import React from "react";

import "./thankYou.scss";

const ThankYou = () => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center thankU-page">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex justify-content-center">
            {/* <TaskAltIcon /> */}
            <img
              style={{ maxWidth: "300px" }}
              src="../../../../assests/images/Tick-Main_08_t.gif"
              alt="icon"
            />
          </div>
          <h2 className="thankU text-center">Thank You!</h2>
          <p className="text-center">We have received your feedback.</p>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
