import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HomeIcon from "@mui/icons-material/Home";
import DomainIcon from "@mui/icons-material/Domain";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import "./showactivity.scss";
import { employeeDataSelector } from "@root/actions/getEmployee";
import { useSelector } from "react-redux";
import { attendanceListSelector } from "@root/actions/attendance";
import React from "react";

const ShowActivity = (props: any) => {
  // const employeeData = useSelector(employeeDataSelector);
  // const temperatureData = useSelector(attendanceListSelector)
  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };
  const pColr = props.activeId === props.Activity._id ? "Pink" : "Black";
  const activeType =
    props.activeId === props.Activity._id ? props.activeType : 0;
  // const pColr = props.mClass === 'active' ? 'Pink' : 'Black';

  const onSelectAct = (_type: number, loc: any) => {
    if (!props.noEvent) {
      props.onSelectActivity(_type, loc);
    }
  };

  return (
    <div className={`activity ${props.isLast ? " last" : ""}`}>
      {props.Activity.clocked_out_at !== null ? (
        <div className="clock-out">
          <div className="clockout-header d-flex">
            <div
              className="actionIcon"
              onClick={() =>
                onSelectAct(2, props.Activity.geo_location.clockout)
              }
            >
              {/* 'ClockinPin' : 'ClockoutPin'; */}
              <img
                src={`../../../../assests/images/ClockoutPin_${
                  activeType === 2 ? "Pink" : "Black"
                }.svg`}
                alt="icon"
              />
            </div>
            <p className="mt-3">ClockedOut</p>
          </div>

          <div className="activity-icon clockout-border">
            <div className="active-icon">
              <WatchLaterIcon fontSize="small" />
              &nbsp;
              <p className="mb-0">
                {getTimeFormat(props.Activity.clocked_out_at)}
              </p>
            </div>
            <div className="active-icon">
              <ThermostatIcon /> &nbsp;
              <p className="mb-0">N/A</p>
            </div>
            <div className="active-icon">
              {/* {props.Activity.mode?.clockout === "WFH" ?
           <> <HomeIcon /> &nbsp;<p className="mb-0"> At Home</p> </>: <> <DomainIcon/> &nbsp;<p className="mb-0"> At Office</p></> } */}
              {props.Activity.mode?.clockout === "WFH" && (
                <>
                  {" "}
                  <HomeIcon />
                  &nbsp; <p className="mb-0">At Home</p>{" "}
                </>
              )}

              {props.Activity.mode?.clockout === "WFO" && (
                <>
                  {" "}
                  <DomainIcon />
                  &nbsp; <p className="mb-0">At Office</p>
                </>
              )}
              {props.Activity.mode?.clockout === "VIBA" && (
                <>
                  {" "}
                  <img src="../../../../assests/images/logo-small.png" alt="" />
                  &nbsp; <p className="mb-0">By Viba</p>{" "}
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {props.Activity.clocked_in_at !== null ? (
        <div className="clock-in">
          <div className="clockin-header">
            <div
              className="actionIcon"
              onClick={() =>
                onSelectAct(1, props.Activity.geo_location.clockin)
              }
            >
              <img
                src={`../../../../assests/images/ClockinPin_${
                  activeType === 1 ? "Pink" : "Black"
                }.svg`}
                alt="icon"
              />
            </div>
            <p className="mt-3 ml-4">ClockedIn</p>
          </div>
          <div className="activity-icon d-flex clockin-border">
            <div className="active-icon">
              <WatchLaterIcon fontSize="small" />
              &nbsp;
              <p className="mb-0">
                {getTimeFormat(props.Activity.clocked_in_at)}
              </p>
            </div>
            <div className="active-icon">
              <ThermostatIcon />
              &nbsp;
              <p className="mb-0">
                {props.Activity.temperature?.value}
                {"\u00b0"} {props.Activity.temperature?.measure}
              </p>
            </div>
            <div className="active-icon">
              {props.Activity.mode?.clockin === "WFH" ? (
                <>
                  {" "}
                  <HomeIcon />
                  &nbsp; <p className="mb-0">At Home</p>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <DomainIcon />
                  &nbsp; <p className="mb-0">At Office</p>
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ShowActivity;
