import "./employeeCard.scss";
import React from "react";

const EmployeeCard = (props: any) => {
  const employee = props.employee;

  return (
    <div className="col employee-card">
      <div className="mb-3">
        <div className="employee-card-header">
          <div className="highlighter"></div>
          <div
            style={{ position: "relative" }}
            className="d-flex justify-content-center"
          >
            <div className="profile-picture">
              <img
                src={
                  employee?.image !== undefined
                    ? employee?.image
                    : "../../../../assests/images/user.png"
                }
                alt="profile"
              />
            </div>
            <div className={`employee-card-status ${employee.status}`}>
              <p className="mb-0">
                {employee.status === "approved"
                  ? "Active"
                  : employee.status === "rejected"
                  ? "Inactive"
                  : employee.status === "draft"
                  ? "Draft"
                  : "New"}
              </p>
            </div>
          </div>
          <div className="employee-card-content p-3">
            <div className="py-3">
              <h6 className="text-center mb-0">{`${employee.first_name} ${employee.last_name}`}</h6>
              <p className="text-center">{employee.description}</p>
            </div>
            <div>
              <div className="fb-row d-flex">
                <div className="first-child">
                  <p className="fb-title mb-2">Email</p>
                  <p className="">{employee.email}</p>
                </div>
              </div>
              <div className="fb-row d-flex">
                <div className="first-child">
                  <p className="fb-title mb-2">Phone</p>
                  <p className="">{employee.phone}</p>
                </div>
              </div>
            </div>
            {employee.status !== "draft" && (
              <div className="d-flex justify-content-center">
                <button className="grid-action" onClick={props.action}>
                  {employee.status === "in-review" ? "Take Action" : "View"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
