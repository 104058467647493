import PrivateLayout from "@root/layouts/PrivateLayout/PrivateLayout";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import EmployeeList from "@root/pages/private/employeeList/index";
import Attendance from "@root/pages/private/attendance/index";
import AbnormalTempEmp from "@root/pages/private/temperatureUsers/index";
import Visitors from "../visitor";
import Profile from "../profile/index";
import Setting from "../settings";
import Report from "../reports";
import Dashboard from "../dashboard";
import Pusher from "pusher-js";

import { useEffect } from "react";
import { useUnmount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { accTerminated } from "@root/actions/auth";
// import { empListSelector } from "@root/actions/employee-list";
import MyAttendance from "../myAttendance";
import PreInvitations from "../preInvitations";
import { lockSOSScreen, resetSOS } from "@root/actions/sosAlert";

import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Integrations from "../settings/Integrations";
import Holidays from "../settings/Holidays";
import { currentUser } from "@root/actions/header";

const Manage = () => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const acc_id = localStorage.getItem("account_id");
  const usr_id = JSON.stringify(localStorage.getItem("userId"));
  const userToken = localStorage.getItem("token");
  const userRole = localStorage.getItem("Role");
  const pushr = JSON.parse(localStorage.getItem("pusher")!);
  // const employeeList = useSelector(empListSelector);
  let pusher: Pusher;
  // const chanelId = userRole === 'admin' ? `acc-${acc_id}` : `usr-${usr_id}`;
  const loggedInUser = useSelector(currentUser);

  const accChannelId = `acc-${acc_id}`;
  const usrChannelId = `usr-${usr_id}`;
  // const [newStatus, setNewStatus] = useState({from_status: "",to_status: "", user_id: ""});

  useEffect(() => {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: "a7f7fb99-e843-4142-a047-7d8af9bff138",
    });

    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: "https://dev-backend.viba.ai:3000/api/v1/auth/beams-token",
      headers: {
        "x-auth-token": `${userToken}`,
      },
    });

    beamsClient
      .start()
      .then(() => {
        beamsClient.setUserId(usr_id, beamsTokenProvider);
      })

      .catch(console.error);

    return () => {
      beamsClient.stop().catch(console.error);
    };
  }, [userToken, usr_id]);

  const connectBeams = (id: string) => {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: "a7f7fb99-e843-4142-a047-7d8af9bff138",
    });

    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: "https://dev-backend.viba.ai:3000/api/v1/auth/beams-token",
      headers: {
        "x-auth-token": `${userToken}`,
      },
    });

    beamsClient
      .start()
      .then(() => {
        beamsClient.setUserId(id, beamsTokenProvider);
      })

      .catch(console.error);
  };

  useEffect(() => {
    if (pushr) {
      pusher = new Pusher(pushr.api_key, {
        cluster: pushr.cluster,
      });

      // user specific channel
      const channel = pusher.subscribe(usrChannelId);
      channel.bind("user-demoted", (data: any) => {
        const contact = {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
        };

        dispatch(
          accTerminated({
            status: true,
            msg: "Looks like your account has been terminated by admin. Please contact the account administrator.",
            contact: contact,
          })
        );
        console.log(data);
      });

      channel.bind("user-rejected", (data: any) => {
        const contact = {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
        };
        dispatch(
          accTerminated({
            status: true,
            msg: "Looks like your account has been terminated by admin. Please contact the account administrator.",
            contact: contact,
          })
        );
        // setNewStatus(data);
      });

      // account specific channel
      const accChannel = pusher.subscribe(accChannelId);
      accChannel.bind("account-deactivated", (data: any) => {
        // const contact = {email: data.email, first_name: data.first_name, last_name: data.last_name};
        dispatch(
          accTerminated({
            status: true,
            msg: "Looks like your organisation account has been terminated. Please contact Viba Team.",
          })
        );
      });

      accChannel.bind("sos-enabled", (data: any) => {
        dispatch(lockSOSScreen());
      });

      accChannel.bind("sos-disabled", (data: any) => {
        dispatch(resetSOS());
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
        accChannel.unbind_all();
        accChannel.unsubscribe();
        if (pusher) pusher.disconnect();
      };
    }
  }, []);

  useUnmount(() => {
    if (pusher) pusher.unsubscribe(usrChannelId);
    if (pusher) pusher.unsubscribe(accChannelId);
  });

  // useEffect(() => {
  //   getUpdatedList(newStatus.user_id, newStatus.to_status);
  // }, [newStatus])

  // const getUpdatedList = (_id: string, status: string) => {
  //   const newList = employeeList?.map((el:any) => {
  //     if(el._id === _id){
  //       return {...el, status }
  //     } else {
  //       return el
  //     }
  //   });
  //   console.log(newList);
  // }

  const vmsOnly = localStorage.getItem("vms_only");

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect
            to={`${path}${vmsOnly === "true" ? "visitors" : "my-attendance"}`}
          ></Redirect>
        </Route>
        <Route
          path={`${path}employees`}
          render={() => (
            <PrivateLayout>
              <EmployeeList />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}attendance`}
          render={() => (
            <PrivateLayout>
              <Attendance />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}my-attendance`}
          render={() => (
            <PrivateLayout>
              <MyAttendance />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}pre-invitations`}
          render={() => (
            <PrivateLayout>
              <PreInvitations />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}visitors`}
          render={() => (
            <PrivateLayout>
              <Visitors />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}profile`}
          render={() => (
            <PrivateLayout>
              <Profile />
            </PrivateLayout>
          )}
        />
        {["admin", "moderator"].includes(userRole || "") ? (
          <Route
            path={`${path}settings`}
            render={() => (
              <PrivateLayout>
                <Setting />
              </PrivateLayout>
            )}
          />
        ) : null}
        <Route
          path={`${path}reports`}
          render={() => (
            <PrivateLayout>
              <Report />
            </PrivateLayout>
          )}
        />

        <Route
          path={`${path}dashboard`}
          render={() => (
            <PrivateLayout>
              <Dashboard />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}abnormalTemperatureEmployee`}
          render={() => (
            <PrivateLayout>
              <AbnormalTempEmp />
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}holidays`}
          render={() => (
            <PrivateLayout>
              <div className="h-full bg-white p-3">
                <Holidays />
              </div>
            </PrivateLayout>
          )}
        />
        <Route
          path={`${path}integrations`}
          render={() => (
            <PrivateLayout>
              <div className="h-full bg-white">
                <Integrations />
              </div>
            </PrivateLayout>
          )}
        />
      </Switch>
    </>
  );
};

export default Manage;
