import "./index.scss";
import React from "react";

const VisitorNameWithProfile = (props: any) => {
  return (
    <>
      <div className="employee-profile">
        <div className="profile-picture">
          <img
            src={
              props.data?.visitor?.image !== undefined
                ? props.data.visitor.image
                : "../../../../assests/images/user.png"
            }
            alt="profile"
          />
        </div>
        <div className="">
          <p className="mb-1"> {props.data?.visitor?.name} </p>
          {props.data?.visitor?.phone ? (
            <p className="mb-0">
              <small>{`+${props.data?.visitor?.phone?.slice(
                0,
                2
              )} ${props.data?.visitor?.phone?.slice(
                2,
                7
              )} ${props.data?.visitor?.phone?.slice(7)}`}</small>
            </p>
          ) : (
            <p className="mb-0">
              <small>--</small>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default VisitorNameWithProfile;
