import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import DatePicker from "@mui/lab/DatePicker";
import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
// import Box from "@mui/material/Box";
import { useUnmount, useUpdateEffect } from "react-use";
import { useEffect, useState } from "react";
import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
import TimePicker from "@mui/lab/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelPreInvitation,
  newPreInvitation,
  updatePreInvitation,
  newRecordFetch,
  recordUpdated,
  preInvLoader,
} from "@root/actions/preInvitations";
import { panelStatusSelector } from "@root/actions/attendance";
import { showNotificationError } from "@root/actions/toast-notification";
import DeleteIcon from "@mui/icons-material/Delete";
import "./newInvitation.scss";
import Spinner from "../Spinner/Spinner";

const VibaTextField = styled(MuiTextField)({
  //#FF0062
  "& label.Mui-focused": {
    color: "#FF0062",
  },
  "& label.Mui-error": {
    color: "#FF0000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FF0062",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.32)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.8)",
    },
    "&.Mui-focused fieldset": {
      borderColor: `#FF0062`,
    },
    "&.Mui-error fieldset": {
      borderColor: `#FF0000`,
      borderWidth: "2px",
    },
  },
});

const NewInvitation = (props: any) => {
  const labels = {
    title: "Title",
    name: "Name",
    email: "Email",
    phone: "Phone",
    purpose: "Purpose of Visit",
    start: "Start Time",
    end: "End Time",
    date: "Date",
  };
  const dispatch = useDispatch();
  const isOpen = useSelector(panelStatusSelector);
  const selectedVisitor = props.selectedVis;
  const invitation = {
    title: "",
    name: "",
    email: "",
    phone: "",
    purpose: "",
    _date: "",
  };

  const [preInvitation, setPreInvitation] = useState(invitation);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isoStartTime, setIsoStartTime] = useState("");
  const [isoEndTime, setIsoEndTime] = useState("");
  const [dateTimeStr, setdateTimeStr] = useState<Date | null>();
  const [fieldDisable, setFieldDisable] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
  const isNewRecord = useSelector(newRecordFetch);
  const isRecordUpdated = useSelector(recordUpdated);
  const isLoading = useSelector(preInvLoader);

  const handleChange = (event: any) => {
    let target = event.target as HTMLInputElement;

    if (target.name === "name" || target.name === "title") {
      target.value = target.value.replace(/[^A-Za-z ]/gi, ""); //Added new regex [a-zA-Z0-9_ ] // old regex [^A-Za-z]
    }

    const newInvitation = { ...preInvitation, [target.name]: target.value };
    setPreInvitation(newInvitation);
    // console.log(target.name, target.value)
  };

  const handleDateChange = (event: any) => {
    const _date = new Date(event);
    // console.log(`${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`);
    const newInvitation = {
      ...preInvitation,
      _date: `${_date.getFullYear()}-${
        _date.getMonth() + 1
      }-${_date.getDate()}`,
    };
    setPreInvitation(newInvitation);
    setdateTimeStr(_date);
  };

  const resetForm = () => {
    setPreInvitation({
      title: "",
      name: "",
      email: "",
      phone: "",
      purpose: "",
      _date: "",
    });
    setStartTime("");
    setEndTime("");
    setIsoStartTime("");
    setIsoEndTime("");
  };
  useUpdateEffect(() => {
    if (!isOpen) resetForm();
  }, [isOpen]);

  useEffect(() => {
    if (props.isView) {
      setPreInvitation({
        ...preInvitation,
        title: selectedVisitor?.title ? selectedVisitor?.title : "",
        name: selectedVisitor?.name,
        email: selectedVisitor?.email,
        phone: selectedVisitor?.phone,
        purpose: selectedVisitor?.purpose,
        _date: selectedVisitor?.start,
      });
      const start = new Date(selectedVisitor?.start);
      const end = new Date(selectedVisitor?.end);

      const utcStart = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getUTCHours(),
        start.getUTCMinutes(),
        0
      );
      const utcEnd = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        end.getUTCHours(),
        end.getUTCMinutes(),
        0
      );
      setStartTime(utcStart.toString());
      setEndTime(utcEnd.toString());
      setIsoStartTime(selectedVisitor?.start);
      setIsoEndTime(selectedVisitor?.end);
    }
    if (props.isNew) {
      setFieldDisable(false);
    } else {
      setFieldDisable(true);
    }
    // console.log(props.selectedVis);
    // setSelectedVisitor()
  }, [selectedVisitor]);

  const handleTimeChange = (val: string | null, tpe: string) => {
    if (val) {
      const _date = new Date(preInvitation._date);
      const _time = new Date(val);
      let newInvitation;
      if (tpe === "start") {
        // newInvitation = {...preInvitation, start: _time.setTime(val)};

        // _date.setTime(_time.setDate())
        let myToday = new Date(
          _date.getFullYear(),
          _date.getMonth(),
          _date.getDate(),
          _time.getHours(),
          _time.getMinutes(),
          0
        );
        let isoDate = new Date(
          _date.getFullYear(),
          _date.getMonth(),
          _date.getDate(),
          _time.getHours() + 5,
          _time.getMinutes() + 30,
          0
        );
        setStartTime(myToday.toISOString());
        setIsoStartTime(isoDate.toISOString());
        // const dd = new Date(myToday.toISOString());
        // console.log(isoDate.toISOString());
        // console.log(myToday.toString());
      } else {
        // newInvitation = {...preInvitation, end: `${_time.getHours()}:${_time.getMinutes()}`};
        let myToday = new Date(
          _date.getFullYear(),
          _date.getMonth(),
          _date.getDate(),
          _time.getHours(),
          _time.getMinutes(),
          0
        );
        let isoDate = new Date(
          _date.getFullYear(),
          _date.getMonth(),
          _date.getDate(),
          _time.getHours() + 5,
          _time.getMinutes() + 30,
          0
        );
        setEndTime(myToday.toISOString());
        setIsoEndTime(isoDate.toISOString());
        // setEndTime(val);
      }

      // setPreInvitation(newInvitation);
    }
  };

  useUpdateEffect(() => {
    if (isNewRecord || isRecordUpdated) props.onCancel();
  }, [isNewRecord, isRecordUpdated]);

  useUnmount(() => {
    // console.log('Unmounted');
    resetForm();
  });

  const handleSubmit = () => {
    if (props.isView) {
      if (fieldDisable) {
        props.onCancel();
      } else {
        updateInvitation();
      }
    } else {
      createInvitation();
    }
  };

  const createInvitation = () => {
    // official, interview, meeting, delivery
    // newPreInvitation
    const obj = {
      title: preInvitation.title,
      name: preInvitation.name,
      email: preInvitation.email,
      phone: `${preInvitation.phone ? "91" + preInvitation.phone : ""}`,
      purpose: preInvitation.purpose,
      start: isoStartTime,
      end: isoEndTime,
    };
    // console.log(obj);
    const inval: string[] = [];
    for (const [key, value] of Object.entries(obj)) {
      //@ts-ignore
      if (!value.trim()) inval.push(labels[key]);
    }
    if (!dateTimeStr) inval.push("Date");
    let message: string = "";
    let isTimeSame: boolean =
      isoStartTime !== "" && isoEndTime !== "" && isoStartTime === isoEndTime;
    if (inval.length > 0) {
      message = `All fields are mandatory. Please check ${inval.join(
        ", "
      )}. \r\n`;
    }
    if (isTimeSame) {
      message = message + "End time should be greater than start time.";
      inval.push("End Time");
    }
    if (inval.length > 0 || isTimeSame) {
      dispatch(showNotificationError({ type: "error", message }));
      setInvalidInputs(inval);
    } else {
      dispatch(newPreInvitation(obj));
      // props.onCancel();
    }
  };

  const updateInvitation = () => {
    const obj = {
      _id: selectedVisitor?._id,
      name: `${preInvitation.name}`,
      purpose: preInvitation.purpose,
      start: isoStartTime,
      end: isoEndTime,
    };
    // console.log(obj);
    dispatch(updatePreInvitation(obj));
  };

  const cancelInvitation = () => {
    const obj = {
      _id: selectedVisitor?._id,
      name: `${selectedVisitor?.name}`,
      purpose: selectedVisitor?.purpose,
      start: selectedVisitor?.start,
      end: selectedVisitor?.end,
    };
    // console.log(obj);
    dispatch(cancelPreInvitation(obj));
    setShowConfirm(false);
    // props.onCancel();
  };

  const handleCancelClick = () => {
    if (fieldDisable) {
      setFieldDisable(false);
    } else {
      resetForm();
      props.onCancel();
    }
  };

  return (
    <>
      <div className="d-flex flex-column h-100 position-relative newInvitation">
        <div className="top-wrapper">
          <div className="py-2 d-flex justify-content-between align-items-center title-header">
            <div>
              <p className="mb-0 font-500 title">
                {`${props.isNew ? "Create New" : "View"}`} Invitation
              </p>
            </div>
            {console.log(
              "Value of edit pass data :::::",
              props.selectedVis.clockin
            )}
            {props.isView &&
            fieldDisable &&
            props.selectedVis.clockin == undefined ? (
              <button
                type="button"
                onClick={handleCancelClick}
                className={"btn btn-outline-dark edit-button"}
              >
                <EditIcon />
                &nbsp;Edit
              </button>
            ) : null}
          </div>
        </div>

        <div className="h-full empl-form flex-grow-1">
          <form noValidate autoComplete="OFF">
            <p className="mb-2 text-right">
              {props.selectedVis.clockin == undefined ? (
                <p>
                  {" "}
                  <span className="text-danger">* </span>All fields are
                  required.{" "}
                </p>
              ) : (
                ""
              )}
            </p>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Title"
                  value={preInvitation.title}
                  onChange={handleChange}
                  name="title"
                  variant="outlined"
                  className="input-feild"
                  color="secondary"
                  disabled={fieldDisable ? true : false}
                  fullWidth
                  error={invalidInputs.includes("Title") ? true : false}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Full Name"
                  value={preInvitation.name}
                  // onChange={(e) => handleEmployeeDataChange(e, "first_name")}
                  onChange={handleChange}
                  name="name"
                  variant="outlined"
                  className="input-feild"
                  type="text"
                  color="secondary"
                  disabled={fieldDisable ? true : false}
                  fullWidth
                  error={invalidInputs.includes("Name") ? true : false}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Email"
                  value={preInvitation.email}
                  // onChange={(e) => handleEmployeeDataChange(e, "email")}
                  name="email"
                  onChange={handleChange}
                  variant="outlined"
                  className="email"
                  type="email"
                  disabled={props.isView || fieldDisable ? true : false}
                  fullWidth
                  color="secondary"
                  error={invalidInputs.includes("Email") ? true : false}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Phone"
                  value={preInvitation.phone}
                  // onChange={(e) => handleEmployeeDataChange(e, "email")}
                  name="phone"
                  onChange={handleChange}
                  variant="outlined"
                  className="phone"
                  type="tel"
                  disabled={props.isView || fieldDisable ? true : false}
                  fullWidth
                  color="secondary"
                  error={invalidInputs.includes("Phone") ? true : false}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Appointment Date"
                    value={
                      preInvitation._date ? new Date(preInvitation._date) : null
                    }
                    onChange={handleDateChange}
                    inputFormat="dd/MM/yyyy"
                    className="datepicker"
                    disabled={fieldDisable ? true : false}
                    minDate={new Date()}
                    onError={(reason, value) => {}}
                    renderInput={(params) => (
                      <VibaTextField
                        {...params}
                        error={invalidInputs.includes("Date") ? true : false}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-6 mt-3">
                <FormControl fullWidth>
                  <InputLabel id="purpose-select-label">
                    Purpose of visit
                  </InputLabel>
                  <Select
                    labelId="purpose-select-label"
                    id="demo-simple-select"
                    value={preInvitation.purpose}
                    label="Purpose of visit"
                    variant="outlined"
                    name="purpose"
                    disabled={fieldDisable ? true : false}
                    onChange={handleChange}
                    error={
                      invalidInputs.includes("Purpose of Visit") ? true : false
                    }
                  >
                    <MenuItem value={"meeting"}>Meeting</MenuItem>
                    <MenuItem value={"interview"}>Interview</MenuItem>
                    <MenuItem value={"delivery"}>Delivery</MenuItem>
                    <MenuItem value={"official"}>Official</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-6 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Start Time"
                    value={
                      preInvitation._date
                        ? startTime
                          ? startTime
                          : null
                        : null
                    }
                    onChange={(newValue) => {
                      if (newValue) {
                        handleTimeChange(newValue.toString(), "start");
                      }
                    }}
                    minTime={preInvitation._date ? dateTimeStr : null}
                    disabled={
                      preInvitation._date || fieldDisable ? false : true
                    }
                    renderInput={(params) => (
                      <VibaTextField
                        {...params}
                        error={
                          invalidInputs.includes("Start Time") ? true : false
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-6 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    label="End Time"
                    value={
                      preInvitation._date ? (endTime ? endTime : null) : null
                    }
                    onChange={(newValue) => {
                      if (newValue) {
                        handleTimeChange(newValue.toString(), "end");
                      }
                    }}
                    minTime={preInvitation._date ? dateTimeStr : null}
                    disabled={
                      preInvitation._date || fieldDisable ? false : true
                    }
                    renderInput={(params) => (
                      <VibaTextField
                        {...params}
                        error={
                          invalidInputs.includes("End Time") ? true : false
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </form>
        </div>

        <div className="button-container d-flex align-items-center justify-content-between">
          {props.isView && !fieldDisable && (
            <button
              type="button"
              onClick={() => setShowConfirm(true)}
              className={"btn text-danger d-flex align-items-center ml-3"}
            >
              <DeleteIcon />
              Cancel Invitation
            </button>
          )}

          <div className="flex-grow-1 d-flex justify-content-end">
            {props.isView && !fieldDisable ? (
              <button
                type="button"
                onClick={handleCancelClick}
                className={"btn btn-outline-dark me-2"}
              >
                Cancel
              </button>
            ) : null}

            <button
              type="button"
              onClick={handleSubmit}
              className={"btn btn-gradient"}
            >
              {props.isView ? (fieldDisable ? "Okay" : "Update") : "Submit"}
            </button>
          </div>
        </div>
        {showConfirm && (
          <div className="confirm-box d-flex justify-content-center align-items-center">
            <div className="confirm-wrapper shadow">
              <div className="confirm-header">
                <p>Are sure you want to cancel invitation?</p>
              </div>
              <div className="confirm-actions pt-4">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    onClick={() => setShowConfirm(false)}
                    className={"btn btn-outline-dark edit-button"}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={cancelInvitation}
                    className={"btn btn-gradient"}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading === "loading" && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default NewInvitation;
