import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TextareaAutosize } from "@material-ui/core";
import "./feedback.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  canVisitorFeedback,
  visitorName,
  submitFeedback,
  checkCanFeedback,
  isSubmitted,
  feedLoader,
  feedbackError,
} from "@root/actions/feedback";
import Page4O4 from "@root/components/page4O4";
import Spinner from "@root/components/Spinner/Spinner";
import { useUpdateEffect } from "react-use";
import { showNotificationError } from "@root/actions/toast-notification";
import ThankYou from "@root/components/ThankYou";

const Feedback = () => {
  const slug: { id: string } = useParams();
  const [rate, setRate] = useState<
    "worst" | "bad" | "okay" | "good" | "excellent" | null
  >(null);
  const [text, setText] = useState("");
  const allowVisitorFeedback = useSelector(canVisitorFeedback);
  const visitor_name = useSelector(visitorName);
  const isFeedbackSubmitted = useSelector(isSubmitted);
  const isfeedbackError = useSelector(feedbackError);

  const dispatch = useDispatch();
  const [canFeedback, setCanFeedback] = useState(false);
  const [show4O4, setshow4O4] = useState(false);
  const loading = useSelector(feedLoader);

  const onTextChange = (e: React.SyntheticEvent) => {
    let target = e.target as HTMLInputElement;
    setText(target.value);
  };

  useEffect(() => {
    if (slug.id && !isFeedbackSubmitted && !isfeedbackError) {
      dispatch(checkCanFeedback({ id: slug.id }));
    }
  }, [slug]);

  useUpdateEffect(() => {
    if (allowVisitorFeedback || !isfeedbackError) {
      setCanFeedback(true);
      setshow4O4(false);
    } else {
      setshow4O4(true);
      setCanFeedback(false);
    }
  }, [allowVisitorFeedback, isfeedbackError]);

  useUpdateEffect(() => {
    setCanFeedback(false);
    setshow4O4(false);
  }, [isFeedbackSubmitted]);

  const onFeedBack = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (!rate) {
      return void dispatch(
        showNotificationError({
          type: "error",
          message: "Please select one of the rating.",
        })
      );
    }

    if (!text) {
      return void dispatch(
        showNotificationError({
          type: "error",
          message: "Feedback is required.",
        })
      );
    }

    const obj = { id: slug.id, text: text, rate: rate };
    dispatch(submitFeedback(obj));
  };

  if (loading === "loading" && !isFeedbackSubmitted) {
    return <Spinner />;
  }

  return (
    <>
      {canFeedback && (
        <div className="ifeed-main">
          <img
            className="ifeed-logo"
            src="../../../../assests/images/ideeo.png"
            alt="logo"
          />
          {visitor_name && <p className="ifeed-para">Hello {visitor_name},</p>}
          <p className="ifeed-para">
            Please tell us your overall experience on today's visit?
          </p>

          <section>
            <ul className="feedback">
              <li
                onClick={() => setRate("worst")}
                className={`angry ${rate === "worst" ? "active" : ""}`}
              >
                <div>
                  <svg className="eye left">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="eye right">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="mouth">
                    <image
                      width="667"
                      height="667"
                      x="673"
                      xlinkHref="#mouth"
                    />
                    <use xlinkHref="#mouth" />
                  </svg>
                </div>
                <span>Worst</span>
              </li>
              <li
                onClick={() => setRate("bad")}
                className={`sad ${rate === "bad" ? "active" : ""}`}
              >
                <div>
                  <svg className="eye left">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="eye right">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="mouth">
                    <use xlinkHref="#mouth" />
                  </svg>
                </div>
                <span>Bad</span>
              </li>
              <li
                onClick={() => setRate("okay")}
                className={`ok ${rate === "okay" ? "active" : ""}`}
              >
                <div></div>
                <span>Okay</span>
              </li>
              <li
                onClick={() => setRate("good")}
                className={`good ${rate === "good" ? "active" : ""}`}
              >
                <div>
                  <svg className="eye left">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="eye right">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="mouth">
                    <use xlinkHref="#mouth" />
                  </svg>
                </div>
                <span>Good</span>
              </li>
              <li
                onClick={() => setRate("excellent")}
                className={`happy ${rate === "excellent" ? "active" : ""}`}
              >
                <div>
                  <svg className="eye left">
                    <use xlinkHref="#eye" />
                  </svg>
                  <svg className="eye right">
                    <use xlinkHref="#eye" />
                  </svg>
                </div>
                <span>Excellent</span>
              </li>
            </ul>

            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
              <symbol
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 7 4"
                id="eye"
              >
                <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
              </symbol>
              <symbol
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 7"
                id="mouth"
              >
                <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
              </symbol>
            </svg>
          </section>

          <label className="ifeed-label" htmlFor="textarea">
            Comments:
          </label>
          <TextareaAutosize
            aria-label="feedback text"
            minRows={7}
            className="ifeed-textarea w-100"
            placeholder="Start Typing..."
            value={text}
            onChange={onTextChange}
          />
          <button onClick={onFeedBack} className="ifeed-btn" type="button">
            Submit My Feedback
          </button>
        </div>
      )}

      {show4O4 && !isFeedbackSubmitted && <Page4O4 />}

      {isFeedbackSubmitted && (
        <div className="spinner-box">
          <ThankYou />
        </div>
      )}
    </>
  );
};

export default Feedback;
