import NiceModal, { useModal } from "@ebay/nice-modal-react";

type ToastConfirmationProps = {
  handleYes: () => void;
  message: string;
};

export default NiceModal.create((props: ToastConfirmationProps) => {
  const modal = useModal();
  return (
    <div className={`vb-modal right-16 top-16 ${modal.visible ? "open" : ""}`}>
      <div className="vb-modal-dialog">
        <div className="vb-modal-content">
          <div className="vb-modal-header">
            <p className="vb-modal-title mb-0" id="staticBackdropLabel">
              {props.message}
            </p>
          </div>

          <div className="vb-modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark revoke-btns"
              onClick={modal.hide}
            >
              No
            </button>
            <button
              type="submit"
              className="btn btn-gradient revoke-btns"
              onClick={() => {
                props.handleYes();
                modal.hide();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
