import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField as MuiTextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { panelStatusSelector, togglePanel } from "@root/actions/attendance";
import { employeeRoleSelector } from "@root/actions/auth";
import { getAllHolidays, holidaysList } from "@root/actions/holidays";
import { setTitle } from "@root/actions/metadata";
import ENotice from "@root/components/ENotice";
import Holiday from "@root/components/Holiday";
import NewHoliday from "@root/components/NewHoliday";
import SettingsTablePanel from "@root/components/SettingsTablePanel";
import SidePanel from "@root/components/SidePanel/SidePanel";
import SidePanelDrawer from "@root/components/SidePanel/SidePanelDrawer";
import Spinner from "@root/components/Spinner/Spinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useUpdateEffect } from "react-use";

const VibaTextField = styled(MuiTextField)({
  //#FF0062
  "& label.Mui-focused": {
    color: "#FF0062",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FF0062",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.32)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.8)",
    },
    "&.Mui-focused fieldset": {
      borderColor: `#FF0062`,
    },
  },
});

function Holidays() {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(
      setTitle(location.pathname.includes("settings") ? "Settings" : "Holidays")
    );
  }, [dispatch, location.pathname]);

  const dateFiletr = (date: any) => {
    const _date = new Date(date);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return {
      date: _date.getDate(),
      day: days[_date.getDay()],
      month: months[_date.getMonth()],
    };
  };

  const [colHolidaysDefs, setColHolidaysDefs] = useState([
    {
      field: "date",
      headerName: "DAY, MONTH",
      width: 150,
      cellRendererFramework: (params: any) => {
        return (
          <p className="mb-0">
            {params.data && params.data?.date
              ? `${dateFiletr(params.data.date).day}, ${
                  dateFiletr(params.data.date).month
                } ${dateFiletr(params.data.date).date}`
              : "--"}
          </p>
        );
      },
    },
    {
      field: "title",
      headerName: "HOLIDAY NAME",
      width: 150,
      cellRendererFramework: (params: any) => {
        return (
          <p className="mb-0">
            {params.data && params.data?.title ? params.data.title : "--"}
          </p>
        );
      },
    },
    {
      field: "type",
      headerName: "HOLIDAY TYPE",
      width: 150,
      cellRendererFramework: (params: any) => {
        return (
          <p className="mb-0">
            {params.data && params.data?.type === "FES"
              ? "Festival"
              : "Celebrations"}
          </p>
        );
      },
    },
    {
      field: "holiday",
      headerName: "COMPANY HOLIDAY",
      width: 150,
      cellRendererFramework: (params: any) => {
        return (
          <p className="mb-0">{params.data?.company_holiday ? "Yes" : "No"}</p>
        );
      },
    },
  ]);
  const holidayList = useSelector(holidaysList);
  const [dateHoliday, setDateHoliday] = useState<Date | null>(new Date());
  const [holidayType, setHolidayType] = useState("all");
  const [companyHoliday, setCompanyHoliday] = useState(true);
  const [newHoliday, setNewHoliday] = useState(false);
  const roleSelector = useSelector(employeeRoleSelector);

  const [rowHolidays, setRowHolidays] = useState<any>();

  const holidayTypeChange = (event: any) => {
    let target = event.target as HTMLInputElement;
    setHolidayType(target.value as string);
  };

  useUpdateEffect(() => {
    setRowHolidays(holidayList);
    setColHolidaysDefs(colHolidaysDefs);
  }, [holidayList]);

  const openNewHoliday = () => {
    setNewHoliday(true);
  };

  const closeNewHoliday = () => {
    setTimeout(() => {
      setNewHoliday(false);
    });
  };

  useEffect(() => {
    const fetchHolidays = () => {
      if (dateHoliday) {
        const obj = {
          year: dateHoliday.getFullYear().toString(),
          status: holidayType,
          company_holiday: companyHoliday,
          page_size: 25,
          page_index: 0,
        };
        const cc = Object.values(obj).filter((el) => el === "NaN");
        if (cc.length === 0) dispatch(getAllHolidays(obj));
      }
    };
    fetchHolidays();
  }, [companyHoliday, dateHoliday, dispatch, holidayType, newHoliday]);

  return (
    <>
      <div className="col-lg-12">
        <div className="holiday-container">
          <div className="d-flex justify-content-between  align-items-end pt-3 ">
            <div className="d-flex">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  label="Year"
                  value={dateHoliday}
                  // onChange={handleDateChange}
                  onChange={(newValue) => {
                    setDateHoliday(newValue);
                  }}
                  // inputFormat="dd/MM/yyyy"
                  className="datepicker-month mt-4"
                  // disabled={fieldDisable ? true : false}
                  // minDate={new Date()}
                  renderInput={(params) => <VibaTextField {...params} />}
                />
              </LocalizationProvider>
              <div className="pl-3">
                <FormControl fullWidth>
                  <InputLabel id="holiday-type-label">Holiday Type</InputLabel>
                  <Select
                    labelId="holiday-type-label"
                    id="viba-simple-select"
                    value={holidayType}
                    label="Holiday Type"
                    variant="outlined"
                    name="purpose"
                    // disabled={fieldDisable ? true : false}
                    onChange={holidayTypeChange}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"upcoming"}>Upcoming</MenuItem>
                    <MenuItem value={"completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="d-flex align-items-center pl-3">
                &nbsp;
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        size="small"
                        checked={companyHoliday}
                        onChange={() => setCompanyHoliday(!companyHoliday)}
                      />
                    }
                    label={"Company Holidays"}
                    labelPlacement="end"
                  />
                </FormGroup>
                {/* <Switch
          checked={companyHoliday}
          onChange={() => setCompanyHoliday(!companyHoliday)}
          inputProps={{ 'aria-label': 'controlled' }}
        /> */}
              </div>
            </div>
            <div>
              {/* Show new  */}
              {roleSelector !== "employee" &&
              !location.pathname.endsWith("/holidays") ? (
                <button
                  className="btn btn-gradient"
                  onClick={openNewHoliday}
                  style={{ minWidth: "120px" }}
                >
                  + New
                </button>
              ) : null}
            </div>
          </div>

          <div className="holiday-list-container row justify-content-start pt-4">
            {holidayList?.map((holiday) => (
              <Holiday holiday={holiday} />
            ))}
          </div>
          {!rowHolidays ? (
            <div className="loader-container">
              <Spinner />
            </div>
          ) : null}
        </div>
      </div>
      <SidePanelDrawer isOpen={newHoliday} handleCloseClick={closeNewHoliday}>
        <NewHoliday onCancel={closeNewHoliday} />
      </SidePanelDrawer>
    </>
  );
}

export default Holidays;
