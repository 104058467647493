import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import DatePicker from "@mui/lab/DatePicker";
import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import "./newHoliday.scss";
import { useState } from "react";
import { useUnmount, useUpdateEffect } from "react-use";
import { showNotificationError } from "@root/actions/toast-notification";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewHoliday,
  isNewHoliday,
  newHolidayLoader,
} from "@root/actions/holidays";
import Spinner from "../Spinner/Spinner";

const VibaTextField = styled(MuiTextField)({
  //#FF0062
  "& label.Mui-focused": {
    color: "#FF0062",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FF0062",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.32)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.8)",
    },
    "&.Mui-focused fieldset": {
      borderColor: `#FF0062`,
    },
  },
});

const NewHoliday = (props: any) => {
  const dispatch = useDispatch();
  const [holiday, setHoliday] = useState({
    title: "",
    date: "",
    type: "FES",
    company_holiday: true,
  });
  const isNewHolidayAdded = useSelector(isNewHoliday);
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
  const isLoading = useSelector(newHolidayLoader);

  const handleChange = (event: any) => {
    let target = event.target as HTMLInputElement;
    let newArray;
    if (target.type === "radio") {
      newArray = {
        ...holiday,
        company_holiday: target.value === "1" ? true : false,
      };
    } else {
      newArray = { ...holiday, [target.name]: target.value as string };
    }
    setHoliday(newArray);
  };

  useUpdateEffect(() => {
    if (isNewHolidayAdded) {
      props.onCancel();
    }
  }, [isNewHolidayAdded]);

  useUnmount(() => {
    // alert('Hi');
    props.onCancel();
  });

  const handleDateChange = (event: any) => {
    const _date = new Date(event);
    // console.log(`${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`);
    setHoliday({ ...holiday, date: _date.toISOString() });
  };

  const onSubmitHoliday = () => {
    const inval: string[] = [];
    for (const [key, value] of Object.entries(holiday)) {
      //@ts-ignore
      if (value === "") inval.push(key.toUpperCase());
    }
    if (inval.length > 0) {
      dispatch(
        showNotificationError({
          type: "error",
          message: `All fields are mandatory. Please check ${inval.join(", ")}`,
        })
      );
      setInvalidInputs(inval);
    } else {
      dispatch(createNewHoliday(holiday));
    }
  };

  return (
    <>
      <div className="d-flex flex-column h-100 position-relative">
        <div className="top-wrappers">
          {/* title haeder */}
          <div className="d-flex justify-content-between align-items-center title-header py-2">
            <p className="font-500 title mb-0">Add New Holiday</p>
          </div>
        </div>
        <div className="new-holiday flex-grow-1 h-full">
          <form noValidate autoComplete="OFF">
            <p className="mb-2 text-right">
              <span className="text-danger">*</span>All fields are required.
            </p>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <TextField
                  label="Holiday Title"
                  value={holiday.title}
                  // onChange={(e) => handleEmployeeDataChange(e, "first_name")}
                  onChange={handleChange}
                  name="title"
                  variant="outlined"
                  className="input-feild"
                  color="secondary"
                  fullWidth
                  error={invalidInputs.includes("TITLE") ? true : false}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={holiday.date ? new Date(holiday.date) : null}
                    onChange={handleDateChange}
                    inputFormat="dd/MM/yyyy"
                    className="w-100"
                    minDate={new Date()}
                    renderInput={(params) => (
                      <VibaTextField
                        {...params}
                        error={invalidInputs.includes("DATE") ? true : false}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 mt-3">
                <FormControl fullWidth>
                  <InputLabel id="type-select-label">Holiday Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="demo-simple-select"
                    value={holiday.type}
                    label="Holiday Type"
                    variant="outlined"
                    name="type"
                    onChange={handleChange}
                  >
                    <MenuItem value={"FES"}>Festival</MenuItem>
                    <MenuItem value={"CEL"}>Celebrations</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <RadioGroup
              aria-label="type"
              name="company_holiday"
              value={holiday.company_holiday ? "1" : "0"}
              onChange={handleChange}
            >
              <div className="row m-0 pb-0">
                <div className="col-sm-6">
                  <FormControlLabel
                    value="1"
                    control={<Radio color="secondary" />}
                    label="Company Holiday"
                  />
                </div>
                <div className="col-sm-6">
                  <FormControlLabel
                    value="0"
                    control={<Radio color="secondary" />}
                    label="Not Company Holiday"
                  />
                </div>
              </div>
            </RadioGroup>
          </form>
        </div>
        <div className="button-container d-flex align-items-center justify-content-end">
          <button
            type="button"
            onClick={props.onCancel}
            className={"btn btn-outline-dark me-2"}
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={onSubmitHoliday}
            className={"btn btn-gradient"}
          >
            Submit
          </button>
        </div>

        {isLoading === "loading" && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default NewHoliday;
