import axios from "axios";

export const createInvitation = (
  title: string,
  name: string,
  email: string,
  phone: string,
  purpose: string,
  start: string,
  end: string
) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/invitations`,
    {
      title,
      name,
      email,
      phone,
      purpose,
      start,
      end,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

export const updateInvitation = (
  _id: string,
  name: string,
  purpose: string,
  start: string,
  end: string
) => {
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}/invitations/${_id}`,
    {
      name,
      purpose,
      start,
      end,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

// {{base_url}}/invitations/cancel/6197c6de57965b2e940c6ae2
export const cancelInvitation = (
  _id: string,
  name: string,
  purpose: string,
  start: string,
  end: string
) => {
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}/invitations/cancel/${_id}`,
    {
      name,
      purpose,
      start,
      end,
    },
    {
      validateStatus: (status) => status >= 200 && status <= 404,
    }
  );
};

export const getPreInvitations = async (
  date: string,
  status: string,
  page_size: number,
  page_index: number
) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/invitations/get-by-date`,
    params: {
      date,
      status,
      page_size,
      page_index,
    },
    validateStatus: (status) => status >= 200 && status <= 404,
  });
};
