import { InjectedFormProps, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, {
  FormEventHandler,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import TextField from "@mui/material/TextField";
import {
  errorSelector,
  fetchSpinnerSelector,
  hasOtpGenerated,
  hasOTPSuccessSelector,
  invalidOtpSubmission,
  isAdminAuthorised,
  makeLoginRequest,
  makeOTPRequest,
  otpRegisteredPhone,
  tokenSelector,
} from "@root/actions/auth";
import OTPInput from "@root/components/OtpInput";
import { useUpdateEffect } from "react-use";
import "./auth.scss";
import setAuthorizationToken from "@root/utils/setAuthToken";
import InputAdornment from "@mui/material/InputAdornment";
// import { statusSelector } from '../../../actions/settings/index';
import { showNotificationError } from "@root/actions/toast-notification";
import Buttonspinner from "@root/components/buttonspinner";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { setUserLocation } from "@root/actions/metadata";
import { useMutation, useQuery } from "react-query";
import { validateSubdomain } from "@root/services/auth";
import { APIError } from "@root/types";
import { currentUser } from "@root/actions/header";

interface Props {}

export const LoginView: React.FC<Props & InjectedFormProps<{}, Props>> = (
  props: any
) => {
  const [otp, setOtp] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);
  const dispatch = useDispatch();
  const statusSelector = useSelector(fetchSpinnerSelector);
  const [phone, setPhone] = useState("");
  const hasOTPGenerated = useSelector(hasOTPSuccessSelector);
  // const spinnerSelector = useSelector(fetchSpinnerSelector);
  const otpRegisteredPhoneNumber = useSelector(otpRegisteredPhone);
  const isError = useSelector(errorSelector);
  // const isLoading = useSelector(loadingSelector);
  const token: string | null = useSelector(tokenSelector);
  const history = useHistory();
  const isLoggedIn = useSelector(isAdminAuthorised);
  const [resend, setResend] = useState(false);
  const [askPermission, setaskPermission] = useState(false);
  const [hasPermissions, setPermissions] = useState({
    notification: false,
    location: false,
  });

  const [perNotification, setperNotification] = useState(false);
  const [perLocation, setperLocation] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

  const [isDomainNameValid, setDomainNameValid] = useState(true);

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const { handleSubmit, submitting } = props;

  useEffect(() => {
    checkPermissions();
  }, []);

  useUpdateEffect(() => {
    setPermissions({ notification: perNotification, location: perLocation });
    if (perLocation && perNotification) {
      history.push("/");
    }
  }, [perNotification, perLocation]);

  const onContinue = () => {
    askPermissions();
    // history.push("/employees");
  };

  const checkPermissions = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((result) => {
          if (result.state === "granted") {
            return { ...hasPermissions, notification: true };
          }
          return { ...hasPermissions, notification: false };
        })
        .then((data: any) => {
          const pers = navigator.permissions
            .query({ name: "geolocation" })
            .then((result) => {
              if (result.state === "granted") {
                navigator.geolocation.getCurrentPosition((pos: any) => {
                  let crd = pos.coords;
                  dispatch(
                    setUserLocation({ lat: crd.latitude, lng: crd.longitude })
                  );
                });
                return { ...data, location: true };
              }
              return { ...data, location: false };
            });
          return pers;
        })
        .then((permis: any) => {
          setPermissions(permis);
        });
    } else {
      alert("Sorry Not available!");
    }
  };

  const askPermissions = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((result) => {
          if (result.state === "granted") {
            setperNotification(true);
          } else if (result.state === "prompt") {
            Notification.requestPermission().then((permission) => {
              if (result.state === "granted") {
                setperNotification(true);
              } else {
                setperNotification(false);
              }
            });
          } else if (result.state === "denied") {
            setperNotification(false);
            dispatch(
              showNotificationError({
                type: "error",
                message:
                  "As part of our compliance, you must accept below mentioned permissions to continue.",
              })
            );
          }
          result.onchange = () => {
            if (result.state === "granted") {
              setperNotification(true);
            } else {
              setperNotification(false);
            }
          };
        })
        .then(() => {
          navigator.permissions
            .query({ name: "geolocation" })
            .then((result) => {
              if (result.state === "granted") {
                //If granted then you can directly call your function here
                setperLocation(true);
                navigator.geolocation.getCurrentPosition((pos: any) => {
                  let crd = pos.coords;
                  dispatch(
                    setUserLocation({ lat: crd.latitude, lng: crd.longitude })
                  );
                });
              } else if (result.state === "prompt") {
                navigator.geolocation.getCurrentPosition(
                  (pos: any) => {
                    let crd = pos.coords;
                    dispatch(
                      setUserLocation({ lat: crd.latitude, lng: crd.longitude })
                    );
                    setperLocation(true);
                  },
                  (err: any) => {
                    console.warn(`ERROR(${err.code}): ${err.message}`);
                    setperLocation(false);
                    dispatch(setUserLocation({ lat: 0, lng: 0 }));
                  },
                  options
                );
              } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                setperLocation(false);
                dispatch(setUserLocation({ lat: 0, lng: 0 }));
                dispatch(
                  showNotificationError({
                    type: "error",
                    message:
                      "As part of our compliance, you must accept below mentioned permissions to continue.",
                  })
                );
              }
              result.onchange = function () {
                if (result.state === "granted") {
                  setperLocation(true);
                  navigator.geolocation.getCurrentPosition((pos: any) => {
                    let crd = pos.coords;
                    dispatch(
                      setUserLocation({ lat: crd.latitude, lng: crd.longitude })
                    );
                  });
                } else {
                  setperLocation(false);
                  dispatch(setUserLocation({ lat: 0, lng: 0 }));
                }
              };
            });
        });
    } else {
      alert("Sorry Not available!");
    }
  };

  const handleChangeClick = (e: any) => {
    e.preventDefault();
    dispatch(hasOtpGenerated());
    history.push("/");
  };

  const onPhoneChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    setPhone(target.value);
  };

  const loggedInUser = useSelector(currentUser);
  useUpdateEffect(() => {
    if (token) {
      setAuthorizationToken(token);
      localStorage.setItem("token", token);
      if (hasPermissions.location && hasPermissions.notification) {
        console.log(hasPermissions.location);
        setaskPermission(false);
        history.push(
          loggedInUser.account.subscription === "visitors_only"
            ? "/visitors"
            : "/my-attendance"
        );
      } else {
        setaskPermission(true);
      }
      // const redirectTo = localStorage.getItem("redirectTo");
      // if (redirectTo) {
      //   localStorage.removeItem("redirectTo");
      //   history.push(redirectTo);
      // } else {
      //   history.push("/attendance");
      // }
    }
  }, [token]);

  useUpdateEffect(() => {
    if (isError) {
      dispatch(showNotificationError({ type: "error", message: isError }));
    }
  }, [isError]);

  const [domainName, setDomainName] = useState("");
  const mutation = useMutation({
    mutationKey: "validate-domain",
    mutationFn: validateSubdomain,
    onSuccess: (data: any, subdomain) => {
      localStorage.setItem("account-data", JSON.stringify(data));
      window.location.href = `http://${subdomain}.${process.env.REACT_APP_DOMAIN}`;
    },
    onError: (error: APIError) => {
      dispatch(
        showNotificationError({ type: "error", message: "Invalid domain" })
      );
    },
  });
  const handleSubdomainChange: FormEventHandler = (event) => {
    event.preventDefault();
    if (subdomain === process.env.REACT_APP_SUB_DOMAIN) {
      if (domainName) {
        setDomainNameValid(true);
        mutation.mutate(domainName);
      } else {
        setDomainNameValid(false);
        dispatch(
          showNotificationError({
            type: "error",
            message: "Please enter a valid domain.",
          })
        );
      }
    }
  };
  const subdomain =
    window && window.location.host.split(".").length > 1
      ? window.location.host.split(".")[0]
      : process.env.REACT_APP_SUB_DOMAIN;

  const query = useQuery<any, any>({
    queryKey: "get-account-data",
    queryFn: () => validateSubdomain(subdomain as string),
    onError: (error) => {
      dispatch(
        showNotificationError({ type: "error", message: "Invalid domain" })
      );
    },
    enabled: subdomain !== process.env.REACT_APP_SUB_DOMAIN,
  });

  const hanldeSubmission = () => {
    if (otpRegisteredPhoneNumber && !otpStatus) {
      setInvalidInputs(["OTP"]);
      dispatch(
        invalidOtpSubmission({
          error: "Invalid OTP",
        })
      );
      dispatch(
        showNotificationError({
          type: "error",
          message: "Please enter valid OTP to continue",
        })
      );
    } else if (!phone) {
      setInvalidInputs(["Phone"]);
      dispatch(
        showNotificationError({
          type: "error",
          message: "Please enter valid mobile number.",
        })
      );
    } else if (!hasOTPGenerated) {
      if (phone.length < 10) {
        setInvalidInputs(["Phone"]);
        dispatch(
          showNotificationError({
            type: "error",
            message: "Please enter valid mobile number.",
          })
        );
      } else {
        dispatch(
          makeLoginRequest({
            phone: `91${phone}`,
            account_id: query.data?.data?.account_id as string,
          })
        );
      }
    } else if (otpStatus && otpRegisteredPhoneNumber) {
      dispatch(
        makeOTPRequest({
          phone: otpRegisteredPhoneNumber.toString(),
          otp: otp,
          account_id: query.data?.data?.account_id as string,
        })
      );
    }
  };
  const handleOtpInput = (val: any) => {
    dispatch(
      invalidOtpSubmission({
        error: null,
      })
    );
    setOtp(val);
    if (val.length === 5) {
      setOtpStatus(true);
    } else {
      setOtpStatus(false);
    }
  };

  const onResend = (event: React.SyntheticEvent) => {
    event?.preventDefault();
    setResend(true);
    dispatch(
      makeLoginRequest({
        phone: `91${phone}`,
        account_id: query.data?.data?.account_id as string,
      })
    );
  };

  return (
    <>
      <div className="card login-card border-0">
        {askPermission ? (
          <>
            <h5 className="mb-3">Permissions</h5>
            <p className="mb-4">
              As part of our compliance, you must accept below mentioned
              permissions to continue:
            </p>
            <FormGroup>
              <FormControlLabel
                disabled
                control={
                  <Checkbox checked name="notification" color="default" />
                }
                label="Push Notifications"
              />
              <FormControlLabel
                disabled
                control={<Checkbox checked name="location" color="default" />}
                label="Location"
              />
            </FormGroup>{" "}
            <br />
            <button
              type="button"
              onClick={onContinue}
              className="login-btn w-100 mt-4 text-white"
            >
              I Accept &amp; Continue
            </button>
          </>
        ) : (
          <>
            {(hasOTPGenerated || resend) && (
              <p className="pb-12 text-black">
                OTP has been sent to{" "}
                {otpRegisteredPhoneNumber?.replace(
                  /(\d{2})(\d{5})(\d{5})/g,
                  "$1 $2 $3"
                )}{" "}
              </p>
            )}
            <div className="form-login">
              <form
                className="w-100 login-form"
                style={{ opacity: "1", transform: "none" }}
                onSubmit={
                  subdomain !== process.env.REACT_APP_SUB_DOMAIN
                    ? handleSubmit(hanldeSubmission)
                    : handleSubdomainChange
                }
              >
                {hasOTPGenerated || resend ? (
                  <div className="form-group otp-input">
                    <OTPInput
                      autoFocus
                      isNumberInput
                      length={5}
                      className="otpContainer"
                      inputClassName={`otpInput ${
                        invalidInputs.includes("OTP") ? "error" : ""
                      }`}
                      onChangeOTP={(otp) => handleOtpInput(otp)}
                    />
                  </div>
                ) : (
                  <>
                    {subdomain !== process.env.REACT_APP_SUB_DOMAIN ? (
                      <>
                        <div className="flex items-center">
                          <TextField
                            label="Domain Name"
                            // onChange={onPhoneChange}
                            variant="outlined"
                            name="phone"
                            type="text"
                            fullWidth
                            disabled
                            value={subdomain}
                            color="secondary"
                            placeholder="Domain Name"
                            className="input-feild bg-[#F7F5F6]"
                            // error={
                            //   invalidInputs.includes("Phone") ? true : false
                            // }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span className="border-l-2 pl-2">
                                    .viba.ai
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="mt-8">
                          <TextField
                            label="Mobile Number"
                            onChange={onPhoneChange}
                            variant="outlined"
                            name="phone"
                            type="tel"
                            inputProps={{
                              pattern: "[0-9]+",
                              maxLength: "10",
                              minLength: "10",
                              max: "10",
                              min: "10",
                            }}
                            fullWidth
                            color="secondary"
                            placeholder="Mobile Number"
                            className="input-feild"
                            error={
                              invalidInputs.includes("Phone") ? true : false
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  91 |
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center">
                        <TextField
                          label="Domain Name"
                          // onChange={onPhoneChange}
                          variant="outlined"
                          // name="phone"
                          type="text"
                          fullWidth
                          color="secondary"
                          onChange={(event) => {
                            setDomainNameValid(true);
                            setDomainName(event.target.value);
                          }}
                          value={domainName}
                          placeholder="Domain Name"
                          className="input-feild"
                          disabled={mutation.isLoading}
                          error={mutation.isError || !isDomainNameValid}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span className="border-l-2 pl-2">
                                  .viba.ai
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {statusSelector === "loading" ? (
                  <button
                    type="submit"
                    className="login-btn w-100 mt-4"
                    id="reg"
                  >
                    <Buttonspinner />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="login-btn btn btn-gradient w-100 mt-4"
                    id="reg"
                    disabled={submitting}
                  >
                    <span>
                      {subdomain !== process.env.REACT_APP_SUB_DOMAIN
                        ? hasOTPGenerated
                          ? "Confirm OTP to Signin"
                          : "Request OTP to Sign in"
                        : "Verify Domain"}
                    </span>
                  </button>
                )}

                {hasOTPGenerated || resend ? (
                  <div className="pt-6 text-black">
                    Didn't receive OTP?{" "}
                    <button
                      onClick={onResend}
                      type="button"
                      className="text-[#00B]"
                    >
                      Request again.
                    </button>
                  </div>
                ) : null}
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Login = reduxForm<{}, Props>({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  form: "user",
})(LoginView);

export default Login;
