import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  googleURLSelector,
  microsoftURLSelector,
  isDisconnectedIntegration,
  getMicrosoftAuthUrl,
  getGoogleAuthUrl,
  putDisconnectIntegration,
} from "@root/actions/integrations";
import { setTitle } from "@root/actions/metadata";
import { getAllSettings, settingsSelector } from "@root/actions/settings";
import IntegrationCard from "@root/components/IntegrationCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";

function Integrations() {
  const dispatch = useDispatch();
  const settings = useSelector(settingsSelector);

  const googleAuthURL = useSelector(googleURLSelector);
  const microsoftAuthURL = useSelector(microsoftURLSelector);

  const [integration, setintegration] = useState("msoffice");
  const isDisconnected = useSelector(isDisconnectedIntegration);

  let windowObjectReference: any = null;
  let previousUrl: any = null;
  const openAuthWindow = (url: any) => {
    // const url = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events.owned&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiNjE1Yzk1YmZmYTYxMjM1NmY1ZjA5MjY3IiwiX2lkIjoiNjFiMThjMmRhZjc3Y2YwZTdhYjU2MzYwIiwiZW1haWwiOiJpdHNtZW1pbmluQGdtYWlsLmNvbSIsInJvbGUiOiJtb2RlcmF0b3IiLCJpYXQiOjE2NDMzNTE0MDl9.Pqy1oPCQHit6j75nSImVwTsMt4tutxxwktbsw9uAsfE&response_type=code&client_id=710086298906-qlh9asjnlm0rjte1vku728u6j0ror4bh.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fdev-backend.viba.ai%3A3000%2Fapi%2Fv1%2Fauth%2Fgoogle%2Fverify';

    // window features
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, "popup", strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, "popup", strWindowFeatures);
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }
    // checkPopup();
    previousUrl = url;
  };
  useEffect(() => {
    if (windowObjectReference) console.log(windowObjectReference);
  }, [windowObjectReference]);

  useUpdateEffect(() => {
    if (googleAuthURL) {
      openAuthWindow(googleAuthURL);
    }
  }, [googleAuthURL]);

  useUpdateEffect(() => {
    if (microsoftAuthURL) {
      openAuthWindow(microsoftAuthURL);
    }
  }, [microsoftAuthURL]);

  useUpdateEffect(() => {
    if (isDisconnected) {
      dispatch(getAllSettings({}));
    }
  }, [isDisconnected]);

  const authorizeIntegration = (_type: "msoffice" | "google") => {
    if (_type === "msoffice") {
      dispatch(getMicrosoftAuthUrl());
    } else {
      dispatch(getGoogleAuthUrl());
    }
  };

  const onDisconnect = (integration: "google" | "microsoft") => {
    dispatch(putDisconnectIntegration({ integration }));
  };

  const toggleIntegration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setintegration((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    dispatch(setTitle("Integrations"));
  }, [dispatch]);

  useEffect(() => {
    if (
      (!settings.integration.microsoft && !settings.integration.google) ||
      settings.integration.microsoft
    ) {
      setintegration("msoffice");
    } else {
      setintegration("google");
    }
  }, [settings.integration.google, settings.integration.microsoft]);

  return (
    <div className="col-lg-12">
      <div className="integration-container p-5">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={integration}
            onChange={toggleIntegration}
          >
            <div className="d-flex align-items-start">
              <FormControlLabel
                value="msoffice"
                control={<Radio color="default" />}
                label=""
              />
              <IntegrationCard
                show={integration === "msoffice" ? true : false}
                image={"../../../assests/images/office365.png"}
                title="Microsoft Office 365"
                isIntegrated={settings.integration.microsoft}
                onClick={() => authorizeIntegration("msoffice")}
                onDisconnect={() => onDisconnect("microsoft")}
              />
            </div>

            <div className="d-flex align-items-start">
              <FormControlLabel
                value="google"
                control={<Radio color="default" />}
                label=""
              />
              <IntegrationCard
                show={integration === "google" ? true : false}
                image={"../../../assests/images/googleL.png"}
                title="Google"
                isIntegrated={settings.integration.google}
                onClick={() => authorizeIntegration("google")}
                onDisconnect={() => onDisconnect("google")}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default Integrations;
