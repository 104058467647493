import "./panelProfile.scss";
import React from "react";

const PanelProfile = (props: any) => {
  return (
    <>
      <div className="profile-container d-flex flex-column align-items-center p-3 pt-0">
        <div className="profile-pic">
          <img
            className="img-fluid"
            src={props.image ? props.image : "../../../assests/images/user.png"}
            alt="Profile pic"
          />
        </div>
        <div className="profile-content">
          <h4 className="name text-center mb-1">{props.name}</h4>
          <p className="designation text-center">{props.designation}</p>
        </div>
      </div>
    </>
  );
};

export default PanelProfile;
