import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getTempUsers, getTempVisitors } from "@root/services/temp-employee";

const initialState: TempEmployeeListState = {
  status: "",
  error: "",
  eList: [], //employee list
  vList: [], // visitors list
};

export const getTempEmployeeList = createAsyncThunk<
  Dictionary,
  {
    date: string;
    page_size: number;
    page_index: number;
  },
  { rejectValue: ResponseError }
>(
  "@admin/getTempEmployeeList",
  async ({ date, page_size, page_index }, thunkAPI) => {
    try {
      const response: any = await getTempUsers(date, page_size, page_index);
      const errMsg: any = response.data;
      return response.status === 200
        ? {
            page_index,
            list: response.data.data,
            counts: response.data.counts,
            hasNext: response.hasNext,
            total: response.data.total,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const getTempVisitorsList = createAsyncThunk<
  Dictionary,
  {
    date: string;
    page_size: number;
    page_index: number;
  },
  { rejectValue: ResponseError }
>(
  "@admin/getTempVisitorsList",
  async ({ date, page_size, page_index }, thunkAPI) => {
    try {
      const response: any = await getTempVisitors(date, page_size, page_index);
      const errMsg: any = response.data;
      return response.status === 200
        ? {
            page_index,
            list: response.data.data,
            counts: response.data.counts,
            hasNext: response.hasNext,
            total: response.data.total,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const tempEmployeeSlice = createSlice({
  name: "tempEmployee",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ---------------------------getTempEmployeeList-------------------------------------
    builder.addCase(getTempEmployeeList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getTempEmployeeList.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload");
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.eList = action.payload.list;
    });
    builder.addCase(getTempEmployeeList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });
    // -------------------------------getTempVisitorsList--------------------------------
    builder.addCase(getTempVisitorsList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
    });
    builder.addCase(getTempVisitorsList.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload");
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.vList = action.payload.list;
    });
    builder.addCase(getTempVisitorsList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.tempEmployee;
export const tempEmployeeListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.eList
);
export const tempVisitorsListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.vList
);
export const fetchStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export default tempEmployeeSlice.reducer;
