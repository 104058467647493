import React, { LegacyRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { setTitle, updateDatePickerStatus } from "@actions/metadata";
import {
  tempEmployeeListSelector,
  tempVisitorsListSelector,
  fetchStatusSelector,
} from "@root/actions/temperatureEmployee";
import Tooltip from "@mui/material/Tooltip";
import "./index.scss";
import {
  getTempEmployeeList,
  getTempVisitorsList,
} from "@root/actions/temperatureEmployee";
import EmployeeNameWithProfileRenderer from "@root/components/EmployeeNameWithProfileRenderer/EmployeeNameWithProfileRenderer";
import Spinner from "@root/components/Spinner/Spinner";
import Pagination from "@mui/material/Pagination";
import {
  startDateStringSelector,
  setStartDate,
  currentUser,
} from "@root/actions/header";
import { useUnmount, useUpdateEffect } from "react-use";
import InfoAlert from "@root/components/InfoAlert";
import CAlert from "@root/components/CAlert";

const AbnormalTempEmp = (props: any) => {
  const loggedInUser = useSelector(currentUser);
  const [activeTab, setActiveTab] = useState(0);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [vistorsData, setVistorsData] = useState<any>([]);
  const dispatch = useDispatch();
  const eData = useSelector(tempEmployeeListSelector);
  const vData = useSelector(tempVisitorsListSelector);
  const status = useSelector(fetchStatusSelector);
  const [gridTempApi, setGridTempApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const startDate = useSelector(startDateStringSelector);

  const gridRef: LegacyRef<AgGridReact> = useRef(null);

  useEffect(() => {
    const today = new Date();
    dispatch(setStartDate(today.toString()));
    dispatch(updateDatePickerStatus(true));
    dispatch(setTitle("Abnormal Temperature"));
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 1:
        dispatch(
          getTempVisitorsList({
            date: startDate,
            page_index: pageIndex,
            page_size: pageSize,
          })
        );
        break;
      default:
        dispatch(
          getTempEmployeeList({
            date: startDate,
            page_index: pageIndex,
            page_size: pageSize,
          })
        );
        break;
    }
  }, [startDate, pageIndex, pageSize]);

  useEffect(() => {
    const tempData = eData.map((val) => {
      return {
        first_name: val?.user?.first_name,
        last_name: val?.user?.last_name,
        user_designation: val?.user?.description,
        user_temperature: val?.temperature?.value + val?.temperature?.measure,
        admin_first_name: val?.admin?.first_name ? val?.admin?.first_name : "-",
        admin_last_name: val?.admin?.last_name ? val?.admin?.last_name : "-",
        entrance_allowed: val?.entrance_allowed ? "Yes" : "No",
        admin_comment: val?.admin_comment !== null ? val?.admin_comment : "--",
        admin_image: val?.admin?.image,
        admin_description: val?.admin?.description,
        image: val?.user?.image,
      };
    });
    setEmployeeData(tempData);
  }, [eData]);

  useEffect(() => {
    const tempData = vData.map((val) => {
      return {
        first_name: val?.visitor?.name ? val?.visitor?.name : "--",
        last_name: "",
        user_phone: val?.visitor?.phone,
        user_temperature: val?.temperature?.value + val?.temperature?.measure,
        admin_first_name: val?.admin ? val?.admin?.first_name : "--",
        admin_last_name: val?.admin ? val?.admin?.last_name : "--",
        entrance_allowed: val?.entrance_allowed ? "Yes" : "No",
        admin_comment: val?.admin_comment !== null ? val?.admin_comment : "--",
        admin_image: val?.admin?.image,
        admin_description: val?.admin?.description,
        image: val.visitor?.image,
      };
    });
    setVistorsData(tempData);
  }, [vData]);

  useUnmount(() => {
    const today = new Date();
    dispatch(setStartDate(today));
    dispatch(updateDatePickerStatus(false));
  });

  const getRowClass = (params: any) => {
    return "active";
  };
  const processCells = (params: any) => {
    if (params.accumulatedRowIndex === 0) {
      return params.node.data.name;
    } else return params.value;
  };
  const getParams = () => {
    return {
      // @ts-ignore
      processCellCallback: (params) => processCells(params),
    };
  };
  const onBtnExport = () => {
    // @ts-ignore
    (gridRef.current as AgGridReact).api?.exportDataAsCsv(getParams());
  };

  const onPageSizeChanged = () => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      ?.value;
    //@ts-ignore
    gridTempApi?.paginationSetPageSize(Number(value));
    setPageSize(Number(value));
  };

  const onPageNum = (e: any, num: any) => {
    setPageIndex(num - 1);
  };

  const [eColDefs, seteColDefs] = useState([
    {
      field: "user_first_name",
      headerName: "EMPLOYEE NAME",
      sortable: true,
      cellRendererFramework: ({ data }: any) => {
        return EmployeeNameWithProfileRenderer({
          data,
        });
      },
    },
    { field: "user_designation", headerName: "DESIGNATION" },
    {
      field: "user_temperature",
      headerName: "TEMPERATURE",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            <p className={"text-danger"}>{params.data.user_temperature}</p>
          </div>
        );
      },
    },
    {
      field: "admin_first_name",
      sortable: true,
      cellRendererFramework: ({ data }: any) => {
        return EmployeeNameWithProfileRenderer(
          {
            data: {
              first_name: data.admin_first_name,
              last_name: data.admin_last_name,
              image: data.admin_image,
              role: data.admin_description,
            },
          },
          true
        );
      },
      headerName: "ACTION TAKEN BY",
    },
    { field: "entrance_allowed", headerName: "ACCESS GRANTED?" },
    {
      field: "admin_comment",
      headerName: "NOTES",
      cellRendererFramework: (params: any) => {
        return (
          <Tooltip
            title={
              <div className="p-2">
                <label>Notes:</label>
                <p className="pt-2">{params.data?.admin_comment}</p>
              </div>
            }
            placement="left"
            color="secondary"
            arrow
          >
            <button className="grid-action" onClick={() => {}}>
              View
            </button>
          </Tooltip>
        );
      },
    },
    {
      field: "",
      headerName: "",
      width: 80,
      headerComponentFramework: (props: any) => {
        return (
          <Tooltip
            title={"Export to Excel"}
            placement="bottom"
            color="secondary"
            arrow
          >
            <button className="export-btn" onClick={() => onBtnExport()}>
              <img
                src="../../../../assests/images/excel-file.png"
                alt="icon"
                className="m-0"
              />
            </button>
          </Tooltip>
        );
      },
    },
  ]);

  const [vColDefs, setvColDefs] = useState([
    {
      field: "user_first_name",
      headerName: "VISITOR NAME",
      sortable: true,
      cellRendererFramework: ({ data }: any) => {
        return EmployeeNameWithProfileRenderer({
          data,
        });
      },
    },
    { field: "user_phone", headerName: "PHONE NUMBER" },
    {
      field: "user_temperature",
      headerName: "TEMPERATURE",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            <p className={"text-danger"}>{params.data.user_temperature}</p>
          </div>
        );
      },
    },
    {
      field: "admin_first_name",
      sortable: true,
      cellRendererFramework: ({ data }: any) => {
        return EmployeeNameWithProfileRenderer(
          {
            data: {
              first_name: data.admin_first_name,
              last_name: data.admin_last_name,
              image: data.admin_image,
              role: data.admin_description,
            },
          },
          true
        );
      },
      headerName: "ACTION TAKEN BY",
    },
    { field: "entrance_allowed", headerName: "ACCESS GRANTED?" },
    { field: "admin_comment", headerName: "NOTES" },
    {
      field: "",
      headerName: "",
      width: 80,
      headerComponentFramework: (props: any) => {
        return (
          <Tooltip
            title={"Export to Excel"}
            placement="bottom"
            color="secondary"
            arrow
          >
            <button className="export-btn" onClick={() => onBtnExport()}>
              <img
                src="../../../../assests/images/excel-file.png"
                alt="icon"
                className="m-0"
              />
            </button>
          </Tooltip>
        );
      },
    },
  ]);

  useEffect(() => {
    switch (activeTab) {
      case 1:
        dispatch(
          getTempVisitorsList({
            date: startDate,
            page_index: pageIndex,
            page_size: pageSize,
          })
        );
        break;
      default:
        dispatch(
          getTempEmployeeList({
            date: startDate,
            page_index: pageIndex,
            page_size: pageSize,
          })
        );
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    if (loggedInUser.account.subscription === "visitors_only") {
      setActiveTab(1);
    }
  }, [loggedInUser.account.subscription]);

  const onGridReady = (params: any) => {
    setGridTempApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="temperature h-100">
      <div className="mb-3  bg-white">
        <div className="col-12">
          <div className="d-flex">
            <ul className="nav my-company-tabs">
              {loggedInUser.account.subscription === "visitors_only" ? null : (
                <li className={`${activeTab === 0 ? "active" : ""}`}>
                  <button className="btn" onClick={() => setActiveTab(0)}>
                    Employees
                  </button>
                </li>
              )}
              <li className={`${activeTab === 1 ? "active" : ""}`}>
                <button className="btn" onClick={() => setActiveTab(1)}>
                  Visitors
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="main-panel d-flex flex-column">
        <div className="flex-grow-1" style={{ height: "100%", width: "100%" }}>
          {vistorsData?.length > 0 && (
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%", position: "relative" }}
            >
              {activeTab === 0 && (
                <AgGridReact
                  ref={gridRef}
                  frameworkComponents={{
                    employeeNameWithProfileRenderer:
                      EmployeeNameWithProfileRenderer,
                  }}
                  reactUi={true}
                  rowClass={"minin-class"}
                  getRowClass={getRowClass}
                  pagination={true}
                  rowData={employeeData}
                  rowHeight={100}
                  rowSelection={"single"}
                  columnDefs={eColDefs}
                  onGridReady={onGridReady}
                  paginationPageSize={20}
                  suppressPaginationPanel={true}
                  // onPaginationChanged={()=> alert('changed')}
                />
              )}
              {activeTab === 1 && (
                <AgGridReact
                  ref={gridRef}
                  frameworkComponents={{
                    employeeNameWithProfileRenderer:
                      EmployeeNameWithProfileRenderer,
                  }}
                  reactUi={true}
                  rowClass={"minin-class"}
                  getRowClass={getRowClass}
                  pagination={true}
                  rowData={vistorsData}
                  rowHeight={100}
                  rowSelection={"single"}
                  columnDefs={vColDefs}
                  onGridReady={onGridReady}
                  paginationPageSize={20}
                  suppressPaginationPanel={true}
                  // onPaginationChanged={()=> alert('changed')}
                />
              )}

              <div className="d-flex justify-content-between pagination-box bg-white p-2">
                <select
                  onChange={onPageSizeChanged}
                  id="page-size"
                  className="pagination-dropdown"
                  value={pageSize}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>

                <Pagination
                  count={Math.ceil(employeeData.length / pageSize)}
                  showFirstButton
                  showLastButton
                  onChange={onPageNum}
                />
              </div>
            </div>
          )}
          {vistorsData?.length < 1 && (
            <CAlert>No records found. Please try with another date.</CAlert>
          )}
        </div>
      </div>
      {status === "loading" ? (
        <div className="spinner-container-main">
          <Spinner />{" "}
        </div>
      ) : null}
    </div>
  );
};

export default AbnormalTempEmp;
