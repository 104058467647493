import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HomeIcon from "@mui/icons-material/Home";
import DomainIcon from "@mui/icons-material/Domain";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import "./visitoractivity.scss";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { useSelector } from "react-redux";
import { visitorDataSelector } from "@root/actions/getvisitor";
import React from "react";
const Visitoractivity = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };
  const getDateFormat = (timeString: string) => {
    const months = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    const time = new Date(timeString);
    return `${time.getUTCFullYear()}-${
      time.getUTCMonth() + 1
    }-${time.getUTCDate()}`;
  };
  return (
    <div className={`activitys ${props.isLast ? " last" : ""}`}>
      {props.Activity.clocked_out_at !== null ? (
        <div className="check-out">
          <div className="checkout-header d-flex">
            <div className="checkout-icon out">
              <DirectionsWalkIcon />
            </div>
            <p className="mt-3">CheckedOut</p>
          </div>
          <div className="check-icon clockout-border">
            <div className="active-icon">
              <WatchLaterIcon fontSize="small" />
              <p className="mb-0">
                &nbsp;{getDateFormat(props.Activity.clocked_out_at)} |{" "}
                {getTimeFormat(props.Activity.clocked_out_at)}
              </p>
            </div>
            <div className="active-icon">
              <ThermostatIcon />
              <p className="mb-0">N/A</p>
            </div>
          </div>
        </div>
      ) : null}
      {props.Activity.clocked_in_at !== null ? (
        <div className="check-in">
          <div className="checkin-header poss-relative">
            <div className="checkin-icon ">
              <DirectionsWalkIcon />
            </div>
            <div className=" d-flex poss-relative">
              <span>CheckedIn for</span> &nbsp;
              {/* my changes ******************* */}
              <span className="checkin-span">
                {props.Activity.purpose}&nbsp;&nbsp;
              </span>{" "}
              {props.Activity.invited_by ? " with " : ""}{" "}
              <span className="checkin-span">
                &nbsp;&nbsp;{props.Activity.invited_by?.first_name}
              </span>
              {/* my changes *************end****** */}
              {/* <Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" 
            onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} >
              <span className="checkin-span">{props.Activity.purpose}</span> {props.Activity.invited_by ? 'with' : ''} <span className="checkin-span">{props.Activity.invited_by?.first_name}</span>
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
                m:4,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 3 }}>
                <div className="vibaa-popover">
                  <div className="p-content">
                    <p className="mb-1"><span>Sender Details</span></p>
                    <p className="mb-1">{props.Activity.invited_by?.first_name}&nbsp;{props.Activity.invited_by?.last_name}</p>
                    <p>{props.Activity.invited_by?.description}  <br />
                    {props.Activity.invited_by?.email} <br />
                    {props.Activity.invited_by?.phone} </p>
                  </div>
                  <div className="p-img">
                    <img src={props.Activity.invited_by?.image} alt=""
                    className="img-fluid" />
                  </div>
                </div>
              </Typography>
            </Popover> */}
            </div>
            {props.Activity.invited_by ? (
              <div className="vibaa-popover shadow">
                <div className="p-content">
                  <p className="mb-1">
                    <span>Sender Details</span>
                  </p>
                  <p className="mb-2 bold">
                    {props.Activity.invited_by?.first_name}&nbsp;
                    {props.Activity.invited_by?.last_name}
                  </p>
                  <p className="mb-0">
                    {props.Activity.invited_by?.description}
                  </p>
                  <p className="mb-0">{props.Activity.invited_by?.email}</p>
                  <p className="mb-0">{`+${props.Activity.invited_by?.phone.slice(
                    0,
                    2
                  )} ${props.Activity.invited_by?.phone.slice(
                    2,
                    7
                  )} ${props.Activity.invited_by?.phone.slice(7)}`}</p>
                </div>
                <div className="p-img">
                  <img
                    src={props.Activity.invited_by?.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="check-icon clockin-border">
            <div className="active-icon">
              <WatchLaterIcon fontSize="small" />
              &nbsp;
              <p className="mb-0">
                {getDateFormat(props.Activity.clocked_in_at)} |{" "}
                {getTimeFormat(props.Activity.clocked_in_at)}
              </p>
            </div>
            <div className="active-icon">
              <ThermostatIcon />
              <p className="mb-0">
                {props.Activity.temperature?.value}
                {"\u00b0"} {props.Activity.temperature?.measure}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Visitoractivity;
