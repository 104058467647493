import axios from "axios";

// export function validateOtpService2(phone: string, otp: string) {
//     return axios({
//         method: "post",
//         url: `${process.env.REACT_APP_BASE_URL}/auth/validate-otp`,
//         data: {
//             phone,
//             sms_otp: otp
//         }
//     });
// }

export function validateOtpService(payload: {
  phone: string;
  sms_otp: string;
  account_id: string;
}) {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL + "/auth/validate-otp"}`,
    payload,
    {
      validateStatus: function (status) {
        return status >= 200 && status <= 404;
      },
    }
  );
}

export function validateSubdomain(subdomain: string) {
  const url = new URL(
    `${process.env.REACT_APP_BASE_URL}/auth/validate-subdomain` as string
  );
  url.searchParams.set("term", subdomain);
  const res = axios.get(url.toString(), {
    validateStatus: function (status) {
      return status >= 200 && status <= 300;
    },
  });
  return res;
}

export function getOtpService(phone: string, account_id: string) {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL_V2 + "/auth/send-otp/admin-db"}`,
    {
      phone,
      account_id,
    },
    {
      validateStatus: function (status) {
        return status >= 200 && status <= 404;
      },
    }
  );
}
