import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef, store } from "@root/store";
import { genFeedbackReport } from "../../services/report";
import { showNotificationError } from "../toast-notification";

const initialState: ReportState = {
  report: {
    processed: false,
    url: "",
    _id: "",
    type: "",
    date: "",
    __v: 0,
  },
  status: "idle",
  error: "",
  isGenerated: false,
};

export const genFeedbackReportList = createAsyncThunk<
  Dictionary,
  { from_date: string; to_date: string },
  { rejectValue: ResponseError }
>("@admin/Feedback_Report", async ({ from_date, to_date }, thunkAPI) => {
  try {
    const response: any = await genFeedbackReport(from_date, to_date);
    const errMsg: any = response.data;

    if (response.status === 200) {
      store.dispatch(
        showNotificationError({
          type: "success",
          message: "Report generated and sent to your email.",
        })
      );
      return {
        data: response.data,
        status: true,
      };
    } else {
      store.dispatch(
        showNotificationError({ type: "error", message: errMsg.error })
      );
      return { status: false, error: errMsg };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});

const feedbackReportSlice = createSlice({
  name: "feedbackreport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(genFeedbackReportList.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.isGenerated = false;
    });
    builder.addCase(genFeedbackReportList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isGenerated = action.payload.status ? true : false;
      if (action.payload.status) state.report = { ...action.payload.data };
    });
    builder.addCase(genFeedbackReportList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
      state.isGenerated = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.feedbackreport;

export const feedbackReportStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const feedbackReportGenStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isGenerated
);
// export const visitorErrorSelector = createDraftSafeSelector(selfSelect, state => state.error);
export const feedbackFetchedReport = createDraftSafeSelector(
  selfSelect,
  (state) => state.report
);

// const { resetVisitorReporGenStatus } = feedbackReportSlice.actions;

export default feedbackReportSlice.reducer;
