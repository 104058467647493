import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { promoteEmployee } from "@root/services/demote-employee";
import { StoreDef } from "@root/store";

interface promoteEmployeeState {
  status: "idle" | "loading";
  message: string;
  error: string;
  hasPromoted: boolean;
}

const initialState: promoteEmployeeState = {
  status: "idle",
  message: "",
  error: "",
  hasPromoted: false,
};

export const getPromotedEmployee = createAsyncThunk<
  any,
  { id: string },
  { rejectValue: ResponseError }
>("promote/employee", async ({ id }, thunkAPI) => {
  try {
    const response = await promoteEmployee(id);
    const errMsg: any = response.data;
    return response.status === 200
      ? {
          message: response.data,
          status: true,
        }
      : { status: false, error: errMsg };
  } catch (error) {
    return thunkAPI.rejectWithValue(error as ResponseError);
  }
});
const promoteEmployeeSlice = createSlice({
  name: "promoteEmployee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPromotedEmployee.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.hasPromoted = false;
    });
    builder.addCase(getPromotedEmployee.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload?.error?.error;
      state.hasPromoted = action.payload.status;
      state.message = { ...action.payload.message };
    });
    builder.addCase(getPromotedEmployee.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
      state.hasPromoted = false;
    });
  },
});

const selfSelect = (state: StoreDef) => state.promoteEmp;

export const messageSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.message
);
export const hasEmployeePromotedSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.hasPromoted
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);

export default promoteEmployeeSlice.reducer;
