import "./checkIn.scss";
import React from "react";

const Checkinlabel = (props: any) => {
  return (
    <>
      <div
        className={`d-flex text-white align-items-center checkin-label-indicator no-icon`}
      >
        <p className="mb-0 text-white">
          {props.status === "checkin" ? "CheckedIn" : "CheckedOut"}
        </p>
      </div>
    </>
  );
};

export default Checkinlabel;
