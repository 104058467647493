import "./page4o4.scss";
import React from "react";

const Page4O4 = () => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center fourO4-page">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex justify-content-center">
            <img src="../../../../assests/images/logo-small.png" alt="logo" />
          </div>
          <h2 className="fourO4 text-center">404</h2>
          <p className="text-center">Uffss!! Page Not Found.</p>
        </div>
      </div>
    </>
  );
};

export default Page4O4;
