import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getAttendanceReport } from "../../services/report";

const initialState: ReportState = {
  report: {
    processed: false,
    url: "",
    _id: "",
    type: "",
    date: "",
    __v: 0,
  },
  status: "idle",
  error: "",
  isGenerated: false,
};
export const getReportlist = createAsyncThunk<
  Dictionary,
  {
    from_date: string;
    to_date: string;
    email: boolean;
    dob: boolean;
    doj: boolean;
    gender: boolean;
    department: boolean;
    wfo_days: boolean;
    late_clockins: boolean;
    abnormal_temp: boolean;
    type: string;
    late_count: boolean;
    custom_id?: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_emp_Attandance_report",
  async (
    {
      from_date,
      to_date,
      email,
      dob,
      doj,
      gender,
      department,
      wfo_days,
      late_clockins,
      abnormal_temp,
      type,
      late_count,
      custom_id,
    },
    thunkAPI
  ) => {
    try {
      const response: any = await getAttendanceReport(
        from_date,
        to_date,
        email,
        dob,
        doj,
        gender,
        department,
        wfo_days,
        late_clockins,
        abnormal_temp,
        type,
        late_count,
        custom_id
      );
      const errMsg: any = response.data;
      return response.status === 200
        ? {
            data: response.data,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);
const reportSlice = createSlice({
  name: "rpattendance",
  initialState: initialState,
  reducers: {
    resetReporGenStatus: (state) => {
      state.isGenerated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportlist.pending, (state, action) => {
      state.status = "loading";
      state.error = "";
      state.isGenerated = false;
    });
    builder.addCase(getReportlist.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.isGenerated = action.payload.status;
      state.report = { ...action.payload.data };
      // setTimeout(() => {}, )
    });
    builder.addCase(getReportlist.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
      state.isGenerated = false;
    });
  },
});
const selfSelect = (state: StoreDef) => state.rpattendance;

export const reportStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);
export const reportGenStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isGenerated
);
export const fetchSpinnerSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const fetchedReport = createDraftSafeSelector(
  selfSelect,
  (state) => state.report
);

export const { resetReporGenStatus } = reportSlice.actions;

export default reportSlice.reducer;
