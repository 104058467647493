import classNames from "classnames";
import "./spinner.scss";

type SpinnerProps = {
  /**
   * Show spinner inline instead of covering all the app
   */
  isInline?: boolean;
};

const Spinner = (props: SpinnerProps) => {
  return (
    <>
      <div
        id="preloader"
        className={classNames({ "page-loader": !props.isInline })}
      >
        <img className="img-fluid" src="../../../brand-icon.png" alt="Viba" />
      </div>
    </>
  );
};

export default Spinner;
