import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "@actions/auth";
import SidebarReducer from "@actions/sidebar";
import MetadataReducer from "@actions/metadata";
import EmployeeListReducer from "@actions/employee-list";
import EditEmployeeReducer from "@actions/editEmployee";
import AttendanceReducer from "@root/actions/attendance";
import TempEmployeeReducer from "@root/actions/temperatureEmployee";
import GetEmployeeReducer from "@actions/getEmployee";
import ToastifyReducer from "@actions/toast-notification";
import GetVisitorsReducer from "@actions/visitor";
import GetVisitorByIdReducer from "@actions/getvisitor";
import GetLogoReducer from "@actions/header";
import GetSettingsTableReducer from "@actions/settingsTable";
import SettingsReducer from "@actions/settings";
import GetEmployeeProfileReducer from "@actions/profileAndCompany";
import TabPinReducer from "@actions/generatePin";
import GetDemoteEmployeeReducer from "@actions/demoteEmployee";
import GetSearchEmployeeReducer from "@actions/settingsSearch";
import GetPromoteEmployeeReducer from "@actions/promoteEmployee";
import SendMessageReducer from "@actions/sendMessage";
import GetCurrentUserReducer from "@actions/getCurrentUser";
import AttendanceReportReducer from "@root/actions/reportAttendance";
import AllReportReducer from "@root/actions/allReports";
import EmployeesReportReducer from "@root/actions/reportEmployees";
import VisitorsReportReducer from "@root/actions/reportVisitors";
import SOSalertReducer from "@root/actions/sosAlert";
import PreInvitationReducer from "@root/actions/preInvitations";
import HolidaysReducer from "@root/actions/holidays";
import FeedbackReducer from "@root/actions/feedback";
import ClockinReducer from "@actions/globalClockin";
import IntegrationReducer from "@actions/integrations";
import FeedbackReportReducer from "@actions/reportFeedbacks";
import Notices from "@actions/notices";
import UnknownVisitorsReducer from "@root/actions/unknownVisitors";
import { reducer as reduxFormReducer } from "redux-form";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    form: reduxFormReducer,
    sidebar: SidebarReducer,
    metadata: MetadataReducer,
    employeeList: EmployeeListReducer,
    editEmployee: EditEmployeeReducer,
    attendance: AttendanceReducer,
    tempEmployee: TempEmployeeReducer,
    toast: ToastifyReducer,
    getEmployee: GetEmployeeReducer,
    visitors: GetVisitorsReducer,
    getLogo: GetLogoReducer,
    getEmployeeProfileData: GetEmployeeProfileReducer,
    settingsTable: GetSettingsTableReducer,
    settings: SettingsReducer,
    getVisitor: GetVisitorByIdReducer,
    tabpin: TabPinReducer,
    demoteEmp: GetDemoteEmployeeReducer,
    searchEmployee: GetSearchEmployeeReducer,
    promoteEmp: GetPromoteEmployeeReducer,
    sendmessage: SendMessageReducer,
    currentUser: GetCurrentUserReducer,
    rpattendance: AttendanceReportReducer,
    all_reports: AllReportReducer,
    visitorsreport: VisitorsReportReducer,
    empreport: EmployeesReportReducer,
    sosAlert: SOSalertReducer,
    preInvitaions: PreInvitationReducer,
    holidays: HolidaysReducer,
    feedback: FeedbackReducer,
    checkin: ClockinReducer,
    integrations: IntegrationReducer,
    feedbackreport: FeedbackReportReducer,
    notices: Notices,
    unknownVisitors: UnknownVisitorsReducer,
  },
  devTools: true,
});

export type StoreDef = ReturnType<typeof store.getState>;
