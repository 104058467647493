import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";

import {
  getVisitors,
  getinvitedVisitors,
  getVisitorsFeedbackList,
  getSelectedVisitorList,
} from "../../services/visitors";

const today = new Date();
const todayFormat = `${today?.getFullYear()}-${
  today?.getMonth()! + 1
}-${today?.getDate()}`;
const initialState: VisitorListState = {
  list: [],
  preInviteList: [],
  selectedVisitorData: {},
  feedbackList: [],
  date: todayFormat,
  isOpenFilter: false,
  error: null,
  status: "idle",
  selectedVisitorId: "",
  matrix: {
    clocked_in: 0,
    clocked_out: 0,
    not_normal: 0,
  },
  feedbackMatrix: {
    all: 0,
    bad: 0,
    excellent: 0,
    good: 0,
    okay: 0,
    worst: 0,
  },
  filterMeta: {
    pageIndex: 0,
    hasNext: false,
  },
  total: 0,
};

export const getVisitorsData = createAsyncThunk<
  Dictionary,
  {
    from_date: string;
    to_date: string;
    page_size: number;
    pageIndex: number;
    search?: string;
    temperature?: string;
    purpose?: string;
    status?: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_visitorlist",
  async (
    {
      from_date,
      to_date,
      page_size,
      pageIndex,
      search,
      temperature,
      purpose,
      status,
    },
    thunkAPI
  ) => {
    try {
      const response: any = await getVisitors(
        from_date,
        to_date,
        page_size,
        pageIndex,
        search,
        temperature,
        purpose,
        status
      );

      return {
        pageIndex,
        list: response.data.data,
        hasNext: response.hasNext,
        matrix: response.data.counts,
        total: response.data.total,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const getPreInviteVisitorsData = createAsyncThunk<
  Dictionary,
  {
    from_date: string;
    to_date: string;
    page_size: number;
    pageIndex: number;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_pre_invite_visitorlist",
  async ({ from_date, to_date, page_size, pageIndex }, thunkAPI) => {
    try {
      const response: any = await getinvitedVisitors(
        from_date,
        to_date,
        page_size,
        pageIndex
      );

      return {
        pageIndex,
        preInviteList: response.data.data,
        hasNext: response.data.hasNext,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const getVisitorsFeedbackData = createAsyncThunk<
  Dictionary,
  {
    from_date: string;
    to_date: string;
    page_size: number;
    pageIndex: number;
    rate: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_visitor_feedback_list",
  async ({ from_date, to_date, page_size, pageIndex, rate }, thunkAPI) => {
    try {
      const response: any = await getVisitorsFeedbackList(
        from_date,
        to_date,
        page_size,
        pageIndex,
        rate
      );

      return {
        pageIndex,
        feedbackList: response.data.data,
        hasNext: response.data.hasNext,
        feedbackMatrix: response.data.counts,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

export const fetchSelectedVisitorData = createAsyncThunk<
  Dictionary,
  {
    invitation_id: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_selected_visitor__data",
  async ({ invitation_id }, thunkAPI) => {
    try {
      const response: any = await getSelectedVisitorList(invitation_id);
      return {
        selectedVisitorData: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const visitorSlice = createSlice({
  name: "visitors",
  initialState: initialState,
  reducers: {
    toggleFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setVisitorId: (state, action) => {
      state.selectedVisitorId = action.payload._id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVisitorsData.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getVisitorsData.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = null;
      state.selectedVisitorId = action.payload._id;
      // if (action.payload?.pageIndex === 0) {
      state.list = [...action.payload?.list];
      //   state.filterMeta.pageIndex = 0;
      // } else {
      //   state.list = [...state.list, action.payload?.list];
      // }
      state.filterMeta.hasNext = action.payload?.hasNext;
      state.matrix = action.payload.matrix;
      state.total = action.payload?.total;
    });
    builder.addCase(getVisitorsData.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
    });

    builder.addCase(getPreInviteVisitorsData.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getPreInviteVisitorsData.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = null;
      state.preInviteList = [...action.payload?.preInviteList];
      state.filterMeta.hasNext = action.payload?.hasNext;
    });
    builder.addCase(getPreInviteVisitorsData.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
    });

    builder.addCase(getVisitorsFeedbackData.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getVisitorsFeedbackData.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = null;
      state.feedbackList = [...action.payload?.feedbackList];
      state.filterMeta.hasNext = action.payload?.hasNext;
      state.feedbackMatrix = action.payload?.feedbackMatrix;
    });
    builder.addCase(getVisitorsFeedbackData.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
    });
    builder.addCase(fetchSelectedVisitorData.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchSelectedVisitorData.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = null;
      state.selectedVisitorData = [...action.payload?.selectedVisitorData];
    });
    builder.addCase(fetchSelectedVisitorData.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload!.response!.data!.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.visitors;

export const fetchStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);

export const preInvitationVisitorList = createDraftSafeSelector(
  selfSelect,
  (state) => state.preInviteList
);
export const feedbackListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => {
    return state.feedbackList;
  }
);
export const feedbackMatrixCountSelector = createDraftSafeSelector(
  selfSelect,
  (state) => {
    return state.feedbackMatrix;
  }
);

export const visitorListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const DateSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.date
);
export const idSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.selectedVisitorId
);
export const matrixCountSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.matrix
);
export const totalRecordsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.total
);
export const payloadSelector = createDraftSafeSelector(selfSelect, (state) => {
  return {
    page_size: 25,
    pageIndex: state.filterMeta.pageIndex,
  };
});

export const getSelectedVisitorData = createDraftSafeSelector(
  selfSelect,
  (state) => state.selectedVisitorData
);

export const { setDate, setVisitorId } = visitorSlice.actions;

export default visitorSlice.reducer;
