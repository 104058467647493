import { Tooltip } from "@mui/material";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

type FullscreenButtonProps = {
  tableFullScreen: boolean;
  onTableFUllScreen: () => void;
};

function FullscreenButton(props: FullscreenButtonProps) {
  return props.tableFullScreen ? (
    <Tooltip
      title={"Exit Fullscreen"}
      placement="bottom"
      color="secondary"
      arrow
    >
      <button className="export-btn" onClick={() => props.onTableFUllScreen()}>
        <FullscreenExitIcon />
      </button>
    </Tooltip>
  ) : (
    <Tooltip title={"Fullscreen"} placement="bottom" color="secondary" arrow>
      <button className="export-btn" onClick={() => props.onTableFUllScreen()}>
        <FullscreenIcon />
      </button>
    </Tooltip>
  );
}

export default FullscreenButton;
