import "./matrixIconPanel.scss";
import React from "react";

const MatrixIconPanel = (props: any) => {
  return (
    <>
      <div className="matrix-icon d-flex align-items-center">
        <div className="matrix-info d-flex justify-content-between align-items-center">
          <div className={`icon ${props.iClass}`}>{props.children}</div>
        </div>
        <div className="matrix-count pe-2">
          <p className="mb-0">{props.count}</p>
        </div>
        {!props.islastChild && <div className="vertical-line ms-2" />}
      </div>
    </>
  );
};

export default MatrixIconPanel;
