import "./sidepanel.scss";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";

type SidePanelDrawerProps = {
  isOpen: boolean;
  isFullScreen?: boolean;
  handleCloseClick: () => void;
  children: React.ReactNode;
  className?: string;
};

function SidePanelDrawer(props: SidePanelDrawerProps) {
  return (
    <div
      className={classnames("side-drawer d-flex flex-column align-items-end", {
        opened: props.isOpen,
        "full-screen": props.isFullScreen,
      })}
    >
      <div className="panel-wrapper shadow">
        <button
          onClick={props.handleCloseClick}
          className="panel-close shadow-sm"
        >
          <CloseIcon />
        </button>
        <div className={classnames("panel-content", props.className)}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default SidePanelDrawer;
