import "./sos.scss";
// import CloseIcon from "@material-ui/icons/Close";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import { toggleSOS } from "@root/actions/sidebar";
import { useDispatch, useSelector } from "react-redux";
import OTPInput from "@root/components/OtpInput";
import { toggleGlSidePanel } from "@root/actions/metadata";
import { useState } from "react";
import { currentUser } from "@root/actions/header";
import {
  getSOSotp,
  validateSOSotp,
  isSOSEnabledStatus,
} from "@root/actions/sosAlert";
import { showNotificationError } from "@root/actions/toast-notification";
import { useUnmount, useUpdateEffect } from "react-use";

import React from "react";

const SosPanel = (props: any) => {
  // const isOpenSOS = useSelector(sosStatusSelector);
  const curUser = useSelector(currentUser);
  const dispatch = useDispatch();
  const [otpfield, setOtpField] = useState(false);
  // const [ disable, setDisable]= useState(false);
  const [otp, setOtp] = useState("");
  const sosEnabled = useSelector(isSOSEnabledStatus);

  const handleSOSClose = () => {
    dispatch(toggleSOS(false));
    setOtpField(false);
    // setDisable(true)
  };

  useUnmount(() => {
    handleSOSClose();
  });

  const handleOtpInput = (val: any) => {
    setOtp(val);
  };

  const handleOtpField = (event: any) => {
    event.preventDefault();
    setOtpField(true);
    if (curUser) {
      dispatch(getSOSotp({ phone: curUser?.phone }));
    }
  };

  const enableSOSNow = () => {
    if (otp.length === 5) {
      dispatch(validateSOSotp({ otp }));
    } else {
      dispatch(
        showNotificationError({ type: "error", message: "Enter valid otp" })
      );
    }
  };

  useUpdateEffect(() => {
    if (sosEnabled) {
      handleSOSClose();
    }
  }, [sosEnabled]);

  return (
    <div className="sos-container">
      <div className="sos-header">
        <div className="flex-grow-1 text-center">
          <h6>SOS / Fire Alarm Alert</h6>
        </div>
        {/* <div>
          <button className="sos-close"  onClick={handleSOSClose}>
            <CloseIcon />
          </button>
        </div> */}
      </div>
      <div className="sos-content">
        <div className="sos-fire mb-3">
          <FireExtinguisherIcon />
        </div>
        <div>
          <p className="text-danger">
            Please make sure on valid SOS, Fire or any emergency event. We
            request you NOT to send any FALSE alarm which will impact on
            employee, visitors safety &amp; security.
          </p>
        </div>
        <div className="sos-password pt-4">
          <button
            className={`btn sos-button w-100 mb-3`}
            disabled={otpfield ? true : false}
            onClick={handleOtpField}
          >
            Request OTP to Send SOS Alert
          </button>

          {otpfield ? (
            <div className="mt-4 pb-4">
              <p>
                Sent you 5 digit OTP to your mobile number ending with x
                {`${curUser?.phone}`.slice(8)}
              </p>
              <div className="form-group otp-input mb-4 ">
                <OTPInput
                  autoFocus
                  isNumberInput
                  length={5}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={(otp) => handleOtpInput(otp)}
                  placeholder={"⬤"}
                />
              </div>

              <button
                onClick={enableSOSNow}
                className="btn sos-button w-100 mb-3"
              >
                Confirm &amp; Send SOS Alert
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SosPanel;
