import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { StoreDef } from "@root/store";
import { getAttendance } from "@root/services/attendance";

const today = new Date();
const todayFormat = `${today?.getFullYear()}-${
  today?.getMonth()! + 1
}-${today?.getDate()}`;
const initialState: AttendanceListState = {
  isOpenFilter: false,
  isOpenPanel: false,
  isFullScreen: false,

  error: null,
  list: [],
  startDate: todayFormat,
  filterData: {
    status: "all",
    temperature: "all",
    time: "all",
    workType: "all",
    only_clockins: false,
  },
  matrix: {
    clocked_in: 0,
    yet_to_clock_in: 0,
    clocked_out: 0,
    yet_to_clock_out: 0,
    normal: 0,
    not_normal: 0,
    wfh: 0,
    wfo: 0,
    on_time: 0,
    late: 0,
  },
  status: "idle",
  filteredList: [],
  filterMeta: {
    search: "",
    pageIndex: 0,
    hasNext: false,
  },
  total: 0,
};

export const getAttendanceList = createAsyncThunk<
  Dictionary,
  {
    from_date: string;
    to_date: string;
    page_size: number;
    page_index: number;
    search?: string;
    temperature?: string;
    only_clockins?: boolean;
    on_time?: string;
    mode?: string;
    status?: string;
  },
  { rejectValue: ResponseError }
>(
  "@admin/fetch_attendance",
  async (
    {
      from_date,
      to_date,
      page_size,
      page_index,
      search,
      temperature,
      only_clockins,
      on_time,
      mode,
      status,
    },
    thunkAPI
  ) => {
    try {
      const response: any = await getAttendance(
        from_date,
        to_date,
        page_size,
        page_index,
        search,
        temperature,
        only_clockins,
        on_time,
        mode,
        status
      );
      const errMsg: any = response.data;
      return response.status === 200
        ? {
            page_index,
            list: response.data.data,
            counts: response.data.counts,
            hasNext: response.hasNext,
            total: response.data.total,
            status: true,
          }
        : { status: false, error: errMsg };
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  }
);

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: initialState,
  reducers: {
    toggleFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    togglePanel: (state) => {
      state.isOpenPanel = !state.isOpenPanel;
    },
    closeFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },
    closePanel: (state, action) => {
      state.isOpenPanel = action.payload;
    },
    toggleFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    toggleClockin: (state, action) => {
      state.filterData.only_clockins = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAttendanceList.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getAttendanceList.fulfilled, (state, action) => {
      state.status = "idle";
      state.error = action.payload.error?.error!;
      state.list = [...action.payload?.list];
      state.matrix = action.payload.counts;
      state.filterMeta.hasNext = action.payload?.hasNext;
      state.total = action.payload?.total;
    });
    builder.addCase(getAttendanceList.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload?.response?.data?.error || "something is wrong";
    });
  },
});

const selfSelect = (state: StoreDef) => state.attendance;

export const filterStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.isOpenFilter
);
export const fetchStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.status
);
export const panelStatusSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.isOpenPanel
);
export const attendanceListSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.list
);
export const startDateSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.startDate
);
export const countMatSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.matrix
);

export const clockinSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterData.only_clockins
);

export const totalRecordsSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.total
);
export const fullScreenStatus = createDraftSafeSelector(
  selfSelect,
  (state) => state.isFullScreen
);

export const filterDataSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.filterData
);

export const errorSelector = createDraftSafeSelector(
  selfSelect,
  (state) => state.error
);

export const payloadSelector = createDraftSafeSelector(selfSelect, (state) => {
  return {
    only_clockins: state.filterData.only_clockins,
    page_size: 25,
    page_index: state.filterMeta.pageIndex,
  };
});

export const {
  toggleFilter,
  togglePanel,
  // setStartDate,
  setFilterData,
  closePanel,
  closeFilter,
  toggleClockin,
  toggleFullScreen,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
