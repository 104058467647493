import "./buttonspinner.scss";
import React from "react";

const Buttonspinner = () => {
  return (
    <>
      <div className="buttonspinner-container d-flex justify-content-center align-items-center">
        <div className="buttonspinner"></div>
      </div>
    </>
  );
};

export default Buttonspinner;
