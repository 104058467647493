import "./personCard.scss";
import React from "react";

const PersonCard = (props: any) => {
  const person = props.person;

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);

    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  return (
    <div className="col person-card">
      <div className="mb-3">
        <div className="person-card-header">
          <div className="highlighter"></div>
          <div
            style={{ position: "relative" }}
            className="d-flex justify-content-center"
          >
            <div className="profile-picture">
              {props.type === "visitor" ? (
                <img
                  src={
                    person?.visitor.image !== undefined
                      ? person?.visitor.image
                      : "../../../../assests/images/user.png"
                  }
                  alt="profile"
                />
              ) : (
                <img
                  src={
                    person?.image !== undefined
                      ? person?.image
                      : "../../../../assests/images/user.png"
                  }
                  alt="profile"
                />
              )}
            </div>
          </div>

          <div className="person-card-content p-3">
            {props.type === "visitor" ? (
              <>
                <div className="">
                  <h6 className="text-center mb-0">{`${person?.visitor.name}`}</h6>
                  <p className="text-center mb-0">{`+${person?.visitor.phone.slice(
                    0,
                    2
                  )} ${person?.visitor.phone.slice(
                    2,
                    7
                  )} ${person?.visitor.phone.slice(7)}`}</p>
                  <p className="text-center checkin-status">
                    {person?.clocked_out_at ? "Clockedout" : "Clockedin"}
                  </p>
                </div>
                <div>
                  <div className="fb-row d-flex">
                    <div className="first-child">
                      <p className="fb-title mb-2">Temperature</p>
                      <p className="">
                        {person?.temperature ? (
                          person.temperature.measure === "F" ? (
                            <p
                              className={`mb-0 line-height1 ${
                                person?.temperature.value > 99
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >{`${person?.temperature.value}${"\u00b0"} ${
                              person?.temperature.measure
                            }`}</p>
                          ) : (
                            <p
                              className={`mb-0 line-height1 ${
                                person?.temperature.value > 37.2
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >{`${person?.temperature.value}${"\u00b0"} ${
                              person?.temperature.measure
                            }`}</p>
                          )
                        ) : (
                          "--"
                        )}
                      </p>
                    </div>
                    <div className="">
                      <p className="fb-title mb-2">Purpose Of Visit</p>
                      <p className="">
                        {person?.purpose ? person?.purpose : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="fb-row d-flex">
                    <div className="first-child">
                      <p className="fb-title mb-2">In-Time</p>
                      <p className="">
                        {person?.clocked_in_at
                          ? getTimeFormat(person?.clocked_in_at)
                          : "--"}
                      </p>
                    </div>
                    <div className="">
                      <p className="fb-title mb-2">Out-Time</p>
                      <p className="">
                        {person?.clocked_out_at
                          ? getTimeFormat(person?.clocked_out_at)
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="">
                  <h6 className="text-center mb-0">{`${person?.first_name} ${person?.last_name}`}</h6>
                  <p className="text-center mb-0">{`${person?.description}`}</p>
                  <p className="text-center checkin-status">
                    {person?.checkin
                      ? person?.checkin?.clocked_out_at
                        ? "Clockedout"
                        : "Clockedin"
                      : "--"}
                  </p>
                </div>
                <div>
                  <div className="fb-row d-flex">
                    <div className="first-child">
                      <p className="fb-title mb-2">Temperature</p>
                      <p className="">
                        {person?.checkin?.temperature ? (
                          person?.checkin?.temperature.measure === "F" ? (
                            <p
                              className={`mb-0 line-height1 ${
                                person?.checkin.temperature.value > 99
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >{`${
                              person?.checkin?.temperature.value
                            }${"\u00b0"} ${
                              person?.checkin?.temperature.measure
                            }`}</p>
                          ) : (
                            <p
                              className={`mb-0 line-height1 ${
                                person?.checkin?.temperature.value > 37.2
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >{`${
                              person?.checkin?.temperature.value
                            }${"\u00b0"} ${
                              person?.checkin?.temperature.measure
                            }`}</p>
                          )
                        ) : (
                          "--"
                        )}
                      </p>
                    </div>
                    <div className="">
                      <p className="fb-title mb-2">Mode Of Work</p>
                      <p className="">
                        {person?.checkin ? person?.checkin.mode.clockin : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="fb-row d-flex">
                    <div className="first-child">
                      <p className="fb-title mb-2">In-Time</p>
                      <p className="">
                        {person?.checkin
                          ? getTimeFormat(person?.checkin.clocked_in_at)
                          : "--"}
                      </p>
                    </div>
                    <div className="">
                      <p className="fb-title mb-2">Out-Time</p>
                      <p className="">
                        {person?.checkin && person?.checkin.clocked_out_at
                          ? getTimeFormat(person?.checkin.clocked_out_at)
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="d-flex justify-content-center">
              <button className="grid-action" onClick={props.action}>
                {" "}
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
