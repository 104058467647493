import { closeFilter, closePanel } from "@root/actions/attendance";
import {
  currentUser,
  setStartDate,
  startDateStringSelector,
} from "@root/actions/header";
import {
  setTitle,
  updateDatePickerStatus,
  updateMapviewSwitchStatus,
} from "@root/actions/metadata";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUnmount, useUpdateEffect } from "react-use";
import "./myAttendance.scss";
import { AgGridReact } from "ag-grid-react";
import {
  employeeDataSelector,
  getAttendaceByID,
} from "@root/actions/getEmployee";
import Spinner from "@root/components/Spinner/Spinner";
import { Tooltip } from "@mui/material";
import React from "react";
import InfoAlert from "@root/components/InfoAlert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CAlert from "@root/components/CAlert";
import FullscreenButton from "@root/components/FullscreenButton";

const MyAttendance = () => {
  const meUser = useSelector(currentUser);
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState<any>();
  const startDate = useSelector(startDateStringSelector);
  const employeeData = useSelector(employeeDataSelector);
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const coldefss = [
    { field: "_type", headerName: "Activity" },
    { field: "_time", headerName: "Time" },
    { field: "_temp", headerName: "Temperature" },
    { field: "_mode", headerName: "Work Mode" },
    {
      field: "",
      headerName: "",
      // width: 80,
      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];
  const [colDefs, setColDefs] = useState(coldefss);

  const onTableFUllScreen = () => {
    setTableFullScreen(!tableFullScreen);
  };

  useEffect(() => {
    dispatch(setTitle("My Attendance"));
    dispatch(updateDatePickerStatus(true));
    // dispatch(closePanel(false));
    // dispatch(closeFilter(false));
    const today = new Date();
    dispatch(setStartDate(today.toString()));
  }, []);

  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);
    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  useEffect(() => {
    if (meUser._id)
      dispatch(getAttendaceByID({ _id: meUser._id, _date: startDate }));
  }, [meUser._id, startDate]);

  const onBtnExport = () => {
    // @ts-ignore
    gridApi.exportDataAsCsv();
  };

  const getWorkMode = (_mode: string) => {
    let mode;
    switch (_mode) {
      case "WFO":
        mode = "Work From Office";
        break;
      case "WFH":
        mode = "Work From Home";
        break;
      default:
        mode = "By Viba";
        break;
    }
    return mode;
  };

  useUpdateEffect(() => {
    const _data: any[] = [];
    employeeData.activities.forEach((el) => {
      const obj = [
        {
          _id: el._id,
          _type: "Clockedout",
          _mode: getWorkMode(el.mode.clockout),
          _time: getTimeFormat(el.clocked_out_at),
          _temp: "--",
        },
        {
          _id: el._id,
          _type: "Clockedin",
          _mode: getWorkMode(el.mode.clockin),
          _time: getTimeFormat(el.clocked_in_at),
          _temp: el.temperature
            ? `${el.temperature.value}${"\u00b0"} ${el.temperature.measure}`
            : "--",
        },
      ];
      _data.push(...obj);
    });
    const activities = _data.filter((el) => el._time !== undefined);
    setRowData(activities);
    setColDefs(coldefss);
  }, [employeeData, tableFullScreen]);

  useUnmount(() => {
    dispatch(updateDatePickerStatus(false));
    dispatch(closeFilter(false));
    dispatch(closePanel(false));
  });
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      {rowData?.length > 0 && (
        <div className="h-100 pos-relative p-3">
          <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%", position: "relative" }}
          >
            <AgGridReact
              reactUi={true}
              rowClass={"minin-class"}
              pagination={false}
              rowData={rowData}
              rowHeight={100}
              rowSelection={"single"}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              suppressLoadingOverlay={true}
              defaultColDef={{
                flex: 1,
              }}
            ></AgGridReact>
          </div>
        </div>
      )}
      {rowData?.length < 1 && (
        <div className="h-full bg-white p-4">
          <CAlert type="info">
            No attendance found. Please try with another date.
          </CAlert>
        </div>
      )}
      {!rowData ? (
        <div className="loader-container">
          <Spinner />
        </div>
      ) : null}
    </>
  );
};

export default MyAttendance;
