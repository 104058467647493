import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CloseIcon from "@material-ui/icons/Close";
import Select, { StylesConfig } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { togglebar, toggleStatus } from "@actions/sidebar";

import InfoIcon from "@mui/icons-material/Info";
import Buttonspinner from "@root/components/buttonspinner";

import {
  titleSelector,
  datePickerStatusSelector,
  showEmpListFilterSelector,
  setEmpListFilterValue,
  empListfilterValueSelector,
  sendLinkStatusSelector,
  reportBtnStatusSelector,
  userGeoLocation,
  setUserLocation,
} from "@actions/metadata";

import {
  checkinByDate,
  userCheckin,
  clockinLoading,
  checkIfOutsideOrganization,
  isOutsedeOrg,
  userClockinRequest,
  userClockoutRequest,
  orgLoading,
  clockinSuccess,
  clockoutSuccess,
} from "@actions/globalClockin";
// import { useState } from "react";
// import { countSelector } from "@root/actions/employee-list";
import DateSelect from "../DateSelect";
import {
  currentUser,
  startDateStringSelector,
  toggleOpenAppLink,
} from "@root/actions/header";
import { toggleGlSidePanel } from "@root/actions/metadata";
import { togglePanel } from "@root/actions/attendance";
// import { FormControlLabel, FormGroup } from "@mui/material";
// import { styled } from '@mui/system';
// import Stack from '@mui/material/Stack';
// import SwitchUnstyled from '@mui/base/SwitchUnstyled';
import "./header.scss";
import { useEffect, useState } from "react";
import { showNotificationError } from "@root/actions/toast-notification";
import { useUpdateEffect } from "react-use";
import ProfileDropdown from "../ProfileDropdown";
const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const Header = (props: any) => {
  const isOpen = useSelector(toggleStatus);
  const title = useSelector(titleSelector);
  const showPicker = useSelector(datePickerStatusSelector);
  const showLink = useSelector(sendLinkStatusSelector);
  const showDropdown = useSelector(showEmpListFilterSelector);
  const showReportBtn = useSelector(reportBtnStatusSelector);
  // const count = useSelector(countSelector);
  const dispatch = useDispatch();
  const empListfilterValue = useSelector(empListfilterValueSelector);
  const brandlogo = useSelector(currentUser);
  const userLocation = useSelector(userGeoLocation);
  const [showCModal, setshowCModal] = useState(false);
  const startDate = useSelector(startDateStringSelector);
  const checkinObj = useSelector(userCheckin);
  const [checkinType, setcheckinType] = useState<"in" | "out">("in");
  const isLoading = useSelector(clockinLoading);
  const checkingOutsideOrg = useSelector(orgLoading);
  const isUseOutsideOrg = useSelector(isOutsedeOrg);
  const isClockinSuccess = useSelector(clockinSuccess);
  const isClockoutSuccess = useSelector(clockoutSuccess);
  const [showSuccess, setshowSuccess] = useState(false);
  // const history = useHistory();
  // const [isModalClosed, setisModalClosed] = useState(false);
  // const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };

  const dropdown = [
    { value: "all", label: "All" },
    { value: "approved", label: "Active" },
    { value: "rejected", label: "Inactive" },
    { value: "in-review", label: "New" },
    { value: "draft", label: "Draft" },
  ];
  const pageCount = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 12, label: 12 },
  ];
  const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: "#000" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        minWidth: "200px",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#000",
        color: isDisabled ? "#fff" : isSelected ? "#fff" : "#fff",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#000"
              : "#000"
            : undefined,
          color: "#fff",
        },
      };
    },
    input: (styles) => ({ ...styles, color: "#fff", minWidth: "150px" }),
    placeholder: (styles) => ({ ...styles, color: "#fff" }),
    singleValue: (styles, { data }) => ({ ...styles, color: "#fff" }),
    menu: (styles) => ({ ...styles, backgroundColor: "#000" }),
  };

  const getTodayDateInfo = () => {
    const today = new Date();
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${days[today.getDay()]} ${today.getDate()}, ${
      months[today.getMonth()]
    } ${today.getFullYear()}`;
  };

  // uncomment this code for location

  useEffect(() => {
    if (userLocation.lng === 0 && userLocation.lng === 0) {
      askPermissions();
    }
  }, [userLocation]);

  useUpdateEffect(() => {
    setshowSuccess(true);
  }, [isClockinSuccess, isClockoutSuccess]);

  useUpdateEffect(() => {
    if (!showCModal) setshowSuccess(false);
  }, [showCModal]);

  useEffect(() => {
    dispatch(checkinByDate({ date: startDate }));
  }, []);

  useEffect(() => {
    if (checkinObj === null) {
      setcheckinType("in");
    } else {
      if (checkinObj?.clocked_out_at === null) {
        setcheckinType("out");
      } else {
        setcheckinType("in");
      }
    }
  }, [checkinObj]);

  const clockinClockout = () => {
    setshowCModal(true);
    if (checkinType === "in") {
      // setshowCModal(true);
      if (userLocation.lng !== 0 && userLocation.lng !== 0) {
        // const latlng = {lat: 17.422286, lng: 78.5939449};
        // dispatch(checkIfOutsideOrganization({geo_location: latlng}));
        dispatch(checkIfOutsideOrganization({ geo_location: userLocation }));
      }
    }
  };

  const doClockin = () => {
    if (userLocation.lng !== 0 && userLocation.lng !== 0) {
      dispatch(userClockinRequest({ geo_location: userLocation }));
      // dispatch(userClockinRequest({geo_location: {lat: 17.422286, lng: 78.5939449}}));
    }
  };

  const doClockout = () => {
    if (userLocation.lng !== 0 && userLocation.lng !== 0) {
      dispatch(userClockoutRequest({ geo_location: userLocation }));
      // dispatch(userClockoutRequest({geo_location: {lat: 17.422286, lng: 78.5939449}}))
    }
  };

  const requestClockinClockout = () => {
    if (checkinType === "in") {
      doClockin();
    } else {
      doClockout();
    }
  };

  const handleChange = (event: any) => {
    dispatch(setEmpListFilterValue(event.value));
  };

  const askPermissions = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "notifications" }).then(() => {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition((pos: any) => {
              let crd = pos.coords;
              dispatch(
                setUserLocation({ lat: crd.latitude, lng: crd.longitude })
              );
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (pos: any) => {
                let crd = pos.coords;
                dispatch(
                  setUserLocation({ lat: crd.latitude, lng: crd.longitude })
                );
              },
              (err: any) => {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                dispatch(setUserLocation({ lat: 0, lng: 0 }));
              },
              options
            );
          } else if (result.state === "denied") {
            dispatch(setUserLocation({ lat: 0, lng: 0 }));
            dispatch(
              showNotificationError({
                type: "error",
                message:
                  "As part of our compliance, you must accept below mentioned permissions to continue.",
              })
            );
          }
          result.onchange = function () {
            if (result.state === "granted") {
              navigator.geolocation.getCurrentPosition((pos: any) => {
                let crd = pos.coords;
                dispatch(
                  setUserLocation({ lat: crd.latitude, lng: crd.longitude })
                );
              });
            } else {
              dispatch(setUserLocation({ lat: 0, lng: 0 }));
            }
          };
        });
      });
    } else {
      alert("Sorry Not available!");
    }
  };

  const sendAppLink = () => {
    dispatch(toggleGlSidePanel(true));
    dispatch(toggleOpenAppLink(true));
  };

  // helper function to get label
  const getLable = (val: any) => {
    const el = dropdown.find((el) => el.value === val);

    return `${el?.label.charAt(0).toUpperCase()}${el?.label.slice(1)}`;
  };

  return (
    <>
      <div className="app-header d-flex align-items-center p-2">
        <div className="flex-grow-1 d-flex align-items-center">
          <button className="toggler" onClick={() => dispatch(togglebar())}>
            {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </button>
          &nbsp;
          <div className="page-title">
            <p className="mb-0">{title}</p>
          </div>
          {showDropdown && brandlogo?.role ? (
            <div>
              {brandlogo?.role !== "employee" && (
                <Select
                  className="drop-select"
                  styles={colourStyles}
                  options={dropdown}
                  isSearchable={false}
                  value={{
                    value: `${empListfilterValue}`,
                    label: getLable(empListfilterValue),
                  }}
                  onChange={handleChange}
                />
              )}
            </div>
          ) : null}
          {showPicker ? (
            <div>
              <DateSelect selected={new Date()} />
            </div>
          ) : null}
          {/* {showLink && brandlogo?.role ? (
            <div className="pl-3">
              {brandlogo?.role !== "employee" && (
                <button
                  className="btn btn-outline-dark sendapp-link"
                  onClick={sendAppLink}
                >
                  <PhoneIphoneIcon /> Send app link
                </button>
              )}
            </div>
          ) : null} */}
          {showReportBtn && (
            <div className="pl-3">
              <button
                className="btn btn-gradient"
                onClick={() => dispatch(togglePanel())}
              >
                Generate New Report
              </button>
            </div>
          )}
        </div>
        <div className="flex-grow-1 d-flex justify-content-end align-items-center">
          {/* <div className="user-brand">
            {brandlogo?.account?.brand_logo ?
            <img
              //@ts-ignore
              src={brandlogo?.account?.brand_logo}
              alt="brand log0"
            />
            : null}
          </div> */}

          <ProfileDropdown user={brandlogo} />
        </div>

        {/* clockin module $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$--clockin module--$$$$$$$$$$$$$$$$$$$$$$$$$ */}

        {brandlogo.account.subscription === "visitors_only" ? null : (
          <>
            {" "}
            <button onClick={clockinClockout} className="btn global-clockin">
              <div className={`d-flex c${checkinType}`}>
                <ArrowRightAltIcon />
                <p className="mb-0">
                  {checkinType === "in" ? "Clockin" : "Clockout"}
                </p>
              </div>
            </button>
            <div
              className={`global-clockin-modal ${
                showCModal ? "open" : "closed"
              }`}
            >
              <button
                onClick={() => setshowCModal(false)}
                className="panel-close shadow-sm"
              >
                <CloseIcon />
              </button>
              {checkingOutsideOrg === "loading" ? (
                <Buttonspinner />
              ) : (
                <div>
                  {!showSuccess && (
                    <>
                      <h6 className="text-white">{getTodayDateInfo()}</h6>
                      <p className="work-from">
                        {isUseOutsideOrg
                          ? "Work From Home"
                          : "Work From Office"}
                      </p>
                    </>
                  )}
                  {!isUseOutsideOrg &&
                  checkinType === "in" &&
                  !isClockoutSuccess ? (
                    <div className="d-flex align-items-center pt-4 text-white">
                      <InfoIcon />
                      &nbsp;&nbsp;
                      <p style={{ fontSize: "16px" }} className="mb-0">
                        You're in office. Please Clockin at Kiosk.
                      </p>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center pt-4">
                      {/* <p>{`Clockin: ${isClockinSuccess} --- Clockout: ${isClockoutSuccess}`}</p> */}

                      {!showSuccess && (
                        <button
                          className="btn btn-clockin btn-gradient"
                          onClick={requestClockinClockout}
                        >
                          {isLoading === "loading" ? (
                            <Buttonspinner />
                          ) : (
                            `${checkinType === "in" ? "Clockin" : "Clockout"}`
                          )}
                        </button>
                      )}

                      {showSuccess && (
                        <div className="d-flex flex-column">
                          <img
                            style={{ maxWidth: "200px", marginTop: "-30px" }}
                            src="../../../../assests/images/Tick-Main_08_t.gif"
                            alt="icon"
                          />
                          {(isClockoutSuccess || isClockinSuccess) && (
                            <p className="mb-0 text-center text-white">
                              Successfully{" "}
                              {isClockoutSuccess ? "Clockedout" : "Clockedin"}.
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {/* clockin module $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$--clockin module--$$$$$$$$$$$$$$$$$$$$$$$$$ */}
      </div>
    </>
  );
};

export default Header;
