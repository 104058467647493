import { format, isValid } from "date-fns";

export function getPrettyDate(date: string | Date): string {
  if (typeof date === "string") {
    date = new Date(date);
  }

  if (!isValid(date)) {
    return "";
  }

  return format(date, "MMM d, yyyy");
}
