import { titleSelector } from "@root/actions/metadata";
import { useSelector } from "react-redux";

type FullscreentTitleProps = {
  title?: string;
  tableFullScreen: boolean;
};

function FullscreentTitle(props: FullscreentTitleProps) {
  const title = useSelector(titleSelector);

  if (!props.tableFullScreen) {
    return null;
  }

  return (
    <div className="bg-white">
      <h1 className="p-4 text-lg font-semibold">{props.title || title}</h1>
    </div>
  );
}

export default FullscreentTitle;
