import axios from "axios";

export async function getEmployeelist(
  pageIndex: number,
  pageSize: number,
  status: any,
  search?: string
) {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/users/employee-dashboard`,
    params: {
      page_size: pageSize,
      page_index: pageIndex,
      status,
      search,
    },
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
}
