import React from "react";
import ReactDOM from "react-dom";
import "@root/designTokens/css-variables.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Routes from "./routes/Routes";
import { store } from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import setAuthorizationToken, {
  setAxiosInterceptors,
} from "./utils/setAuthToken";
import { loginSuccess, logoutSuccess, setLoadingStatus } from "./actions/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

// import * as PusherPushNotifications from "@pusher/push-notifications-web";

setAxiosInterceptors();
store.dispatch(setLoadingStatus(true));
const token = localStorage.getItem("token");
if (token) {
  setAuthorizationToken(token);
  store.dispatch(loginSuccess({ token: token }));
  // serviceWorker.register();
} else {
  store.dispatch(logoutSuccess());
  // serviceWorker.unregister();
}

// const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
//   url: "https://dev-backend.viba.ai:3000/api/v1/auth/beams-token",
// });

// const beamsClient = new PusherPushNotifications.Client({
//   instanceId: "a7f7fb99-e843-4142-a047-7d8af9bff138"
// });

// beamsClient
//   .start()
//   .then(() => beamsClient.setUserId("61b18c2daf77cf0e7ab56360", beamsTokenProvider))
//   .catch(console.error);

// beamsClient
//   .start()
//   //@ts-ignore
//   .then((beamsClient) => beamsClient?.getDeviceId())
//   .then((deviceId) =>
//     console.log("Successfully registered with Beams. Device ID:", deviceId)
//   )
//   .catch(console.error);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <NiceModal.Provider>
      <QueryClientProvider client={client}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </NiceModal.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();
