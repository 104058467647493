import "./editemployee.scss";
import React from "react";

// import SmsIcon from '@mui/icons-material/Sms';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  RadioGroup,
  TextField,
} from "@material-ui/core";

import { TextField as MuiTextField } from "@mui/material";
import { useForm } from "react-hook-form";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import DatePicker from "@mui/lab/DatePicker";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  empDetailsSelector,
  errorSelector,
  fetchSelectedUserInfo,
  getSelectedEmployeeId,
  hasUpdatedErrorSelector,
  hasUpdatedSelector,
  resetEmpInfo,
  setEmployeeId,
  statusSelector,
  updateSelectedUserInfo,
} from "@root/actions/editEmployee";

// import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";

import { useUpdateEffect } from "react-use";
import StatusLabel from "../StatusLabel";

import Spinner from "../Spinner/Spinner";

import { panelStatusSelector, togglePanel } from "@root/actions/attendance";
import { showNotificationError } from "@root/actions/toast-notification";
import PanelProfile from "../PanelProfile/PanelProfile";
import SendMessage from "../sendMessage";
import { updateMsgFlag } from "@actions/sendMessage";
import { setEmpListFilterValue } from "@root/actions/metadata";

const VibaTextField = styled(MuiTextField)({
  //#FF0062
  "& label.Mui-focused": {
    color: "#FF0062",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FF0062",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.32)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.8)",
    },
    "&.Mui-focused fieldset": {
      borderColor: `#FF0062`,
    },
  },
});

const EditEmployee = (props: any) => {
  const userrole = localStorage.getItem("Role")!;
  const userId = localStorage.getItem("userId")!;
  const isOpen = useSelector(panelStatusSelector);
  const status = useSelector(statusSelector);
  const hasEmployeeUpdated = useSelector(hasUpdatedSelector);
  // const hasErrorOccured = useSelector(hasUpdatedErrorSelector);
  const [msg, setMsg] = useState(false);
  const dispatch = useDispatch();
  const [cancel, setCancel] = useState<boolean | null>(false);
  const [isView, setIsView] = useState<boolean | null>(null);
  const employeeSelectorData = useSelector(empDetailsSelector);
  const selectedEmployeeId = useSelector(getSelectedEmployeeId);
  const isError = useSelector(errorSelector);
  const [isRejected, setIsRejected] = useState(false);
  // const [statusDirty, setStatusDirty] = useState(false);
  const labels = {
    status: "Status",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    joined: "DOJ",
    phone: "Phone",
    description: "Designation",
    department: "Department",
    gender: "",
    dob: "DOB",
    custom_id: "Employee ID",
    reject_reason: "Reject Reason",
  };

  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

  const initialEmpData: EmployeeState = {
    status: "",
    first_name: "",
    last_name: "",
    email: "",
    joined: "",
    phone: "",
    description: "",
    department: "",
    gender: "",
    dob: "",
    custom_id: "",
    _id: "",
    reject_reason: "",
    image: "",
  };

  //field
  const [employeeDetail, setEmployeeDetail] =
    useState<EmployeeState>(initialEmpData);
  const [fieldDisable, setFieldDisable] = useState<boolean>(false);

  const [id, setId] = useState("");

  // const [dob, setDob] = useState<Date | null>(new Date(initialEmpData.dob));
  // const [doj, setDoj] = useState<Date | null>(new Date());

  const methods = useForm({
    reValidateMode: "onBlur",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const onStatusChanged = (event: any) => {
    let target = event.target as HTMLInputElement;
    let updatedEmpData = { ...employeeDetail, status: target.value };
    if (target.value === "rejected") {
      setIsRejected(true);
      // setStatusDirty(true)
    } else {
      setIsRejected(false);
    }
    setEmployeeDetail(updatedEmpData);
  };

  const handleEmployeeDataChange = (event: any, type: string) => {
    let updatedEmpData = { ...employeeDetail, [type]: event.target.value };
    setEmployeeDetail(updatedEmpData);
  };
  const handleDateChange = (event: any, type: string) => {
    // const _date = new Date(event);
    let updatedEmpDate = { ...employeeDetail, [type]: event };

    // //@ts-ignore
    // updatedEmpDate[type] = new Date(event);

    setEmployeeDetail(updatedEmpDate);
  };
  useEffect(() => {
    if (selectedEmployeeId) {
      dispatch(fetchSelectedUserInfo({ id: selectedEmployeeId }));
      setId(selectedEmployeeId);
    }
  }, [selectedEmployeeId]);

  useUpdateEffect(() => {
    setIsRejected(false);
    if (!isOpen) {
      setEmployeeDetail(initialEmpData);
      dispatch(setEmployeeId(""));
      setEmployeeId({ id: "" });
      setMsg(false);
      setInvalidInputs([]);
      setIsRejected(false);
    } else {
      setCancel(false);
    }
  }, [isOpen]);

  useUpdateEffect(() => {
    if (employeeSelectorData) {
      const detail: EmployeeState = {
        status: employeeSelectorData.status,
        first_name: employeeSelectorData.first_name,
        last_name: employeeSelectorData.last_name,
        email: employeeSelectorData.email,
        joined: employeeSelectorData.joined,
        phone: employeeSelectorData.phone,
        description: employeeSelectorData.description,
        department: employeeSelectorData.department,
        gender: employeeSelectorData.gender,
        dob: employeeSelectorData.dob,
        custom_id: employeeSelectorData.custom_id,
        _id: employeeSelectorData._id,
        reject_reason: "",
        image: employeeSelectorData.image,
      };
      setEmployeeDetail({ ...detail });
    }
  }, [employeeSelectorData]);

  useUpdateEffect(() => {
    if (isError) {
      dispatch(showNotificationError({ type: "error", message: isError }));
    }
  }, [isError]);

  useUpdateEffect(() => {
    if (employeeSelectorData?.status) {
      if (["in-review"].includes(employeeSelectorData?.status)) {
        setIsView(false);
        setFieldDisable(true);
      } else if (
        ["approved", "rejected"].includes(employeeSelectorData?.status)
      ) {
        setIsView(true);
        setFieldDisable(false);
      } else {
        setIsView(null);
      }
    }
  }, [employeeSelectorData?.status]);

  const handleEditClick = () => {
    // disabled
    setFieldDisable(true);
    setCancel(true);
  };
  const handleMessageClick = (event: any) => {
    event.preventDefault();
    setMsg(true);
  };
  const handleclosemsgClick = () => {
    setMsg(false);
    dispatch(updateMsgFlag(false));
  };

  const handleCancelClick = (event: any) => {
    event.preventDefault();
    setFieldDisable(false);
    setCancel(false);
    dispatch(togglePanel());
    setIsRejected(false);
    setInvalidInputs([]);
  };
  const handleOkayClick = (event: any) => {
    event.preventDefault();
    dispatch(resetEmpInfo());
    dispatch(togglePanel());
    setIsRejected(false);
    setInvalidInputs([]);
  };
  const handleEmployeeDetailsSubmit = () => {
    setInvalidInputs([]);
    const inval: string[] = [];

    for (const [key, value] of Object.entries(employeeDetail)) {
      if (
        key !== "account_id" &&
        key !== "image" &&
        key !== "reject_reason" &&
        key !== "_id" &&
        key !== "gender"
      ) {
        console.log(`${key} : ${value}`);
        //@ts-ignore
        if (!value) inval.push(labels[key]);
      }
    }

    if (isRejected && !employeeDetail.reject_reason)
      inval.push(labels.reject_reason);

    if (inval.length > 0) {
      dispatch(
        showNotificationError({
          type: "error",
          message: `All fields are mandatory. Please check ${inval.join(", ")}`,
        })
      );
      setInvalidInputs(inval);
    } else {
      dispatch(updateSelectedUserInfo({ ...employeeDetail, id }));
      setCancel(false);
      setIsRejected(false);
    }
  };

  useUpdateEffect(() => {
    if (hasEmployeeUpdated) {
      dispatch(setEmpListFilterValue("approved"));
      dispatch(togglePanel());
    }
  }, [hasEmployeeUpdated]);

  if (status === "loading") {
    return (
      <div className="h-100 w-100 flex items-center justify-center">
        <Spinner isInline />
      </div>
    );
  } else {
    return (
      <>
        <div className="d-flex flex-column h-100 position-relative edit-emp">
          <div className="top-wrapper">
            {/* status label  */}
            <div className="status-label-container d-flex justify-content-center align-items-center">
              {employeeDetail?.status ? (
                <StatusLabel status={employeeDetail.status} />
              ) : null}
            </div>

            {/* title haeder */}
            <div className="d-flex justify-content-between align-items-center title-header py-2">
              <div>
                <p className="font-500 title mb-0">
                  {employeeDetail?.status === "in-review"
                    ? "Take Action"
                    : "View Employee"}
                </p>
              </div>
              <div className="icons-container d-flex">
                {employeeDetail?.description !== "admin" &&
                isView &&
                !cancel ? (
                  <>
                    {["admin", "moderator"].includes(userrole) &&
                    employeeDetail._id !== userId ? (
                      <button
                        type="button"
                        onClick={handleEditClick}
                        className={"btn btn-outline-dark edit-button"}
                      >
                        <EditIcon />
                        &nbsp; Edit
                      </button>
                    ) : null}
                  </>
                ) : null}

                {/* 
                {userrole !== 'employee' ? 
                <>
                

                <a
                  className="icons"
                  href={`tel:${employeeDetail.phone}`}
                  target="__blank"
                >
                  <CallIcon></CallIcon>
                </a>
                </> : null} */}
              </div>
            </div>

            {/* panel-profile  */}
            <div className="d-flex justify-content-center">
              <PanelProfile
                image={employeeDetail.image}
                name={`${employeeDetail.first_name} ${employeeDetail.last_name}`}
                designation={employeeDetail.description}
              />
            </div>
          </div>

          <div className="empl-form flex-grow-1 h-full">
            <form
              // onSubmit={methods.handleSubmit(handleEmployeeDetailsSubmit)}
              method="PUT"
              noValidate
              autoComplete="OFF"
            >
              <p className="mb-2 text-right">
                <span className="text-danger">*</span>All fields are required.
              </p>
              {/* {status: "Status", first_name: "First Name", last_name: "Last Name", email: "Email", joined: "DOJ", phone: "Phone", description: "Designation", department: "Department", gender: "", dob: "DOB", custom_id: "Employee ID", reject_reason: "Reject Reason"} */}
              <div className="row mb-3">
                <div className="col-6 mt-3">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        value={employeeDetail?.status}
                        label="Status"
                        name="status"
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        disabled={!fieldDisable}
                        onChange={onStatusChanged}
                        error={invalidInputs.includes("Status") ? true : false}
                      >
                        <MenuItem value="none">
                          <span className="none">Select</span>
                        </MenuItem>
                        <MenuItem value={"approved"}>
                          <span className="approved">Approve</span>
                        </MenuItem>
                        <MenuItem value={"rejected"}>
                          <span className="rejected">Reject</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="col-6 mt-3">
                  <TextField
                    label="Employee ID"
                    value={employeeDetail?.custom_id}
                    onChange={(e) => handleEmployeeDataChange(e, "custom_id")}
                    variant="outlined"
                    size={"medium"}
                    disabled={!fieldDisable}
                    fullWidth
                    color="secondary"
                    className="input-feild"
                    error={invalidInputs.includes("Employee ID") ? true : false}
                  />
                </div>
              </div>
              {isRejected && (
                <div className="row mb-3">
                  <div className="col mt-3">
                    <TextField
                      label="Reason"
                      value={employeeDetail?.reject_reason}
                      onChange={(e) =>
                        handleEmployeeDataChange(e, "reject_reason")
                      }
                      variant="outlined"
                      className="input-feild"
                      color="secondary"
                      disabled={!fieldDisable}
                      fullWidth
                      error={
                        invalidInputs.includes("Reject Reason") ? true : false
                      }
                    />
                    {/* <TextareaAutosize
                  minRows={4}
                  className="w-100 text-area"
                  placeholder="Start Typing..."
                  value={employeeDetail?.reject_reason}
                  onChange={(e) => handleEmployeeDataChange(e, "reject_reason")} */}
                    {/* /> */}
                  </div>
                </div>
              )}
              <div className="row mb-3">
                <div className="col-6 mt-3">
                  <TextField
                    label="First Name"
                    value={employeeDetail?.first_name}
                    onChange={(e) => handleEmployeeDataChange(e, "first_name")}
                    variant="outlined"
                    className="input-feild"
                    color="secondary"
                    disabled={!fieldDisable}
                    fullWidth
                    error={invalidInputs.includes("First Name") ? true : false}
                  />
                </div>
                <div className="col-6 mt-3">
                  <TextField
                    label="Last Name"
                    value={employeeDetail?.last_name}
                    onChange={(e) => handleEmployeeDataChange(e, "last_name")}
                    variant="outlined"
                    size={"medium"}
                    className="input-feild"
                    disabled={!fieldDisable}
                    color="secondary"
                    fullWidth
                    error={invalidInputs.includes("Last Name") ? true : false}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6 mt-3">
                  <TextField
                    label="Designation"
                    value={employeeDetail?.description}
                    onChange={(e) => handleEmployeeDataChange(e, "description")}
                    variant="outlined"
                    className="input-feild"
                    disabled={!fieldDisable}
                    color="secondary"
                    fullWidth
                    error={invalidInputs.includes("Designation") ? true : false}
                  />
                </div>
                <div className="col-6 mt-3">
                  <TextField
                    label="Department"
                    value={employeeDetail?.department}
                    onChange={(e) => handleEmployeeDataChange(e, "department")}
                    variant="outlined"
                    className="input-feild"
                    disabled={!fieldDisable}
                    color="secondary"
                    fullWidth
                    error={invalidInputs.includes("Department") ? true : false}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6 mt-3 ">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date of birth"
                      value={employeeDetail?.dob}
                      onChange={(e) => handleDateChange(e, "dob")}
                      inputFormat="dd/MM/yyyy"
                      disabled={!fieldDisable}
                      className="datepicker"
                      renderInput={(params) => (
                        <VibaTextField
                          {...params}
                          error={invalidInputs.includes("DOB") ? true : false}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-6 mt-3 w-6">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date of joining"
                      inputFormat="dd/MM/yyyy"
                      className="datepicker"
                      value={employeeDetail?.joined}
                      onChange={(e) => handleDateChange(e, "joined")}
                      disabled={!fieldDisable}
                      renderInput={(params) => (
                        <VibaTextField
                          {...params}
                          error={invalidInputs.includes("DOJ") ? true : false}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 mt-3">
                  <TextField
                    label="Email"
                    value={employeeDetail?.email}
                    onChange={(e) => handleEmployeeDataChange(e, "email")}
                    variant="outlined"
                    className="email"
                    type="email"
                    disabled={!fieldDisable}
                    fullWidth
                    color="secondary"
                    error={invalidInputs.includes("Email") ? true : false}
                  />
                </div>
              </div>
              <div className="col-12 mt-4 mb-3">
                <TextField
                  label="Phone"
                  value={employeeDetail?.phone}
                  onChange={(e) => handleEmployeeDataChange(e, "phone")}
                  variant="outlined"
                  className="phone"
                  disabled={!fieldDisable}
                  color="secondary"
                  error={invalidInputs.includes("Phone") ? true : false}
                />
              </div>
              <div className="col-12 mt-3 ">
                <FormControl component="fieldset" className="input-feild">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    color="secondary"
                  >
                    <FormControlLabel
                      value="f"
                      checked={employeeDetail?.gender === "f"}
                      onChange={(e) => handleEmployeeDataChange(e, "gender")}
                      control={<Radio />}
                      label="Female"
                      disabled={!fieldDisable}
                    />
                    <FormControlLabel
                      value="m"
                      checked={employeeDetail?.gender === "m"}
                      onChange={(e) => handleEmployeeDataChange(e, "gender")}
                      control={<Radio />}
                      label="Male"
                      disabled={!fieldDisable}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </form>
          </div>
          {isOpen && (
            <div className="button-container d-flex align-items-center justify-content-end">
              {employeeDetail?.description !== "Owner" ? (
                isView ? (
                  <div className="btn-edit">
                    {cancel ? (
                      <>
                        <button
                          type="button"
                          onClick={handleCancelClick}
                          className={"btn btn-outline-dark me-2"}
                        >
                          Cancel
                        </button>

                        <button
                          type="submit"
                          className={"btn btn-gradient"}
                          onClick={methods.handleSubmit(
                            handleEmployeeDetailsSubmit
                          )}
                        >
                          Update
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        onClick={handleOkayClick}
                        className={"btn btn-gradient"}
                      >
                        Okay
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="btn-take-action">
                    <button
                      type="button"
                      className="btn btn-outline-dark me-2"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="btn btn-gradient"
                      onClick={methods.handleSubmit(
                        handleEmployeeDetailsSubmit
                      )}
                    >
                      Update{" "}
                    </button>
                  </div>
                )
              ) : null}
            </div>
          )}
          {/* {msg ? ( */}
        </div>
        {/* {msg ? ( */}
        <SendMessage
          _class={msg ? "open" : ""}
          onClick={handleclosemsgClick}
          id={employeeDetail._id}
        />
      </>
    );
  }
};

export default EditEmployee;
