import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicLayout from "@root/layouts/PublicLayout/publicLayout";
import React from "react";

//pages
// import Home from '@root/pages/public/home';
import Login from "@root/pages/public/auth/index";
import Manage from "@root/pages/private/manage";
import {
  getLoadingStatusSelector,
  isAdminAuthorised,
} from "@root/actions/auth";
import { useSelector } from "react-redux";
import Toastify from "@root/components/ToastNotification";
import { enableToast } from "@root/actions/toast-notification";
import Feedback from "@root/pages/public/feedback";

export default function Routes() {
  const loadingStatus = useSelector(getLoadingStatusSelector);
  const toastEnableSelector = useSelector(enableToast);

  // const beamsClient = new PusherPushNotifications.Client({
  //   instanceId: "a7f7fb99-e843-4142-a047-7d8af9bff138"
  // });

  // beamsClient
  //   .start()
  //   //@ts-ignore
  //   .then((beamsClient) => beamsClient?.getDeviceId())
  //   .then((deviceId) =>
  //     console.log("Successfully registered with Beams. Device ID:", deviceId)
  //   )
  //   .then(() => beamsClient.addDeviceInterest("hello"))
  //   .then(() => beamsClient.getDeviceInterests())
  //   .then((interests) => console.log("Current interests:", interests))
  //   .catch(console.error);

  if (loadingStatus) {
    return (
      <div className="loading-container">
        Verifying the session. Please wait...
      </div>
    );
  } else {
    return (
      <div className="wrapper vh-100">
        <Switch>
          <Route
            path="/login"
            exact
            render={() => (
              <PublicLayout>
                <Login />
              </PublicLayout>
            )}
          />
          <Route path="/feedback/:id" exact render={() => <Feedback />} />

          <PrivateRoute path={"/"} component={Manage} />
        </Switch>
        {toastEnableSelector ? <Toastify /> : null}
      </div>
    );
  }
}
