import { useEffect, useState } from "react";
import React from "react";
import { MdLocationOn } from "react-icons/md";
import { AgGridReact } from "ag-grid-react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
// import Switch from '@mui/material/Switch';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { useUnmount, useUpdateEffect } from "react-use";
import {
  mapViewSelector,
  setTitle,
  toggleMapView,
  updateDatePickerStatus,
  updateMapviewSwitchStatus,
  showMapviewSelector,
} from "@actions/metadata";
import SearchIcon from "@material-ui/icons/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import TocIcon from "@mui/icons-material/Toc";
import FilterListIcon from "@material-ui/icons/FilterList";
// import SidePanel from "@components/SidePanel/SidePanel";
import CloseIcon from "@material-ui/icons/Close";
import FilterPanel from "@root/components/FilterPanel/FilterPanel";
// import ThermostatIcon from "@mui/icons-material/Thermostat";
import HomeIcon from "@mui/icons-material/Home";
import DomainIcon from "@material-ui/icons/Domain";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  toggleFilter,
  closeFilter,
  togglePanel,
  getAttendanceList,
  attendanceListSelector,
  payloadSelector,
  filterStatusSelector,
  countMatSelector,
  closePanel,
  filterDataSelector,
  setFilterData,
  totalRecordsSelector,
} from "@root/actions/attendance";
import { getAttendaceByID } from "@actions/getEmployee";
import EmployeeNameWithProfileRenderer from "@root/components/EmployeeNameWithProfileRenderer/EmployeeNameWithProfileRenderer";
import Attendancepancel from "@root/components/attendancepanel";
import Filter from "../../../components/filter/index";
import Spinner from "@root/components/Spinner/Spinner";
import MatrixLabel from "@root/components/matrixLabel/matrixLabel";
import { startDateStringSelector, setStartDate } from "@root/actions/header";
import Pagination from "@mui/material/Pagination";
import Pusher from "pusher-js";
import LargeSidePanel from "@root/components/LargeSidePanel/LargeSidePanel";
import MapView from "@root/components/MapView";
import "./index.scss";
import SwitchInput from "@root/components/SwitchInput";
import PersonCard from "@root/components/PersonCard";
import { fullScreenStatus } from "@root/actions/attendance";
import FullscreenButton from "@root/components/FullscreenButton";
import FullscreentTitle from "@root/components/FullscreentTitle";

const Attendance = (props: any) => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  // const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [toggleClockin, setToggleClockin] = useState(false);

  const payload = useSelector(payloadSelector);
  const attendanceList = useSelector(attendanceListSelector);
  const counterSelector = useSelector(countMatSelector);
  const [attMatrixCount, setAttMatrixCount] = useState(counterSelector);
  const [rowData, setRowData] = useState<any>();
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const startDate = useSelector(startDateStringSelector);
  const [filteringData, setFilteringData] = useState([]);
  const isFilterOpen = useSelector(filterStatusSelector);
  const [searchTerm, setSearchTerm] = useState("");
  const filterOption = useSelector(filterDataSelector);
  const totalRecords = useSelector(totalRecordsSelector);
  const [newClockin, setNewClockin] = useState({ user_id: "", checkin: {} });
  const [newClockout, setNewClockout] = useState({ user_id: "", checkin: {} });
  const [onlyClockin, setOnlyClockin] = useState<any[]>([]);
  const [dataView, setDataView] = useState<"table" | "grid" | "map">("table");
  const isFullScreen = useSelector(fullScreenStatus);
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const showMapviewSwitch = useSelector(showMapviewSelector);
  const isMapview = useSelector(mapViewSelector);

  const acc_id = localStorage.getItem("account_id");

  const pushr = JSON.parse(localStorage.getItem("pusher")!);
  let pusher: Pusher;
  const accChannelId = `acc-${acc_id}`;

  // useEffect(() => {
  //   if(pushr) {
  //     pusher = new Pusher(pushr.api_key, {
  //       cluster: pushr.cluster
  //     });

  //     // account specific channel
  //     const accChannel = pusher.subscribe(accChannelId);
  //     accChannel.bind("user-clocked-in", (data:any) => {
  //       // console.log(data);
  //       setNewClockin({user_id: data.checkin.user_id, checkin: data.checkin});
  //       setAttMatrixCount(data.counts);
  //     });

  //     accChannel.bind("user-clocked-out", (data:any) => {
  //       // console.log(data);
  //       setNewClockout({user_id: data.checkin.user_id, checkin: data.checkin});
  //       setAttMatrixCount(data.counts);
  //     });

  //     return () => {
  //       accChannel.unbind_all();
  //       accChannel.unsubscribe()
  //       if (pusher) pusher.disconnect();
  //     };
  //   }
  // }, []);

  useUpdateEffect(() => {
    setAttMatrixCount(counterSelector);
  }, [counterSelector]);

  useUpdateEffect(() => {
    clockinUpdateList(newClockin.user_id, newClockin.checkin);
  }, [newClockin]);

  useUpdateEffect(() => {
    clockoutUpdateList(newClockin.user_id, newClockout.checkin);
  }, [newClockout]);

  const clockinUpdateList = (id: string, clockin: any) => {
    const element = rowData?.find((el: any) => el._id === id);
    let obj: any;
    if (element) {
      if (element.checkin === null) {
        obj = {
          clocked_out_at: clockin.clocked_out_at,
          clocked_in_at: clockin.clocked_in_at,
          mode: clockin.mode,
          clocked_in_status: clockin.clocked_in_status,
        };
        if (clockin.temperature) obj["temperature"] = clockin.temperature;
      } else {
        obj = { ...element.checkin, clocked_out_at: clockin.clocked_out_at };
      }
      const newElement = { ...element, checkin: obj };
      const newList = rowData.map((el: any) =>
        el._id === id ? newElement : el
      );
      setRowData(newList);
    }
  };

  const clockoutUpdateList = (id: string, clockin: any) => {
    let element = rowData?.find((el: any) => el._id === id);
    if (element && element.checkin) {
      const obj: any = {
        clocked_out_at: clockin.clocked_out_at,
        clocked_in_at: element.checkin.clocked_in_at,
        mode: {
          clockin: element.checkin.mode.clockin,
          clockout: clockin.mode.clockout,
        },
        clocked_in_status: clockin.clocked_in_status,
      };

      const newElement = { ...element, checkin: obj };

      const newList = rowData.map((el: any) =>
        el._id === id ? newElement : el
      );
      setRowData(newList);
    }
  };

  const [empId, setEmpId] = useState("");
  const getRowClass = (params: any) => {
    if (params.data?._id === empId) {
      return "active";
    }
  };
  // AG grid stuff
  const coldefss = [
    {
      field: "first_name",
      headerName: "EMPLOYEE NAME",
      sortable: true,
      cellRendererFramework: EmployeeNameWithProfileRenderer,
    },
    { field: "description", headerName: "DESIGNATION" },
    {
      field: "checkin",
      headerName: "TEMPERATURE[F]",
      cellRendererFramework: (params: any) => {
        return (
          <div>
            {params.data?.checkin ? (
              params.data?.checkin.temperature ? (
                <p
                  className={`line-height1 mb-0 ${
                    params.data?.checkin.temperature.measure === "F"
                      ? params.data?.checkin.value > 99
                        ? "text-danger"
                        : "text-success"
                      : params.data?.checkin.value > 37.2
                      ? "text-danger"
                      : "text-success"
                  }`}
                >{`${params.data?.checkin.temperature.value}${"\u00b0"} ${
                  params.data?.checkin.temperature.measure
                }`}</p>
              ) : null
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      field: "checkin",
      headerName: "IN-TIME",
      sortable: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <div className="d-flex flex-column">
              <p className="line-height1 mb-2">
                {params.data?.checkin
                  ? getTimeFormat(params.data?.checkin.clocked_in_at)
                  : "--"}
              </p>
              {params.data?.checkin ? (
                params.data?.checkin.clocked_in_status === "late" ? (
                  <p className="text-danger line-height1 mb-0">
                    Late Clockedin
                  </p>
                ) : null
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      field: "checkin",
      headerName: "OUT-TIME",
      cellRendererFramework: (params: any) => {
        return (
          <p className="line-height1 mb-0">
            {params.data?.checkin && params.data?.checkin.clocked_out_at
              ? getTimeFormat(params.data?.checkin.clocked_out_at)
              : "--"}
          </p>
        );
      },
    },
    {
      field: "checkin",
      headerName: "MODE OF WORK",
      cellRendererFramework: (params: any) => {
        return params.data?.checkin ? params.data?.checkin.mode.clockin : "--";
      },
    },
    {
      field: "",
      headerName: "CURRENT STATUS",
      cellRendererFramework: (params: any) => {
        if (params.data?.checkin) {
          return params.data?.checkin.clocked_out_at ? (
            <>
              <div className="checkin-indicator">
                <ArrowRightAltIcon className="lable-icon-clockout" />
                <p className="mb-0">ClockedOut</p>
              </div>
            </>
          ) : (
            <div className=" checkin-indicator">
              <ArrowRightAltIcon className="lable-icon-clockin" />
              <p className="mb-0">ClockedIn</p>
            </div>
          );
        }
        return "--";
      },
    },
    {
      field: "",
      headerName: "ACTIONS",
      cellRendererFramework: (params: any) => {
        if (params.data?.checkin) {
          return (
            <button
              className="grid-action"
              onClick={() => {
                handleEmployeeViewclick(params.data?._id);
              }}
            >
              View
            </button>
          );
        }
        return "--";
      },

      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <span className="pr-4">Actions</span>
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];
  const [colDefs, setColDefs] = useState(coldefss);

  useEffect(() => {
    dispatch(setTitle("Employees Attendance"));
    dispatch(updateDatePickerStatus(true));
    dispatch(updateMapviewSwitchStatus(true));
    dispatch(closePanel(false));
    dispatch(closeFilter(false));
    const today = new Date();
    dispatch(setStartDate(today.toString()));
  }, []);

  useEffect(() => {
    // console.log(startDate);
    dispatch(
      getAttendanceList({
        from_date: startDate,
        to_date: startDate,
        ...payload,
        page_index: pageIndex,
        page_size: pageSize,
      })
    );
  }, [startDate, pageSize, pageIndex]);

  useUpdateEffect(() => {
    setFilteringData(props.setData);
  });

  useEffect(() => {
    if (empId) dispatch(getAttendaceByID({ _id: empId, _date: startDate }));
  }, [empId, startDate]);

  useUnmount(() => {
    dispatch(updateDatePickerStatus(false));
    dispatch(updateMapviewSwitchStatus(false));
    dispatch(toggleMapView(false));
    if (pusher) pusher.unsubscribe(accChannelId);
    dispatch(closePanel(false));
  });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const processCells = (params: any) => {
    if (params.accumulatedRowIndex === 0) {
      return params.node.data.first_name + " " + params.node.data.last_name;
    } else if (params.accumulatedRowIndex === 2) {
      const temp = params.node.data.checkin?.temperature;
      if (temp === undefined) {
        return "--";
      }

      return temp ? `${temp?.value} ${temp?.measure}` : "--";
    } else if (params.accumulatedRowIndex === 3) {
      const inTime = params.node.data.checkin?.clocked_in_at;
      if (inTime === null) {
        return "--";
      }
      return inTime ? `${getTimeFormat(inTime)}` : "--";
    } else if (params.accumulatedRowIndex === 4) {
      const outTime = params.node.data.checkin?.clocked_out_at;
      if (outTime === null) {
        return "--";
      }
      return outTime ? `${getTimeFormat(outTime)}` : "--";
    } else if (params.accumulatedRowIndex === 5) {
      return params.node.data.checkin?.mode;
    } else return params.value;
  };
  const getParams = () => {
    return {
      // @ts-ignore
      processCellCallback: (params) => processCells(params),
    };
  };
  const onBtnExport = () => {
    // @ts-ignore
    gridApi.exportDataAsCsv(getParams());
  };

  const onTableFUllScreen = () => {
    setTableFullScreen(!tableFullScreen);
  };

  useUpdateEffect(() => {
    setRowData(attendanceList);
    setColDefs(coldefss);
    const only_checkins = attendanceList.filter((el) => el.checkin !== null);
    setOnlyClockin(only_checkins);
  }, [attendanceList, tableFullScreen]);

  const handleEmployeeViewclick = (id: string) => {
    setEmpId(id);
    setTableFullScreen(false);
    dispatch(togglePanel());
  };
  const getTimeFormat = (timeString: string) => {
    const time = new Date(timeString);

    const hh = time.getUTCHours();
    const mm = time.getUTCMinutes();
    const hours = hh > 12 ? hh - 12 : hh;
    const minutes = mm;
    return `${hours > 9 ? hours : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hh > 11 ? "pm" : "am"}`;
  };

  const handleIconChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  useUpdateEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getAttendanceList({
          from_date: startDate,
          to_date: startDate,
          ...payload,
          search: searchTerm,
        })
      );
    };

    const timer = setTimeout(() => {
      if (searchTerm.length >= 3 || searchTerm.length === 0) {
        fetchData();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleValue = (event: any) => {
    if (event.key === "Enter") {
      dispatch(
        getAttendanceList({
          from_date: startDate,
          to_date: startDate,
          ...payload,
          page_index: pageIndex,
          page_size: pageSize,
          search: searchTerm,
        })
      );
    }
  };

  const onToggleClockin = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    setToggleClockin(!toggleClockin);
    const filter = { ...filterOption, only_clockins: event.target.checked };
    dispatch(setFilterData(filter));
    dispatch(
      getAttendanceList({
        from_date: startDate,
        to_date: startDate,
        ...payload,
        page_index: pageIndex,
        page_size: pageSize,
        ...filter,
      })
    );
  };

  const handleCloseIcon = () => {
    setSearchTerm("");
    dispatch(
      getAttendanceList({
        from_date: startDate,
        to_date: startDate,
        ...payload,
        page_index: pageIndex,
        page_size: pageSize,
      })
    );
  };
  // useUnmount(() => {
  //   dispatch(closePanel(false));
  // });

  const onPageSizeChanged = () => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      ?.value;
    //@ts-ignore
    gridApi?.paginationSetPageSize(Number(value));
    setPageSize(Number(value));
  };

  const onPageNum = (e: any, num: any) => {
    setPageIndex(num - 1);
  };

  // const onMapview = (status: boolean) => {
  //   setMapView(status);
  // }

  return (
    <>
      <div className="h-100 pos-relative p-3">
        <>
          <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
            <div className="d-flex align-items-center">
              <div className="input-group search-field">
                <input
                  type="text"
                  className="form-control"
                  id="filter-text-box"
                  onKeyPress={handleValue}
                  value={searchTerm}
                  onChange={handleIconChange}
                  // onInput={handleInput}
                  placeholder="Search by employee Name"
                />

                <span className="input-group-text">
                  {searchTerm ? (
                    <CloseIcon onClick={handleCloseIcon} />
                  ) : (
                    <SearchIcon />
                  )}
                </span>
              </div>

              <div className="d-flex">
                <Tooltip
                  title={"Filters"}
                  placement="bottom"
                  color="secondary"
                  arrow
                >
                  <button
                    onClick={() => dispatch(toggleFilter())}
                    className={`filter-btn ml-3 ${
                      isFilterOpen ? "active" : ""
                    }`}
                  >
                    <FilterListIcon />
                  </button>
                </Tooltip>

                <div className="pl-3">
                  <SwitchInput
                    hidden
                    label="Not Clockedin"
                    color="secondary"
                    checked={toggleClockin}
                    onChange={onToggleClockin}
                  />
                </div>
              </div>
            </div>
            {counterSelector ? (
              <div className="matrix-container flex-grow-1 d-flex justify-content-end">
                <MatrixLabel
                  label={"On-time"}
                  count={`${attMatrixCount.on_time}`}
                  iClass={"in"}
                >
                  <ArrowRightAltIcon />
                </MatrixLabel>
                <MatrixLabel
                  label={"Late"}
                  count={`${attMatrixCount.late}`}
                  iClass={"out"}
                >
                  <ArrowRightAltIcon />
                </MatrixLabel>
                {/* <MatrixLabel
                  label={"Abnormal"}
                  count={`${attMatrixCount.not_normal}`}
                  iClass={""}
                >
                  <ThermostatIcon />
                </MatrixLabel> */}
                <MatrixLabel
                  label={"WFO"}
                  count={`${attMatrixCount.wfo}`}
                  iClass={""}
                >
                  <DomainIcon />
                </MatrixLabel>
                <MatrixLabel
                  label={"WFH"}
                  count={`${attMatrixCount.wfh}`}
                  iClass={""}
                >
                  <HomeIcon />
                </MatrixLabel>
              </div>
            ) : null}

            <div className="d-inline-flex">
              <div className="export-border pe-0 ps-0 flex pt-2 pb-2">
                <Tooltip
                  title={"Table View"}
                  placement="bottom"
                  color="secondary"
                  arrow
                >
                  <button
                    onClick={() => setDataView("table")}
                    className={`viewToggle-btn ${
                      dataView === "table" ? "active" : ""
                    }`}
                  >
                    <TocIcon />
                  </button>
                </Tooltip>
                <Tooltip
                  title={"Grid View"}
                  placement="bottom"
                  color="secondary"
                  arrow
                >
                  <button
                    onClick={() => setDataView("grid")}
                    className={`viewToggle-btn m-0 ${
                      dataView === "grid" ? "active" : ""
                    }`}
                  >
                    <GridViewIcon />
                  </button>
                </Tooltip>
                {showMapviewSwitch ? (
                  <Tooltip
                    title={"Map View"}
                    placement="bottom"
                    color="secondary"
                    arrow
                  >
                    <button
                      onClick={() => setDataView("map")}
                      className={`viewToggle-btn m-0 ${
                        dataView === "map" ? "active" : ""
                      }`}
                    >
                      <MdLocationOn className="text-xl" />
                    </button>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>
          <div className="main-panel d-flex flex-column">
            {dataView === "map" ? (
              <div
                className={`map-container ${isFullScreen ? "full-scrn" : ""}`}
              >
                <div className="map">
                  <div className="h-100">
                    <MapView mapPins={onlyClockin} />
                    {onlyClockin.length > 0 && (
                      <div
                        style={{ borderLeft: 0, borderRight: 0 }}
                        className="d-flex justify-content-end pagination-box bg-white p-2"
                      >
                        <Pagination
                          count={Math.ceil(totalRecords / pageSize)}
                          showFirstButton
                          showLastButton
                          onChange={onPageNum}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <FilterPanel>
                  <Filter setData={setFilteringData} />
                </FilterPanel>

                <div
                  className={`flex-grow-1 table-container ${
                    tableFullScreen ? "full-scrn" : ""
                  }`}
                  // style={{ height: "100%", width: "100%" }}
                >
                  <FullscreentTitle tableFullScreen={tableFullScreen} />

                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: "95%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {dataView === "table" && (
                      <AgGridReact
                        frameworkComponents={{
                          employeeNameWithProfileRenderer:
                            EmployeeNameWithProfileRenderer,
                        }}
                        reactUi={true}
                        rowClass={"minin-class"}
                        getRowClass={getRowClass}
                        pagination={true}
                        rowData={rowData}
                        rowHeight={100}
                        rowSelection={"single"}
                        columnDefs={colDefs}
                        onGridReady={onGridReady}
                        paginationPageSize={pageSize}
                        suppressLoadingOverlay={true}
                        suppressPaginationPanel={true}
                        // onPaginationChanged={()=> alert('changed')}
                      ></AgGridReact>
                    )}

                    {dataView === "grid" && (
                      <div className="row">
                        {attendanceList.length > 0 &&
                          attendanceList.map((employee) => (
                            <PersonCard
                              key={employee._id}
                              type={"employee"}
                              person={employee}
                              action={() =>
                                handleEmployeeViewclick(employee._id)
                              }
                            />
                          ))}
                      </div>
                    )}

                    <div className="d-flex justify-content-between pagination-box bg-white p-2">
                      <select
                        onChange={onPageSizeChanged}
                        id="page-size"
                        className="pagination-dropdown"
                        value={pageSize}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>

                      <Pagination
                        count={Math.ceil(totalRecords / pageSize)}
                        showFirstButton
                        showLastButton
                        onChange={onPageNum}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <LargeSidePanel>
            <Attendancepancel onClose={() => setEmpId("")} />
          </LargeSidePanel>

          {!rowData ? (
            <div className="loader-container">
              <Spinner />
            </div>
          ) : null}
        </>
      </div>
    </>
  );
};

export default Attendance;
