import { logoutSuccess } from "@root/actions/auth";
import { showNotificationError } from "@root/actions/toast-notification";
import axios from "axios";
import { store } from "../store/index";

export default function setAuthorizationToken(token: string) {
  if (token) {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
  }
}

export function setAxiosInterceptors() {
  axios.interceptors.response.use(undefined, (error) => {
    const { status } = error.response;
    if (status === 400 || status === 500) {
      store.dispatch(logoutSuccess());
      store.dispatch(
        showNotificationError({
          type: "error",
          message: "Server Error : Something failed.",
        })
      );
    }
    return Promise.reject(error);
  });
}
