import { useEffect, useState } from "react";
import React from "react";

import { AgGridReact } from "ag-grid-react";
import CloseIcon from "@material-ui/icons/Close";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import GridViewIcon from "@mui/icons-material/GridView";
import TocIcon from "@mui/icons-material/Toc";
import {
  setEmpListFilterValue,
  setTitle,
  updateEmpListFilterStatus,
  updatesendLinkStatus,
} from "@actions/metadata";
import {
  payloadSelector,
  getEmployee,
  empListSelector,
  matrixCountSelector,
  totalRecordsSelector,
  updateEmpMatrix,
} from "@root/actions/employee-list";

import { useUnmount, useUpdateEffect } from "react-use";

import SearchIcon from "@material-ui/icons/Search";
import { empListfilterValueSelector } from "../../../actions/metadata/index";
import EmployeeNameWithProfileRenderer from "@root/components/EmployeeNameWithProfileRenderer/EmployeeNameWithProfileRenderer";

import { hasUpdatedSelector } from "@actions/editEmployee";

import {
  setEmployeeId,
  getSelectedEmployeeId,
} from "@root/actions/editEmployee";
import EditEmployee from "@root/components/Editemployee";
import "./index.scss";

import SidePanel from "@root/components/SidePanel/SidePanel";
import { closeFilter, closePanel, togglePanel } from "@root/actions/attendance";

import Spinner from "@root/components/Spinner/Spinner";
import MatrixLabel from "@root/components/matrixLabel/matrixLabel";
import Pagination from "@mui/material/Pagination";
import Pusher from "pusher-js";
import { currentUser } from "@root/actions/header";
// import { accTerminated } from "@root/actions/auth";
import "./index.scss";
import EmployeeCard from "@root/components/EmployeeCard";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenButton from "@root/components/FullscreenButton";
import FullscreentTitle from "@root/components/FullscreentTitle";

function EmployeeList(_props: any) {
  const dispatch = useDispatch();

  const empId = useSelector(getSelectedEmployeeId);
  const employeeList = useSelector(empListSelector);
  const payload = useSelector(payloadSelector);
  const empListfilterValue = useSelector(empListfilterValueSelector);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState<any>();
  const hasEmployeeUpdated = useSelector(hasUpdatedSelector);
  const rowClass = "minin-class";
  const [searchTerm, setSearchTerm] = useState("");
  const empMatrix = useSelector(matrixCountSelector);
  const [matrixCount, setMatrixCount] = useState(empMatrix);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const totalRecords = useSelector(totalRecordsSelector);
  const meUser = useSelector(currentUser);
  const roleSelector = meUser.role;
  const [tableGridView, settableGridView] = useState<"table" | "grid">("table");
  const [tableFullScreen, setTableFullScreen] = useState(false);

  const [newStatus, setNewStatus] = useState({
    from_status: "",
    to_status: "",
    user_id: "",
  });

  const acc_id = localStorage.getItem("account_id");

  const pushr = JSON.parse(localStorage.getItem("pusher")!);
  let pusher: Pusher;
  const chanelId = `acc-${acc_id}`;

  const handleEmployeeViewclick = (id: string) => {
    dispatch(setEmployeeId({ id }));
    setTableFullScreen(false);
    dispatch(togglePanel());
  };

  const toggleTableGridView = (view: "table" | "grid") => {
    settableGridView(view);
  };

  const colDef = [
    {
      field: "first_name",
      headerName: "EMPLOYEE NAME",
      sortable: true,
      cellRendererFramework: EmployeeNameWithProfileRenderer,
    },

    { field: "description", headerName: "DESIGNATION" },
    {
      field: "phone",
      headerName: "PHONE NUMBER",
      cellRendererFramework: (params: any) => {
        return (
          <p>
            +
            {`${params.data.phone.slice(0, 2)} ${params.data.phone.slice(
              2,
              7
            )} ${params.data.phone.slice(7)}`}
          </p>
        );
      },
    },
    { field: "email", headerName: "EMAIL" },
    {
      field: "status",
      headerName: "STATUS",
      cellRendererFramework: (params: any) => {
        return (
          <p className={`emp-status-indicator ${params.data.status}`}>
            {params.data.status === "approved"
              ? "Active"
              : params.data.status === "rejected"
              ? "Inactive"
              : params.data.status === "draft"
              ? "Draft"
              : "New"}
          </p>
        );
      },
    },
    {
      field: "",
      headerName: "ACTIONS",
      cellRendererFramework: (params: any) => {
        return (
          <button
            className="grid-action"
            onClick={() => {
              handleEmployeeViewclick(params.data?._id);
            }}
          >
            View
          </button>
        );
      },

      headerComponentFramework: (props: any) => {
        return (
          <div className="flex items-center space-x-4">
            <span className="pr-4">Actions</span>
            <Tooltip
              title={"Export to Excel"}
              placement="bottom"
              color="secondary"
              arrow
            >
              <button className="export-btn" onClick={() => onBtnExport()}>
                <img
                  src="../../../../assests/images/excel-file.png"
                  alt="icon"
                  className="m-0"
                />
              </button>
            </Tooltip>
            <FullscreenButton
              tableFullScreen={tableFullScreen}
              onTableFUllScreen={onTableFUllScreen}
            />
          </div>
        );
      },
    },
  ];
  const [colDefs, setColDefs] = useState(colDef);

  const onTableFUllScreen = () => {
    setTableFullScreen(!tableFullScreen);
  };

  useUpdateEffect(() => {
    setMatrixCount(empMatrix);
  }, [empMatrix]);

  useEffect(() => {
    if (pushr) {
      pusher = new Pusher(pushr.api_key, {
        cluster: pushr.cluster,
      });
      const channel = pusher.subscribe(chanelId);
      channel.bind("user-status-changed", (data: any) => {
        const obj = {
          from_status: data.from_status,
          to_status: data.to_status,
          user_id: data.user._id,
        };
        setNewStatus(obj);
        // dispatch(updateEmpMatrix(data.counts));
        setMatrixCount(data.counts);
        // matrixCount = {...data.counts};
        console.log();
      });
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
        if (pusher) pusher.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    getUpdatedList(newStatus.user_id, newStatus.to_status);
  }, [newStatus]);

  const getUpdatedList = (_id: string, status: string) => {
    const newList = rowData?.map((el: any) => {
      if (el._id === _id) {
        return { ...el, status };
      } else {
        return el;
      }
    });
    setRowData(newList);
  };

  useEffect(() => {
    const today = new Date();
    dispatch(setTitle("Mobile App Requests"));
    dispatch(updateEmpListFilterStatus(true));
    // dispatch(updateDatePickerStatus(false));
    dispatch(updatesendLinkStatus(true));

    dispatch(closePanel(false));
    dispatch(closeFilter(false));
  }, []);

  useEffect(() => {
    dispatch(
      getEmployee({
        ...payload,
        pageSize,
        pageIndex,
        status: empListfilterValue,
      })
    );
  }, [pageSize, pageIndex]);

  useUpdateEffect(() => {
    setRowData(employeeList);
  }, [employeeList, empId]);

  useEffect(() => {
    setColDefs(colDef);
    setRowData(employeeList);
  }, [tableFullScreen]);

  useUpdateEffect(() => {
    // onBtShowLoading();
    // @ts-ignore
    dispatch(getEmployee({ ...payload, status: empListfilterValue }));
  }, [empListfilterValue]);

  useUnmount(() => {
    dispatch(updatesendLinkStatus(false));
    dispatch(updateEmpListFilterStatus(false));
    dispatch(setEmpListFilterValue("approved"));
    // if (pusher) pusher.unsubscribe(chanelId);
    // if (pusher) pusher.disconnect();
  });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const processCells = (params: any) => {
    if (params.accumulatedRowIndex === 0) {
      return params.node.data.first_name + " " + params.node.data.last_name;
    } else return params.value;
  };
  const getParams = () => {
    return {
      // @ts-ignore
      processCellCallback: (params) => processCells(params),
    };
  };
  const onBtnExport = () => {
    // @ts-ignore
    gridApi.exportDataAsCsv(getParams());
  };

  useUpdateEffect(() => {
    if (hasEmployeeUpdated) {
      dispatch(getEmployee({ ...payload, status: "approved" }));
    }
  }, [hasEmployeeUpdated]);
  // console.log(rowData)

  const getRowClass = (params: any) => {
    if (params.data._id === empId) {
      return "active";
    }
  };
  const handleIconChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getEmployee({ ...payload, status: "approved", search: searchTerm })
      );
    };

    const timer = setTimeout(() => {
      if (searchTerm.length >= 3 || searchTerm.length === 0) {
        fetchData();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleValue = (event: any) => {
    if (event.key === "Enter") {
      dispatch(getEmployee({ ...payload, status: "all", search: searchTerm }));
    }
  };

  const onPageSizeChanged = () => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      ?.value;
    //@ts-ignore
    gridApi?.paginationSetPageSize(Number(value));
    setPageSize(Number(value));
  };

  const onPageNum = (e: any, num: any) => {
    setPageIndex(num - 1);
  };

  const handleCloseIcon = () => {
    setSearchTerm("");
    dispatch(getEmployee({ ...payload, status: "all" }));
  };
  useUnmount(() => {
    dispatch(closePanel(false));
  });

  return (
    <>
      <div className="data-table h-100 p-3">
        <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
          <div className="input-group search-field">
            <input
              type="text"
              className="form-control"
              id="filter-text-box"
              onKeyPress={handleValue}
              value={searchTerm}
              onChange={handleIconChange}
              // onInput={handleInput}
              placeholder="Search by employee name"
            />
            <span className="input-group-text">
              {searchTerm ? (
                <CloseIcon onClick={handleCloseIcon} />
              ) : (
                <SearchIcon />
              )}
            </span>
            &nbsp;&nbsp;
          </div>
          {matrixCount && roleSelector && roleSelector !== "employee" ? (
            <div className="matrix-container flex-grow-1 d-flex justify-content-end">
              <MatrixLabel
                label={"New"}
                count={`${matrixCount.in_review}`}
                iClass={""}
              >
                <img
                  src="../../../../../assests/images/Emp_New_Icon.svg"
                  alt="icon"
                />
              </MatrixLabel>
              <MatrixLabel
                label={"Active"}
                count={`${matrixCount.approved}`}
                iClass={""}
              >
                <img
                  src="../../../../../assests/images/Emp_Approved_Icon.svg"
                  alt="icon"
                />
              </MatrixLabel>
              <MatrixLabel
                label={"Inactive"}
                count={`${matrixCount.rejected}`}
                iClass={""}
              >
                <img
                  src="../../../../../assests/images/Emp_Rejected_icon.svg"
                  alt="icon"
                />
              </MatrixLabel>
              <MatrixLabel
                label={"Total"}
                count={`${matrixCount.all}`}
                iClass={""}
              >
                <img
                  src="../../../../../assests/images/Emp_Total_icon.svg"
                  alt="icon"
                />
              </MatrixLabel>
            </div>
          ) : null}

          <div className="d-inline-flex">
            <div className="export-border pe-0 ps-0 pt-2 pb-2">
              <Tooltip
                title={"Table View"}
                placement="bottom"
                color="secondary"
                arrow
              >
                <button
                  onClick={() => toggleTableGridView("table")}
                  className={`viewToggle-btn ${
                    tableGridView === "table" ? "active" : ""
                  }`}
                >
                  <TocIcon />
                </button>
              </Tooltip>
              <Tooltip
                title={"Grid View"}
                placement="bottom"
                color="secondary"
                arrow
              >
                <button
                  onClick={() => toggleTableGridView("grid")}
                  className={`viewToggle-btn m-0 ${
                    tableGridView === "grid" ? "active" : ""
                  }`}
                >
                  <GridViewIcon />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        {rowData?.length > 0 && (
          <div className="main-panel d-flex flex-column">
            <div
              className={`flex-grow-1 table-container ${
                tableFullScreen ? "full-scrn" : ""
              }`}
            >
              <FullscreentTitle tableFullScreen={tableFullScreen} />

              <div
                className="ag-theme-alpine"
                style={{
                  height: "calc(100% - 50px)",
                  width: "100%",
                  position: "relative",
                }}
              >
                {tableGridView === "table" && (
                  <AgGridReact
                    frameworkComponents={{
                      employeeNameWithProfileRenderer:
                        EmployeeNameWithProfileRenderer,
                    }}
                    reactUi={true}
                    rowClass={rowClass}
                    getRowClass={getRowClass}
                    pagination={true}
                    rowData={rowData}
                    rowHeight={100}
                    rowSelection={"single"}
                    defaultColDef={{
                      flex: 1,
                    }}
                    columnDefs={colDefs}
                    onGridReady={onGridReady}
                    paginationPageSize={pageSize}
                    suppressPaginationPanel={true}
                  ></AgGridReact>
                )}

                {tableGridView === "grid" && (
                  <div className="row">
                    {employeeList.length > 0 &&
                      employeeList.map((emp) => (
                        <EmployeeCard
                          key={emp._id}
                          employee={emp}
                          action={() => handleEmployeeViewclick(emp._id)}
                        />
                      ))}
                  </div>
                )}
                <div className="d-flex justify-content-between pagination-box bg-white p-2">
                  <select
                    onChange={onPageSizeChanged}
                    id="page-size"
                    className="pagination-dropdown"
                    value={pageSize}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>

                  <Pagination
                    count={Math.ceil(totalRecords / pageSize)}
                    showFirstButton
                    showLastButton
                    onChange={onPageNum}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <SidePanel>
          <EditEmployee />
        </SidePanel>
      </div>
      {!rowData ? (
        <div className="loader-container">
          <Spinner />
        </div>
      ) : null}
    </>
  );
}

export default EmployeeList;
