import axios from "axios";
// {{base_url}}/accounts/regenerate-tablet-pin
export const generatePin = async () => {
  return await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL}/accounts/regenerate-tablet-pin`,
    validateStatus: (status) => {
      return status >= 200 && status <= 404;
    },
  });
};
