import "./index.scss";
import React from "react";

const EmployeeNameWithProfileRenderer = (props: any, hasRole?: boolean) => {
  return (
    <>
      <div className="employee-profile">
        <div className="profile-picture">
          <img
            src={
              props.data?.image !== undefined
                ? props.data?.image
                : "../../../../assests/images/user.png"
            }
            alt="profile"
          />
        </div>
        <div className="employee-data">
          <p className="mb-0 d-flex flex-column">
            {props.data?.first_name + " " + props.data?.last_name}
            <span className="role">{hasRole ? props.data?.role : null}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default EmployeeNameWithProfileRenderer;
